export const CustomTracerEvents = {
  loginApi: 'login_api',
  loginError: 'login_error',
  enrollCohort: `enroll_cohort`, // cohort change from topbar or from profile
  pageCrash: 'page_crash',
  userVisitUtm: 'userVisitUtm',
  loginUtm: 'loginUtm',
  registerUtm: 'registerUtm',
};

export const UserActivityTracerEvents = {
  aits: 'aits',
  aitsAnalysis: 'aits_analysis',
  aitsPdfDownload: 'aits_pdf_download',
  byjusTestSeries: 'byjus_test_series',
  mockTest: 'mock_test',
  mockTestAnalysis: 'mock_test_analysis',
  joinNow: 'join_now',
  minimize: 'minimize_onboarding',
  viewOnboardingDetails: 'view_onbaording_details',
  logout: 'logout',
};
