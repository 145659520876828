import { v4 as uuidv4 } from 'uuid';
import { setCookie, getCookie } from '../utils/cookie';
import { sessionExpiryInHours } from '../constants/global';

// create cookie if not there
const checkAndCreateCookie = () => {
  return (
    !getCookie('lp2Session') &&
    setCookie('lp2Session', uuidv4(), sessionExpiryInHours)
  );
};


/*
  On every login will create new session ID,
  On every one hour will update with new session ID,
  On every logout will delete cookie
*/
const sessionIdHandler = () => {
  return checkAndCreateCookie(); // creating a cookie with hour expiry
};

const deleteSessionIdHandler = () => {
  setCookie('lp2Session', '', -1); // expiring cookie by setting to past hour from now i.e. -1
};

const getSessionCookie = () => {
  checkAndCreateCookie();
  return getCookie('lp2Session');
}

const sessionId = () => getSessionCookie(); // Always refer this function to get lp2Session id insted of directly refering to cookie

export {
  checkAndCreateCookie,
  sessionIdHandler,
  deleteSessionIdHandler,
  sessionId,
};
