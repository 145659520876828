import { ComponentType, lazy } from 'react';

const lazyWithRetry = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentImport: () => Promise<{ default: ComponentType<any> }>,
  assetName: string,
) =>
  lazy(async () => {
    const pageKey = `IsPageAutoRefreshed-${assetName}`;
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      sessionStorage.getItem(pageKey) || 'false',
    );
    try {
      const component = await componentImport();
      if (pageHasAlreadyBeenForceRefreshed) {
        // try removing the entry if the asset is loaded successfully
        sessionStorage.removeItem(pageKey);
      }
      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        sessionStorage.setItem(pageKey, 'true');
        return window.location.reload();
      }
      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // raise the custom error, to make sure we know LazyRetry was attempted
      console.error(error);
      throw new Error(`LazyRetry has failed for ${assetName}`, {
        cause: error,
      });
    }
  });

export default lazyWithRetry;
