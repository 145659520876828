import React, { useEffect, useState } from 'react';
import {
  QuestionPageComponent,
  Layout,
} from '@toppr-engg/instalearn-components/lib/esm';
import '@toppr-engg/instalearn-components/lib/esm/index.css';
import jwt from 'jsonwebtoken';
import AppConfig from 'config/AppConfig';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UserState } from 'states/UserState';
import { getGradeNumber } from 'helpers/getGradeNumber';
import { CurrentCohortState } from 'states/CohortState';
import AskTutorPage from './AskTutorPage';
import {
  docQueryImage,
  docQueryText,
  searchCount,
  searchKey,
} from 'states/SearchState';
import { DoesSelfServeWidgetNeedDifferentStyle } from 'states/SelfServeState';
import { OngoingTutorSession } from 'hooks/AskDoubts/UseAskTutorPopup';
import { useHistory, useLocation } from 'react-router-dom';

function QuestionPage() {
  const [showActivationCta, setShowActivationCta] = useState(false);
  const [showVideoBanner, setShowVideoBanner] = useState(false);
  const [subjectId, setSubjectId] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const { pathname, search } = location;
  const query = new URLSearchParams(search);
  const queryParam = query.get('searchkey');

  const baseUrl = pathname?.split('/')[1];

  const [, setSearchKey] = useRecoilState(searchKey);
  const [, setDocQueryImage] = useRecoilState(docQueryImage);
  const [, setDocQueryText] = useRecoilState(docQueryText);
  const [, setSearchCount] = useRecoilState(searchCount);
  const [
    selfServeWidgetNeedDifferentStyle,
    setSelfServeWidgetNeedDifferentStyle,
  ] = useRecoilState(DoesSelfServeWidgetNeedDifferentStyle);
  const [ongoingTutorSession] = useRecoilState(OngoingTutorSession);
  const userState = useRecoilValue(UserState);
  const currentCohortState = useRecoilValue(CurrentCohortState);
  const docSecret = '7HpCPOgGUZHgxXlKui9eN7aUoW5wJjvM93viUBCMsU';

  useEffect(() => {
    setDocQueryText('');
    setDocQueryImage('');

    if (!queryParam?.trim()) {
      history.push(`/${baseUrl}`);
    }
  }, []);

  useEffect(() => {
    if (!ongoingTutorSession) setSelfServeWidgetNeedDifferentStyle(true);
    else setSelfServeWidgetNeedDifferentStyle(false);

    return () => {
      setSelfServeWidgetNeedDifferentStyle(false);
    };
  }, [ongoingTutorSession, selfServeWidgetNeedDifferentStyle]);

  const tokenPayload = {
    user_profile_id: userState?.id,
    platform: 'web',
    name: userState?.full_name,
    klass: getGradeNumber(currentCohortState?.cohort?.grade),
    cohort_id: currentCohortState?.cohort?.id,
    client_name: 'learn_portal',
  };

  const apiBaseUrl = AppConfig.qnaBaseUrl;
  const origin = AppConfig.baseUrl;
  const userId = userState.id;
  const authKey = AppConfig.qnaToken?.split(' ')[1];
  const docToken = jwt.sign(tokenPayload, docSecret, {
    algorithm: 'HS256',
    noTimestamp: true,
  });
  const docApiBaseUrl = AppConfig.instalearnApiBaseUrl;
  const docClientId = AppConfig.instalearnClientId;
  const searchType = 'text';
  const cdnUrl = AppConfig.instalearnCdnUrl;

  const getSubjectRelatedToSearch = data => {
    setSubjectId(data.subjectId);
  };

  const handleQuestionClick = data => {
    const searchedText = data?.hrefUrl;
    if (searchedText) {
      setDocQueryText('');
      setDocQueryImage('');
      setSearchKey(searchedText);
      history.push(`/${baseUrl}/question?searchkey=${searchedText}`);
      document.querySelector('.instalearnContainer').scrollTop = 0;
    } else {
      history.push(`/${baseUrl}/question?searchkey=-1`);
    }
  };

  const handleActivationCtaClick = data => {
    if (data?.imageData) {
      setDocQueryImage(data?.imageData);
    } else {
      setDocQueryText(data?.searchText);
    }
  };

  const handleBackClick = () => {
    setDocQueryText('');
    setDocQueryImage('');
    history.goBack();
  };

  const searchKeyValue = useRecoilValue(searchKey);

  return (
    <div className="instalearnContainer">
      <QuestionPageComponent
        apiBaseUrl={docApiBaseUrl}
        origin={origin}
        userId={userId}
        authKey={`Bearer ${authKey}`}
        questionSlug={queryParam}
        searchType={searchType}
        handleQuestionClick={handleQuestionClick}
        getSubjectRelatedToSearch={getSubjectRelatedToSearch}
        docApiBaseUrl={docApiBaseUrl}
        docToken={docToken}
        docClientId={docClientId}
        showActivationCta={showActivationCta}
        handleActivationCtaClick={handleActivationCtaClick}
        handleBackClick={handleBackClick}
        isTodFeature={true}
        cdnUrl={cdnUrl}
      />
      <Layout layoutClassName="layoutDoc">
        <AskTutorPage
          showSessionList={false}
          setShowActivationCta={setShowActivationCta}
          setShowVideoBanner={setShowVideoBanner}
          subjectId={subjectId}
        />
      </Layout>
    </div>
  );
}

export default QuestionPage;
