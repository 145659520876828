/* eslint-disable check-file/filename-naming-convention */
import { persistToLocalStorage } from 'helpers/persistence';
import { IWidgetInfo } from 'interfaces';
import { atom } from 'recoil';

const SduiState = atom<IWidgetInfo | null>({
  key: 'SduiRes',
  default: null,
  effects_UNSTABLE: [persistToLocalStorage],
});

export default SduiState;
