// Expected snake_case olap properties from prod so disabling camel case for this file
/* eslint-disable camelcase */
import { APICore } from 'helpers/api/apiCore';
import moment from 'moment-timezone';
import uaParserJs from 'ua-parser-js';
import { getSanitisedUrlParams } from 'utils/Sanitise';
import { IUserState } from 'states/UserState';
import { CurrentCohort } from 'helpers/eligibility';
import {
  IBatch,
  ISlots,
} from 'modules/byjusclasses/selfserve/slotAvailability/types/Index';
import { GradeEligibilityResponse } from 'helpers/api/dashboard/gradeSwitchEligibility';
import appConfig from '../../config/AppConfig';
import signature from './signature';
import { sessionId } from '../../helpers/sessionId';

export interface OlapDataType {
  m_comments?: string;
  m_category?: string;
  m_priority?: string;
  u_event_id?: number;
  m_desc?: string;
  kingdom?: string;
  phylum?: string;
  counter?: string;
  tribe?: string | number | ISlots[];
  family?: string;
  genus?:
    | string
    | {
        premiumAccountId: string | undefined;
        record_timeslot_preference: IBatch[];
      };
  species?: string;
  record?: number;
  form?: string;
  locale?: string;
  event_id?: string;
  ref_id?: string;
  param1?: string;
  param2?: string;
  param3?: string | GradeEligibilityResponse[];
  param4?: string | number;
  param5?:
    | string
    | {
        [key: string]: number;
      };
  param6?: string;
  value3?: string;
  value5?: string;
  premium_id?: number | string;
}
interface IOptionalData {
  userId: number;
  cohertId: number;
  userVariety: string;
}

type Userdata = IUserState | Record<string, unknown>;
type CurrentCohortData = CurrentCohort | Record<string, unknown>;

const api = new APICore();
const getDeviceName = (userAgentData: uaParserJs.IResult) => {
  const source = JSON.parse(
    localStorage.getItem('LoginSource') as string,
  ) as Record<string, string>;
  if (source?.source === 'd4-app') {
    return 'learn_station';
  }
  return userAgentData?.device?.type ? userAgentData.device.type : 'desktop';
};
const olap = (data: OlapDataType, optionalData?: IOptionalData) => {
  try {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = getSanitisedUrlParams(
      Object.fromEntries(urlSearchParams.entries()),
    );

    if (window.location.search === '') {
      const queryString = localStorage?.getItem('queryString');
      const querySubString = queryString?.substring(1);
      const queryArray = querySubString?.split('&');

      if (queryArray) {
        queryArray.forEach(item => {
          const splitedItem = item?.split('=');
          const key = splitedItem[0];
          const value = splitedItem[1];
          params[key] = value;
        });
      }
    }

    const userState = localStorage.getItem('UserState');
    const currentCohort = localStorage.getItem('CurrentCohort');
    let userData: Userdata = {};
    let currentCohortData: CurrentCohortData = {};
    userData = userState ? (JSON.parse(userState) as IUserState) : {};
    currentCohortData = currentCohort
      ? (JSON.parse(currentCohort) as CurrentCohort)
      : {};

    const userAgentData = uaParserJs(navigator.userAgent);
    const defaultParams = {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      variety: userData?.user_subscription_type || optionalData?.userVariety, // 'premium_byjus_class_active_user', // <user_type> premium_byjus_class_active_user / premium_byjus_class_expired_user / parity_active_user / parity_expired_user / free_user
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      user_id: userData?.id || optionalData?.userId,
      device_id: null,
      date: moment
        .tz(new Date(), 'Asia/Calcutta')
        .format('YYYY-MM-DD HH:mm:ss'), // '2021-11-29 00:28:18.000 +0530',
      session_id: sessionId(),
      param7: {
        device_type: getDeviceName(userAgentData),
        os_type: userAgentData.os.name,
        os_version: userAgentData.os.version,
        browser_name: userAgentData.browser.name,
        version: userAgentData.browser.version,
      },
      ...(Object.keys(params).length !== 0 && { param8: { ...params } }),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      value1: currentCohortData?.cohort?.id || optionalData?.cohertId,
      value2:
        // eslint-disable-next-line no-nested-ternary
        userAgentData.device.type === 'mobile'
          ? 0
          : userAgentData.device.type === 'tablet'
          ? 2
          : 1, // mobile_web/web/tablet_view <0/1/2>
      app_id: appConfig.olapAppId,
    };
    if (appConfig.olapEnable === 'true') {
      const date = Math.floor(Date.now() / 1000);
      const axiosOlapHeader = {
        headers: {
          'X-TNL-APPID': appConfig.olapAppId,
          'X-TNL-DEVICEOS': 'web',
          'X-TNL-TIMESTAMP': date,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          'X-TNL-SIG': signature(appConfig.olapAppId, date),
        },
      };
      api
        .create(
          appConfig.olapApi,
          [Object.assign(defaultParams, data)],
          true,
          axiosOlapHeader,
        )
        .catch(err => {
          console.error(err);
        });
    } else {
      console.info('OLAP', Object.assign(defaultParams, data));
    }
  } catch (err) {
    console.error(err);
  }
};

export default olap;
