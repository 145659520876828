import { AppConfig } from 'config';
import { ISelfServeData } from 'interfaces';

interface IHaptik {
  signup: (authOptions, callback) => void;
  show: () => void;
  hide: () => void;
  openLanguagePicker: () => void;
  prompt: () => void;
  launchMessage: () => void;
}
const haptikSdkEventListener = (userData: ISelfServeData) => {
  const { token, userDetails } = userData;
  const {
    firstName: fullName,
    authId,
    authCode,
    mobileNumber: mobileNo,
    email,
    premiumId,
  } = userDetails;

  (HaptikSDK as IHaptik).signup(
    {
      username: fullName,
      auth_id: authId,
      auth_code: authCode,
      mobile_no: mobileNo,
      email,
      'signup-type': 'third_party',
      custom_data: {
        premiumId,
        apiAuthToken: token,
        userDetails,
        baseUrl: `${AppConfig.selfServeBaseUrl}`,
      },
    },
    // eslint-disable-next-line
    (success: any, error: any, data: any) => {
      if (success) {
        // eslint-disable-next-line no-console
        console.log('SIGNUP REQUEST SUCCEEDED!', data);
        (HaptikSDK as IHaptik).hide();
        (HaptikSDK as IHaptik).openLanguagePicker();
      } else {
        // eslint-disable-next-line no-console
        console.log('ERROR:', error);
      }
    },
  );
};
export default haptikSdkEventListener;
