import { Route } from 'react-router-dom';
import ByjusSwitch from 'routes/ByjusSwitch';
import Tod from './Tod';
import QuestionPage from './QuestionPage';
import SearchPage from './SearchPage';

const TodRouter = ({ match }: any) => (
  <ByjusSwitch>
    <Route path={match.url} exact component={Tod} />
    <Route path={`${match.url}/search`} exact component={SearchPage} />
    <Route path={`${match.url}/question`} exact component={QuestionPage} />
  </ByjusSwitch>
);
export default TodRouter;
