import moment from 'moment';
import AppConfig from 'config/AppConfig';
import { CohortCourses, ISession } from 'interfaces';
import { APICore, handleResourceNotFoundStatus } from '../apiCore';
import { ISessionResponse } from './ibht';
import { transformSessionAPIResponse } from './transformSessionAPIResponse';
import checkForSubjectiveAssessment from './checkForSubjectiveAssessment';

const appConfig = AppConfig as {
  tutorApiBaseUrl: string;
};

const getSessions = async (
  courseStateData: CohortCourses,
  isPrevious: boolean,
  cohortId: number,
  pageNumber: number,
  sessionId: string | number,
): Promise<ISession | ISession[]> => {
  try {
    const api = new APICore();
    let statusValues;
    if (isPrevious === true) {
      statusValues = ['completed', 'canceled'];
    } else if (isPrevious === false) {
      statusValues = ['confirmed', 'canceled', 'in_progress'];
    }
    const sessionResponse: ISessionResponse = await api.get(
      `${
        appConfig.tutorApiBaseUrl
      }/ttplus_web_v2/web/bht/sessions?date_filter%5Bpivot_date%5D=${moment().format(
        'YYYY-MM-DDTHH:mm',
      )}&date_filter%5Bis_previous%5D=${isPrevious.toString()}&page=${
        pageNumber || 1
      }&per_page=16&cohort_id=${cohortId}&status[]=${String(statusValues)}`,
      null,
      false,
      { validateStatus: handleResourceNotFoundStatus },
    );
    const formattedSessions = transformSessionAPIResponse(
      courseStateData,
      sessionResponse.data.sessions,
    );
    const sessionData = checkForSubjectiveAssessment(
      formattedSessions,
      sessionId,
    );
    return sessionData;
  } catch (error) {
    return [];
  }
};

export default getSessions;
