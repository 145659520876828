/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import AppConfig from 'config/AppConfig';
import { getGradeNumber } from 'helpers/getGradeNumber';
import { persistToLocalStorage } from 'helpers/persistence';
import { useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';
import useDocToken from './UseDocToken';

interface PopupState {
  show: boolean;
  queryImage: { [key: string]: unknown } | null;
  queryText?: string;
  subjectName?: string | null;
}

interface AskTutorPopupPropTypes {
  clientId: string;
  token: string;
  subjectList: Array<string>;
  onModalCloseCallback(): void;
  handleToggleAskDoubtButton(data: Record<string, unknown>): void;
  apiBaseUrl: string;
  queryImage: { [key: string]: unknown } | null;
  subjectName?: string | null;
  queryText?: string;
  isChatOnly: boolean;
}

interface AsktTutorPopupReturnTypes {
  showAskTutorpopup: boolean;
  openAskTutorPopup: (
    imageData: { [key: string]: unknown } | null,
    subName?: string,
    searchString?: string,
  ) => void;
  getAskTutorProps: () => AskTutorPopupPropTypes;
  ongoingTutorSession: boolean;
}

/**
 * The state of the popup is manageed globally in recoil ,to make it accessible for AskTutor popup added in detached layout and the Touchpoints(invoking the popup) in various components.
 * @key 'show' to show/hide the popup
 * @key 'queryImage' screenshot image data to be passed to the component (required)
 * @key 'subjectName' subjectName to be passed to skip the subject selection step and display subject name on chat box
 * @key 'queryText' to be passed to the asktutor component incase the image upload api fails
 */
export const AskTutorPopupState = atom<PopupState>({
  key: 'AskTutorPopupState',
  default: {
    show: false,
    queryImage: null,
    queryText: '',
    subjectName: null,
  },
  effects_UNSTABLE: [persistToLocalStorage],
});

/**
 * This state is to indicate , if a tutor session is active
 */
export const OngoingTutorSession = atom<boolean>({
  key: 'OngoingTutorSession',
  default: false,
  effects_UNSTABLE: [persistToLocalStorage],
});

/**
 * Handles the functionality of AskTutor package ,(https://www.npmjs.com/package/@toppr-engg/ask-tutor)
 * Returns an object containing state of the Asktutor Popup, popup invoking function ,
 *  prop getter function,ongoingTutorSession which indicates if there is a session already ongoing
 * @return { showAskTutorpopup, openAskTutorPopup, getAskTutorProps,ongoingTutorSession }
 */
const useAskTutorPopup = (): AsktTutorPopupReturnTypes => {
  let grade = '';
  const apiBaseUrl = AppConfig.instalearnApiBaseUrl as string;
  const clientId = AppConfig.instalearnClientId as string;
  const [askTutorPopupState, setaskTutorPopupState] =
    useRecoilState(AskTutorPopupState);
  const [ongoingTutorSession, setTutorSession] =
    useRecoilState(OngoingTutorSession);
  const {
    show: showAskTutorpopup,
    queryImage,
    subjectName,
    queryText,
  } = askTutorPopupState;
  const token = useDocToken();
  const currentCohort = JSON.parse(
    localStorage.getItem('CurrentCohort') as string,
  ) as { [key: string]: { [key: string]: unknown } };
  if (currentCohort) {
    grade = currentCohort?.cohort?.grade as string;
  }

  const getSubjectList = () => {
    const subject = currentCohort?.cohort?.courses.map(item => {
      return item.name;
    });
    return subject;
  };

  const subjectList = getSubjectList();

  useEffect(() => {
    setaskTutorPopupState({
      show: false,
      queryImage: null,
      subjectName: null,
      queryText: '',
    });
  }, []);

  const onModalCloseCallback = () => {
    // console.log('show cta');
    setTutorSession(false);
    setaskTutorPopupState({
      show: false,
      queryImage: null,
      subjectName: null,
      queryText: '',
    });
  };
  const handleToggleAskDoubtButton = (data: Record<string, unknown>) => {
    if ((data.liveSessionCount as number) < 1) {
      setTutorSession(false);
    } else {
      setTutorSession(true);
    }
  };

  /**
   * Sets the state of the popup , with the data passed from the touch points
   * Can be used in components to invoke the popup , by passing below params
   * @param imageData data recieved from image upload api (https://www.notion.so/byjus/Upload-Image-Api-Contracts-73c3f69509f94f7290d2df02c5f5123d) or null if api fails
   * @param subId to be passed to skip subject selection
   * @param searchString to be passed in case the image upload api fails
   * One of imageData or searchString is required to open the popup
   * @return {void}
   */
  const openAskTutorPopup = (
    imageData: { [key: string]: unknown } | null,
    subName?: string,
    searchString?: string,
  ): void => {
    if (imageData && typeof imageData === 'object') {
      // AskTuor component dosent excpet the key media_link key which we get from the image upoad api , it excepts camaleCase key "mediaLink"
      imageData.mediaLink = imageData.media_link;
      delete imageData.media_link;
      setaskTutorPopupState({
        show: true,
        queryImage: imageData,
        subjectName: subName || null,
      });
      return;
    }
    if (searchString) {
      setaskTutorPopupState({
        show: true,
        queryImage: null,
        subjectName: subName || null,
        queryText: searchString,
      });
      return;
    }
    throw new Error(
      'Image data or search string is required to open the popup',
    );
  };

  /**
   * @returns {object} of required props for AskTutor package,
   */
  const getAskTutorProps = (): AskTutorPopupPropTypes => {
    return {
      clientId,
      token,
      subjectList,
      onModalCloseCallback,
      handleToggleAskDoubtButton,
      apiBaseUrl,
      queryImage,
      subjectName,
      queryText,
      isChatOnly: true,
    };
  };
  return {
    showAskTutorpopup,
    openAskTutorPopup,
    getAskTutorProps,
    ongoingTutorSession,
  };
};

export default useAskTutorPopup;
