import {
  CohortCourses,
  IAssessmentDetails,
  IAssignments,
  IBhtJourneyDetails,
  IBhtVideoDetails,
  ICertificateDetails,
  IClassNoteDetail,
  IPrePostRequisites,
  ISession,
} from 'interfaces';
import { getJourneyChapterName } from 'modules/byjusHomeTuition/utils/utils';
import {
  ISessionRaw,
  IAssessmentDetailsRaw,
  IAssignmentsRaw,
  ICertificateDetailsRaw,
  IClassNoteDetailRaw,
  IJourneyDetailsBhtRaw,
  IPrePostRequisitesRaw,
  IVideoDetailsBhtRaw,
} from './ibht';

const transformClassNoteDetailsAPIResponse = (
  classNoteDetail: IClassNoteDetailRaw,
): IClassNoteDetail => {
  return {
    classNoteId: classNoteDetail?.class_note_id || null,
    classNoteSize: classNoteDetail?.class_note_size || null,
    classNoteUrl: classNoteDetail?.class_note_url || '',
  };
};

const transformJourneyDetailsAPIResponse = (
  journeyDetail: IJourneyDetailsBhtRaw,
): IBhtJourneyDetails => {
  return {
    journeyId: journeyDetail?.journey_id || null,
    icon: journeyDetail?.icon || '',
    chapterId: journeyDetail?.chapter_id || null,
  };
};

const transformVideoDetailsAPIResponse = (
  videoDetail: IVideoDetailsBhtRaw,
): IBhtVideoDetails => {
  return {
    videoHlsUrl: videoDetail?.video_hls_url || '',
    videoDashUrl: videoDetail?.video_dash_url || '',
    hlsLicenseKey: videoDetail?.hls_license_key || '',
    dashLicenseKey: videoDetail?.dash_license_key || '',
  };
};

const transformAssignmentsAPIResponse = (
  assignments: Array<IAssignmentsRaw>,
): Array<IAssignments> => {
  const formattedAssignment = assignments?.map(
    (assignment: IAssignmentsRaw) => {
      return {
        assigneeScore: assignment?.assignee_score || null,
        assignmentId: assignment?.assignment_id || null,
        status: assignment?.status || '',
        submittedAt: assignment?.submitted_at || null,
        totalScore: assignment?.total_score || null,
      };
    },
  );
  return formattedAssignment;
};

const transformAssessmentDetailsAPIResponse = (
  assessmentDetail: IAssessmentDetailsRaw,
): IAssessmentDetails => {
  return {
    assessmentId: assessmentDetail?.assessment_id || null,
    assessmentType: assessmentDetail?.assessment_type || '',
    attemptsCount: assessmentDetail?.attempts_count || 0,
    questionsCount: assessmentDetail?.questions_count || null,
    availableStarting: assessmentDetail?.available_starting || null,
    availableUntil: assessmentDetail?.available_until || null,
    type: assessmentDetail?.type || '',
    subType: assessmentDetail?.sub_type || '',
    categoryId: assessmentDetail?.category_id || null,
    resultsAvailableFrom: assessmentDetail?.results_available_from || null,
    isResultPostProcessingComplete:
      assessmentDetail?.is_result_post_processing_complete || false,
    isOnlineOnly: assessmentDetail?.is_online_only || false,
    assignments: transformAssignmentsAPIResponse(
      assessmentDetail?.assignments || [],
    ),
    isSubjectiveAssessment: assessmentDetail?.is_subjective_assessment || false,
    status: assessmentDetail?.status || '',
    marks: assessmentDetail?.marks || null,
    answerId: assessmentDetail?.answerId || null,
    isError: assessmentDetail?.isError || false,
    message: assessmentDetail?.message || '',
    totalMarks: assessmentDetail?.totalMarks || null,
    isNewerAssessment: assessmentDetail?.isNewerAssessment || false,
    assessmentInfo: assessmentDetail?.assessment_info || {},
    section: assessmentDetail?.section || '',
    subject: assessmentDetail?.subject || '',
    tllmsAssessmentId: assessmentDetail?.tllmsAssessmentId || null,
    board: assessmentDetail?.board || '',
    grade: assessmentDetail?.grade || '',
    topicName: assessmentDetail?.topic_name || '',
    batchId: assessmentDetail?.batch_id || null,
    isSubjectiveTest: assessmentDetail?.is_subjective_test || false,
  };
};

const transformCertificateDetailsAPIResponse = (
  certificateDetail: ICertificateDetailsRaw,
): ICertificateDetails => {
  return {
    certificateUrl: certificateDetail?.certificate_url || '',
  };
};
const transformRequisitesAPIResponse = (
  courseStateData: CohortCourses,
  reqDataArray: Array<IPrePostRequisitesRaw>,
): Array<IPrePostRequisites> => {
  const formattedRequisites = reqDataArray?.map(
    (req: IPrePostRequisitesRaw) => {
      return {
        chapterName:
          req?.chapterName ||
          (req.assets_type === 'Journey'
            ? getJourneyChapterName(courseStateData, req)
            : ''),
        statusTxt: req?.statusTxt || '',
        btnText: req?.btnText || '',
        isOnlineBLCAssessment: req?.isOnlineBLCAssessment || false,
        displayName: req?.display_name || '',
        assetsType: req?.assets_type || '',
        classNoteDetails: transformClassNoteDetailsAPIResponse(
          req?.class_note_details || {},
        ),
        displayStartTime: req?.display_start_time || null,
        displayEndTime: req?.display_end_time || null,
        assessmentDetails: transformAssessmentDetailsAPIResponse(
          req?.assessment_details || {},
        ),
        certificateDetails: transformCertificateDetailsAPIResponse(
          req?.certificate_details || {},
        ),
        testType: req?.test_type || '',
        journeyDetails: transformJourneyDetailsAPIResponse(
          req?.journey_details || {},
        ),
        videoDetails: transformVideoDetailsAPIResponse(
          req?.video_details || {},
        ),
      };
    },
  );
  return formattedRequisites;
};

const transformSessionAPIResponse = (
  courseStateData: CohortCourses,
  sessionArr: ISessionRaw[],
): ISession[] => {
  const formatedSessions = sessionArr.map(session => {
    return {
      id: session?.id || '',
      status: session?.status || '',
      courseId: session.course_id || null,
      classroomStatus: session.classroom_status || '',
      courseType: session?.course_type || 'bht',
      batchId: session?.batch_id || null,
      grade: session?.grade || '',
      syllabus: session?.syllabus || '',
      batchType: session?.batch_type || 'regular',
      // batchType: 'paid_trial',
      // batchType: 'regular',
      courseTag: session?.course_tag || '',
      startTime: session?.start_time || null,
      endTime: session?.end_time || null,
      tutorId: session?.tutor_id || null,
      subBatchId: session?.sub_batch_id || null,
      sessionType: session?.session_type || '',
      topicId: session?.topic_id || null,
      chapterName: session?.chapter_name || '',
      topicName: session?.topic_name || '',
      subjectName: session?.subject_name || '',
      sessionRating: session?.sessions_rating || {},
      description: session?.description,
      // sessionRating: {
      //   rating: 1,
      //   feedback: [
      //     'teaching_technique',
      //     'solution_provided',
      //     'tutors_behaviour',
      //     'call_quality',
      //     'video_quality',
      //   ],
      //   version: 1,
      // },
      preRequisites: transformRequisitesAPIResponse(
        courseStateData,
        session?.pre_requisites || [],
      ),
      postRequisites: transformRequisitesAPIResponse(
        courseStateData,
        session?.post_requisites || [],
      ),
      testRequisites: transformRequisitesAPIResponse(
        courseStateData,
        session?.test_requisites || [],
      ),
    };
  });
  return formatedSessions;
};

const transformClassDetailAPIResponse = (
  courseStateData: CohortCourses,
  session: ISessionRaw,
) => {
  return {
    id: session?.id || '',
    status: session?.status || '',
    courseId: session.course_id || null,
    classroomStatus: session.classroom_status || '',
    courseType: session?.course_type || 'bht',
    batchId: session?.batch_id || null,
    grade: session?.grade || '',
    syllabus: session?.syllabus || '',
    batchType: session?.batch_type || 'regular',
    // batchType: 'paid_trial',
    // batchType: 'regular',
    courseTag: session?.course_tag || '',
    startTime: session?.start_time || null,
    endTime: session?.end_time || null,
    tutorId: session?.tutor_id || null,
    subBatchId: session?.sub_batch_id || null,
    sessionType: session?.session_type || '',
    topicId: session?.topic_id || null,
    chapterName: session?.chapter_name || '',
    topicName: session?.topic_name || '',
    subjectName: session?.subject_name || '',
    sessionRating: session?.sessions_rating || {},
    description: session?.description,
    // sessionRating: {
    //   rating: 1,
    //   feedback: [
    //     'teaching_technique',
    //     'solution_provided',
    //     'tutors_behaviour',
    //     'call_quality',
    //     'video_quality',
    //   ],
    //   version: 1,
    // },
    preRequisites: transformRequisitesAPIResponse(
      courseStateData,
      session?.pre_requisites || [],
    ),
    postRequisites: transformRequisitesAPIResponse(
      courseStateData,
      session?.post_requisites || [],
    ),
    testRequisites: transformRequisitesAPIResponse(
      courseStateData,
      session?.test_requisites || [],
    ),
  };
};
export { transformSessionAPIResponse, transformClassDetailAPIResponse };
