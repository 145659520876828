import { AtomEffect, DefaultValue } from 'recoil';

export const persistToLocalStorage: AtomEffect<any> = ({
  setSelf,
  onSet,
  node,
}) => {
  const item = node.key;
  const storedData = localStorage.getItem(item);
  if (storedData) {
    // this is not safe
    setSelf(JSON.parse(storedData));
  }
  onSet(value => {
    if (value instanceof DefaultValue || !value) {
      localStorage.removeItem(item);
    } else {
      localStorage.setItem(item, JSON.stringify(value));
    }
  });
};
