/* eslint-disable no-empty */
import * as layoutConstants from '../constants';

/**
 * Changes the body attribute
 */
function changeBodyAttribute(attribute, value) {
  if (document.body) document.body.setAttribute(attribute, value);
  return true;
}

/**
 * Changes the layout width
 * @param {*} param0
 */
function changeLayoutWidth(width) {
  try {
    changeBodyAttribute('data-layout-mode', width);
  } catch (error) {}
}

/**
 * Changes the left sidebar theme
 * @param {*} param0
 */
function changeLeftSidebarTheme(theme) {
  try {
    changeBodyAttribute('data-leftbar-theme', theme);
  } catch (error) {}
}

/**
 * Changes the left sidebar type
 * @param {*} param0
 */
function changeLeftSidebarType(type) {
  try {
    changeBodyAttribute('data-leftbar-compact-mode', type);
  } catch (error) {}
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function changeLayout(layout) {
  try {
    changeBodyAttribute('data-layout', layout);
    if (layout === layoutConstants.LAYOUT_VERTICAL) {
      changeLeftSidebarTheme(layoutConstants.LEFT_SIDEBAR_THEME_DEFAULT);
      changeLeftSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED);
    }

    if (layout === layoutConstants.LAYOUT_HORIZONTAL) {
      changeLeftSidebarTheme(layoutConstants.LEFT_SIDEBAR_THEME_DEFAULT);
      changeLeftSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_FIXED);
    }

    if (layout === layoutConstants.LAYOUT_DETACHED) {
      changeLayoutWidth(layoutConstants.LAYOUT_WIDTH_FLUID);
      changeLeftSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_SCROLLABLE);
      changeLeftSidebarTheme(layoutConstants.LEFT_SIDEBAR_THEME_DEFAULT);
    }
  } catch (error) {}
}

export { changeLayout, changeLeftSidebarType };
