import AppConfig from 'config/AppConfig';
import { useHistory } from 'react-router-dom';
import {
  SessionPopBackGround,
  SessionPopButton,
  SessionPopCard,
  SessionPopCardBody,
  SessionPopCol,
  SessionPopContentBody,
  SessionPopHeader,
  SessionPopImage,
  SessionPopImageDiv,
  SessionPopLink,
  SessionPopRow,
  SessionPopTitle,
} from './LoginSessionExceeded.style';
import { getLoginSource } from './Utilities';

export const LoginSessionExceededPop = (props: any) => {
  const handleGoBackClick = () => {
    window.parent.postMessage(JSON.stringify({ type: 'removeIframe' }), '*');
  };
  const source = getLoginSource();
  const history = useHistory();

  return (
    <SessionPopBackGround>
      <SessionPopCard>
        <SessionPopCardBody>
          <SessionPopRow>
            <SessionPopCol>
              <SessionPopContentBody>
                <SessionPopImageDiv>
                  <SessionPopImage
                    src={`${AppConfig.onboarding.images}sessionExceeded-icon.png`}
                    alt="session exceeded"
                  />
                </SessionPopImageDiv>
                <SessionPopHeader>{props.title}</SessionPopHeader>
                <SessionPopTitle>{props.subTitle}</SessionPopTitle>
                <SessionPopImageDiv>
                  {props.isBackbtn ? (
                    <SessionPopButton
                      type="button"
                      onClick={() => {
                        props.handleLpBackendLogin();
                      }}
                    >
                      {props.primaryBtnText}
                    </SessionPopButton>
                  ) : (
                    <SessionPopButton
                      type="button"
                      className="mb-3"
                      onClick={() => {
                        history.push('/account/logout');
                      }}
                    >
                      {props.primaryBtnText}
                    </SessionPopButton>
                  )}
                </SessionPopImageDiv>
                {props.isBackbtn && (
                  <SessionPopImageDiv>
                    <SessionPopLink
                      onClick={() => {
                        source && source === 'iframe'
                          ? handleGoBackClick()
                          : history.push('/account/login');
                      }}
                    >
                      Go back
                    </SessionPopLink>
                  </SessionPopImageDiv>
                )}
              </SessionPopContentBody>
            </SessionPopCol>
          </SessionPopRow>
        </SessionPopCardBody>
      </SessionPopCard>
    </SessionPopBackGround>
  );
};
