import React, { useEffect, useState } from 'react';
import './DoubtsOnChat.scss';
import {
  HomePageComponent,
  Layout,
} from '@toppr-engg/instalearn-components/lib/esm';
import '@toppr-engg/instalearn-components/lib/esm/index.css';
import '@toppr-engg/byjus-search-qna/lib/esm/index.css';

import AppConfig from 'config/AppConfig';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UserState } from 'states/UserState';
import jwt from 'jsonwebtoken';
import { getGradeNumber } from 'helpers/getGradeNumber';
import { CurrentCohortState } from 'states/CohortState';
import {
  docQueryImage,
  docQueryText,
  isTodEnabled,
  searchKey,
} from 'states/SearchState';
import { useHistory, useLocation } from 'react-router-dom';
import { toggleTodIdsFlag } from 'config/toggleFeature';
import { olap } from 'analytics';
import { OlapDataType } from 'analytics/olap';
import AskTutorPage from './AskTutorPage';

const Tod = () => {
  const [showActivationCta, setShowActivationCta] = useState(false);
  const [showVideoBanner, setShowVideoBanner] = useState(false);
  const [subjectId, setSubjectId] = useState(null);
  const [showDoubtAskViaModal, setShowDoubtAskViaModal] = useState(false);
  const [conceptDoubt, setConceptDoubt] = useState(false);
  const [isNewTodUserTod, setIsNewTodUser] = useState(false);
  const [showSessionList, setShowSessionList] = useState(false);
  const isTodEnabledValue = useRecoilValue(isTodEnabled);
  const [isOrderProcessingStarted, setIsOrderProcessingStarted] =
    useState(false);
  const [showIntentSelectionScreen, setShowIntentSelectionScreen] =
    useState(false);

  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const query = new URLSearchParams(location.search);

  const baseUrl = pathname?.split('/')[1];

  if (!isTodEnabledValue || !toggleTodIdsFlag) {
    history.push('/');
    return;
  }

  const [, setSearchKey] = useRecoilState(searchKey);
  const [, setDocQueryImage] = useRecoilState(docQueryImage);
  const [, setDocQueryText] = useRecoilState(docQueryText);
  const userState = useRecoilValue(UserState);
  const currentCohortState = useRecoilValue(CurrentCohortState);
  const jwtPayment = jwt as {
    sign(
      docPaymentTokenPayload: {
        premiumId: string | null | undefined;
        sourcePlatform: string;
        productId: string;
        userId: number;
        exp: number;
        iat: number;
      },
      docPaymentSecretKey: string,
      arg2: { algorithm: string },
    ): string;
  };

  const jwtTod = jwt as {
    sign(
      tokenPayload: {
        // eslint-disable-next-line camelcase
        user_profile_id: number;
        platform: string;
        name: string;
        klass: string;
        // eslint-disable-next-line camelcase
        cohort_id: number;
        // eslint-disable-next-line camelcase
        client_name: string;
      },
      docSecret: string,
      arg2: { algorithm: string; noTimestamp: boolean },
    ): string;
    temp: string;
  };

  function dispatchTodOlapEvents() {
    olap({
      m_category: `Tutor on demand`,
      u_event_id: 9202062,
      m_desc: `tutor on demand landing page`,
      kingdom: `tutor_on_demand_page`,
      phylum: `view`,
      counter: `landing_page_view`,
    });

    const todPageloadOlapEventData = {
      m_category: `Tutor on demand`,
      m_desc: `TOD from LP2.0`,
      kingdom: `tutor_on_demand_page`,
      phylum: `view`,
    };

    const utmSource = query.get('utm-source')?.toLowerCase();

    if (utmSource === 'wa') {
      olap({
        ...todPageloadOlapEventData,
        u_event_id: 9100042,
        tribe: 'WA',
      });
    }
    if (utmSource === 'sms') {
      olap({
        ...todPageloadOlapEventData,
        u_event_id: 9100043,
        tribe: 'SMS',
      });
    }
    if (utmSource === 'email') {
      olap({
        ...todPageloadOlapEventData,
        u_event_id: 9100044,
        tribe: 'EMAIL',
      });
    }
  }

  useEffect(() => {
    localStorage.setItem('todVisited', 'true');
    setDocQueryText('');
    setDocQueryImage('');

    dispatchTodOlapEvents();
    return () => {
      setDocQueryText('');
      setDocQueryImage('');
    };
  }, []);

  const docPaymentTokenPayload = {
    premiumId: userState?.premium_account_id,
    sourcePlatform: 'LearnPortal' as string,
    productId: '123456' as string,
    userId: userState?.id as number,
    exp:
      Math.floor(Date.now() / 1000) +
      parseInt(AppConfig.docWebTechTokenExpiryTime, 10),
    iat: Math.floor(Date.now()),
  };

  const docPaymentToken = jwtPayment.sign(
    docPaymentTokenPayload,
    AppConfig.docPaymentSecretKey,
    {
      algorithm: 'HS256',
    },
  );

  const docSecret = '7HpCPOgGUZHgxXlKui9eN7aUoW5wJjvM93viUBCMsU';
  const tokenPayload = {
    user_profile_id: userState?.id as number,
    platform: 'web' as string,
    name: userState?.full_name as string,
    klass: getGradeNumber(currentCohortState?.cohort?.grade) as string,
    cohort_id: currentCohortState?.cohort?.id as number,
    client_name: 'learn_portal' as string,
  };

  const origin = AppConfig.baseUrl;
  const userId = userState?.id;
  const authKey = `AppConfig.qnaToken?.split(' ')[1]`;
  const docToken = jwtTod.sign(tokenPayload, docSecret, {
    algorithm: 'HS256',
    noTimestamp: true,
  });
  const docApiBaseUrl = AppConfig.instalearnApiBaseUrl;
  const docClientId = AppConfig.instalearnClientId;

  const onSuggestionClick = (data: { href: string; searchText: string }) => {
    const href = data?.href;
    const searchedText = data?.searchText;
    if (href) {
      setSearchKey(href);
      history.push(`/${baseUrl}/question?searchkey=${href}`);
    } else {
      localStorage.removeItem('imageSearched');
      setSearchKey(searchedText);
      history.push(`/${baseUrl}/search?searchkey=${searchedText}`);
    }
  };

  const onCustomSearch = (searchedText: string) => {
    if (searchedText) {
      localStorage.removeItem('imageSearched');
      setSearchKey(searchedText);
      history.push(`/${baseUrl}/search?searchkey=${searchedText}`);
    } else {
      history.push(`/${baseUrl}/search?searchkey=-1`);
    }
  };

  const onImageUploaded = (imgData: {
    data: { text: string };
    imageData: string;
  }) => {
    const searchedText = imgData?.data?.text;
    const imageBase64Data = imgData?.imageData;
    localStorage.setItem('imageSearched', imageBase64Data);
    if (searchedText) {
      setSearchKey(searchedText);
      history.push(`/${baseUrl}/search?searchkey=${searchedText}`);
    } else {
      history.push(`/${baseUrl}/search?searchkey=-1`);
    }
  };

  const handleRenewClick = () => {
    const todUrl = `${AppConfig.webTechFrontendBaseUrl}/tutor-on-demand/?token=${docPaymentToken}`;
    setTimeout(() => {
      window.location.href = todUrl;
    }, 300);
  };

  const handleActivationCtaClick = (data: {
    type: string;
    data: string | null;
    text: string | ((currVal: string | null) => string | null) | null;
  }) => {
    setTimeout(() => {
      if (data.type === 'image') {
        setDocQueryImage(data?.data);
      } else {
        setDocQueryText(data?.text);
      }
    }, 500);
  };
  const getSubjectRelatedToSearch = (data: {
    subjectId: React.SetStateAction<null>;
  }) => {
    setSubjectId(data.subjectId);
  };

  const handleConceptCardClick = () => {
    setConceptDoubt(true);
    setShowDoubtAskViaModal(false);
  };

  const handleGetTodAccessFlag = ({
    isNewTodUser,
  }: {
    isNewTodUser: boolean;
  }) => {
    setShowSessionList(!isNewTodUser);
    setIsNewTodUser(isNewTodUser);
  };

  const handleOlapEvent = (olapevents: OlapDataType) => {
    olap(olapevents);
  };

  // receive callback from InstaLearn components package indicating if the payment is done.
  const handleOrderProcessing = () => {
    setIsOrderProcessingStarted(true);
  };
  const subscriptionType = `${userState?.user_subscription_type as string}_${
    userState?.program_type as string
  }`;

  return (
    <div
      className={`${showActivationCta ? 'todHome' : 'todHomeWithDoubts'} ${
        isNewTodUserTod ? 'newTodHome' : ''
      }`}
    >
      <HomePageComponent
        subscriptionType={subscriptionType}
        apiBaseUrl={docApiBaseUrl}
        origin={origin}
        userId={userId}
        authKey={`Bearer ${authKey}`}
        docToken={docToken}
        docApiBaseUrl={docApiBaseUrl}
        docClientId={docClientId}
        onSuggestionClick={onSuggestionClick}
        onCustomSearch={onCustomSearch}
        handleRenewClick={handleRenewClick}
        onImageUploaded={onImageUploaded}
        showActivationCta={showActivationCta}
        showVideoBanner={showVideoBanner}
        getSubjectRelatedToSearch={getSubjectRelatedToSearch}
        isTodFeature={true} // TOD
        directSearchViaAskADoubt={true} // TOD
        heading={`<div style='color : #7F5CF4' >Tutor on Demand</div>`} // TOD
        askExpertBtnHeading="Connect to a tutor" // TOD
        handleActivationCtaClick={handleActivationCtaClick} // TOD
        isUserVisitedFirstTime={false}
        bannerHeadingHTmlFormat={`<div>Have a <span  style='color:#8C69FF'> 1-on-1 video call </span> with a tutor & get your doubts<span style='color:#8C69FF'> resolved </span> instantly <div>`}
        onGetTodAccessFlags={handleGetTodAccessFlag}
        handleConceptCardClick={handleConceptCardClick}
        showDoubtAskViaPopup={showDoubtAskViaModal}
        isNewTodUser={isNewTodUserTod}
        handleOlapEvent={handleOlapEvent}
        handleOrderProcessing={handleOrderProcessing}
        showIntentSelectionScreen={showIntentSelectionScreen}
      />
      <Layout layoutClassName="layoutDoc">
        <AskTutorPage
          setShowActivationCta={setShowActivationCta}
          setShowVideoBanner={setShowVideoBanner}
          subjectId={subjectId}
          setShowDoubtAskViaModal={setShowDoubtAskViaModal}
          doubtAskViaConcept={conceptDoubt}
          setConceptDoubt={setConceptDoubt}
          showSessionList={showSessionList}
          setIsNewTodUser={setIsNewTodUser}
          setShowSessionList={setShowSessionList}
          isOrderProcessingStarted={isOrderProcessingStarted}
          setShowIntentSelectionScreen={setShowIntentSelectionScreen}
          setIsOrderProcessingStarted={setIsOrderProcessingStarted}
        />
      </Layout>
    </div>
  );
};

export default Tod;
