import AppConfig from 'config/AppConfig';
import { CohortCourses, ISession } from 'interfaces';
import { APICore, handleResourceNotFoundStatus } from '../apiCore';
import { ISessionResponse } from './ibht';
import { transformClassDetailAPIResponse } from './transformSessionAPIResponse';

const appConfig = AppConfig as {
  tutorApiBaseUrl: string;
};

const getClassDetails = async (
  courseStateData: CohortCourses,
  sessionId: string | number,
): Promise<ISession | ISession[]> => {
  try {
    const api = new APICore();
    const classResponse: ISessionResponse = await api.get(
      `${appConfig.tutorApiBaseUrl}/ttplus_web_v2/web/bht/sessions/${sessionId}`,
      null,
      false,
      { validateStatus: handleResourceNotFoundStatus },
    );

    const formattedSessions = transformClassDetailAPIResponse(
      courseStateData,
      classResponse.data,
    );
    return formattedSessions;
  } catch (error) {
    return [];
  }
};
export default getClassDetails;
