/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { sessionId } from 'helpers/sessionId';
import {
  context,
  trace,
  SpanStatusCode,
  Span,
  AttributeValue,
} from '@opentelemetry/api';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
// import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { Resource } from '@opentelemetry/resources';

import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { AppConfig } from 'config';
import { AxiosError, AxiosResponse } from 'axios';
import { IAssessment } from 'helpers/api/exams/iExams';
import { IUserState } from 'states';

export type TraceProviderProps = {
  children?: React.ReactNode;
};

type OtelCustomErrorKeys = {
  origin?: string;
  pathName?: string;
  search?: string;
  url?: string;
  apiSourcePageUrl?: string;
  tracerType: // | 'ApiTracer'
  | 'ErrorTracer'
    | 'CustomTracer'
    // | 'DocumentTracer'
    | 'UserActivityTracer';
  errorMessage?: string;
  options?:
    | AxiosError
    | AxiosResponse
    | Error
    | Record<string, string | number>
    | IAssessment;
  errorType?: string;
  httpStatusCode?: string;
  spanName: string;
};
type IUserData = {
  id: string | '';
  sessionId: string;
  user_subscription_type: string;
  premium_account_id: string;
};

type ICurrentCohortData = {
  cohort: {
    id: string;
  };
};

const userData: IUserData = JSON.parse(localStorage.getItem('UserState'));
const currentCohortData: ICurrentCohortData = JSON.parse(
  localStorage.getItem('CurrentCohort'),
);
const addonsData: Array<string> = JSON.parse(localStorage.getItem('AddOns'));

const collectorOptions = {
  url: AppConfig.tracesEndpoint,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Basic ${btoa('oteldev:oteldevk8s789')}`,
  },
  concurrencyLimit: 20,
};

const provider = new WebTracerProvider({
  resource: new Resource({
    [SemanticResourceAttributes.SERVICE_NAME]: AppConfig.otelServiceName,
  }),
});
const exporter = new OTLPTraceExporter(collectorOptions);

const tracer = provider.getTracer(AppConfig.otelServiceName);

// logging on the console
// provider.addSpanProcessor(new BatchSpanProcessor(new ConsoleSpanExporter()));
provider.addSpanProcessor(new BatchSpanProcessor(exporter));

provider.register();

let activitySpan: Span;

const sendUserInfo = (spanData: Span, customData?: OtelCustomErrorKeys) => {
  spanData.setAttribute('userId', userData?.id);
  spanData.setAttribute('sessionId', sessionId());
  spanData.setAttribute('premiumId', userData?.premium_account_id);
  spanData.setAttribute('subscriptionType', userData?.user_subscription_type);
  spanData.setAttribute('cohortId', currentCohortData?.cohort?.id);
  spanData.setAttribute('addons', addonsData?.toString());
  spanData.setAttribute('pageUrl', window.location.href);

  Object.keys(customData).forEach((item: string) => {
    const value: AttributeValue = JSON.stringify(customData[item]);
    spanData.setAttribute(item, value);
  });
};

if (AppConfig.isOtelEnabled) {
  // Registering instrumentations
  registerInstrumentations({
    instrumentations: [
      new DocumentLoadInstrumentation(),
      // Removed api tracer to avoid data limit being hit if we trace all the apis hit by user.
      // new XMLHttpRequestInstrumentation({
      //   ignoreUrls: [AppConfig.olapApi],
      //   applyCustomAttributesOnSpan: (span: Span) => {
      //     if (span.attributes) {
      //       const parsedUrl = new URL(span.attributes['http.url']);
      //       sendUserInfo(span, {
      //         origin: parsedUrl.origin,
      //         pathName: parsedUrl.pathname,
      //         search: parsedUrl.search,
      //         url: parsedUrl.href,
      //         apiSourcePageUrl: window.location.href,
      //         tracerType: 'ApiTracer',
      //       });
      //       span.updateName(
      //         `${span.attributes['http.method']} ${span.attributes['http.url']}`,
      //       );
      //       span.end();
      //     }
      //   },
      // }),
    ],
  });
}

const forceflush = () => {
  provider
    .forceFlush()
    .then(() => {
      return true;
    })
    .catch(e => {
      console.error('error', e);
    });
};

export function onUserActivityTracerStart(userInfo: IUserState) {
  activitySpan = tracer.startSpan(`${userInfo?.id} user activity`);
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function otelCustomTracer<F extends (...args: any) => ReturnType<F>>(
  name: string,
  customData: OtelCustomErrorKeys,
  forceFlush = false,
  logout = false,
) {
  if (AppConfig.isOtelEnabled) {
    if (
      customData.tracerType === 'ErrorTracer' &&
      // skip non-critical apis tracing - olap
      [AppConfig.olapApi].includes(name)
    ) {
      return;
    }

    const ctx = trace.setSpan(context.active(), activitySpan);
    const subSpan: Span = tracer.startSpan(name, undefined, ctx);

    if (!activitySpan) {
      onUserActivityTracerStart(userData);
    }
    return context.with(trace.setSpan(context.active(), activitySpan), () => {
      try {
        if (customData.tracerType === 'ErrorTracer') {
          subSpan.setStatus({ code: SpanStatusCode.ERROR });
        }
        sendUserInfo(subSpan, {
          ...customData,
          spanName: name,
        });
        subSpan.end();
        if (forceFlush) {
          forceflush();
        }
        if (logout) {
          forceflush();
          activitySpan.end();
        }
      } catch (e) {
        subSpan.setStatus({ code: SpanStatusCode.ERROR });
        subSpan.end();
        activitySpan.end();
        console.error('error', e);
      }
    });
  }
}

export const TraceProvider = ({ children }: TraceProviderProps) => {
  return children;
};
