/* eslint-disable import/prefer-default-export */
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { handleDispatchAlert } from 'components/alert/alert';
import AppConfig from 'config/AppConfig';
import { otelCustomTracer, UserActivityTracerEvents } from 'tools/telemetry';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
const { appSubPath = '' } = AppConfig;

const maxSessionReached = (response: AxiosResponse) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return response.data?.error?.code === 12088;
};

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  isCritical: string;
}

interface CustomAxiosError extends Omit<AxiosError, 'config'> {
  config: CustomAxiosRequestConfig;
}

export const errorInterceptor = (
  axiosErrorObject: CustomAxiosError,
): Promise<CustomAxiosError> => {
  // Any status codes that falls outside the range of 2xx causes this function to trigger
  const { response, ...rest } = axiosErrorObject || {};

  // This is meant for error caused due to CORS policy
  const endPoint: string = (axiosErrorObject?.config?.url || '')
    .split('/')
    .slice(2, 3)
    .join('/');

  if (!response) {
    return Promise.reject(
      new Error(`CORS | ${endPoint}`, { cause: axiosErrorObject }),
    );
  }

  const { status } = response;

  if (status && status > 400 && status !== 404) {
    const isCritical = rest.config?.isCritical;

    if (isCritical) {
      handleDispatchAlert({
        title: isCritical,
        type: 'default',
        delay: 5000,
      });
    }
  }

  switch (response.status) {
    case 401:
      otelCustomTracer(
        UserActivityTracerEvents.logout,
        {
          options: response,
          tracerType: 'UserActivityTracer',
        },
        true,
        true,
      );
      window.localStorage.clear();
      window.location.assign(`/${appSubPath}/account/login`);
      break;
    case 403:
      localStorage.setItem('ErrorCode', JSON.stringify(response.status));
      if (maxSessionReached(response)) {
        // eslint-disable-next-line
        const errorCode: number = response.data?.error?.code || 0;

        return Promise.reject(
          new Error(`${errorCode || ''} | Login limit reached!`, {
            cause: axiosErrorObject,
          }),
        );
      }
      break;
    default:
      console.error(axiosErrorObject.toJSON());
      break;
  }
  return Promise.reject(axiosErrorObject);
};
