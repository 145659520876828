import { useHistory } from 'react-router-dom';

const useAskDoubtRedirect = (): ((searchText: string) => void) => {
  const history = useHistory();
  const { pathname } = window.location;
  const queryparam = pathname.split('/');

  const searchRedirect = (searchText: string) => {
    if (queryparam[2] === 'concept-videos') {
      history.push(
        `/ask-a-doubt/search?searchkey=${encodeURIComponent(
          searchText,
        )}&origin=lp2_concept_videos`,
      );
    } else if (queryparam[2] === 'byjus-classes') {
      history.push(
        `/ask-a-doubt/search?searchkey=${encodeURIComponent(
          searchText,
        )}&origin=lp2_byjus_classes_completed`,
      );
    } else {
      history.push(
        `/ask-a-doubt/question?searchkey=${encodeURIComponent(searchText)}`,
      );
    }
  };

  const askDoubtRedirect = (searchText: string): void => {
    if (!searchText) throw new Error('pass a valid search key');
    localStorage.removeItem('imageSearched');
    searchRedirect(searchText);
  };
  return askDoubtRedirect;
};

export default useAskDoubtRedirect;
