import { ISduiResponse, IWidgetInfo } from 'interfaces';

const transformSectionWidgets = (widgets: ISduiResponse[]): IWidgetInfo[] => {
  const formattedResponse: IWidgetInfo[] = [];
  widgets.map((widget: ISduiResponse) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const response = transformWidgetData(widget);
    formattedResponse.push(response);
    return formattedResponse;
  });
  return formattedResponse;
};

const transformWidgetData = (widget: ISduiResponse): IWidgetInfo => {
  if (widget.type === 'row') {
    return {
      cursor: widget?.cursor,
      data: widget?.data,
      id: widget?.id,
      pageSize: widget?.page_size,
      sortSequence: widget?.sort_sequence,
      totalSize: widget?.total_size,
      type: widget?.type,
      widgets: transformSectionWidgets(widget?.widgets),
    };
  }
  return {
    componentName: widget?.component_name,
    data: widget?.data,
    id: widget?.id,
    pageSize: widget?.page_size,
    sortSequence: widget?.sort_sequence,
    totalSize: widget?.total_size,
    type: widget?.type,
  };
};

const transformSingleColumnLayout = (
  widgets: ISduiResponse[],
): IWidgetInfo[] => {
  const formattedResponse: IWidgetInfo[] = [];
  widgets.map((widget: ISduiResponse) => {
    const response = transformWidgetData(widget);
    formattedResponse.push(response);
    return formattedResponse;
  });
  return formattedResponse;
};

const transformTwoCloumnLayout = (widgets: ISduiResponse[]): IWidgetInfo[] => {
  const formattedResponse: IWidgetInfo[] = [];
  widgets.map((widget: ISduiResponse) => {
    const response = {
      id: widget?.id,
      data: widget?.data,
      pageSize: widget?.page_size,
      totalSize: widget?.total_size,
      sortSequence: widget?.sort_sequence,
      type: widget?.type,
      cursor: widget?.cursor || '',
      widgets: transformSingleColumnLayout(widget?.widgets),
    };
    formattedResponse.push(response);
    return formattedResponse;
  });
  return formattedResponse;
};

const transformSduiResponse = (SduiResponse: ISduiResponse): IWidgetInfo => {
  const formattedwidgets =
    SduiResponse.type === 'column'
      ? transformSingleColumnLayout(SduiResponse?.widgets)
      : transformTwoCloumnLayout(SduiResponse?.widgets);

  return {
    id: SduiResponse?.id,
    data: SduiResponse?.data,
    pageSize: SduiResponse?.page_size,
    totalSize: SduiResponse?.total_size,
    tags: SduiResponse?.tags,
    sortSequence: SduiResponse?.sort_sequence,
    type: SduiResponse?.type,
    cursor: SduiResponse?.cursor || '',
    widgets: formattedwidgets,
  };
};

export default transformSduiResponse;
