export default {
  Mathematics: {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-mathematics@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-mathematics@3x.png',
    },
  },
  Physics: {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-physics@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-physics@3x.png',
    },
  },
  Chemistry: {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-chemistry@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-chemistry@3x.png',
    },
  },
  Biology: {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-biology@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-biology@3x.png',
    },
  },
  Science: {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-science@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-science@3x.png',
    },
  },
  History: {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
  },
  Geography: {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
  },
  Polity: {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
  },
  Economy: {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
  },
  'Science and Technology': {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
  },
  'Environment and Ecology': {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
  },
  'Social Issues and Social Justice': {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
  },
  'International Relations': {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
  },
  Governance: {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
  },
  'Internal Security': {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
  },
  'Disaster Management': {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
  },
  'Ethics, Integrity and Aptitude': {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
  },
  Essay: {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
  },
  CSAT: {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
  },
  Civics: {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
  },
  'Free Tests': {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
  },
  English: {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
  },
  'Social Studies': {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
  },
  Coding: {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
  },
  'Yet to be decided': {
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
  },
  INSPIRE: {
    android: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-inspire@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-inspire@3x.png',
    },
    ios: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-inspire@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-inspire@3x.png',
    },
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-inspire@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-inspire@3x.png',
    },
  },
  XCEL: {
    android: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-inspire@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-inspire@3x.png',
    },
    ios: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-inspire@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-inspire@3x.png',
    },
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-inspire@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-inspire@3x.png',
    },
  },
  'Summer Workshop': {
    android: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-summer-workshops%403.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-summer-workshops%403.png',
    },
    ios: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-summer-workshops%403.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-summer-workshops%403.png',
    },
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-summer-workshops%403.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-summer-workshops%403.png',
    },
  },
  STEAM: {
    android: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-steam@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-steam@3x.png',
    },
    ios: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-steam@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-steam@3x.png',
    },
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-steam@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-steam@3x.png',
    },
  },
  'DSSL Masterclass': {
    android: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
    ios: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
  },
  PTM: {
    android: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-inspire@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-inspire@3x.png',
    },
    ios: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-inspire@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-inspire@3x.png',
    },
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-inspire@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-inspire@3x.png',
    },
  },
  ptm: {
    android: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
    ios: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
  },
  'DSSL Workshop': {
    android: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
    ios: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
  },
  'MLP Partnership': {
    android: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
    ios: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
    web: {
      small:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
      large:
        'https://static.tllms.com/assets/byjus_classes/production/web/subject_icons/subject-default@3x.png',
    },
  },
};
