import AppConfig from 'config/AppConfig';
import { cloneDeep, get as _get, isEmpty as _isEmpty } from 'lodash';

import { findMenuItemByPath, getMenuItems } from 'helpers/menu';
import { toggleBHT } from 'config/toggleFeature';
import { ILeaveEligibility } from 'interfaces/leaveManagement';

/* eslint-disable camelcase */
type ConfigurationAttributes = {
  is_web_test_enabled: boolean;
  is_web_practice_enabled: boolean;
  is_doubts_enabled: boolean;
  revision_web_enabled: boolean;
  downloads_web_enabled: boolean;
  mock_tests_web_enabled: boolean;
  is_workbook_qr_code_enabled: boolean;
  doubts_sessions_enabled: boolean;
  is_workshop_enabled: boolean;
  is_one_to_many_sessions_enabled: boolean;
  is_one_to_mega_sessions_enabled: boolean;
  is_web_daily_quiz_enabled: boolean;
  is_bce_enabled: boolean;
  is_career_counselling_enabled: boolean;
  is_exam_prep_enabled: boolean;
  is_learn_journey_enabled: boolean;
};

export type CurrentCohort = {
  cohort: {
    id: number;
    idnumber: string;
    is_doubts_enabled: boolean;
    is_practice_enabled: boolean;
    is_test_enabled: boolean;
    grade: string;
    display_name: string;
    sort_sequence: number;
    courses: object[];
    configuration_attributes: ConfigurationAttributes;
  };
  is_premium: boolean;
  has_one_on_one_subscription: boolean;
  has_one_to_many_subscription: boolean;
  has_one_to_mega_subscription: boolean;
  user_subscription_type: string;
};

type AakashLiveEligibility = {
  display_cta: boolean;
};

type EligibilityRes = {
  eligibility_response: {
    cohort_enabled: boolean;
    paid: {
      has_neo_subscriptions: boolean;
      has_one_to_mega_subscriptions: boolean;
      is_active: boolean;
    };
    free: {
      payment_enabled: boolean;
      free_trial: {
        rebooking_enabled: boolean;
        booking_flow: string;
        booking_eligible: boolean;
        has_upcoming_session: boolean;
        has_ongoing_session: boolean;
        atleast_one_normal_booking: boolean;
        remaining_normal_booking_count: number;
      };
      bootcamp: {
        booking_eligible: boolean;
        has_upcoming_session: boolean;
        has_ongoing_session: boolean;
        atleast_one_booking: boolean;
        remaining_booking_count: number;
      };
      master_class: {
        booking_eligible: boolean;
        has_upcoming_session: boolean;
        has_ongoing_session: boolean;
        atleast_one_booking: boolean;
        remaining_booking_count: number;
      };
      neo_master_class: {
        bookable_session_type: string;
        booking_eligible: true;
        has_upcoming_session: boolean;
        has_ongoing_session: boolean;
        atleast_one_booking: true;
        remaining_booking_count: number;
      };
      instant_class: {
        booking_eligible: boolean;
        has_upcoming_session: boolean;
        has_ongoing_session: boolean;
      };
    };
    meta: {
      redirect: {
        enabled: boolean;
        url: string;
      };
    };
    user_segment: string;
  };
};

type BLCEligibility = {
  display_cta: boolean;
  subscription: {
    mode: string;
    type: string;
  };
  syllabus?: { name?: string };
};

export type EligibilityConfig = {
  currentCohort: CurrentCohort;
  aakashLiveEligibility: AakashLiveEligibility;
  cohortSetting: EligibilityRes;
  blcEligibility: BLCEligibility;
  bhtSubscription: boolean;
  leaveEligibility: ILeaveEligibility;
};

type ChildNavItem = {
  key: string;
  url: string;
  label?: string;
  criteria?: string[] | string;
  parentKey: string;
};

type NavItem = {
  key: string;
  url: string;
  label?: string;
  criteria?: string[] | string;
  newTag?: boolean;
  icon: string;
  children?: ChildNavItem[];
};
export function checkEligibility(
  eligibilityConfig: EligibilityConfig,
  key?: string,
): boolean {
  const {
    currentCohort,
    aakashLiveEligibility,
    cohortSetting,
    blcEligibility,
    bhtSubscription,
    leaveEligibility,
  } = eligibilityConfig;
  if (!key) {
    return true;
  }
  const leaveEligibilitySetting = _get(leaveEligibility, 'leave_eligibility');
  if (key === 'is_one_to_mega_sessions_enabled') {
    if (
      _get(aakashLiveEligibility, 'display_cta') &&
      !_get(cohortSetting, 'eligibility_response.paid.is_active')
    ) {
      return false;
    }

    if (_get(blcEligibility, 'display_cta')) {
      return true;
    }
    return _get(cohortSetting, 'eligibility_response')?.cohort_enabled || false;
  }
  if (key === 'is_aakash_live_enabled') {
    return (
      (!_get(cohortSetting, 'eligibility_response.paid.is_active') &&
        _get(aakashLiveEligibility, 'display_cta')) ||
      false
    );
  }
  if (key === 'is_bht_tab_enabled') {
    if (bhtSubscription && toggleBHT) {
      return true;
    }
    return false;
  }
  if (key === 'mock_tests_web_enabled') {
    return (
      currentCohort?.cohort.configuration_attributes[key] &&
      currentCohort?.is_premium
    );
  }
  if (key === 'is_bce_enabled') {
    return (
      currentCohort?.cohort.configuration_attributes[key] &&
      currentCohort?.is_premium
    );
  }
  if (key === 'is_btc_free_user') {
    const subscription = _get(blcEligibility, 'subscription');
    if (!currentCohort?.is_premium && !_isEmpty(subscription)) {
      // Check Grade => 4th to 10th allowed
      // TODO: Add API or Fixed CohortId Comparison
      for (let i = 4; i <= 10; i += 1) {
        const currentGrade = currentCohort?.cohort?.display_name;
        if (currentGrade && currentGrade.includes(i.toString())) return true;
      }
    }
    return false;
  }
  if (key === 'is_exam_prep_enabled') {
    return currentCohort?.cohort.configuration_attributes[key];
  }
  if (key === 'is_learn_journey_enabled') {
    return (
      currentCohort?.is_premium ||
      _get(cohortSetting, 'eligibility_response')?.paid.is_active ||
      (blcEligibility?.subscription?.mode === 'paid' &&
        blcEligibility?.subscription?.type === 'blc')
    );
  }
  if (key === 'is_byjus_test_series_enabled') {
    const { k10Grades } = AppConfig;
    return (
      k10Grades.includes(currentCohort?.cohort?.grade) &&
      currentCohort?.is_premium
    );
  }
  if (key === 'leave-management') {
    return !!leaveEligibilitySetting;
  }
  if (key === 'exam-prep-corner') {
    return (
      currentCohort?.is_premium &&
      (blcEligibility?.syllabus?.name === 'CBSE' ||
        blcEligibility?.syllabus?.name === 'ICSE')
    );
  }

  return currentCohort?.cohort.configuration_attributes[
    key as keyof ConfigurationAttributes
  ];
}

export function getEligibleNavItems(
  eligibilityConfig: EligibilityConfig,
): NavItem[] {
  const draftItems: NavItem[] = cloneDeep(getMenuItems());

  return draftItems.filter(navItem => {
    const { criteria } = navItem;
    if (!criteria) return true;

    if (typeof criteria === 'string')
      return checkEligibility(eligibilityConfig, criteria);

    return criteria.reduce(
      (agg, criterion) => agg || checkEligibility(eligibilityConfig, criterion),
      false,
    );
  });
}

const getRouteCriteria = (menuItemPath: string[] | string | undefined) => {
  // TO-DO: Fix return type of fn:findMenuItemByPath()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const menuItem = findMenuItemByPath(getMenuItems(), menuItemPath);
  if (menuItem) {
    // TO-DO: Fix return type of menuItem
    // eslint-disable-next-line
    return menuItem.criteria;
  }
  return null;
};

export const checkRouteEligibility = (
  eligibilityConfig: EligibilityConfig,
  routePath?: string,
  routeCriteria?: string | string[],
): boolean => {
  try {
    // TO-DO: Fix return type of fn:getRouteCriteria()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const criteria: string | string[] | undefined =
      routeCriteria || getRouteCriteria(routePath);
    if (!criteria) return true;

    if (typeof criteria === 'string')
      return checkEligibility(eligibilityConfig, criteria);

    return criteria.reduce(
      (agg, criterion) => agg || checkEligibility(eligibilityConfig, criterion),
      false,
    );
  } catch (error) {
    console.error(error);
    return false;
  }
};
