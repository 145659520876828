/* eslint-disable import/prefer-default-export */
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import lpAxios from './lpAxios';

/**
 *
 * @param url
 * @param params object of query parameters to be included in the url
 * @param config https://axios-http.com/docs/req_config
 * @returns AxiosResponse
 */

export const axiosGet = async <T, R>(
  url: string,
  params?: T,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<R>> => {
  return lpAxios.get<R>(url, {
    ...config,
    params,
  });
};
