/* eslint-disable camelcase */
/* eslint-disable check-file/filename-naming-convention */
import { atom, selector } from 'recoil';
import { get as _get, values, union } from 'lodash';

import { persistToLocalStorage } from 'helpers/persistence';

interface ConstantsStateInterface {
  feedback_choices: [];
  subscription_types: { string: string[] };
}
export const ConstantsState = atom<ConstantsStateInterface | null>({
  key: 'Constants',
  default: null,
  effects_UNSTABLE: [persistToLocalStorage],
});

function getDefaultProducts(products: Record<string, string[]>) {
  return union(...values(products));
}

export const ProductsQuery = selector({
  key: 'GetProducts',
  get: ({ get }) => {
    const constants = get(ConstantsState);
    const products = _get(constants, 'subscription_types', {
      default: ['Other'],
    });
    const defaultProduct = getDefaultProducts(products);
    return { ...products, default: defaultProduct } as Record<string, string[]>;
  },
});
