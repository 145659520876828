import { TokenResponse } from '@openid/appauth';
import { AccountData } from 'interfaces';
import jwtDecode from 'jwt-decode';
import App from './api/appAuth';
import { User } from './api/user';
import { isTokenExpired } from './helper';

export function getAccountId(): string | undefined {
  const currentUserData = localStorage.getItem('learn_portal_user');
  if (!currentUserData) {
    return;
  }
  const currentUser = JSON.parse(currentUserData) as User | null;
  if (!currentUser) {
    return;
  }
  const userId = currentUser.id;
  const accountsData = localStorage.getItem('filteredAccounts');
  if (!accountsData) {
    return;
  }
  const accounts = JSON.parse(accountsData) as AccountData[] | null;
  if (!accounts) {
    return;
  }
  return accounts.filter(
    e => e.profiles?.filter(key => key.backend_uid === `${userId}`).length,
  )[0]?.id;
}

export async function getConfigData() {
  const idServiceTokenData = localStorage.getItem('identity_level_tokens');
  if (!idServiceTokenData) {
    return;
  }
  const idSvcTokens = JSON.parse(idServiceTokenData) as TokenResponse | null;
  if (!idSvcTokens || !idSvcTokens.refreshToken) {
    return;
  }
  if (isTokenExpired(idSvcTokens)) {
    const app = new App();
    return App.fetchServiceConfiguration().then(configuration => {
      app.configuration = configuration;
      return app
        .makeRefreshTokenRequest(
          configuration,
          idSvcTokens.refreshToken as string,
        )
        .then(response => {
          if (!response) {
            throw new Error('Error while fetching the Refresh Token');
          }
          localStorage.setItem(
            'identity_level_tokens',
            JSON.stringify(response),
          );
          localStorage.setItem('identity_id_token', response.idToken as string);
          return {
            headers: {
              Authorization: `Bearer ${response.accessToken}`,
              'Content-Type': 'application/json',
            },
          };
        })
        .catch(err => Promise.reject(err));
    });
  }
  return {
    headers: {
      Authorization: `Bearer ${idSvcTokens.accessToken}`,
      'Content-Type': 'application/json',
    },
  };
}

export function getIdentityData() {
  const tokenData = localStorage.getItem('identity_level_tokens');
  if (!tokenData) {
    return;
  }
  const accounts = JSON.parse(tokenData) as TokenResponse;
  const tokenValue = localStorage.getItem('identity_id_token');
  if (!tokenValue) {
    return;
  }
  const rawToken = jwtDecode<{ sub: string }>(tokenValue);
  const idToken = rawToken.sub.split(':', 3)[2];
  return {
    access_token: accounts.accessToken,
    identity_id: idToken,
    profile_type: 'k12-offline',
  };
}
