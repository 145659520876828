/* eslint-disable check-file/filename-naming-convention */
import { atom } from 'recoil';

import { persistToLocalStorage } from 'helpers/persistence';

export const BhtSubscriptionState = atom<boolean | null>({
  key: 'bhtSubscriptionState',
  default: null,
  effects_UNSTABLE: [persistToLocalStorage],
});
