export const subscriptionGroup = [
  { id: 197, grade: 'LKG' },
  { id: 198, grade: 'UKG' },
  { id: 164, grade: 'Class 1' },
  { id: 163, grade: 'Class 2' },
  { id: 162, grade: 'Class 3' },
  { id: 29, grade: 'Class 4' },
  { id: 30, grade: 'Class 5' },
  { id: 22, grade: 'Class 6' },
  { id: 23, grade: 'Class 7' },
  { id: 14, grade: 'Class 8' },
  { id: 15, grade: 'Class 9' },
  { id: 16, grade: 'Class 10' },
  { id: 209, grade: 'Class 11 (JEE/NEET/JEE & NEET)' },
  { id: 211, grade: 'Class 11 (JEE/NEET/JEE & NEET)' },
  { id: 53, grade: 'Class 11 (JEE/NEET/JEE & NEET)' },
  { id: 208, grade: 'Class 12 (JEE/NEET/JEE & NEET)' },
  { id: 213, grade: 'Class 12 (JEE/NEET/JEE & NEET)' },
  { id: 206, grade: 'Class 12 Pass (JEE/NEET/JEE & NEET)' },
  { id: 210, grade: 'Class 12 Pass (JEE/NEET/JEE & NEET)' },
  { id: 212, grade: 'Class 12 Pass (JEE/NEET/JEE & NEET)' },
  { id: 207, grade: 'Class 12 Pass (JEE/NEET/JEE & NEET)' },
  { id: 104, grade: 'Class 11 - Commerce' },
  { id: 71, grade: 'Class 12 - Commerce' },
  { id: 24, grade: 'IAS' },
  { id: 21, grade: 'CAT' },
  { id: 28, grade: 'GATE' },
  { id: 27, grade: 'gre' },
];

export const classesArray = [
  {
    value: 'byjus-classes',
    label: 'BYJU’S Classes',
  },
  {
    value: 'jee-neet',
    label: 'Live Classes for JEE/NEET',
  },
];
