/* eslint-disable react/require-default-props */
import { isArray } from 'lodash';
import { memo, ReactNode, useCallback, useEffect } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { otelCustomTracer } from 'tools/telemetry';

const ByjusSwitch = memo(
  (props: { children: ReactNode; fallback?: () => JSX.Element }) => {
    const match = useRouteMatch();
    const { children, fallback } = props;
    const lastChild = fallback ? (
      <Route component={fallback} />
    ) : (
      <Redirect to={match.url} />
    );

    const switchChildRoutes = isArray(children)
      ? children.concat(lastChild)
      : [children, lastChild];
    const routesWithKey = switchChildRoutes.map((route, index) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return { ...route, key: `byjus-route-${index}` };
    });

    const trackPageView = useCallback(() => {
      otelCustomTracer(window.location.href, {
        tracerType: 'UserActivityTracer',
      });
    }, []);

    useEffect(() => {
      trackPageView();
    }, [trackPageView]);

    return <Switch>{routesWithKey}</Switch>;
  },
);

export default ByjusSwitch;
