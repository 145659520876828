/* eslint-disable */
/* eslint-disable no-debugger */
// TODO for entire file

import axios from 'axios';
import { handleDispatchAlert } from 'components/alert/alert';
import AppConfig from 'config/AppConfig';
import { SUCCESS_REGEX } from 'constants/global';
import { UserActivityTracerEvents, otelCustomTracer } from 'tools/telemetry';
import { errorInterceptor } from './core/errorInterceptor';

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';

const AUTH_SESSION_KEY = 'learn_portal_user';
const SESSION_STATE_KEY = 'SessionState';
const simpleAxiosInstance = axios.create();

const maxSessionReached = lgResponse => {
  return lgResponse.data?.error?.code === 12088;
};

axios.interceptors.request.use(function (requestConfiguration) {
  requestConfiguration.headers.accept = 'application/json';

  const currentSession = localStorage.getItem(SESSION_STATE_KEY);
  if (currentSession) {
    const { accessToken, tokenType, tnlToken, userId } =
      JSON.parse(currentSession);
    if (accessToken) {
      requestConfiguration.headers.authorization = `${tokenType} ${accessToken}`;
    } else {
      delete requestConfiguration.headers.authorization;
    }

    requestConfiguration.headers['X-TNL-APPID'] = AppConfig.idService.xTnlAppId;

    if (tnlToken) {
      requestConfiguration.headers['X-TNL-TOKEN'] = tnlToken;
    } else {
      delete requestConfiguration.headers['X-TNL-TOKEN'];
    }

    if (userId) {
      requestConfiguration.headers['X-TNL-USER-ID'] = userId;
    } else {
      delete requestConfiguration.headers['X-TNL-USER-ID'];
    }
  }
  return requestConfiguration;
});


axios.interceptors.response.use(
  response => {
    return response;
  },
  errorInterceptor,
);

simpleAxiosInstance?.interceptors?.response?.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  axiosErrorObject => {
    const { response } = axiosErrorObject || {};
    // Capturing exceptions
    if (!response) {
      otelCustomTracer(axiosErrorObject?.config?.url, {
        tracerType: 'ErrorTracer',
        options: { axiosErrorObject, apiErrorType: 'CORS' },
      });
    } else {
      otelCustomTracer(response.config?.url, {
        tracerType: 'ErrorTracer',
        options: response,
        httpStatusCode: response.status,
      });
    }
    console.error(axiosErrorObject.toJSON());
    return Promise.reject(axiosErrorObject);
  },
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token, tokenType = 'JWT') => {
  if (token) {
    axios.defaults.headers.common.Authorization = `${tokenType} ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

const setTnlAuthorization = (token, userID) => {
  if (token && userID) {
    axios.defaults.headers.common['X-TNL-TOKEN'] = token;
    axios.defaults.headers.common['X-TNL-USER-ID'] = userID;
    axios.defaults.headers.common['X-TNL-APPID'] =
      AppConfig.idService.xTnlAppId;
  } else {
    delete axios.defaults.headers.common['X-TNL-TOKEN'];
    delete axios.defaults.headers.common['X-TNL-USER-ID'];
  }
};

export const getUserFromSession = () => {
  const user =
    sessionStorage.getItem(AUTH_SESSION_KEY) ||
    localStorage.getItem(AUTH_SESSION_KEY);
  return user && user !== 'undefined'
    ? typeof user === 'object'
      ? user
      : JSON.parse(user)
    : null;
};
class APICore {
  /**
   * Fetches data from given url
   */
  get = (url, params, isPlainRequest, headers) => {
    let response;
    const axiosInstance = isPlainRequest ? simpleAxiosInstance : axios;
    if (params) {
      const queryString = params
        ? Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&')
        : '';
      response = axiosInstance.get(`${url}?${queryString}`, headers);
    } else {
      response = axiosInstance.get(`${url}`, headers);
    }
    return response;
  };

  getFreshAxiosWithHeaders = (url, config, isPlainRequest = false) => {
    const axiosInstance = isPlainRequest ? simpleAxiosInstance : axios;
    if (config) {
      return axiosInstance.get(`${url}`, config);
    }
    return axiosInstance.get(`${url}`);
  };

  getFile = (url, params) => {
    let response;
    if (params) {
      const queryString = params
        ? Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&')
        : '';
      response = axios.get(`${url}?${queryString}`, { responseType: 'blob' });
    } else {
      response = axios.get(`${url}`, { responseType: 'blob' });
    }
    return response;
  };

  getMultiple = (urls, params, config) => {
    const requests = [];
    let queryString = '';
    if (params) {
      queryString = params
        ? Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&')
        : '';
    }

    for (const url of urls) {
      let finalUrl = url;
      if (queryString) {
        finalUrl = `${url}?${queryString}`;
      }
      requests.push(axios.get(finalUrl, config));
    }
    return axios.all(requests);
  };

  /**
   * post given data to url
   */
  create = (url, data, isPlainRequest, headers) => {
    const axiosInstance = isPlainRequest ? simpleAxiosInstance : axios;
    return axiosInstance.post(url, data, headers);
  };

  /**
   * Updates patch data
   */
  updatePatch = (url, data) => {
    return axios.patch(url, data);
  };

  /**
   * Updates data
   */
  update = (url, data, config, isPlainRequest) => {
    const axiosInstance = isPlainRequest ? simpleAxiosInstance : axios;
    if (config) {
      return axiosInstance.put(url, data, config);
    }
    return axiosInstance.put(url, data);
  };

  /**
   * Deletes data
   */
  delete = (url, config) => {
    return axios.delete(url, config);
  };

  /**
   * post given data to url with file
   */
  createWithFile = (url, data) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        'content-type': 'multipart/form-data',
      },
    };
    return axios.post(url, formData, config);
  };

  createNew = (url, data, customConfig) => {
    const config = {
      headers: {
        ...axios.defaults.headers,
      },
      ...customConfig,
    };
    config.headers['X-TNL-APPID'] = AppConfig.idService.xTnlAppId;
    return axios.post(url, data, config);
  };

  /**
   * post given data to url with file
   */
  updateWithFile = (url, data) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        'content-type': 'multipart/form-data',
      },
    };
    return axios.patch(url, formData, config);
  };

  isUserAuthenticated = () => {
    // TODO : Add relevant logic or remove this wrapper function
    return !!this.getLoggedInUser();
  };

  setLoggedInUser = session => {
    if (session)
      sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
    else {
      sessionStorage.removeItem(AUTH_SESSION_KEY);
    }
  };

  /**
   * Returns the logged in user
   */
  getLoggedInUser = () => {
    return getUserFromSession();
  };

  setUserInSession = modifiedUser => {
    const userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
    if (userInfo) {
      const { token, user } = JSON.parse(userInfo);
      this.setLoggedInUser({ token, ...user, ...modifiedUser });
    }
  };
}

/*
Check if token available in session
*/
const currentUser = getUserFromSession();
if (currentUser) {
  // TODO : remove this logic - as this is no longer in use
  const { token } = currentUser;
  if (token) {
    setAuthorization(token);
  }
}

/**
 * Use this method in axios request config, only if 404 is valid use case.
 * Considers HTTP status code 404 as valid because some APIs indicate that the requested resource is not found
 * but it doesn't mean the endpoint is not defined
 * @param httpStatusCode this will be passed by Axios
 * @returns boolean
 */
const handleResourceNotFoundStatus = (httpStatusCode: number) => {
  const defaultAxiosValidStatus = httpStatusCode >= 200 && httpStatusCode < 300;
  return defaultAxiosValidStatus || httpStatusCode === 404;
};

export {
  APICore,
  setAuthorization,
  setTnlAuthorization,
  handleResourceNotFoundStatus,
};
