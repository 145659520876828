/* eslint-disable check-file/filename-naming-convention */
/* eslint-disable camelcase */
import { atom, selector } from 'recoil';

import {
  cohortGroups,
  CohortInterface,
} from 'constants/dashboard/cohortGroups';
import { subscriptionGroup } from 'constants/profile/call-back';
import { persistToLocalStorage } from 'helpers/persistence';
import { CohortArray } from '../interfaces/profileInterface';

export const CohortState = atom<CohortArray | null>({
  key: 'Cohorts',
  default: null,
  effects_UNSTABLE: [persistToLocalStorage],
});

type CurrentCohortProp = {
  is_premium: boolean;
  user_subscription_type: string;
  cohort: {
    id: number;
    idnumber: string;
    grade: string;
    display_name: string;
    configuration_attributes: {
      [key: string]: boolean;
    };
    courses: unknown[];
  };
};

export const CurrentCohortState = atom<CurrentCohortProp | null>({
  key: 'CurrentCohort',
  default: null,
  effects_UNSTABLE: [persistToLocalStorage],
});

export const CohortSettingsState = atom({
  key: 'CohortSetting',
  default: null,
});

export const filterCategory = (cohortId: number | undefined) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in cohortGroups) {
    if (Object.prototype.hasOwnProperty.call(cohortGroups, key)) {
      const matchedCohort: CohortInterface | undefined = cohortGroups[key].find(
        item => item.id === cohortId,
      );
      if (matchedCohort) {
        return {
          group: key,
          category: matchedCohort.category ?? '8-above',
        };
      }
    }
  }
  return { group: '', category: '8-above' };
};

export const CohortGroup = selector({
  key: 'CohortGroup',
  get: ({ get }) => {
    const currentCohort = get(CurrentCohortState);
    localStorage.setItem(
      'CohortGroup',
      JSON.stringify(filterCategory(currentCohort?.cohort?.id)),
    );
    return filterCategory(currentCohort?.cohort?.id);
  },
});

export const filterCategoryForSubscription = (cohortId: number | undefined) => {
  const matchedCohort = subscriptionGroup.find(
    (item: { id: number; grade: string }) => item.id === cohortId,
  );
  if (matchedCohort) {
    return {
      cohortId: matchedCohort.id,
      grade: matchedCohort.grade,
    };
  }
  return { cohortId, grade: 'default' };
};

export const CurrentGradeForSubscription = selector({
  key: 'subscriptionGroup',
  get: ({ get }) => {
    const currentCohort = get(CurrentCohortState);
    return filterCategoryForSubscription(currentCohort?.cohort?.id);
  },
});

export const isPracticeEnabled = selector({
  key: 'isPracticeEnabled',
  get: ({ get }) => {
    const currentCohort: CurrentCohortProp | null = get(CurrentCohortState);
    const confCohort = currentCohort?.cohort.configuration_attributes || {};
    if (currentCohort?.cohort?.id) {
      return confCohort?.is_web_practice_enabled;
    }
    return false;
  },
});

export const isTestEnabled = selector({
  key: 'isTestEnabled',
  get: ({ get }) => {
    const currentCohort: CurrentCohortProp | null = get(CurrentCohortState);
    const confCohort = currentCohort?.cohort.configuration_attributes || {};
    if (currentCohort?.cohort.id) {
      return confCohort.is_web_test_enabled;
    }
    return false;
  },
});

export const isBookmarksEnabled = selector({
  key: 'isBookmarksEnabled',
  get: ({ get }) => {
    const currentCohort: CurrentCohortProp | null = get(CurrentCohortState);
    const confCohort = currentCohort?.cohort.configuration_attributes || {};
    if (currentCohort?.cohort.id) {
      return (
        confCohort.is_web_test_enabled ||
        confCohort.is_doubts_enabled ||
        confCohort.revision_web_enabled ||
        confCohort.is_web_practice_enabled
      );
    }
    return false;
  },
});

export const isDownloadAppEnabled = selector({
  key: 'isDownloadAppEnabled',
  get: ({ get }) => {
    const currentCohort: CurrentCohortProp | null = get(CurrentCohortState);
    const confCohort = currentCohort?.cohort.configuration_attributes || {};
    if (currentCohort?.cohort.id) {
      return confCohort.downloads_web_enabled;
    }
    return false;
  },
});
export const isAskADoubtEnabled = selector({
  key: 'isAskADoubtEnabled',
  get: ({ get }) => {
    const currentCohort: CurrentCohortProp | null = get(CurrentCohortState);
    const confCohort = currentCohort?.cohort.configuration_attributes || {};
    if (currentCohort?.cohort.id) {
      return confCohort.is_doubts_enabled;
    }
    return false;
  },
});
