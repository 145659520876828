import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const SessionExpiredModal = styled(Modal)`
  .modal-dialog {
    max-width: 32.25rem;
  }
`;

export const SessionExpiredModalBody = styled(Modal.Body)`
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const SessionExpiredModalFooter = styled(Modal.Footer)`
  padding: 0.75rem 0.75rem 2rem 0.75rem;
  border-top: none;
  justify-content: center;
  flex-direction: column;
`;

export const HeroText = styled.p`
  font-family: Poppins;
  font-size: 1.75rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #444;
  margin-bottom: 0.625rem;
  @media (max-width: 768px) {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
  }
`;

export const Helptext = styled.p`
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #888;
  @media (max-width: 768px) {
    font-size: 14px;
    font-weight: normal;
  }
`;

export const HelptextLabel = styled.p`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #686868;
`;
