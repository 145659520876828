/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
type ParsedResult<T> =
  | { parsed: T; hasError: false; error?: undefined }
  | { parsed?: undefined; hasError: true; error?: unknown };

export const safeJsonParse =
  <T>(guard: (o: any) => o is T) =>
  (text: string): ParsedResult<T> => {
    let parsed: unknown;
    let parsingError;
    try {
      parsed = JSON.parse(text);
    } catch (error) {
      parsed = undefined;
      parsingError = error;
    }
    return parsed !== undefined && guard(parsed)
      ? { parsed, hasError: false }
      : { hasError: true, error: parsingError };
  };
