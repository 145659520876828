import AppConfig from 'config/AppConfig';

export const downloadApp = {
  imgUrl: `${AppConfig.dashboard.svg}experience-app.svg`,
  heading: `Experience BYJU'S The Learning App`,
  subTitle: ' Access 50,000+ videos, test, quizzes, games, and much more',
  buttonText: 'Download the App Now',
  buttonColor: 'ask-a-doubt',
};

export const RACSuccessCardTime = 48;
