import AppConfig from 'config/AppConfig';
import { ILanguageDetail } from 'interfaces/iHomeTuition';
import { APICore, handleResourceNotFoundStatus } from '../apiCore';
import { ILanguageResponse } from './ibht';
import transformLanguagesAPIResponse from './transformLanguagesAPIResponse';

const appConfig = AppConfig as {
  tutorApiBaseUrl: string;
};

const fetchLanguages = async (): Promise<Array<ILanguageDetail>> => {
  try {
    const api = new APICore();
    const languagesResponse: ILanguageResponse = await api.get(
      `${appConfig.tutorApiBaseUrl}/ttplus_web_v2/web/bht/languages`,
      null,
      false,
      { validateStatus: handleResourceNotFoundStatus },
    );
    const formattedLanguagesResponse = languagesResponse.data.languages.map(
      transformLanguagesAPIResponse,
    );
    const sortedLanguages = formattedLanguagesResponse.sort(
      (a: ILanguageDetail, b: ILanguageDetail) =>
        a.sortSequence - b.sortSequence,
    );
    return sortedLanguages;
  } catch (error) {
    return [];
  }
};

export default fetchLanguages;
