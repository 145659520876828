import { ISlotData } from 'interfaces';
import { ISlotsDetailRaw } from './ibht';

const transformSlotsAPIResponse = (slotDetails: ISlotsDetailRaw): ISlotData => {
  return {
    day: slotDetails?.day,
    startTime: slotDetails?.start_time,
    endTime: slotDetails?.end_time,
    isSelected: false,
  };
};

export default transformSlotsAPIResponse;
