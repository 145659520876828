/* eslint-disable check-file/filename-naming-convention */
import { atom } from 'recoil';
import { persistToLocalStorage } from 'helpers/persistence';

interface AakashLiveEligibilityInterface {
  // eslint-disable-next-line camelcase
  display_cta: boolean;
}

export const AakashLiveEligibilityState =
  atom<AakashLiveEligibilityInterface | null>({
    key: 'AakashLiveEligibility',
    default: null,
    effects_UNSTABLE: [persistToLocalStorage],
  });
