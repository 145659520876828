/**
 * Todo
 * code coverage : to be picked up , as per the coverage plan for modules
 */

/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import Asktutor from '@toppr-engg/ask-tutor';
import jwt from 'jsonwebtoken';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useHistory, useLocation } from 'react-router-dom';
import {
  AskADoubtFullScreen,
  AskADoubtWindowState,
} from 'states/AskADoubtState';
import {
  isDoubtsOnChatEnabled,
  isInstaLearnEnabled,
  isTodEnabled,
  docQueryText,
  docQueryImage,
  searchCount,
  idsQueryDataForTOD,
} from 'states/SearchState';
import MediaBreakPoints from 'utils/mediaBreakPoints';
import { getGradeNumber } from 'helpers/getGradeNumber';
import AppConfig from 'config/AppConfig';
import { appNotification } from 'helpers/app-notification';
import './DoubtsOnChat.scss';
import {
  AskTutorPopupState,
  OngoingTutorSession,
} from 'hooks/AskDoubts/UseAskTutorPopup';
import { olap } from 'analytics';
import { OlapDataType } from 'analytics/olap';
import { UserState } from 'states/UserState';
import { CurrentCohortState } from 'states/CohortState';

interface AskTutorPageProps {
  setConceptDoubt: (arg0: boolean) => void;
  setShowDoubtAskViaModal: (arg0: boolean) => void;
  setShowVideoBanner: (arg0: boolean) => void;
  setShowActivationCta: (arg0: boolean) => void;
  setIsNewTodUser: (arg0: boolean) => void;
  setShowSessionList: (arg0: boolean) => void;
  setShowIntentSelectionScreen: (arg0: boolean) => void;
  showSessionList: boolean;
  subjectId: number | string | null;
  doubtAskViaConcept: boolean;
  isOrderProcessingStarted: boolean;
  setIsOrderProcessingStarted: (arg0: boolean) => void;
}

interface JWT {
  sign(
    tokenPayload: {
      user_profile_id: string | number | null;
      platform: string;
      name: string | number | null;
      klass: string;
      cohort_id: number | null;
      client_name: string;
    },
    docSecret: string,
    arg2: { algorithm: string; noTimestamp: boolean },
  ): string;
}

const AskTutorPage = (props: AskTutorPageProps) => {
  const {
    setConceptDoubt,
    setShowDoubtAskViaModal,
    setShowVideoBanner,
    setShowActivationCta,
    setIsNewTodUser,
    setShowSessionList,
    setShowIntentSelectionScreen,
    showSessionList,
    subjectId,
    doubtAskViaConcept,
    isOrderProcessingStarted,
    setIsOrderProcessingStarted,
  } = props;
  const [, setAskADoubtFullScreen] = useRecoilState(AskADoubtFullScreen);
  const [, setAskADoubtWindowState] = useRecoilState(AskADoubtWindowState);
  const [shouldAsktutorInitiate, setShouldAsktutorInitiate] = useState(false);
  const [, setIsChatOnly] = useState(true);

  // const isDoubtsOnChatEnabledValue = useRecoilValue(isDoubtsOnChatEnabled);
  const isInstaLearnEnabledValue = useRecoilValue(isInstaLearnEnabled);
  const isTodEnabledValue = useRecoilValue(isTodEnabled);
  const [queryText, setQueryText] = useRecoilState(docQueryText);
  const [queryImage, setQueryImage] = useRecoilState(docQueryImage);
  const [idsQueryDataForTODValue, setIdsQueryDataForTODValue] =
    useRecoilState(idsQueryDataForTOD);
  const setDocQueryImage = useSetRecoilState(docQueryImage);
  const setDocQueryText = useSetRecoilState(docQueryText);

  const detectMob = () => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];
    return toMatch.some(toMatchItem => {
      return navigator?.userAgent?.match(toMatchItem);
    });
  };

  // Todo - will be removed commented code on next refactoring cycle.
  // useEffect(() => {
  //   if (isDoubtsOnChatEnabledValue || isInstaLearnEnabledValue) {
  //     setShouldAsktutorInitiate(true);
  //     if (isInstaLearnEnabledValue) {
  //       // DOC + DOV user
  //       setIsChatOnly(false);
  //     } else {
  //       // Only DOC user
  //       setIsChatOnly(true);
  //     }
  //   } else {
  //     setShouldAsktutorInitiate(false);
  //   }
  // }, [isDoubtsOnChatEnabledValue, isInstaLearnEnabledValue]);

  const smDown = MediaBreakPoints({ mediaName: 'smDown' });

  const history = useHistory();
  const location = useLocation();
  const { search, pathname } = location;
  const query = new URLSearchParams(search);
  const collapsedState = query.get('collapsedState');

  const userstate = useRecoilValue(UserState);
  const currentCohort = useRecoilValue(CurrentCohortState);
  const docSecret = '7HpCPOgGUZHgxXlKui9eN7aUoW5wJjvM93viUBCMsU';
  const setTutorSession = useSetRecoilState(OngoingTutorSession);
  const setaskTutorPopupState = useSetRecoilState(AskTutorPopupState);

  let id = null;
  let name = null;
  let grade = null;
  let cohortId = null;

  if (userstate) {
    id = userstate.id;
    name = userstate.full_name;
  }

  if (currentCohort) {
    grade = currentCohort.cohort.grade;
    cohortId = currentCohort.cohort.id;
  }

  const isMobileBrowser = detectMob();

  const tokenPayload = {
    user_profile_id: id,
    platform: isMobileBrowser ? 'mweb' : 'web',
    name,
    klass: getGradeNumber(grade) as string,
    cohort_id: cohortId,
    client_name: 'learn_portal',
  };

  const token = (jwt as JWT).sign(tokenPayload, docSecret, {
    algorithm: 'HS256',
    noTimestamp: true,
  });

  const onModalCloseCallback = () => {
    setQueryText('');
    setQueryImage('');
    setTutorSession(false);
    setDocQueryText('');
    setDocQueryImage('');
    setConceptDoubt(false);
    setShowDoubtAskViaModal(false);
    setaskTutorPopupState({
      show: false,
      queryImage: null,
      subjectName: null,
      queryText: '',
    });
    setIsOrderProcessingStarted(false);
    setIdsQueryDataForTODValue(null);
  };

  const handleToggleAskDoubtButton = (data: {
    nVideoSession: number;
    liveSessionCount: number;
  }) => {
    if (data.nVideoSession < 1) {
      setShowVideoBanner(true);
    } else {
      setShowActivationCta(false);
    }
    if (data.liveSessionCount < 1) {
      setShowActivationCta(true);
      setTutorSession(false);
    } else {
      setShowActivationCta(false);
      setTutorSession(true);
      setIdsQueryDataForTODValue(null);
    }
  };

  const handleChatScreenState = (windowState: boolean) => {
    setAskADoubtWindowState(windowState);
  };

  const handleSessionInMeet = ({ inMeet }: { inMeet: boolean }) => {
    setDocQueryText('');
    setDocQueryImage('');
    if (inMeet && smDown) {
      setAskADoubtFullScreen(true);
    } else {
      setAskADoubtFullScreen(false);
    }
  };

  const handleConceptSelectionBackClick = () => {
    setShowDoubtAskViaModal(true);
    setConceptDoubt(false);
  };

  const navigateToAskADoubt = () => {
    sessionStorage.setItem('il_hs', '');
    history.push(`/tutor-on-demand?collapsedState=false`);
    window.focus();
  };

  const handleNotificationEvent = ({
    event,
    payload,
  }: {
    event: string;
    payload: {
      payload: {
        messages: [
          {
            attachment: string;
            body: string;
          },
        ];
        session: {
          subject: {
            name: string;
          };
        };
      };
      minutesRemaing: number;
    };
  }) => {
    let notificationPayload = {};

    const TimeLeft =
      payload.minutesRemaing === null || undefined
        ? '2'
        : payload.minutesRemaing;

    switch (event) {
      case 'RECIEVE_CHAT_MESSAGE': {
        const messages = payload.payload.messages[0];
        const { session } = payload.payload;
        const subjectName = session.subject.name;
        const { attachment } = messages;
        let notificationMessage = messages.body;

        if (!notificationMessage && attachment) {
          notificationMessage = 'Tutor sent you an attachment';
        }

        notificationPayload = {
          title: `Your ${subjectName} tutor replied`,
          message: notificationMessage,
          onClick: navigateToAskADoubt,
          native: true, // when using native, your OS will handle theming.
        };
        break;
      }
      case 'STUDENT_WAITING_STATE': {
        notificationPayload = {
          title: `Tutor is joining in ${TimeLeft} mins`,
          message: 'Please be ready to join the session',
          onClick: navigateToAskADoubt,
          native: true, // when using native, your OS will handle theming.
        };
        break;
      }
      case 'TUTOR_JOIN_SESSION': {
        notificationPayload = {
          title: 'Tutor connected',
          message: 'Please join the session to get your doubt resolved',
          onClick: navigateToAskADoubt,
          native: true, // when using native, your OS will handle theming.
        };
        break;
      }
      case 'STUDENT_NOT_JOINED_AFTER_TWO_MINS': {
        notificationPayload = {
          title: 'Tutor is waiting',
          message:
            'Please join the session, tutor is ready to solve your doubt',
          onClick: navigateToAskADoubt,
          native: true, // when using native, your OS will handle theming.
        };
        break;
      }
      case 'STUDENT_NOT_JOINED_AFTER_FOUR_MINS': {
        notificationPayload = {
          title: 'Tutor is waiting',
          message:
            'Please join the session, tutor is ready to solve your doubt',
          onClick: navigateToAskADoubt,
          native: true, // when using native, your OS will handle theming.
        };
        break;
      }
      default: {
        notificationPayload = {};
      }
    }

    appNotification(notificationPayload);
  };

  const handleGoToHomeClick = (data: { isTodDoubt: boolean }) => {
    sessionStorage.setItem('il_hs', '');
    if (data?.isTodDoubt) {
      history.push(`/tutor-on-demand`);
    } else {
      history.push(`/ask-a-doubt`);
    }
  };
  useEffect(() => {
    if (smDown) {
      if (pathname.includes('/tutor-on-demand')) {
        document
          .getElementsByClassName('content-page')[0]
          .classList.remove('search-space-animation');
        document
          .getElementsByClassName('navbar-custom')[0]
          .classList.remove('border-0');
      }
    }
  }, [pathname]);
  const clientId = AppConfig.instalearnClientId;
  const apiBaseUrl = AppConfig.instalearnApiBaseUrl;

  const getSubjectList = () => {
    const subjectsArr = currentCohort?.cohort?.courses as {
      id: string;
      name: string;
    }[];
    const subject = subjectsArr?.map(item => {
      return { id: item?.id, name: item?.name };
    });
    return subject;
  };

  const handleClosedLiveSession = () => {
    setIsNewTodUser(false);
    setShowSessionList(true);
  };

  if (!shouldAsktutorInitiate) {
    <div>
      <p>You are not allowed to use this feature</p>
    </div>;
  }

  const handleOlapEvent = (olapevents: OlapDataType) => {
    olap(olapevents);
  };

  const handleOrderCompleted = () => {
    // passed down this information to InstaLearn package
    setShowIntentSelectionScreen(true);
  };
  const subscriptionType = `${userstate?.user_subscription_type as string}_${
    userstate?.program_type as string
  }`;

  return (
    <div>
      {isTodEnabledValue || isInstaLearnEnabledValue ? (
        <Asktutor
          subscriptionType={subscriptionType}
          fallback={<h1>Loading Instalearn package</h1>}
          clientId={clientId}
          token={token}
          apiBaseUrl={apiBaseUrl}
          subjectList={getSubjectList()}
          showSessionList={showSessionList || false}
          onModalCloseCallback={onModalCloseCallback}
          handleToggleAskDoubtButton={handleToggleAskDoubtButton}
          handleSessionInMeet={handleSessionInMeet}
          handleNotificationEvent={handleNotificationEvent}
          handleGoToHomeClick={handleGoToHomeClick}
          queryImage={queryImage || idsQueryDataForTODValue?.queryImage}
          queryText={queryText || idsQueryDataForTODValue?.queryText}
          subjectId={subjectId || idsQueryDataForTODValue?.subjectId}
          searchResultCount={searchCount}
          isChatOnly={false}
          isTodFeature={true}
          bottomBarClassName="askTutorBottomBar"
          collapsedState={collapsedState !== 'false'}
          handleConceptSelectionBackClick={handleConceptSelectionBackClick}
          doubtAskViaConcept={doubtAskViaConcept}
          isNewSubjectList={true}
          handleClosedLiveSession={handleClosedLiveSession}
          handleOlapEvent={handleOlapEvent}
          isOrderProcessingStarted={isOrderProcessingStarted}
          handleOrderProcessingCompleted={handleOrderCompleted}
          handleChatScreenState={handleChatScreenState}
        />
      ) : null}
    </div>
  );
};

export default AskTutorPage;
