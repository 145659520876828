import { useResetRecoilState } from 'recoil';
import PasscodeState, {
  IsAlternateLoginState,
  IsForcedCreatePasscodeFlow,
  IsForgotPasscodeState,
  IsTokenBasedLogin,
  PasscodeEligibilityState,
  SetNewPasscodeReminderData,
} from 'states/PasscodeState';

/**
 * Resets all the atoms related to passcode
 * @returns function which can reset all passcode recoil atoms
 */
const useResetPasscode = () => {
  const resetPasscodeState = useResetRecoilState(PasscodeState);
  const resetPasscodeEligibility = useResetRecoilState(
    PasscodeEligibilityState,
  );
  const resetPasscodeReminderData = useResetRecoilState(
    SetNewPasscodeReminderData,
  );
  const resetForgotPasscodeFlow = useResetRecoilState(IsForgotPasscodeState);
  const resetAlternateLoginFlow = useResetRecoilState(IsAlternateLoginState);
  const resetTokenBasedLoginFlow = useResetRecoilState(IsTokenBasedLogin);
  const resetForcedCreatePasscodeFlow = useResetRecoilState(
    IsForcedCreatePasscodeFlow,
  );

  return () => {
    resetPasscodeState();
    resetPasscodeEligibility();
    resetPasscodeReminderData();
    resetForgotPasscodeFlow();
    resetAlternateLoginFlow();
    resetAlternateLoginFlow();
    resetTokenBasedLoginFlow();
    resetForcedCreatePasscodeFlow();
  };
};
export default useResetPasscode;
