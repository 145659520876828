/* eslint-disable camelcase */
import { AppConfig } from 'config';
import { axiosPost } from '../core';
import { ISduiWidgetWrapper } from './iSdui';

interface ISduiRequest {
  app_id: string;
  cohort_id: number;
  cursor: string;
  device_operating_system: 'android' | 'ios' | 'web';
  device_orientation: 'portrait' | 'landscape';
  page_size: number;
  tags: string[];
}

interface IParams {
  content_type: string;
  x_tnl_deviceos: 'android' | 'ios' | 'web';
}

export const getSduiConfig = async (
  cohortId: number,
  tags = ['lp_homepage'],
  device_operating_system = 'web' as ISduiRequest['device_operating_system'],
  device_orientation = 'landscape' as ISduiRequest['device_orientation'],
  page_size = 10,
  content_type = 'application/json',
  x_tnl_deviceos = 'web' as IParams['x_tnl_deviceos'],
): Promise<ISduiWidgetWrapper | null> => {
  try {
    const apiUrl = `${AppConfig.sduiBaseUrl}/api/v1/layout/generate`;

    const sduiRequest: ISduiRequest = {
      app_id: AppConfig.appId,
      cursor: '',
      cohort_id: cohortId,
      device_operating_system,
      device_orientation,
      page_size,
      tags,
    };

    const response = await axiosPost<ISduiRequest, ISduiWidgetWrapper>(
      apiUrl,
      sduiRequest,
      {
        'content-type': content_type,
        'X-TNL-DEVICEOS': x_tnl_deviceos,
      },
    );

    if (!response?.data) return null;

    return response?.data;
  } catch (error) {
    console.error(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `Error fetching SDUI Layout: ${error}`,
    );
    return null;
  }
};
