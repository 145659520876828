/* eslint-disable no-param-reassign */
import { IPrePostRequisitesRaw } from 'helpers/api/byjusHomeTuition/ibht';
import { CohortCourses } from 'interfaces';
import { getChapterData } from 'modules/popularvideos/utils/mapping';

export const getJourneyChapterName = (
  courseStateData: CohortCourses,
  journeyData: IPrePostRequisitesRaw,
) => {
  const chapterData = getChapterData(
    journeyData?.journey_details?.chapter_id,
    courseStateData,
  );
  return chapterData ? chapterData.name : '';
};

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getInitials = (name: string) => {
  let initials;
  const nameSplit = name.split(' ');
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
    initials =
      nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 1);
  } else return;

  return initials.toUpperCase();
};

export const createImageFromInitials = (
  size: number,
  name: string,
  color: string,
) => {
  if (name == null) return;
  name = getInitials(name);

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  // eslint-disable-next-line no-multi-assign
  canvas.width = canvas.height = size;

  context.fillStyle = '#ffffff';
  context.fillRect(0, 0, size, size);

  context.fillStyle = `${color}50`;
  context.fillRect(0, 0, size, size);

  context.fillStyle = color;
  context.textBaseline = 'middle';
  context.textAlign = 'center';
  context.font = `${size / 2}px Roboto`;
  context.fillText(name, size / 2, size / 2);

  return canvas.toDataURL();
};
