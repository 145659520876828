import { isCurrentUser, isHideProfileFeatures } from 'modules/account/Utilities';
import MENU_ITEMS from '../constants/menu';
import { switchUser } from './api/auth';

const getMenuItems = () => {
  // NOTE - You can fetch from server and return here as well
  return MENU_ITEMS;
};

const findAllParent = (menuItems, menuItem) => {
  let parents = [];
  const parent = findMenuItem(menuItems, menuItem.parentKey);

  if (parent) {
    parents.push(parent.key);
    if (parent.parentKey)
      parents = [...parents, ...findAllParent(menuItems, parent)];
  }
  return parents;
};

const findMenuItem = (menuItems, menuItemKey) => {
  if (menuItems && menuItemKey) {
    for (let i = 0; i < menuItems.length; i++) {
      if (menuItems[i].key === menuItemKey) {
        return menuItems[i];
      }
      const found = findMenuItem(menuItems[i].children, menuItemKey);
      if (found) return found;
    }
  }
  return null;
};

const findMenuItemByPath = (menuItems, path) => {
  if (menuItems && path) {
    for (const item of menuItems) {
      if (item.url === path) {
        return item;
      }
      const found = findMenuItemByPath(item.children, path);
      if (found) return found;
    }
  }
  return null;
};

const secondaryMenuItems = () => {
  // todo get this from the menu.js file
  const SECONDARY_MENU_ITEMS: [] = [
    {
      key: 'profile',
      isVisible: true,
      url: '/profile',
      label: 'My profile',
      icon: 'menu-icons/my_profile.png',
      iconHover: 'menu-icons/my_profile-hover.png',
    },
  ];
  const filteredAccounts = localStorage.getItem('filteredAccounts');
  const accounts = filteredAccounts ? JSON.parse(filteredAccounts) : null;
  if (accounts && accounts.length > 1) {
    const swtPrfObj = {
      key: 'switch-profile',
      isVisible: false,
      url: '/switch-profile',
      label: 'Switch profile',
      icon: 'menu-icons/switch_profile.png',
      iconHover: 'menu-icons/switch_profile-hover.png',
    };
    const userList = [];
    accounts.forEach(function (account) {
      if (!isCurrentUser(account)) {
        const user = {
          key: account.first_name,
          label: `${account.first_name} ${account.last_name}`,
          type: 'method',
          parentKey: 'switch-profile',
          isVisible: true,
          onClick: switchUser,
          url: '#',
          params: [account.id],
        };
        userList.push(user);
      }
    });
    if (userList) {
      swtPrfObj.isVisible = true;
      swtPrfObj.children = userList;
    }
    SECONDARY_MENU_ITEMS.push(swtPrfObj);
  }
  if (!isHideProfileFeatures()) {
    const logoutObj = {
      key: 'logout',
      isVisible: true,
      url: '/account/logout',
      label: 'Logout',
      icon: 'menu-icons/log_out.png',
      iconHover: 'menu-icons/log_out-hover.png',
    };
    SECONDARY_MENU_ITEMS.push(logoutObj);
  }
  return SECONDARY_MENU_ITEMS;
};

export {
  getMenuItems,
  findAllParent,
  findMenuItem,
  findMenuItemByPath,
  secondaryMenuItems,
};
