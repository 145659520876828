const disableConsoleLogs = (disableConsole: string): void => {
  // All log methods in production will disbled (replaced with empty method)
  // Add debug='true' in localstorage and refresh the page to enable logs  in production
  const logMethods: string[] = [
    'assert',
    'clear',
    'count',
    'debug',
    'dir',
    'dirxml',
    'error',
    'group',
    'groupCollapsed',
    'groupEnd',
    'info',
    'log',
    'table',
    'time',
    'timeEnd',
    'trace',
    'warn',
  ];
  const emptyMethod = () => {};
  const debugConsole = localStorage.getItem('debug');
  if (disableConsole && debugConsole !== 'true') {
    logMethods.forEach(method => {
      window.console[method] = emptyMethod;
    });
  }
};

export default disableConsoleLogs;
