import React from 'react';
import { BrowserRouter, Route, RouteProps, Switch } from 'react-router-dom';
import { resolveQueryParams } from 'modules/account/Utilities';
import lazyWithRetry from 'utils/LazyWithRetry';
// All layouts/containers
import TestLayout from '../layouts/TestLayout';
import DefaultLayout from '../layouts/DefaultLayout';
import DetachedLayout from '../layouts/Detached';
import {
  authProtectedFlattenRoutes,
  exactAuthProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
  cwtProtectedRoutes,
} from './OtherRoutes';
import AppConfig from '../config/AppConfig';
import { checkAndCreateCookie } from '../helpers/sessionId';
import { ILPRoute } from '../interfaces';

const PageNotFound = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "PageNotFound" */ 'modules/misc/PageNotFound'),
  'PageNotFound',
);

const getPath = (route: ILPRoute): string => route.path;

const renderRoutes = (route: ILPRoute, index: number) => (
  <route.route
    key={index}
    path={route.path}
    exact={route.exact}
    component={route.component}
    criteria={route.criteria}
  />
);

const Routes: React.FC<RouteProps> = props => {
  checkAndCreateCookie();

  // FIX-ME: define type for AppConfig
  // eslint-disable-next-line
  const env: string = AppConfig.environment;
  // eslint-disable-next-line
  const appSubPath: string = AppConfig.appSubPath || '';

  if (
    window.location.href.indexOf('ptd=Bjs2jPqYc7PCY3G4') !== -1 ||
    localStorage.getItem('ptd') === '1'
  ) {
    localStorage.setItem('ptd', '1');
  }

  resolveQueryParams(window.location);

  const publicRoutePaths = publicProtectedFlattenRoutes.map(getPath);
  const exactPrivateRoutePaths = exactAuthProtectedFlattenRoutes.map(getPath);
  const privateRoutePaths = authProtectedFlattenRoutes.map(getPath);
  const cwtPaths = cwtProtectedRoutes.map(getPath);
  const publicRoutes = publicProtectedFlattenRoutes.map(renderRoutes);
  const exactPrivateRoutes = exactAuthProtectedFlattenRoutes.map(renderRoutes);
  const testPrivateRoutes = cwtProtectedRoutes.map(renderRoutes);
  const privateRoutes = authProtectedFlattenRoutes.map(renderRoutes);

  return (
    <BrowserRouter basename={appSubPath}>
      <Switch>
        {/* Public routes */}
        <Route path={publicRoutePaths}>
          <DefaultLayout {...props}>
            <Switch>{publicRoutes}</Switch>
          </DefaultLayout>
        </Route>
        {/* Private routes */}
        <Route exact path={cwtPaths}>
          <TestLayout {...props}>
            <Switch>{testPrivateRoutes}</Switch>
          </TestLayout>
        </Route>

        <Route exact path={exactPrivateRoutePaths}>
          <DetachedLayout {...props}>
            <Switch>{exactPrivateRoutes}</Switch>
          </DetachedLayout>
        </Route>
        <Route path={privateRoutePaths}>
          <DetachedLayout {...props}>
            <Switch>{privateRoutes}</Switch>
          </DetachedLayout>
        </Route>
        {/* Matches all routes */}
        <Route>
          <DefaultLayout {...props}>
            <PageNotFound />
          </DefaultLayout>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
