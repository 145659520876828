import { ILPRoute, ILPRouteWithChildren } from 'interfaces';

// eslint-disable-next-line import/prefer-default-export
export const flattenRoutes = (routes: ILPRouteWithChildren[]): ILPRoute[] => {
  if (!routes.length) return [];

  const flatRoutes = routes.reduce((aggregate, route) => {
    aggregate.push(route);

    if (route.children?.length) {
      return [...aggregate, ...flattenRoutes(route.children)];
    }

    return aggregate;
  }, [] as ILPRoute[]);

  return flatRoutes;
};
