import { get } from 'lodash';

// Config
import AppConfig from 'config/AppConfig';

// Interfaces
import {
  CohortCourses,
  IImageData,
  PopularVideos,
  SubjectData,
  VideoCardItem,
  VideoData,
} from 'interfaces/video';

// Constants
import { subjectImages } from 'constants/subjectImages';

export interface IMapping {
  category: string;
  group: string;
}

const appConfig = AppConfig as {
  byjusclasses: {
    images: string;
  };
  subjects: {
    images: string;
  };
  dashboard: {
    images: string;
  };
  allsubject: {
    images: string;
  };
  bookmarks: {
    svg: string;
  };
  videos: {
    images: string;
  };
};

const getDefaultImage = (category?: string, subjectName?: string) => {
  let imageData;
  let data: SubjectData;
  if (category && subjectName && (category === '4-5' || category === '6-7')) {
    imageData = get(subjectImages, '8-above', '');
    const dataValue: IImageData = imageData[subjectName] as [string];
    if (dataValue) {
      data = dataValue;
    } else {
      data = getDefaultImage();
    }
    return data;
  }
  return {
    image: `${appConfig.subjects.images}default-icon.png`,
    color: '#f57192',
    bgColor: '#fff2f7',
  };
};

const getSubject = (subjectName = '', cohort = '') => {
  const formattedSubName: string = subjectName
    ?.toLowerCase()
    .replace('-', '')
    .replace(/ /g, '');

  const currentCohortGroup = localStorage.getItem('CohortGroup');
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const cohortCategory: IMapping = JSON.parse(currentCohortGroup);
  const category: string = cohortCategory?.category
    ? cohortCategory?.category
    : cohort;
  let imageData: IImageData;
  let data: SubjectData;
  switch (formattedSubName) {
    case 'math':
    case 'maths':
    case 'mathematics': {
      // console.log(subjectImages[category])
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData?.maths;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'eng':
    case 'english': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.english;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'civics': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.civics;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'physics': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.physics;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'chemistry': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData?.chemistry;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'biology': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData?.biology;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'history': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.history;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'competitiveexams':
    case 'competitiveexammocks': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.competitiveexams;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'geography': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.geography;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'booster': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.booster;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'economics':
    case 'economics1':
    case 'economics2': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.economics;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'economy': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.economy;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'internalsecurity': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.internalsecurity;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'science':
    case 'scienceandtechnology': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.science;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'disastermanagement': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.disastermanagement;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'environmentandecology': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.environmentandecology;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'ethics,integrityandaptitude': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.ethics;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'csat': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.csat;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'essay': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.essay;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'ncerttests': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.ncerttests;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'diandlr': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.diandlr;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'verbalability':
    case 'varc': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.verbalability;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'logicalreasoning': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.logicalreasoning;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'assessment': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.competitiveexams;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'previousyearspapers':
    case 'prelimspreviousyearsquestions': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.previousyearspapers;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }
    case 'businessstudies': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.businessstudies;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'accountancy': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.accountancy;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'statistics': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.statistics;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'quantitativeaptitude': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.quantitativeaptitude;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'gdpi': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.gdpi;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'weeklycurrentaffairs':
    case 'currentaffairs': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.weeklycurrentaffairs;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'polity': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.polity;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'socialissues': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.socialissues;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'internationalrelations': {
      imageData = subjectImages[category] as [string];
      const dataValue: IImageData = imageData.internationalrelations;
      if (dataValue) {
        data = dataValue;
      } else {
        data = getDefaultImage(category, formattedSubName);
      }
      return data;
    }

    case 'all':
      return {
        image: `${appConfig.byjusclasses.images}group_img.png`,
        color: '#888888',
        bgColor: '#FFF',
      };
    case 'general':
      return getDefaultImage();

    default:
      return getDefaultImage();
  }
};

const getSubjectName = (categoryId: number, videoData: VideoData): string => {
  const subjectObj: CohortCourses = videoData?.flatMap[categoryId] as [
    CohortCourses,
  ];
  const subjectDetail: CohortCourses =
    videoData?.flatMap[subjectObj?.parent_id];
  if (subjectDetail && subjectDetail.type === 'subject') {
    return subjectObj.name;
  }
  const subDetail = get(videoData?.flatMap, `${subjectDetail?.parent_id}`, '');
  if (subDetail && subDetail.type === 'subject') {
    return subDetail.name;
  }
  return '';
};

const subNameMap = (val: PopularVideos, videoData: VideoData) => {
  return val.videos.map((item: VideoCardItem) => ({
    ...item,
    subTitle: getSubjectName(item.category_id, videoData).toString(),
    subjectImage: getSubject(
      getSubjectName(item.category_id, videoData).toString(),
    ).image,
  }));
};

const subjectMapping = (val: PopularVideos, videoData: unknown) => {
  const data = subNameMap(val, videoData);
  const videoArr = data.filter((item: PopularVideos) => {
    return item.subTitle !== '';
  });
  return videoArr;
};

const getTestCards = (cardName: string) => {
  const apqCard = {
    image: `${appConfig.dashboard.images}practice.png`,
    color: '#444444',
    text: 'Practice',
    redirect: '/adaptive-questions',
    bgColor: '#FFF5E8',
  };
  if (cardName === 'apq-dashbd') {
    apqCard.text = 'Adaptive Test';
  }
  switch (cardName) {
    case 'apq':
      return {
        image: `${appConfig.allsubject.images}practise.png`,
        text: 'Practise Questions',
        redirect: '/adaptive-questions',
        color: '#444444',
        bgColor: '#E6FAFB',
      };

    case 'cwt':
      return {
        image: `${appConfig.allsubject.images}takeatest.png`,
        color: '#444444',
        redirect: '/chapter-wise-tests',
        text: 'Take a Test',
        bgColor: '#FFF5E8',
      };
    case 'cwt-dashboard':
      return {
        image: `${appConfig.allsubject.images}takeatest.png`,
        color: '#444444',
        redirect: '/chapter-wise-tests',
        text: 'Take a Test',
        bgColor: '#FFF5E8',
      };
    case 'aad':
      return {
        image: `${appConfig.allsubject.images}askadoubt.png`,
        color: '#444444',
        text: 'Have a Doubt?',
        redirect: '/ask-a-doubt',
        bgColor: '#EEF4FF',
      };

    case 'dwnld':
      return {
        image: `${appConfig.bookmarks.svg}dwnldIcon.svg`,
        color: '#444444',
        text: 'View Downloads',
        bgColor: '#E6FAFB',
        redirect: '/downloads',
      };
    case 'bookmark-dashboard':
      return {
        image: `${appConfig.dashboard.images}bookmark.png`,
        color: '#444444',
        text: 'Bookmarks',
        redirect: '/all-bookmarks',
        bgColor: '#E6FAFB',
      };
    case 'aad-dashboard':
      return {
        image: `${appConfig.allsubject.images}askadoubt.png`,
        color: '#444444',
        text: 'Ask a doubt?',
        redirect: '/ask-a-doubt',
        bgColor: '#EEF4FF',
      };
    case 'apq-dashboard':
      return apqCard;
    case 'apq-dashbd':
      return apqCard;
    case 'dwnld-dashboard':
      return {
        image: `${appConfig.bookmarks.svg}dwnldIcon.svg`,
        color: '#444444',
        text: 'Downloads',
        bgColor: '#E6FAFB',
        redirect: '/downloads',
      };
    default:
      return {
        image: `${appConfig.videos.images}nodata.png`,
        color: '#444444',
        bgColor: '#FFF1EA',
      };
  }
};

const getChapterId = (categoryId: number, videoData: VideoData) => {
  const subjectObj: CohortCourses = get(
    videoData?.flatMap,
    `${categoryId}`,
    '',
  );
  const subjectDetail: CohortCourses = get(
    videoData?.flatMap,
    `${subjectObj.parent_id}`,
    '',
  );
  if (subjectDetail && subjectDetail.type === 'chapter') {
    return subjectDetail.id;
  }
};

const getChapterData = (chapterId: number, videoData: VideoData) => {
  const subjectObj: CohortCourses = get(videoData?.flatMap, `${chapterId}`, '');
  if (subjectObj && subjectObj.type === 'chapter') {
    return subjectObj;
  }
};

const getSubjectData = (categoryId: number, videoData: VideoData) => {
  const subjectObj: CohortCourses = get(
    videoData?.flatMap,
    `${categoryId}`,
    '',
  );
  const subjectDetail: CohortCourses = get(
    videoData?.flatMap,
    `${subjectObj.parent_id}`,
    '',
  );
  if (subjectDetail && subjectDetail.type === 'subject') {
    return subjectObj;
  }
  const subDetail = get(videoData?.flatMap, `${subjectDetail.parent_id}`, '');
  if (subDetail && subDetail.type === 'subject') {
    return subDetail;
  }
  return 'General';
};

const getSubjectNameWithChapterId = (
  chapterId: number,
  videoData: VideoData,
) => {
  const subjectObj: CohortCourses = get(videoData?.flatMap, `${chapterId}`, '');
  const subjectDetail: CohortCourses = get(
    videoData?.flatMap,
    `${subjectObj.parent_id}`,
    '',
  );
  if (subjectDetail && subjectDetail.type === 'subject') {
    return subjectDetail.name;
  }
  return 'General';
};

export {
  getSubject,
  subjectMapping,
  getSubjectName,
  getTestCards,
  getChapterId,
  getChapterData,
  getSubjectData,
  getSubjectNameWithChapterId,
};
