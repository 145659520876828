export interface CohortInterface {
  id: number;
  label: string;
  category?: string;
}
export interface CohortGroupsInterface {
  [key: string]: CohortInterface[];
}

export const cohortGroups: CohortGroupsInterface = {
  k3: [
    { id: 197, label: 'LKG', category: '4-5' },
    { id: 198, label: 'UKG', category: '4-5' },
    { id: 164, label: '1st Grade', category: '4-5' },
    { id: 163, label: '2nd Grade', category: '4-5' },
    { id: 162, label: '3rd Grade', category: '4-5' },
  ],
  k10: [
    { id: 29, label: '4th Grade', category: '4-5' },
    { id: 30, label: '5th Grade', category: '4-5' },
    { id: 22, label: '6th Grade', category: '6-7' },
    { id: 23, label: '7th Grade', category: '6-7' },
    { id: 14, label: '8th Grade', category: '8-above' },
    { id: 15, label: '9th Grade', category: '8-above' },
    { id: 16, label: '10th Grade', category: '8-above' },
    { id: 110, label: '8th Grade ICSE', category: '8-above' },
    {
      id: 115,
      label: '8th Grade Maharashtra State Board',
      category: '8-above',
    },
    {
      id: 137,
      label: '8th Grade Andhra Pradesh State Board',
      category: '8-above',
    },
    { id: 133, label: '8th Grade Tamil Nadu State Board', category: '8-above' },
    { id: 140, label: '8th Grade Telangana State Board', category: '8-above' },
    { id: 169, label: '8th Grade Gujarat State Board', category: '8-above' },
    { id: 172, label: '8th Grade Punjab State Board', category: '8-above' },
    { id: 132, label: '8th Grade Karnataka State Board', category: '8-above' },
    {
      id: 152,
      label: '8th Grade - West Bengal State Board',
      category: '8-above',
    },
    { id: 117, label: '8th Grade Kerala State Board', category: '8-above' },
  ],
  k12: [
    { id: 209, label: 'JEE (11th)' },
    { id: 211, label: 'NEET (11th)' },
    { id: 53, label: 'JEE & NEET (11th)' },
    { id: 208, label: 'JEE (12th)' },
    { id: 213, label: 'NEET (12th)' },
    { id: 206, label: 'JEE & NEET (12th)' },
    { id: 210, label: 'JEE (12th Pass)' },
    { id: 212, label: 'NEET (12th Pass)' },
    { id: 207, label: 'JEE & NEET (12th Pass)' },
    { id: 104, label: '11th Grade - Commerce' },
    { id: 71, label: '12th Grade - Commerce' },
  ],
  competitive: [
    { id: 24, label: 'IAS' },
    { id: 21, label: 'CAT' },
    { id: 28, label: 'GMAT' },
    { id: 27, label: 'GRE' },
  ],
};
