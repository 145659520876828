/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import AppLoader from 'components/AppLoader';
import MetaDecorator from 'components/MetaDecorator';
import { useEffect, Suspense, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface IRoute {
  key: string;
  props: {
    component: React.ReactChild | React.ReactChild[];
    content: string;
    criteria: string;
    exact: string;
    path: string;
    roles: string;
    title: string;
  };
}

const loading = () => (
  <div>
    <AppLoader />
  </div>
);

type DefaultLayoutProps = {
  // layout: {
  //   layoutType: string;
  //   layoutWidth: string;
  //   leftSideBarTheme: string;
  //   leftSideBarType: string;
  //   showRightSidebar: boolean;
  // };
  // user: any;
  // eslint-disable-next-line react/no-unused-prop-types
  children: React.ReactChild | React.ReactChild[];
};

const DefaultLayout = (props: DefaultLayoutProps) => {
  const { search } = useLocation();
  const location = useLocation();
  const { pathname } = location;
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');

  useEffect(() => {
    // added auth-iframe-bg class for body if source is iframe
    const loginSource: string | null = new URLSearchParams(search).get(
      'source',
    );
    if (
      loginSource &&
      (loginSource === 'iframe' || loginSource === 'byjus_login')
    ) {
      document.body.classList.add('auth-iframe-bg');
    } else {
      const loginSrc = localStorage.getItem('LoginSource')
        ? JSON.parse(localStorage.getItem('LoginSource')).source
        : null;
      if (loginSrc && (loginSrc === 'iframe' || loginSrc === 'byjus_login')) {
        document.body.classList.add('auth-iframe-bg');
      }
    }

    if (document.body) document.body.classList.add('authentication-bg');

    return () => {
      if (document.body) document.body.classList.remove('authentication-bg');
    };
  }, []);

  // get the child view which we would like to render
  const { children } = props || null;

  useEffect(() => {
    children?.props?.children?.forEach((route: IRoute) => {
      if (route?.props?.path === pathname) {
        setTitle(route?.props?.title);
        setContent(route?.props?.content);
      }
    });
  }, [pathname]);

  return (
    <>
      <MetaDecorator title={title} content={content} />
      <Suspense fallback={loading()}>{children}</Suspense>
    </>
  );
};
export default DefaultLayout;
