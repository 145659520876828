import styled from 'styled-components';
import { Modal, Button, Toast } from 'react-bootstrap';
import Image from 'components/Image';
import StyledButton from 'components/styledbutton/StyledButton';

export const LoginHelpDiv = styled.div`
  width: 100%;
`;

export const PassCodeModal = styled(Modal)`
  .modal-dialog {
    max-width: 32.25rem;
  }
`;

export const PasscodeHeader = styled(Modal.Header)`
  padding: 2rem 2rem 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: none;
  @media (max-width: 768px) {
    padding: 1rem 0.5rem;
  }
`;

export const ModalClose = styled(Button)`
  align-self: center;
  padding: 0;
  &:hover {
    pointer: cursor;
  }
`;

export const PasscodeHeaderTitle = styled(Modal.Title)<{ fontSize: string }>`
  font-size: 1.75rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #444;
  @media (max-width: 768px) {
    font-size: ${({ fontSize }) => fontSize};
    font-weight: 600;
    line-height: 1.9;
    text-align: center;
    color: #444;
  }
`;

export const PassCodeModalBody = styled(Modal.Body)`
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 1rem 0.5rem;
  }
`;

export const PassCodeModalFooter = styled(Modal.Footer)`
  padding: 0.75rem 0.75rem 2rem 0.75rem;
  border-top: none;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 1rem 0.5rem;
  }
`;

export const HeroText = styled.p`
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #686868;
  margin-bottom: 1rem;
`;

export const ExceededAttempts = styled.p`
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #444;
`;

export const HelpText = styled.p`
  font-family: Poppins;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #888;
  margin-bottom: 0.3125rem;
`;

export const UserInfoText = styled.p`
  font-family: Poppins;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  font-weight: 600;
  color: #39bcc4;
  margin-bottom: 1.875rem;
`;

export const PassCodeDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

export const FormLabel = styled.p`
  align-self: flex-start;
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 0.5rem;
  color: #888;
`;

export const ResendText = styled.p`
  margin-top: 0.5rem;
  margin-bottom: 60px;

  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #8c69ff;

  align-self: flex-end;
  cursor: pointer;
`;
export const HelperText = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #888;
`;

export const CallMentorText = styled.a`
  margin-top: 5px;

  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #8c69ff;
`;

export const SetupText = styled.p`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #7f5cf4;
  margin-top: 60px;
  align-self: center;
  cursor: pointer;
  &:hover {
    cursor: pointer;
  }
`;

export const VideoPlayerBody = styled.div`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const VideoPlayerContainer = styled.div`
  width: 30em;
  height: 100%;
  position: relative;
  float: left;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Overlay = styled.a`
  top: 0;
  left: 0;
  width: 90%;
  height: 100%;
  position: absolute;
  &:hover {
    cursor: pointer;
  }
`;

export const ExpiredLoginContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ExpiredLogin = styled.div`
  width: 516px;
  height: auto;
  padding: 57px 30px 30px 30px;
  display: flex;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(68, 68, 68, 0.1);
  border: solid 1px #f6f6f6;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    width: 114px;
    height: auto;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 100vh;
    border-radius: 0px;
    img {
      width: 88px;
      height: auto;
    }
  }
`;

export const ExpiredHeading = styled.p`
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #444;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 18px;
    font-weight: 500;
  }
`;

export const ExpiredSubtext = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #888;
  margin-bottom: 5px;
  @media (max-width: 768px) {
    font-size: 14px;
    font-weight: normal;
  }
`;

export const ErrorMessageDiv = styled.div`
  padding: 5px 0 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
`;

export const ErrorMessage = styled.p`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #ff5959;
  margin-bottom: 0px;
`;

export const WelcomeMesg = styled.p`
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #444;
  margin-bottom: 9px;
`;

export const WelcomeHelper = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #888;
  margin-bottom: 40px;
`;

export const WelcomeDiv = styled.div`
  padding: 10px 50px;
`;
export const PasscodeToastContainer = styled(Toast)`
  width: 80vh !important;
  margin: 0px auto;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(140, 105, 255, 0.1);
  background-color: rgba(51, 51, 51, 0.8);
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  @media (max-width: 768px) {
    max-width: 90%;
    font-size: 12px;
  }
`;

export const PasscodeToastBody = styled(Toast.Body)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const PassCodeToastDiv = styled.div`
  width: max-content;
  max-width: 100%;
  pointer-events: none;
  transform: translateX(-50%) !important;
  position: fixed;
  bottom: 16px;
  left: 50% !important;
  z-index: 9999;
`;

export const CloseImage = styled(Image)`
  width: 32px;
  height: 32px;
  margin: 0 0 0 16px;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 768px) {
    margin-left: 5px;
    font-size: 12px;
  }
`;

export const ResetButton = styled(StyledButton)`
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #888;
  color: #fff;
  margin-left: 10px;
  &:hover {
    cursor: pointer;
    color: #fff;
  }
  @media (max-width: 768px) {
    margin-left: 5px;
    font-size: 12px;
  }
`;

export const AdditionalMessage = styled.p`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #888888;
  margin-bottom: 0px;
`;
export const ToastDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const ResendOtpDiv = styled.div`
  display: contents;
`;
export const SecondDiv = styled.div`
  display: flex;
  align-self: flex-end;
  cursor: pointer;
`;
export const SecondSpan = styled.span`
  color: #39bcc4;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  margin-top: 0.5rem;
  margin-bottom: 60px;
`;

export const ResendTitle = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #b0b0b0;
  margin-top: 0.5rem;
  margin-bottom: 60px;
`;
