import { assetConfig } from 'config/AppConfig';
import React from 'react';
import styled from 'styled-components';

const MaintenancePage = () => {
  return (
    <Container>
      <MaintenanceImage />
      <Title>We'll be back soon!</Title>
      <SubTitle>
        Sorry for the inconvenience, we are performing scheduled maintenance.
      </SubTitle>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 1rem;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 1.75rem;
  margin-top: 1.563rem;
`;

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 1.25rem;
  margin-top: 0.75rem;
`;

const MaintenanceImage = styled.div`
  background-image: url(${assetConfig.misc.images}404.png);
  height: 10rem;
  width: 19.583rem;
  background-size: cover;
`;

export default MaintenancePage;
