interface dataLayer {
  event: string;
  signup_step_name?: string;
  guided_tour_step?: string;
  hamburger_menu_item?: string;
  ask_doubt_type?: string;
  feedback_reason?: string;
  previous_grade?: string;
  registered_user?: string;
  user_id?: number;
  membership_plan?: string;
  subject_name?: string;
  test_name?: string;
  video_name?: string;
  video_watch_time?: string;
  topic_name?: string;
  timeslot?: string;
  class_name?: string;
  grade?: string;
  search_term?: string;
  paper_type?:string;
  class_type?:string;
  chapter_name?:string;
}

export default function pushToDataLayer(data: dataLayer) {
  window?.dataLayer?.push(data);
}
