/*
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except
 * in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing permissions and
 * limitations under the License.
 */

// Represents a Node application, that uses the AppAuthJS library.

import {
  AuthorizationRequest,
  AuthorizationRequestJson,
} from '@openid/appauth/built/authorization_request';
import {
  AuthorizationNotifier,
  AuthorizationRequestHandler,
} from '@openid/appauth/built/authorization_request_handler';
import {
  RedirectRequestHandler,
  LocalStorageBackend,
  DefaultCrypto,
} from '@openid/appauth';
import { AuthorizationServiceConfiguration } from '@openid/appauth/built/authorization_service_configuration';
import { NodeCrypto } from '@openid/appauth/built/node_support/';
import { NodeRequestor } from '@openid/appauth/built/node_support/node_requestor';
import { TokenRequest } from '@openid/appauth/built/token_request';
import {
  BaseTokenRequestHandler,
  TokenRequestHandler,
} from '@openid/appauth/built/token_request_handler';
import { StringMap } from '@openid/appauth/built/types';
import AppConfig from 'config/AppConfig';
import * as uuid from 'uuid';
import { NoHashQueryStringUtils } from './noHashQueryStringUtils';

const applicationConfig = AppConfig;

/* the Node.js based HTTP client. */
const requestor = new NodeRequestor();
// const requestor = new FetchRequestor();
/* an example open id connect provider */
const openIdConnectUrl = applicationConfig.idService.hydraAuthBaseUrl;

/* example client configuration */
const { clientId } = applicationConfig.idService;
const { redirectUri } = applicationConfig.idService;
const scope = applicationConfig.idService.scopes;
const { nonce } = applicationConfig.idService;

export default class App {
  private notifier: AuthorizationNotifier;

  private authorizationHandler: AuthorizationRequestHandler;

  private tokenHandler: TokenRequestHandler;

  // state
  configuration: AuthorizationServiceConfiguration | undefined;

  constructor() {
    this.notifier = new AuthorizationNotifier();
    this.authorizationHandler = new RedirectRequestHandler(
      new LocalStorageBackend(),
      new NoHashQueryStringUtils(),
      window.location,
      new DefaultCrypto(),
    );
    // this.authorizationHandler = new NodeBasedHandler(PORT);
    this.tokenHandler = new BaseTokenRequestHandler(requestor);
    // set notifier to deliver responses
    this.authorizationHandler.setAuthorizationNotifier(this.notifier);
  }

  static fetchServiceConfiguration(): Promise<AuthorizationServiceConfiguration> {
    return AuthorizationServiceConfiguration.fetchFromIssuer(
      openIdConnectUrl,
      requestor,
    ).then(response => {
      return response;
    });
  }

  makeAccountLevelAuthRequest(
    configuration: AuthorizationServiceConfiguration,
    params: Record<string, string>,
  ) {
    // create a request

    const extras = {
      'verification[account_id]': params.account_id,
      'verification[method]': params.method,
      'verification[root_id_token]': params.root_id_token,
      api_version: '2',
    };

    const request = new AuthorizationRequest(
      {
        client_id: clientId,
        redirect_uri: redirectUri,
        scope,
        response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
        state: uuid.v4(),
        extras,
      },
      new NodeCrypto(),
    );

    this.authorizationHandler.performAuthorizationRequest(
      configuration,
      request,
    );
  }

  makeAuthorizationRequest(
    configuration: AuthorizationServiceConfiguration,
    params: Record<string, string | null | undefined>,
  ) {
    // create a request

    const extras: StringMap = {
      'verification[method]': params.method || '',
      'verification[nonce]': params.nonce || '',
      max_age: '0',
      nonce,
      api_version: '2',
    };
    if (params.phone) {
      extras['verification[phone]'] = params.phone;
    }
    if (params.otp) {
      extras['verification[otp]'] = params.otp;
    }

    const request = new AuthorizationRequest(
      {
        client_id: clientId,
        redirect_uri: redirectUri,
        scope,
        response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
        state: params.state || uuid.v4(),
        extras,
      },
      new NodeCrypto(),
    );
    this.authorizationHandler.performAuthorizationRequest(
      configuration,
      request,
    );
  }

  signedTokenAuthRequest(
    configuration: AuthorizationServiceConfiguration,
    params: Record<string, string>,
  ) {
    const extras: StringMap = {
      'verification[method]': 'signed_token',
      'verification[signed_token]': params.token,
      max_age: '0',
      token_issuer: params.tokenIssuer,
      nonce,
      api_version: '2',
    };

    const request = new AuthorizationRequest(
      {
        client_id: clientId,
        redirect_uri: redirectUri,
        scope,
        response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
        state: params.state,
        extras,
      },
      new NodeCrypto(),
    );
    this.authorizationHandler.performAuthorizationRequest(
      configuration,
      request,
    );
  }

  makeRefreshTokenRequest(
    configuration: AuthorizationServiceConfiguration,
    refreshToken: string,
  ) {
    const extras = { api_version: '2' };

    const tokenRequest = new TokenRequest({
      client_id: clientId,
      redirect_uri: redirectUri,
      grant_type: 'refresh_token',
      code: undefined,
      refresh_token: refreshToken,
      extras,
    });

    return this.tokenHandler
      .performTokenRequest(configuration, tokenRequest)
      .then(response => {
        return response;
      })
      .catch(tokenerror => {
        console.error('Error while fetching Token   ', tokenerror);
      });
  }

  makeAccessTokenRequest(
    configuration: AuthorizationServiceConfiguration,
    code: string,
  ) {
    const pkceKey = localStorage.getItem(
      'appauth_current_authorization_request',
    );

    let appAuthKey;
    const extras: StringMap = {
      api_version: '2',
    };
    if (pkceKey) {
      appAuthKey = `${pkceKey}_appauth_authorization_request`;
      const appAuthAuthorizationRequest = localStorage.getItem(appAuthKey);
      let internalData;
      if (appAuthAuthorizationRequest) {
        internalData = JSON.parse(
          appAuthAuthorizationRequest,
        ) as AuthorizationRequestJson;
        extras.code_verifier = internalData.internal?.code_verifier ?? '';
      }
    }

    const request = new TokenRequest({
      client_id: clientId,
      redirect_uri: redirectUri,
      grant_type: 'authorization_code',
      code,
      refresh_token: undefined,
      extras,
    });

    return this.tokenHandler
      .performTokenRequest(configuration, request)
      .then(response => {
        return response;
      });
  }
}
