/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable import/prefer-default-export */
import { RACSuccessCardTime } from 'modules/home/dashboard/constant';
import moment from 'moment';

export const isTokenExpired = (idSvcTokens: any): boolean => {
  if (
    Math.floor(new Date().getTime() / 1000) - idSvcTokens.issuedAt >
    idSvcTokens.expiresIn
  ) {
    return true;
  }
  return false;
};

export const getHumanReadableTime = (milliseconds: number, dp = 0) => {
  const timeScalars = [1000, 60, 60, 24, 7, 52];
  const timeUnits = ['ms', 'secs', 'mins', 'hrs', 'days', 'weeks', 'years'];
  let timeScalarIndex = 0;
  let scaledTime = milliseconds;

  while (scaledTime > timeScalars[timeScalarIndex]) {
    scaledTime /= timeScalars[timeScalarIndex++];
  }

  if (timeScalarIndex == 0) {
    return `${scaledTime.toFixed(dp) / 1000} ${timeUnits[timeScalarIndex + 1]}`;
  }

  return `${scaledTime.toFixed(dp)} ${timeUnits[timeScalarIndex]}`;
};

export const removeCharRecursive = str => {
  const divElement = document.createElement('div');
  divElement.innerHTML = str;
  const elements = divElement.getElementsByTagName('*');
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].getAttribute('data-src')) {
      const imgSrc = elements[i].getAttribute('data-src');
      elements[i].removeAttribute('data-src');
      elements[i].setAttribute('src', imgSrc);
    }
  }
  return divElement.innerHTML;
};

export const getImageSrc = str => {
  const divElement = document.createElement('div');
  divElement.innerHTML = str;
  return divElement.getElementsByTagName('img')[0].getAttribute('src');
};

export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const truncateWithEllpisisCenter = (fullStr: string, strLen: number) => {
  if (fullStr.length <= strLen) return fullStr;

  const separator = '...';

  const sepLen = separator.length;
  const charsToShow = strLen - sepLen;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  return (
    fullStr.substr(0, frontChars) +
    separator +
    fullStr.substr(fullStr.length - backChars)
  );
};

export const decodeString = (str: string): null | unknown => {
  try {
    if (!str) {
      return null;
    }
    const data = atob(str);
    localStorage.setItem('stateObject', data);
    return data && JSON.parse(data);
  } catch (error) {
    return null;
  }
};

export const formattedTestType = testData => {
  const upcoming: any = [];
  const completed: any = [];
  const skipped: any = [];
  if (testData) {
    testData.map((item: any, idx: any) => {
      if (
        item.data.available_until > moment().unix() &&
        item?.attemptsCount >= 1
      ) {
        item.data.total_attempts = item.data.allowed_attempts;
        item.data.attemptsCount = item.attemptsCount;
        upcoming.push(item);
      } else if (item.attemptsCount === 0) {
        item.data.total_attempts = item.data.allowed_attempts;
        item.data.attemptsCount = item.attemptsCount;
        completed.push(item);
      } else if (item.data.available_until < moment().unix()) {
        item.data.total_attempts = item.data.allowed_attempts;
        item.data.attemptsCount = item.attemptsCount;
        skipped.push(item);
      }
    });
  }
  return { upcoming, completed, skipped };
};

export const hasTimeExceeded = requestedAt => {
  const timeLimit = moment(requestedAt)
    .add(RACSuccessCardTime, 'hours')
    .format('YYYY-MM-DD h:mm a');
  if (moment().isSameOrAfter(timeLimit)) {
    return false;
  }
  return true;
};
export const flattenObject = obj => {
  const result = {};
  for (const i in obj) {
    if (typeof obj[i] === 'object' && !Array.isArray(obj)) {
      const temp = flattenObject(obj[i]);
      for (const j in temp) {
        result[`${i}_${j}`] = temp[j];
      }
    } else {
      result[i] = obj[i];
    }
  }
  return result;
};
