/* eslint-disable check-file/filename-naming-convention */
import { atom, selector } from 'recoil';

import { LeaveEligibilityState } from 'states/LeaveManagementState';
import { AakashLiveEligibilityState } from './AkashLiveEligibilityState';
import BLCEligibilityState from './BLCEligibilityState';
import { BhtSubscriptionState } from './BhtSubscriptionState';

import { CohortSettingsState, CurrentCohortState } from './CohortState';
import { EligibilityConfig } from '../helpers/eligibility';

export const IsEligibilityDataLoadedState = atom<boolean>({
  key: 'IsEligibilityDataLoaded',
  default: false,
});

export const EligibilityConfiguration = selector<EligibilityConfig>({
  key: 'EligibilityConfiguration',
  get: ({ get }): EligibilityConfig => {
    const currentCohort = get(CurrentCohortState);
    const aakashLiveEligibility = get(AakashLiveEligibilityState);
    const cohortSetting = get(CohortSettingsState);
    const blcEligibility = get(BLCEligibilityState);
    const bhtSubscription = get(BhtSubscriptionState);
    const isReady = get(IsEligibilityDataLoadedState);
    const leaveEligibility = get(LeaveEligibilityState);

    return {
      isReady,
      currentCohort,
      aakashLiveEligibility,
      cohortSetting,
      blcEligibility,
      bhtSubscription,
      leaveEligibility,
    };
  },
});
