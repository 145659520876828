import { atom } from 'recoil';
import { IBatchesList } from '../selfserve/slotAvailability/types/Index';
import {
  IUserSubscriptions,
  ICoursePriorities,
  ISelfServeOnBoarding,
} from '../selfserve/types';

const userSubscriptionsState = atom<IUserSubscriptions>({
  key: 'user_subscriptions',
  default: {} as IUserSubscriptions,
});

const coursePriorityState = atom<ICoursePriorities>({
  key: 'course_priority',
  default: {} as ICoursePriorities,
});

const slotListState = atom<IBatchesList>({
  key: 'slotList',
  default: {} as IBatchesList,
});

const slotHighlightState = atom({
  key: 'slotListHighlight',
  default: {
    backgroundColor: '#e5fdff',
    border: '1px solid #2ed5e5',
    borderRadius: 4,
  },
});

const selfServeonBoardingState = atom<ISelfServeOnBoarding>({
  key: 'selfServeonBoarding',
  default: {
    run: false,
    stepIndex: 0,
  } as ISelfServeOnBoarding,
});

const isHideHelpCenterCTA = atom<boolean>({
  key: 'isHideHelpCenterCTA',
  default: false,
});

export {
  coursePriorityState,
  userSubscriptionsState,
  slotListState,
  slotHighlightState,
  selfServeonBoardingState,
  isHideHelpCenterCTA,
};
