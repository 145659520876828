import { useEffect, useState } from 'react';

// accepts mediaNames like xsDown, smDown etc.. and will return booleon or we can wrap new components with this to view on respected breeakpoints.
const MediaBreakPoints = ({
  mediaName,
  children,
}: {
  mediaName:
    | 'xsDown'
    | 'smDown'
    | 'mdDown'
    | 'lgDown'
    | 'xlDown'
    | 'xsUp'
    | 'smUp'
    | 'mdUp'
    | 'lgUp'
    | 'xlUp';
  children?: React.ReactNode;
}) => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const flag =
    (mediaName === 'xsDown' && width < 576) ||
    (mediaName === 'smDown' && width < 768) ||
    (mediaName === 'mdDown' && width < 992) ||
    (mediaName === 'lgDown' && width < 1200) ||
    (mediaName === 'xlDown' && width < 1400) ||
    (mediaName === 'xsUp' && width >= 576) ||
    (mediaName === 'smUp' && width >= 768) ||
    (mediaName === 'mdUp' && width >= 992) ||
    (mediaName === 'lgUp' && width >= 1200) ||
    (mediaName === 'xlUp' && width >= 1400);

  return children || flag;
};

export default MediaBreakPoints;
