// eslint-disable-next-line import/prefer-default-export
export const sessionExpiryInHours = 1;

export const TERMS_OF_USE = 'https://byjus.com/tnc_app/';

export const PRIVACY_STMT = 'https://byjus.com/tnc_app/#privacydesc';

export const URL_REGEX =
  /^(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/;

// regex for 2xx http status code
export const SUCCESS_REGEX = /[2][0-9]+/;
export const IS_NAME_REGEX = '^([A-Za-z]{1,}[.]{0,1}[ ]{0,1}){1,}$';

export const IS_EMAIL_REGEX =
  '^([A-Za-z0-9]{1,}([._$-+]{1}[A-Za-z0-9]{1,}){0,}){3,}[@][a-z0-9]{2,}([.][a-z]{2,}){1,2}$';

export const API_CACHE_KEY = 'sw-api-cache';

export const ASSETS_CACHE_KEY = 'sw-assests-cache';

// export const MENTOR_HELPLINE_NUMBER = '+919513138467';
