/* eslint-disable check-file/filename-naming-convention */
/* eslint-disable import/prefer-default-export */

import { INeoSession } from 'interfaces/classes';
import { atom } from 'recoil';

export const OnboardingMinimised = atom({
  key: 'OnboardingMinimised',
  default: false,
});

export const OnboardingAccessToken = atom({
  key: 'OnboardingAccessToken',
  default: '',
});

export const FirstSessionDetails = atom<INeoSession | null>({
  key: 'FirstSessionDetails',
  default: null,
});
