import { Route, Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { APICore } from 'helpers/api/apiCore';
import { SessionState } from 'states/SessionState';
import { UserState } from 'states/UserState';
import { useUserApi } from 'helpers/api/user';
import { CurrentCohortState } from 'states/CohortState';
import { EligibilityConfiguration } from 'states/EligibilityState';
import { checkRouteEligibility } from 'helpers/eligibility';
import AppLoader from 'components/AppLoader';

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({
  component: Component,
  roles,
  path,
  criteria,
  ...rest
}) => {
  const api = new APICore();
  const { initializeApp } = useUserApi();
  const sessionState = useRecoilValue(SessionState);
  const userState = useRecoilValue(UserState);
  const currentCohort = useRecoilValue(CurrentCohortState);
  const eligibilityConfiguration = useRecoilValue(EligibilityConfiguration);
  const isEligibilityDataReady = eligibilityConfiguration.isReady;

  return (
    <Route
      {...rest}
      render={props => {
        if (!api.isUserAuthenticated()) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{
                pathname: '/account/login',
                state: { from: props.location },
                search: props.location.search,
              }}
            />
          );
        }
        const loggedInUser = api.getLoggedInUser();
        // check if route is restricted by role
        if (roles && roles.indexOf(loggedInUser.role) === -1) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: '/' }} />;
        }
        if (sessionState && !userState && !currentCohort) {
          // TODO: recheck this condition and API calls
          // when the page is refreshed, recoil state will be lost, so retry the API calls
          initializeApp();
        }
        // check if the user doesn't meet the route eligibility criteria
        if (
          isEligibilityDataReady &&
          !checkRouteEligibility(eligibilityConfiguration, path, criteria)
        ) {
          // access denied, redirect to home
          return (
            <Redirect
              to={{
                pathname: '/home',
                state: { from: props.location },
                search: props.location.search,
              }}
            />
          );
        }
        if (!isEligibilityDataReady) {
          return <AppLoader />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
