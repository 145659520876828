/* eslint-disable camelcase */
/* eslint-disable check-file/filename-naming-convention */
import { atom, selector } from 'recoil';
import { filter, get as _get } from 'lodash';

import AppConfig from 'config/AppConfig';
import { persistToLocalStorage } from 'helpers/persistence';
import { NeoEligibilityInterface } from 'interfaces/subjects';
import BLCEligibilityState from './BLCEligibilityState';
import { CohortSettingsState, CurrentCohortState } from './CohortState';

export interface ICohortConfigurationAttributes {
  is_web_test_enabled: boolean;
  is_web_practice_enabled: boolean;
  is_doubts_enabled: boolean;
  revision_web_enabled: boolean;
  downloads_web_enabled: boolean;
  mock_tests_web_enabled: boolean;
  is_workbook_qr_code_enabled: boolean;
  cross_promo_app_list: string[];
  doubts_sessions_enabled: boolean;
  is_workshop_enabled: boolean;
  is_one_to_many_sessions_enabled: boolean;
  is_one_to_mega_sessions_enabled: boolean;
  is_web_daily_quiz_enabled: boolean;
  is_bce_enabled: boolean;
  is_career_counselling_enabled: boolean;
  is_exam_prep_enabled: boolean;
}
export interface ICurrentCohortData {
  id: number;
  idnumber: string;
  is_doubts_enabled: boolean;
  is_practice_enabled: boolean;
  is_test_enabled: boolean;
  grade: string;
  display_name: string;
  configuration_attributes: ICohortConfigurationAttributes;
}
export interface IUserProfile {
  avatar: string | null;
  app: {
    id: number;
    name: string;
  };
  cohort: ICurrentCohortData;
}
export interface IUserAdTrackingData {
  gcl_id: null | string;
  fbcl_id: null | string;
  client_id: string;
  utm_content: null | string;
  utm_medium: null | string;
  utm_source: null | string;
  campaign_name: null | string;
  campaign_id: null | string;
}
export interface IUserState {
  id: number;
  full_name: string;
  city: string;
  gender: string;
  date_of_birth: string;
  email: string | null | undefined;
  country: string;
  first_time_visit: boolean;
  premium_account_id: string | null | undefined;
  mobile: string;
  verified_mobile: string;
  program_type: string | null | undefined;
  user_subscription_type: string;
  user_ad_tracking_data: IUserAdTrackingData;
  download_card_visible: boolean;
  roles: [];
  user_profiles: IUserProfile[];
}

export interface IUserBatchDetails {
  subBatchId: string;
  batchId: string;
  grade: string;
  board: string;
  teachers: Array<{
    name: string;
    subjectName: string;
    primaryTeacherId: string;
    profilePicture: string;
    primaryTeacherEmail: string;
    isClassTeacher: boolean;
  }>;
}

export const UserState = atom<null | IUserState>({
  key: 'UserState',
  default: null,
  effects_UNSTABLE: [persistToLocalStorage],
});

export const ProfileAvatar = selector<string>({
  key: 'UserAvatar',
  get: ({ get }) => {
    const user = get(UserState);
    const userProfiles = _get(user, 'user_profiles') || [];
    const userProfile =
      filter(userProfiles, {
        app: { id: parseInt(`${AppConfig.appId}`, 10) },
      }) || [];
    return (
      (_get(userProfile, '0.avatar') as string) ||
      `${AppConfig.profile.images}profile-icon.png`
    );
  },
});

export const UserSubsType = selector({
  key: 'UserSubsType',
  get: ({ get }) => {
    const bLCEligibilityState = get(BLCEligibilityState);
    const cohortEligibility = get(
      CohortSettingsState,
    ) as NeoEligibilityInterface | null;
    const currentCohort = get(CurrentCohortState);

    if (
      cohortEligibility?.eligibility_response?.paid?.has_neo_subscriptions &&
      cohortEligibility?.eligibility_response?.paid?.is_active
    ) {
      return 'paid_neo';
    }
    if (
      bLCEligibilityState?.subscription.mode === 'paid' &&
      bLCEligibilityState?.subscription.type === 'blc'
    ) {
      return 'paid_blc';
    }
    if (currentCohort?.is_premium) {
      return 'paid';
    }
    return 'free';
  },
});

export const UserWhatsappOptin = atom<boolean>({
  key: 'UserWhatsappOptin',
  default: true,
});

export const UserBatchDetails = atom<IUserBatchDetails | null>({
  key: 'UserBatchDetails',
  default: null,
});
