import {
  OnboardingMinimised,
  OnboardingAccessToken,
} from 'states/OnboardingState';
/* eslint-disable camelcase */
import { filter, get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import AppConfig from 'config/AppConfig';
import {
  CohortState,
  CurrentCohortState,
  filterCategory,
  CohortSettingsState,
} from 'states/CohortState';
import { CourseState } from 'states/CourseState';
import { PopularVideosState } from 'states/PopularVideos';
import { SubscriptionState } from 'states/SubscriptionState';
import { BhtSubscriptionState } from 'states/BhtSubscriptionState';
import { IUserState, UserState } from 'states/UserState';
import { ConstantsState } from 'states/ConstantsState';
import { getIdentityData } from 'helpers/idServiceParams';
import {
  callSessionDetailsApi,
  filterSiblingAccounts,
  resolveUrlAndRedirect,
} from 'modules/account/Utilities';
import { toggleBHT } from 'config/toggleFeature';
import { olap, clevertap } from 'analytics';
import { AxiosResponse } from 'axios';
import { handleClevertapLogin } from 'analytics/clevertap/clevertap';
import PasscodeState from 'states/PasscodeState';
import moment from 'moment-timezone';
import { onUserActivityTracerStart } from 'tools/telemetry';
import SduiState from 'states/SduiStates';
import { fetchNeoEligibilitySettings } from './menuItems/fetchNeoEligibilitySettings';
import { APICore } from './apiCore';
import { sessionIdHandler } from '../sessionId';
import { checkIdentity } from './auth';
import { checkBhtEligibility } from './byjusHomeTuition';
import { getSduiResponseConfig } from './getSduiResponseConfig';

const api = new APICore();

export interface User {
  id: number;
  user_subscription_type: string;
  premium_account_id: string | null | undefined;
  mobile: string;
  full_name: string;
  city: string;
  email: string;
  gender: string;
}

interface TempUserData extends IUserState {
  first_name: string;
  last_name: string;
  dob: string;
}

const useUserApi = () => {
  const history = useHistory();
  const setUser = useSetRecoilState(UserState);
  const setCohorts = useSetRecoilState(CohortState);
  const setCurrentCohort = useSetRecoilState(CurrentCohortState);
  const setCourseDetails = useSetRecoilState(CourseState);
  const setOnboardingAccessToken = useSetRecoilState(OnboardingAccessToken);
  const setMinimiseOnboarding = useSetRecoilState(OnboardingMinimised);
  const setPopularVideos = useSetRecoilState(PopularVideosState);
  const setSubscriptions = useSetRecoilState(SubscriptionState);
  const setBhtSubscriptionState = useSetRecoilState(BhtSubscriptionState);
  const setCohortSettings = useSetRecoilState(CohortSettingsState);
  const setConstants = useSetRecoilState(ConstantsState);
  const passcodeData = useRecoilValue(PasscodeState);
  const setSduiRes = useSetRecoilState(SduiState);
  const fetchUserProfile = async (): Promise<IUserState> => {
    const apiUrl = `${AppConfig.tllmsBaseUrl}/web/v1/me`;
    const response = (await api.get(
      apiUrl,
      null,
      false,
      null,
    )) as AxiosResponse<IUserState>;
    setUser(response?.data);
    return response?.data;
  };

  const tempUserUpdate = async (data: TempUserData) => {
    setUser(user => ({
      ...user,
      city: data.city,
      gender: data.gender,
      date_of_birth: data.dob,
      email: data.email,
      full_name: `${data.first_name} ${data.last_name}`,
    }));

    window.clevertap.profile.push({
      Site: {
        Name: `${data.first_name} ${data.last_name}`,
      },
    });

    // Update data in filter accounts
    const params = getIdentityData() || {
      access_token: '',
      identity_id: '',
      profile_type: '',
    };

    const identityResponse = await checkIdentity(params.identity_id, params);

    if (identityResponse) {
      localStorage.setItem(
        'identityresponse',
        JSON.stringify(identityResponse),
      );
    }
    const filteredAccounts = filterSiblingAccounts(identityResponse);
    localStorage.setItem('filteredAccounts', JSON.stringify(filteredAccounts));
  };

  const fetchAllCohorts = async () => {
    const apiUrl = `${AppConfig.tllmsBaseUrl}/web/v1/cohorts?source=lp`;
    const response = await api.get(apiUrl, null, false, null);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setCohorts(response?.data);
    return response?.data as Record<
      string,
      Array<Record<string, Record<string, unknown>>>
    >;
  };

  const fetchCurrentCohort = async (currentCohortId: number) => {
    const apiUrl = `${AppConfig.tllmsBaseUrl}/web/v1/cohorts/${currentCohortId}`;
    const response = await api.get(apiUrl, null, false, null);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setCurrentCohort(response.data);
    return response?.data as {
      cohort: { courses: [] };
    };
  };

  const fetchCategory = (categoryId: number) => {
    const apiUrl = `${AppConfig.tllmsBaseUrl}/web/v1/categories/${categoryId}`;
    return api.get(apiUrl, null, false, null);
  };

  const fetchCourseDetails = async (currentCohortData: {
    cohort: { courses: [] };
  }) => {
    const courses =
      (get(currentCohortData, 'cohort.courses') as Array<
        Record<string, unknown>
      >) || [];
    let courseData = { flatMap: {} };
    const promises = courses.map(element =>
      fetchCategory(element?.id as number),
    );
    const response = await Promise.all(promises);

    response.forEach(subject => {
      const { subtree: chapters, ...subjectCopy } = subject.data as {
        subtree: [];
        id: string;
      };
      const subjectFlatMap: Record<string, unknown> = chapters.reduce(
        (
          chapterFlatMapParam: Record<string, unknown>,
          chapter: {
            subtree: [];
            id: string;
          },
        ) => {
          const { subtree: subtopics, ...chapterCopy } = chapter;
          let chapterFlatMap = chapterFlatMapParam;
          chapterFlatMap[chapter.id] = chapterCopy;
          chapterFlatMap = subtopics.reduce(
            (
              subtopicFlatMapParam: Record<string, unknown>,
              topic: Record<string, unknown>,
            ) => {
              const { subtree, ...topicCopy } = topic;
              const subtopicFlatMap = subtopicFlatMapParam;
              subtopicFlatMap[topic.id as string] = topicCopy;
              return subtopicFlatMap;
            },
            chapterFlatMap,
          );
          return chapterFlatMap;
        },
        courseData.flatMap,
      );
      subjectFlatMap[subjectCopy.id] = subjectCopy;
      courseData = {
        ...courseData,
        flatMap: subjectFlatMap,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        [subject.data.id]: subject.data,
      };
    });
    setCourseDetails(courseData);
    return courseData;
  };

  function getDefaultCohortId(profiles: []): number {
    const legacyUserProfile = filter(profiles, {
      app: { id: AppConfig.legacyAppId },
    });
    return get(legacyUserProfile, '0.cohort.id', AppConfig.defaultCohortId);
  }

  function getCurrentCohortId(user: IUserState): number {
    const userProfiles = (get(user, 'user_profiles') as Array<unknown>) || [];
    const userProfile =
      filter(userProfiles, { app: { id: parseInt(AppConfig.appId, 10) } }) ||
      [];
    return (
      get(userProfile, '0.cohort.id') || getDefaultCohortId(userProfiles as [])
    );
  }

  const enrollCohort = async (cohortId: number, pagePath: string) => {
    const apiUrl = `${AppConfig.tllmsBaseUrl}/web/v1/cohorts/${cohortId}/enroll?source=lp`;
    const response = (await api.create(
      apiUrl,
      null,
      false,
      null,
    )) as AxiosResponse<Record<string, unknown>>;
    if (response.data.status) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      initializeApp(pagePath, null, false).then(
        () => {},
        () => {},
      );
    }
    return response.data;
  };

  const popularVideoList = async (cohortId: number) => {
    const apiUrl = `${AppConfig.tllmsBaseUrl}/web/v1/public/videos/popular?cohort_id=${cohortId}&limit=100`;
    const response = await api.get(apiUrl, null, false, null);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setPopularVideos(response?.data);
    return response?.data as Record<string, unknown>;
  };

  const fetchSubscriptions = async () => {
    const apiUrl = `${AppConfig.tllmsBaseUrl}/web/v1/users/subscriptions`;
    const response = await api.get(apiUrl, null, false, null);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setSubscriptions(response?.data);
    return response?.data as Record<string, unknown>;
  };

  const fetchConstants = async () => {
    const response = await api.get(
      `${AppConfig.tllmsBaseUrl}/common/api/v1/constants`,
      null,
      false,
      null,
    );
    setConstants(response?.data as Record<string, unknown>);
    return response?.data as Record<string, unknown>;
  };

  const preCleanup = () => {
    setCourseDetails(null);
    setPopularVideos(null);
    // reset onboarding recoil data
    setOnboardingAccessToken('');
    setMinimiseOnboarding(false);
    localStorage.setItem('recentActivityAllSubject', JSON.stringify(''));
  };
  const getPasscodeFields = () => {
    const passcodeFields = {
      passcode_enabled: passcodeData?.isEligible,
      passcode_expiryDate:
        passcodeData?.expiryDate &&
        moment(new Date(passcodeData?.expiryDate)).format(
          'YYYY-MM-DD HH:mm:ss',
        ),
      passcode_type: passcodeData?.lastUpdatedBy,
    };

    Object.keys(passcodeFields).forEach(key => {
      if (
        passcodeFields[key as keyof typeof passcodeFields] === undefined ||
        passcodeFields[key as keyof typeof passcodeFields] === null
      ) {
        delete passcodeFields[key as keyof typeof passcodeFields];
      }
    });
    return passcodeFields;
  };

  const initializeApp = async (
    pagePath: string,
    cohortId?: number | null,
    showOlap = true,
  ) => {
    preCleanup();
    // get all the cohorts applicable for the user
    const allLpCohorts = await fetchAllCohorts();

    // get current user data
    const user = await fetchUserProfile();

    localStorage.setItem('learn_portal_user', JSON.stringify(user));
    const currentCohortId = getCurrentCohortId(user);
    sessionIdHandler(); // create lp2Session ID

    if (showOlap) {
      if (localStorage.getItem('flow') === 'login') {
        const analyticsPayload1 = {
          m_comments: 'Onboarding SSO',
          m_category: 'login',
          u_event_id: 9201027,
          m_desc: 'login successful',
          kingdom: 'login',
          phylum: 'trigger',
          counter: 'login_successful',
        };
        olap(analyticsPayload1, {
          userId: user.id,
          cohertId: currentCohortId,
          userVariety: user?.user_subscription_type,
        });
        // capture the profile data in clevertap
        if (user) {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { mobile, gender, city, premium_account_id: premiumID } = user;
          handleClevertapLogin({
            gender,
            phone: mobile,
            city,
            premiumID,
          });
        }
        clevertap(
          { ...analyticsPayload1, ...getPasscodeFields() },
          {
            userId: user.mobile,
            cohertId: currentCohortId,
            userVariety: user?.user_subscription_type,
            PremiumId: user.premium_account_id || '',
          },
        );
      } else {
        const analyticsPayload = {
          m_comments: 'Onboarding SSO',
          m_category: 'signup',
          u_event_id: 9201028,
          m_desc: 'user created and logged in',
          kingdom: 'signup',
          phylum: 'trigger',
          counter: 'signin_successful',
        };
        olap(analyticsPayload, {
          userId: user.id,
          cohertId: currentCohortId,
          userVariety: user?.user_subscription_type,
        });
        // capturing profile data on signin successful
        if (user) {
          const { mobile, gender, city, premium_account_id: premiumID } = user;
          handleClevertapLogin({
            gender,
            phone: mobile,
            city,
            premiumID,
            defaultCommPrefs: {
              // default communication preferences
              is_whatsapp_consent: false,
              'MSG-whatsapp': false,
            },
          });
        }
        clevertap(analyticsPayload, {
          userId: user.mobile,
          cohertId: currentCohortId,
          userVariety: user?.user_subscription_type,
          PremiumId: user.premium_account_id || '',
        });
      }
    }

    const classDetails: unknown = await callSessionDetailsApi(
      pagePath,
      currentCohortId,
    );
    if (classDetails) {
      localStorage.setItem(
        'NUCAlertDetailsState',
        JSON.stringify(classDetails),
      );
    }
    if (cohortId && cohortId !== currentCohortId && allLpCohorts) {
      const requestedCohortData: unknown = allLpCohorts?.cohorts.find(
        item => cohortId === item?.cohort?.id,
      );
      if (requestedCohortData) {
        enrollCohort(cohortId, pagePath).then(
          () => {},
          () => {},
        );
        return;
      }
    }

    // get current user's subscriptions
    fetchSubscriptions().then(
      () => {},
      () => {},
    );

    // get current course details
    const currentCohortData: {
      cohort: { courses: [] };
    } = await fetchCurrentCohort(currentCohortId);

    // get bht subscription details
    const bhtSubscription: boolean = await checkBhtEligibility(currentCohortId);
    localStorage.setItem(
      'CohortGroup',
      JSON.stringify(filterCategory(currentCohortId)),
    );
    setBhtSubscriptionState(bhtSubscription);

    const neoEligibilitySettings = await fetchNeoEligibilitySettings(
      currentCohortId,
    );
    if (neoEligibilitySettings) {
      setCohortSettings(neoEligibilitySettings);
    }

    const isNeoUser =
      neoEligibilitySettings?.eligibility_response?.paid
        ?.has_neo_subscriptions &&
      neoEligibilitySettings?.eligibility_response?.paid?.is_active;
    const subscriptionTag =
      isNeoUser && currentCohortData?.is_premium === true
        ? 'lp_homepage_premium_neo'
        : 'lp_homepage';
    // get sdui response data
    const sduires = await getSduiResponseConfig(
      currentCohortId,
      subscriptionTag,
    );
    setSduiRes(sduires);
    onUserActivityTracerStart(user);
    if (pagePath && pagePath.includes('ttrf=1')) {
      // ttrf=1 -> takeToRedirectUrl=1, redirect to RedirectUrl path i.e. pagePath value
      resolveUrlAndRedirect(history, pagePath);
    } else if (bhtSubscription && toggleBHT) {
      resolveUrlAndRedirect(history, '/byjus-home-tuitions');
    } else if (pagePath) {
      resolveUrlAndRedirect(history, pagePath);
    } else if (neoEligibilitySettings?.eligibility_response.paid.is_active) {
      resolveUrlAndRedirect(history, '/byjus-classes');
    } else {
      history.replace('/home');
    }
    // scroll reset is added for mobile devices
    window.scroll(0, 0);

    popularVideoList(currentCohortId).then(
      () => {},
      () => {},
    );
    // get all topics and subtopics related to the course
    fetchCourseDetails(currentCohortData).then(
      () => {},
      () => {},
    );

    fetchConstants().then(
      () => {},
      () => {},
    );
  };

  return {
    fetchUserProfile,
    fetchAllCohorts,
    fetchCurrentCohort,
    fetchCategory,
    fetchCourseDetails,
    getCurrentCohortId,
    enrollCohort,
    popularVideoList,
    initializeApp,
    tempUserUpdate,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useUserApi };
