import Helmet from 'react-helmet';

interface Meta {
  title: string;
  content: string;
}

const MetaDecorator = ({ title, content }: Meta) => {
  const defaultTitle = "BYJU'S Learn Portal Login";
  const defaultContent =
    "Welcome to the BYJU'S Learn Portal. Login to access and explore an enriching experience of learning from expert teachers.";
  return (
    <Helmet>
      <title>{title || defaultTitle}</title>
      <meta name="description" content={content || defaultContent} />
    </Helmet>
  );
};
export default MetaDecorator;
