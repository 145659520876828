export const stateArray = [
  {
    value: 'cbse-board',
    label: 'CBSE Board',
  },
  {
    value: 'state-board',
    label: 'STATE Board',
  },
];

export const genderArray = [
  {
    value: 'M',
    label: 'Male',
  },
  {
    value: 'F',
    label: 'Female',
  },
];
