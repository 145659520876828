/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import AppConfig from 'config/AppConfig';
import { axiosGet } from 'helpers/api/core';
import { ELIGIBILITY_RESPONSE } from 'constants/eligibility/eligibility';
import { APICore } from 'helpers/api/apiCore';

const api = new APICore();

type RequestParams = {
  cohort_id: number;
};
const appConfig = AppConfig as {
  tllmsBaseUrl: string;
  newTutorApiBaseUrl: string;
};

export const fetchNeoEligibilitySettings = async (currentCohortId: number) => {
  try {
    const currentSession = localStorage.getItem('SessionState') || '';
    const { userId } = JSON.parse(currentSession);
    const eligibility = JSON.parse(JSON.stringify(ELIGIBILITY_RESPONSE));
    await axiosGet<RequestParams, unknown>(
      `${appConfig.tllmsBaseUrl}/elearn/api/v4/logistics/eligibility`,
      {
        cohort_id: currentCohortId,
      },
    ).then(async res => {
      eligibility.data.eligibility_response.paid = res.data.paid;
      eligibility.data.eligibility_response.cohort_enabled =
        res.data.cohort_enabled;
      if (!res.data.paid.is_active) {
        const headers = {
          headers: {
            tenant: 'byjus',
          },
        };
        await api
          .get(
            `${appConfig.newTutorApiBaseUrl}/tth-batching/api/v1/batchSubscription/free/eligibility?studentId=${userId}&cohortId=${currentCohortId}`,
            null,
            false,
            headers,
          )
          .then(ress => {
            eligibility.data.eligibility_response.free.free_trial =
              ress.data.data.free_trial;
          });
      }
    });
    return eligibility.data;
  } catch (error) {
    console.error(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `Error fetching Neo eligibility settings: ${error}`,
    );
    return null;
  }
};
