/* eslint-disable camelcase */
/* eslint-disable check-file/filename-naming-convention */
import qs, { ParsedQs } from 'qs';
import merge from 'lodash/merge';
import { filter } from 'lodash';
import { cityList } from 'helpers/api/onboarding';
import { getAvatars } from 'helpers/api/Personalize';
import { ErrorResponse } from 'modules/account/models';
import AppConfig from 'config/AppConfig';
import { AxiosResponse } from 'axios';
import { checkIdentity } from 'helpers/api/auth';
import jwtDecode from 'jwt-decode';
import { pushToDataLayer } from 'analytics';
import { URL_REGEX } from 'constants/global';
import { getSanitisedUrlParams } from 'utils/Sanitise';
import {
  fetchBookedSessions,
  getUpComingClassDetailsState,
} from 'helpers/api/byjusClasses';
import moment from 'moment';
import { AccountData, IdentityResponse, IHydraError } from 'interfaces';
import { PasscodePolicyResponse } from 'states/PasscodeState';
import { IUserState } from 'states/UserState';
import { TokenResponse } from '@openid/appauth';
import { ISessionDataResponse } from 'interfaces/classes';
import { CustomTracerEvents, otelCustomTracer } from 'tools/telemetry';

const blacklistedQueryParams =
  'redirectFrom,code,scope,state,auth_type,error,invalid_request,error_description,error_hint,tnlToken,token,authToken,issuer,source,userId,redirectUrl,classes[cohort_id],classes[slot_id],classes[course_id],classes[course_type],classes[start_time],classes[end_time],q,collapsedState,activeTab,referrer,classes,client_id,response_type,redirect_uri,session,_branch_match_id,_branch_referrer,click_source,course_type,mobile_no,verification-method';

export function parseLoginURL(): ParsedQs {
  let urlParams = {};
  if (window.location.hash) {
    urlParams =
      qs.parse(window.decodeURIComponent(window.location.hash.substring(1))) ||
      {};
  }
  if (window.location.search) {
    merge(
      urlParams,
      qs.parse(
        window.decodeURIComponent(window.location.search.substring(1)),
      ) || {},
    );
  }
  return urlParams;
}

export function getAccountsCount(
  filteredAccounts: AccountData[] | null,
): number {
  let accountCount = 0;
  if (filteredAccounts) {
    accountCount = filteredAccounts.length;
    return accountCount <= 1 ? accountCount : 2;
  }
  return accountCount;
}

const isK12FreePresent = (accounts: [] | AccountData[] | null): boolean => {
  let k12Free = false;
  if (accounts) {
    accounts.forEach(account => {
      account?.profiles?.forEach(profile => {
        if (profile?.type === 'k12-free') {
          k12Free = true;
        }
      });
    });
  }
  return k12Free;
};

export const filterSiblingAccounts = (
  identityResponse: AxiosResponse<IdentityResponse>,
): AccountData[] | null => {
  const accounts = identityResponse?.data?.accounts;
  const isK12Free = isK12FreePresent(accounts);
  const finalAccounts: AccountData[] | null = [];
  if (!isK12Free) {
    // todo check why we return all accounts in this case but return filtered accounts otherwise
    return accounts;
  }
  if (accounts) {
    accounts.forEach(account => {
      let profileExists = false;
      account.profiles?.forEach(profile => {
        if (profile.type === 'k12-free' || profile.type === 'k12-offline') {
          profileExists = true;
        }
      });
      if (profileExists) {
        finalAccounts.push(account);
      }
    });
  }
  return finalAccounts;
};

export function getBackendUid(accounts: AccountData[]): number | undefined {
  let backendUid: number | undefined;
  let k12FreeBackendId: number | undefined;
  if (accounts && accounts[0].profiles) {
    accounts[0].profiles.forEach(
      (profile: { type: string; backend_uid: string }) => {
        if (profile.type === 'k12-offline') {
          backendUid = parseInt(`${profile.backend_uid}`, 10);
        } else if (profile.type === 'k12-free') {
          k12FreeBackendId = parseInt(`${profile.backend_uid}`, 10);
        }
      },
    );
  }
  if (backendUid) {
    return backendUid;
  }
  return k12FreeBackendId;
}

export function getBackendUidFromAccount(
  accountResponse: AxiosResponse<AccountData>,
): number | undefined {
  let backendUid: number | undefined;
  let k12FreeBackendId: number | undefined;
  if (
    accountResponse &&
    accountResponse.data &&
    accountResponse.data.profiles
  ) {
    accountResponse.data.profiles.forEach(
      (profile: { type: string; backend_uid: string }) => {
        if (profile.type === 'k12-offline') {
          backendUid = parseInt(`${profile.backend_uid}`, 10);
        } else if (profile.type === 'k12-free') {
          k12FreeBackendId = parseInt(`${profile.backend_uid}`, 10);
        }
      },
    );
  }
  if (backendUid) {
    return backendUid;
  }
  return k12FreeBackendId;
}

export function encodeSetPhoneNo(
  selectedCountry: {
    code: string;
    dial_code: string;
    name: string;
  },
  mobileNumber: string,
) {
  localStorage.removeItem('phone_no');
  localStorage.removeItem('phone_no_encoded');
  if (selectedCountry && mobileNumber) {
    const encodedValue = `${encodeURIComponent(
      selectedCountry.dial_code,
    )}-${mobileNumber}`;
    const phoneNo = `${selectedCountry.dial_code}-${mobileNumber}`;
    localStorage.setItem('phone_no', phoneNo);
    localStorage.setItem('phone_no_encoded', encodedValue);
  }
}
export const getAccountId = (accounts: AccountData[]): string | undefined => {
  if (accounts && accounts[0]) {
    return accounts[0].id;
  }
};

export function setTnlSessionTokens(userResponse: {
  data: { user: { id: number }; token: string };
}) {
  const tnlSessionTokens: { token: string | null; userId: number | null } = {
    token: null,
    userId: null,
  };
  if (userResponse && userResponse.data) {
    if (userResponse.data.token) {
      tnlSessionTokens.token = userResponse.data.token;
    }
    if (userResponse.data.user && userResponse.data.user.id) {
      tnlSessionTokens.userId = userResponse.data.user.id;
    }
  }
  return tnlSessionTokens;
}

export function setTnlSessionTokensObj(
  tnlToken: string | null,
  usrId: string | null,
) {
  const tnlSessionTokens = { token: tnlToken, userId: usrId };
  return tnlSessionTokens;
}

export async function getCityList(): Promise<
  { value: string; label: string }[]
> {
  let cityMap: { value: string; label: string }[] = [];
  const cities = await cityList();
  if (cities && cities.data) {
    cityMap = cities.data.map(element => {
      return { value: element.city, label: element.city };
    });
  }
  return cityMap;
}

export async function fetchAvatars(): Promise<
  { id: number; thumb_url: string; url: string }[]
> {
  let avatars: { id: number; thumb_url: string; url: string }[] = [];
  const avatarsResponse = await getAvatars();
  if (avatarsResponse?.data) {
    avatars = avatarsResponse.data as {
      id: number;
      thumb_url: string;
      url: string;
    }[];
  }
  return avatars;
}

export function validateMultiAccOption(account: string) {
  const errorResp = {
    isValid: true,
    message: '',
  };
  if (!account) {
    errorResp.message = 'Please select the Account to continue';
    errorResp.isValid = false;
    return errorResp;
  }
  return errorResp;
}

export function validateOtp(otp: string, mode = 'OTP'): ErrorResponse {
  const errorResp = { isValid: true, message: '' };
  if (!otp) {
    errorResp.message = `Please enter the ${mode}`;
    errorResp.isValid = false;
    return errorResp;
  }

  if (!otp || otp.length !== 4) {
    errorResp.message = `Incomplete ${mode}. Please enter a 4 digit ${mode}`;
    errorResp.isValid = false;
    return errorResp;
  }
  return errorResp;
}

export function validatePhoneNo(isValid: boolean): {
  isValid: boolean;
  message: string;
} {
  const phoneNo = localStorage.getItem('phone_no');
  const errorResp = {
    isValid: true,
    message: '',
  };

  if (!phoneNo) {
    errorResp.message = 'Please enter the mobile number';
    errorResp.isValid = false;
    return errorResp;
  }

  if (!isValid) {
    errorResp.message = 'Please enter valid mobile number';
    errorResp.isValid = false;
    return errorResp;
  }
  return errorResp;
}

export function getPhoneNo() {
  return localStorage.removeItem('phone_no');
}

export function clearError() {
  localStorage.removeItem('error');
  localStorage.removeItem('errorDescription');
  localStorage.removeItem('errorHint');
  localStorage.removeItem('ErrorCode');
  localStorage.removeItem('ErrorState');
}

/**
 * Type predicate for IHydraError,
 * errorDesc - string,
 * rateLimit - number shared as string ex: '100',
 * rateRemaining - number shared as string ex: '0',
 * rateReset - epoch seconds as string or empty string ex: '' or '1664878183'.
 * @param object
 * @returns boolean
 */
export function isHydraError(object: unknown): object is IHydraError {
  if (
    !object ||
    (typeof object === 'object' && Object.keys(object).length === 0)
  ) {
    return false;
  }
  const hydraObject = object as IHydraError;
  return (
    typeof hydraObject.errorDesc === 'string' &&
    typeof hydraObject.rateLimit === 'string' &&
    hydraObject.rateLimit.length !== 0 &&
    Number.isInteger(Number(hydraObject.rateLimit)) &&
    typeof hydraObject.rateRemaining === 'string' &&
    hydraObject.rateRemaining.length !== 0 &&
    Number.isInteger(Number(hydraObject.rateRemaining)) &&
    typeof hydraObject.rateReset === 'string' &&
    (hydraObject.rateReset.length === 0 ||
      moment(hydraObject.rateReset, 'X').isValid())
  );
}

/**
 * When api_version is requested as '2' the hydra error description will be base64 encoded JSON data
 * this method attempts to parse the error
 * @param errorDescription
 * @returns IHydraError | undefined
 */
export function parseHydraErrorDescription(
  errorDescription: string,
): IHydraError | null {
  if (errorDescription) {
    try {
      const errorData = atob(errorDescription);
      const errorObject: unknown = JSON.parse(errorData);
      if (isHydraError(errorObject)) {
        return errorObject;
      }
    } catch (error) {
      console.error('Unable to parse hydra error description', error);
    }
  }
  return null;
}

/**
 * Gets the hydra rate limit data from localStorage
 * @returns IHydraError | null
 */
export function getHydraRateLimitData(): IHydraError | null {
  const rateLimitData = localStorage.getItem('hydraRateLimitData');
  if (rateLimitData) {
    const rateLimitObject: unknown = JSON.parse(rateLimitData);
    if (isHydraError(rateLimitObject)) {
      return rateLimitObject;
    }
  }
  return null;
}

/**
 * Stores the hydra rate limit data in local storage
 * @param errorDescription string
 */
export function storeHydraRateLimitData(errorDescription: string) {
  const errorData = parseHydraErrorDescription(errorDescription);
  if (errorData) {
    localStorage.setItem('hydraRateLimitData', JSON.stringify(errorData));
  }
}

export function getErrorMessage(
  errorType: string | undefined,
  errorHint: string | undefined,
) {
  const defaultErrorMessage = 'Something went wrong!';
  let errorDescription = '';
  if (!errorType || errorType.toLowerCase().includes('something went wrong')) {
    return defaultErrorMessage;
  }
  const errorObject = parseHydraErrorDescription(errorType);
  errorDescription = errorObject ? errorObject.errorDesc : errorType;
  if (
    errorDescription.includes('invalid credentials') &&
    errorHint === '42201'
  ) {
    pushToDataLayer({
      event: 'incorrect_otp_triggered',
      signup_step_name: `OTP Verification`,
    });
    return 'Invalid OTP, please check the OTP and try again';
  }
  if (
    errorDescription.includes('invalid credentials') &&
    errorHint === '42202'
  ) {
    return 'OTP expired, Please click on resend OTP to continue';
  }
  if (
    errorDescription.includes('passcode url expired') &&
    errorHint === '42306'
  ) {
    return 'expired';
  }
  if (
    errorDescription.includes('invalid passcode url') &&
    errorHint === '42305'
  ) {
    return 'Link not found. The link is either expired or incorrect. Please check and retry.';
  }
  if (errorDescription.includes('passcode expired') && errorHint === '42302') {
    return 'Oops! Your Passcode is Expired';
  }
  if (errorDescription.includes('invalid passcode') && errorHint === '42304') {
    return 'Invalid Passcode, please check the Passcode and try again';
  }
  if (
    errorObject &&
    errorDescription.includes('rate limit exceeded') &&
    errorHint === '40001'
  ) {
    return `Error:
    Maximum attempts exhausted.
    You have exceeded maximum number of attempts. Please try after ${moment(
      errorObject.rateReset,
      'X',
    ).fromNow(true)}`;
  }
  return defaultErrorMessage;
}

export const isCurrentUser = (account: AccountData): boolean => {
  const currentUser = localStorage.getItem('learn_portal_user');
  const currentUserId = currentUser
    ? (JSON.parse(currentUser) as IUserState).id
    : null;
  if (currentUserId) {
    const data = filter(account.profiles, {
      backend_uid: currentUserId.toString(),
    });
    if (data && data.length === 1) {
      return true;
    }
  }
  return false;
};

export const getUserProfiles = () => {
  const filteredAccounts = localStorage.getItem('filteredAccounts');
  const accounts = filteredAccounts
    ? (JSON.parse(filteredAccounts) as AccountData[])
    : null;

  const userList = [];

  if (accounts && accounts.length > 1) {
    const header = {
      label: '< Switch Profile',
      redirectTo: 'switch_profile',
      type: 'method',
      isHeader: true,
    };
    userList.push(header);
    accounts.forEach(account => {
      if (!isCurrentUser(account)) {
        const user = {
          label: `${account.first_name} ${account.last_name}`,
          redirectTo: 'switch_user',
          type: 'method',
          accountId: account.id,
          isUserItem: true,
          icon: 'menu-icons/switch_profile.png',
        };
        userList.push(user);
      }
    });
  }

  return userList;
};

export const isHideProfileFeatures = () =>
  localStorage.getItem('hideProfileFeatures') === 'true';

export const getProfileMenu = () => {
  const filteredAccounts = localStorage.getItem('filteredAccounts');
  const accounts = filteredAccounts
    ? (JSON.parse(filteredAccounts) as AccountData[])
    : null;
  const menuItems = [];

  const firstItem = {
    label: 'My profile',
    redirectTo: '/profile',
    type: 'link',
  };
  menuItems.push(firstItem);
  if (accounts && accounts.length > 1) {
    const userItem = {
      label: 'Switch Profile >',
      redirectTo: 'switch_user_menu',
      type: 'method',
      isHeader: false,
    };
    menuItems.push(userItem);
  }
  if (!isHideProfileFeatures()) {
    const logout = {
      label: 'Logout',
      redirectTo: '/account/logout',
      type: 'link',
    };
    menuItems.push(logout);
  }

  return menuItems;
};

export const isExternalUrl = (redirectUrl: string): boolean => {
  if (URL_REGEX.test(redirectUrl)) {
    return true;
  }
  return false;
};

export const resolveQueryParams = (location: Location) => {
  let urlParams: Record<string, string> = {};

  if (location.search) {
    merge(urlParams, qs.parse(location.search.substring(1)) || {});
  }
  // todo uncomment the below code if there is any error initial parsing URL
  // if (location.state?.from?.search) {
  //   merge(urlParams, qs.parse(location.state.from.search.substring(1)) || {});
  // }

  if (urlParams.redirectUrl && typeof urlParams.redirectUrl === 'string') {
    let url;
    if (isExternalUrl(urlParams.redirectUrl)) {
      url = new URL(urlParams.redirectUrl);
      urlParams.redirectUrl = url.origin.concat(url.pathname);
    } else {
      url = new URL(urlParams.redirectUrl, AppConfig.idService.lpHost); // todo check this behavior
    }

    if (url?.search) {
      merge(urlParams, qs.parse(url.search.substring(1)) || {});
    }
    if (url.pathname === `/${AppConfig.appSubPath}/account/login`) {
      urlParams.redirectUrl = '/home';
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  urlParams = getSanitisedUrlParams(urlParams);

  localStorage.setItem('urlParams', JSON.stringify(urlParams));

  if (urlParams?.referrer) {
    const utmParams = qs.parse(
      window.decodeURIComponent(urlParams.referrer.toString()),
    );
    otelCustomTracer(CustomTracerEvents.userVisitUtm, {
      tracerType: 'CustomTracer',
      options: utmParams,
      sourceMedium: utmParams.SourceMedium,
      pageCategory: utmParams.mx_Page_Category,
      sourceLead: utmParams.mx_Source_of_Lead,
      eventPage: window.location.pathname,
    });
    localStorage.setItem('referrer', JSON.stringify(utmParams));
  }

  const blItems = blacklistedQueryParams.split(',');
  const newFinalParams = new Map();

  Object.entries(urlParams).forEach(([key, value]) => {
    if (!blItems.includes(key)) {
      newFinalParams.set(key, value);
    }
  });
  const obj = Object.fromEntries(newFinalParams) as Record<string, string>;
  const queryString = new URLSearchParams(obj).toString();
  if (queryString) {
    localStorage.setItem('queryString', '?'.concat(queryString));
  }
};

export const getLoginSource = (): null | string => {
  const loginSource = localStorage.getItem('LoginSource');
  const sourceObj = (loginSource ? JSON.parse(loginSource) : null) as {
    [key: string]: unknown;
  } | null;
  if (
    sourceObj &&
    typeof sourceObj.source === 'string' &&
    sourceObj.source === 'iframe'
  ) {
    return sourceObj.source;
  }
  return null;
};

export const getUserIdFromToken = (token: string): number | undefined => {
  try {
    let userId: number | undefined;
    const params = jwtDecode(token);
    if (
      params &&
      typeof params === 'object' &&
      (params as { sub: string }).sub
    ) {
      userId = Number((params as { sub: string }).sub.split('@', 2)[0]);
    }
    return userId;
  } catch (error) {
    console.error('Error occurred while decoding token', error);
  }
};

export const getParamsFromState = (
  state: string | null,
  parameter: string,
): string | null => {
  try {
    if (!state || !parameter) {
      return null;
    }
    const decodedString = atob(state);
    const params = JSON.parse(decodedString) as Record<string, string> | null;
    return (params && params[parameter]) || null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const isSignedTokenFlow = (state: string | null): boolean => {
  const authType = getParamsFromState(state, 'authType');
  if (authType && authType === 'signed_token') {
    return true;
  }
  return false;
};

export const getRedirectUrl = (path: string) => {
  const queryString = localStorage.getItem('queryString');
  return queryString ? path.concat(queryString) : path;
};

export const getRedirectionUrl = (
  path: string,
  queryString: string,
): string => {
  return queryString ? path.concat(queryString) : path;
};

export const resolveUrlAndRedirect = (
  history: unknown,
  redirectUrl: string,
) => {
  if (isExternalUrl(redirectUrl)) {
    window.location.replace(redirectUrl);
  } else {
    (history as { replace: (location: string) => void }).replace(redirectUrl);
  }
};
export const getToggleMenuState = (
  startTime: number,
  timerStateValue: Record<string, boolean>,
): boolean => {
  if (timerStateValue && timerStateValue.cancelClicked) {
    return true;
  }
  return false;
};

export const showRedAlarmClock = (
  startTime: number,
  timerStateValue: Record<string, boolean>,
): boolean => {
  const minutes = moment(startTime * 1000).diff(moment(), 'minutes');
  if (
    minutes > 0 &&
    minutes < 15 &&
    (timerStateValue.remindMeClicked || !timerStateValue.nudge1Shown)
  ) {
    return false;
  }
  return true;
};

export const getAlarmIcon = (startTime: number): boolean => {
  const currentTime = new Date().getTime() / 1000;

  if (currentTime >= startTime) {
    return true;
  }
  return false;
};

export const findRedirectUrl = (search: string): string | null => {
  const currentData = localStorage.getItem('urlParams');
  const redirectUrl = new URLSearchParams(search).get('redirectUrl');
  if (!currentData) {
    return redirectUrl;
  }
  const urlParams = JSON.parse(currentData) as Record<string, string>;
  if (urlParams && urlParams.redirectUrl) {
    return urlParams.redirectUrl;
  }
  return redirectUrl;
};

export const getSsoCallbackUrl = (
  sessionTokens: {
    token: string;
    userId: number;
  },
  redirectUrl: string,
): string => {
  const redirectPath = redirectUrl || '%2Fbyjus-classes';
  const url = `${AppConfig.baseUrl}/sso/callback?tnlToken=${sessionTokens.token}&userId=${sessionTokens.userId}&source=iframe&redirectUrl=${redirectPath}`;
  return url;
};

export const setUpFilteredAccounts = async () => {
  const identityId = localStorage.getItem('identity_id');
  const idServiceTokenData = localStorage.getItem(
    'identity_level_tokens',
  ) as string;
  const idSvcTokens = JSON.parse(idServiceTokenData) as TokenResponse;
  const params = {
    access_token: idSvcTokens.accessToken,
    identity_id: identityId || '',
    profile_type: 'k12-offline',
  };
  const identityResponse = await checkIdentity(identityId || '', params);
  if (identityResponse) {
    localStorage.setItem('identityresponse', JSON.stringify(identityResponse));
  }
  const filteredAccounts = filterSiblingAccounts(identityResponse);
  localStorage.setItem('filteredAccounts', JSON.stringify(filteredAccounts));
};

export const getActivityData = (): Record<string, string> => {
  const referrer = localStorage.getItem('referrer');
  return referrer
    ? (JSON.parse(referrer) as Record<string, string>)
    : {
        mx_Source_of_Lead: 'learn-web',
        SourceMedium: 'website',
        mx_Page_Category: 'learn-web',
      }; //  by default setting values if there is no referrer query param on url
};

export const callSessionDetailsApi = async (
  pagePath: string,
  cohortId: number,
) => {
  const whitelistedUrls = ['/chapter-wise-tests', '/concept-videos'];

  if (whitelistedUrls.find(url => url === pagePath)) {
    const result = await fetchBookedSessions(false, '', cohortId, 0)
      .then((response: AxiosResponse<ISessionDataResponse>) => {
        return response?.status === 404 ? { sessions: [] } : response?.data;
      })
      .catch(error => {
        console.error('error', error);
      });
    if (result) {
      const classDetails = getUpComingClassDetailsState(result);
      return classDetails;
    }
  }
  return null;
};

export const clearLocalData = () => {
  localStorage.removeItem('learn_portal_user');
  localStorage.removeItem('SessionState');
  localStorage.removeItem('filteredAccounts');
  localStorage.removeItem('loginVal');
};

export const isPasscodeTokenFlow = (state: string | null): boolean => {
  return getParamsFromState(state, 'verification-method') === 'token';
};

export const getContactDetails = (
  passcodePolicyData: PasscodePolicyResponse,
  separator = ' | ',
  isDataMasked = false,
): string => {
  const result = [];
  if (!passcodePolicyData) {
    return localStorage.getItem('phone_no')?.replace('-', ' ') || '';
  }
  result.push(
    !isDataMasked
      ? localStorage.getItem('phone_no')?.replace('-', ' ')
      : passcodePolicyData.phone,
  );
  if (passcodePolicyData.email) {
    result.push(passcodePolicyData.email);
  }
  return result.join(separator);
};
