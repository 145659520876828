/* eslint-disable import/prefer-default-export */
// FIX-ME: evaluate rule: no-param-reassign
import { AxiosRequestConfig } from 'axios';
import AppConfig from 'config/AppConfig';

const SESSION_STATE_KEY = 'SessionState';
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
const { xTnlAppId }: { xTnlAppId: string } = AppConfig.idService;

interface ISession {
  accessToken: string;
  tokenType: string;
  tnlToken: string;
  userId: string;
}

export const requestInterceptor = (
  requestConfiguration: AxiosRequestConfig,
): AxiosRequestConfig => {
  // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-unsafe-member-access
  requestConfiguration.headers.accept = 'application/json';

  const currentSession = localStorage.getItem(SESSION_STATE_KEY);

  if (currentSession) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { accessToken, tokenType, tnlToken, userId }: ISession =
      JSON.parse(currentSession);

    /* eslint-disable no-param-reassign, @typescript-eslint/no-unsafe-member-access */
    if (accessToken) {
      requestConfiguration.headers.authorization = `${tokenType} ${accessToken}`;
    } else {
      delete requestConfiguration.headers.authorization;
    }

    requestConfiguration.headers['X-TNL-APPID'] = xTnlAppId;

    if (tnlToken) {
      requestConfiguration.headers['X-TNL-TOKEN'] = tnlToken;
    } else {
      delete requestConfiguration.headers['X-TNL-TOKEN'];
    }

    if (userId) {
      requestConfiguration.headers['X-TNL-USER-ID'] = userId;
    } else {
      delete requestConfiguration.headers['X-TNL-USER-ID'];
    }
    /* eslint-disable no-param-reassign, @typescript-eslint/no-unsafe-member-access */
  }

  return requestConfiguration;
};
