import { ILanguageDetail } from 'interfaces';
import { ILanguageDetailRaw } from './ibht';

const transformLanguagesAPIResponse = (
  languageRes: ILanguageDetailRaw,
): ILanguageDetail => {
  return {
    id: languageRes?.id || null,
    name: languageRes?.name || '',
    displayName: languageRes?.display_name || '',
    sortSequence: languageRes?.sort_sequence || null,
  };
};

export default transformLanguagesAPIResponse;
