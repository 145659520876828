/* eslint-disable react/jsx-props-no-spreading */
/*  Image component used for lazy-loading of images based on viewport  */
import {
  useState,
  useRef,
  useEffect,
  SetStateAction,
  CSSProperties,
} from 'react';
import { isIE, isOpera, isSafari } from 'react-device-detect';
import styled from 'styled-components';

type ImageAttributes = {
  src: string;
  alt: string;
  width?: string | number;
  height?: string | number;
  style?: CSSProperties;
  className?: string;
  props?: any;
};

export const ImageLoader = styled.span`
  display: inline-block;
  background-color: #808080;
`;
const ImageObserver = (
  ref: HTMLDivElement | null,
  setShowImage: {
    (value: SetStateAction<boolean>): void;
    (arg0: boolean): void;
  },
) => {
  const observer = new IntersectionObserver(
    (entries, observe) => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          return;
        }
        setShowImage(true);
        observe.disconnect();
      });
    },
    { threshold: 0.0, rootMargin: '200px' },
  );
  observer.observe(ref);
};

const Image = ({ src, alt, width, height, ...props }: ImageAttributes) => {
  const [showImage, setShowImage] = useState(false);
  const placeHolderRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isIE || isOpera || isSafari) {
      setShowImage(true);
    } else {
      ImageObserver(placeHolderRef.current, setShowImage);
    }
  }, []);
  if (showImage) {
    return <img src={src} alt={alt} width={width} height={height} {...props} />;
  }
  return (
    <div ref={placeHolderRef} className="placeholder-glow">
      <ImageLoader
        style={{
          minHeight: `${height}px` || '2rem',
          minWidth: `${width}px` || '2rem',
        }}
        className={`placeholder ${props?.className}`}
      />
    </div>
  );
};
export default Image;
