/* eslint-disable check-file/filename-naming-convention */
import { atom } from 'recoil';

import { persistToLocalStorage } from 'helpers/persistence';
import { PopularVideos } from 'interfaces/video';

interface PopularVideosInterface {
  videos: PopularVideos[];
}
export const PopularVideosState = atom<PopularVideosInterface | null>({
  key: 'PopularVideos',
  default: null,
  effects_UNSTABLE: [persistToLocalStorage],
});
