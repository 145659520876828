/* eslint-disable import/prefer-default-export */
import AppConfig from 'config/AppConfig';
import { APICore } from '../apiCore';

const appConfig = AppConfig as {
  tutorApiBaseUrl: string;
};

const markTutorNoShow = async (
  sessionId: string,
  action: string,
  reason: string,
  comment: string,
  // eslint-disable-next-line @typescript-eslint/ban-types
): Promise<{}> => {
  try {
    const api = new APICore();
    const markTutorNoShowResponse = await api.updatePatch(
      `${appConfig.tutorApiBaseUrl}/ttplus_web_v2/web/bht/sessions/${sessionId}/action`,
      {
        action,
        reason,
        comment,
      },
    );
    return markTutorNoShowResponse;
  } catch (error) {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      message: error?.response?.data?.error?.message,
      status: 'error',
    };
  }
};

export default markTutorNoShow;
