/* eslint-disable check-file/filename-naming-convention */
import { atom } from 'recoil';
import { persistToLocalStorage } from 'helpers/persistence';
import { TokenResponse } from '@openid/appauth';

export const NonceState = atom<null | string>({
  key: 'NonceState',
  default: null,
});

export const IdServiceTokens = atom<null | TokenResponse>({
  key: 'IdServiceTokens',
  default: null,
});

export const ResendOtpState = atom<{ count: number }>({
  key: 'ResendOtpState',
  default: {
    count: 0,
  },
});

export const ErrorState = atom<{ isError?: boolean; message?: string }>({
  key: 'ErrorState',
  default: {
    isError: false,
    message: 'Something went wrong!',
  },
  effects_UNSTABLE: [persistToLocalStorage],
});

export const RedirectState = atom<{ url: string } | null>({
  key: 'RedirectState',
  default: null,
  effects_UNSTABLE: [persistToLocalStorage],
});

export const NudgeUpcomingClassSate = atom<{ show: boolean }>({
  key: 'NudgeUpcomingClassSate',
  default: {
    show: false,
  },
  effects_UNSTABLE: [persistToLocalStorage],
});
