/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */

import ErrorBoundary from 'layouts/ErrorBoundary';

const withErrorBoundary = (Comp: any) => {
  return (props: unknown) => (
    <ErrorBoundary>
      <Comp {...props} />
    </ErrorBoundary>
  );
};
export default withErrorBoundary;
