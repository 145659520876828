/* eslint-disable check-file/filename-naming-convention */
import { atom } from 'recoil';

export const AskADoubtFullScreen = atom<boolean>({
  key: 'AskADoubtFullScreen',
  default: false,
});

export const AskADoubtWindowState = atom<boolean>({
  key: 'AskADoubtWindowState',
  default: false,
});

export const A2HSPromtEventState = atom<boolean>({
  key: 'A2HSPromtEventState',
  default: false,
});
