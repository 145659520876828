import AppConfig from 'config/AppConfig';

const appConfig = AppConfig as {
  schoolId: number;
  academicYear: string;
};

const setSchoolId = appConfig.schoolId;
const setAcademicYr = appConfig.academicYear;
export const schoolId = setSchoolId;
export const academicYear = setAcademicYr;
export const maxFilesCountForSubjectiveTests = 20;
export const maxFilesCountForQuarterlyTests = 50;
export const maxFilesCountMsg = `You can only upload a total of ${maxFilesCountForQuarterlyTests} images.`;
export const addMoreEnabledStatus = ['YET_TO_START', 'INCOMPLETE', 'COMPLETED'];
export const subjectiveGradesEnable = [22];
export const olderSubjectiveAssessmentTimestamp = 1654124400;
export const unsupportedFormatError =
  'Unsupported File Format. Please select .JPEG .JPG .PNG.';
export const supportedFormat =
  'Supported formats are .PDF .JPEG .JPG .PNG. Maximum file size is 5mb.';
export const invalidFileTypeError = 'Invalid File Format.';
export const SUPPORTED_FILE_TYPES = {
  png: 'image/png',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  pdf: 'application/pdf',
};
export const ratingProgress = {
  FIRST_STEP: 1,
  SECOND_STEP: 2,
  THIRD_STEP: 3,
};

export const COURSETAGMAP = {
  'Free Trial': 'free_trial',
  Elective: 'elective',
  Masterclass: 'masterclass',
  Classroom: 'classroom',
  Bootcamp: 'bootcamp',
  'Parent Teacher Meeting': 'ptm',
  'Doubt Resolution': 'doubt_resolution',
  classroom: 'classroom',
  bootcamp: 'bootcamp',
  doubt_resolution: 'doubt_resolution',
  ptm: 'ptm',
  free_trial: 'free_trial',
  elective: 'elective',
};

export const wQuizActivityStates = {
  expired: 'EXPIRED',
  assigned: 'ASSIGNED',
  inProgress: 'IN_PROGRESS',
  completed: 'COMPLETED',
  forceEnded: 'FORCE_ENDED'
}
