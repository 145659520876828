import AppConfig from 'config/AppConfig';
import { IRatingListResponse, IRatingSteps } from 'interfaces';
import { APICore, handleResourceNotFoundStatus } from '../apiCore';
import transformRatingListAPIResponse from './transformRatingListAPIResponse';

const appConfig = AppConfig as {
  tutorApiBaseUrl: string;
};

const getRatingList = async (
  sessionId: string,
): Promise<Array<IRatingSteps>> => {
  try {
    const api = new APICore();
    const ratingListResponse: IRatingListResponse = await api.get(
      `${appConfig.tutorApiBaseUrl}/ttplus_web_v2/web/bht/v1/sessions/${sessionId}/ratings?limit=1`,
      null,
      false,
      { validateStatus: handleResourceNotFoundStatus },
    );
    const formattedRatingList = transformRatingListAPIResponse(
      ratingListResponse?.data?.ratings?.steps,
    );
    return formattedRatingList;
  } catch (error) {
    return [];
  }
};

export default getRatingList;
