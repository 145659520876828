// @flow
import { Toast } from 'react-bootstrap';
import { ErrorState } from 'states/LoginState';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useState, useEffect } from 'react';

import './styles/Toast.scss';
import { LoginSessionExceededPop } from './LoginSessionExceededPop';

const ToastComponent = () => {
  const [showToast, setShowToast] = useState(false);
  const setErrorState = useSetRecoilState(ErrorState);
  const errorCode: number | null = localStorage.getItem(
    'ErrorCode',
  ) as unknown as number;
  const loginErrorState = useRecoilValue(ErrorState);

  useEffect(() => {
    if (loginErrorState && loginErrorState.isError === true) {
      setShowToast(true);
    }
  }, [loginErrorState.isError]);

  const closeToast = () => {
    setTimeout(() => {
      setShowToast(false);
      setErrorState({
        isError: false,
      });
    }, 5000);
  };
  if (errorCode === 403) {
    return (
      <LoginSessionExceededPop
        title="Oops! Your session got expired"
        subTitle="Click on the login button to continue"
        primaryBtnText="Login"
        isBackbtn={false}
      />
    );
  }
  return (
    showToast && (
      <div className="p-3 toast-container position-fixed bottom-0 start-50 translate-middle-x p-3">
        <Toast
          onClose={() => closeToast()}
          show={showToast}
          autohide
          onClick={() => closeToast()}
        >
          <Toast.Body className="text-white font-14">
            {loginErrorState.message}
          </Toast.Body>
        </Toast>
      </div>
    )
  );
};

export default ToastComponent;
