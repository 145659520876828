/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { getGradeNumber } from 'helpers/getGradeNumber';
import jwt from 'jsonwebtoken';
import { useRecoilValue } from 'recoil';
import { CurrentCohortState } from 'states/CohortState';
import { UserState } from 'states/UserState';

/**
 * Returns the token required for Doc and Dov and imageupload api for touchpoints
 * @returns {docToken}
 */
const useDocToken = (): string => {
  const userState = useRecoilValue(UserState) as { [key: string]: unknown };
  const currentCohortState = useRecoilValue(CurrentCohortState);
  const docSecret = '7HpCPOgGUZHgxXlKui9eN7aUoW5wJjvM93viUBCMsU';
  const tokenPayload = {
    user_profile_id: userState?.id,
    platform: 'web',
    name: userState?.full_name,
    klass: getGradeNumber(currentCohortState?.cohort?.grade) as string,
    cohort_id: currentCohortState?.cohort?.id,
    client_name: 'learn_portal',
  };
  const docToken = jwt.sign(tokenPayload, docSecret, {
    algorithm: 'HS256',
    noTimestamp: true,
  });
  return docToken;
};

export default useDocToken;
