/* eslint-disable camelcase */
import moment from 'moment';
import AppConfig from 'config/AppConfig';
import { olap } from 'analytics';
import { ELIGIBILITY_RESPONSE } from 'constants/eligibility/eligibility';
import {
  IAvailableSessionParam,
  IExitFeedback,
  ILeadData,
  IMissedFeedback,
  INeoSessionRating,
  IOlapData,
  ISessionDataResponse,
  ISubjectAssessment,
  IWhatsappReminderOption,
} from 'interfaces/classes';
import { COURSETAGMAP } from 'modules/byjusclasses/components/utils/constants';
import { APICore, handleResourceNotFoundStatus } from './apiCore';
import fetchFreeSessionsApiResponseTransform from './fetchFreeSessionsApiResponseTransform';

const api = new APICore();

const appConfig = AppConfig as {
  tutorApiBaseUrl: string;
  tllmsBaseUrl: string;
  tutorBaseUrl: string;
  tllmsAssessedUrl: string;
  assessedToken: string;
  blcAssessedApiBaseUrl: string;
  micrositeUrl: string;
  uxosApiBaseUrl: string;
  newTutorApiBaseUrl: string;
  mentoringTllmsBaseUrl: string;
  metaTutorApiBaseUrl: string;
};

const missedClasses = (missed_classes_count: number | string) => {
  return api.get(
    `${appConfig.metaTutorApiBaseUrl}/class-experience/web/neo/session_missed_surveys?missed_classes=${missed_classes_count}`,
  );
};

const missedSurveyFeedback = (data: IMissedFeedback) => {
  return api.create(
    `${appConfig.metaTutorApiBaseUrl}/class-experience/web/neo/session_missed_surveys`,
    data,
  );
};

const fetchExitIntentOptions = () => {
  return api.get(
    `${appConfig.metaTutorApiBaseUrl}/class-experience/web/neo/sessions/user_session_feedbacks`,
  );
};

const submitExitFeedBack = (params: IExitFeedback) => {
  return api.create(
    `${appConfig.metaTutorApiBaseUrl}/class-experience/web/neo/user_session_feedbacks`,
    params,
  );
};

// const fetchNewBookedSessions = (
//   isPrevious: boolean,
//   subject: string,
//   cohortId: number,
//   pageNumber: number,
// ) => {
//   let subjectParam = '';
//   if (subject) {
//     subjectParam = `&subject=${subject}`;
//   }
//   return api.get(
//     `${
//       appConfig.tutorApiBaseUrl
//     }/ttplus_web_v2/web/sessions?date_filter%5Bpivot_date%5D=${moment().format(
//       'YYYY-MM-DD',
//     )}&date_filter%5Bis_previous%5D=${isPrevious.toString()}&page=${
//       pageNumber || 1
//     }&per_page=16&cohort_id=${cohortId}${subjectParam}`,
//     null,
//     false,
//     { validateStatus: handleResourceNotFoundStatus },
//   );
// };

const fetchTestReqData = (ids: string) => {
  return api.create(
    `${appConfig.newTutorApiBaseUrl}/fusion-api/v1/requisites`,
    ids,
  );
};

const fetchPrePostReqData = (
  id: string,
  tnlCohortId: number,
  classRoomId: string,
) => {
  return api.get(
    `${appConfig.newTutorApiBaseUrl}/fusion-api/v1/requisite-status?rawTopicId=${id}&classroomId=${classRoomId}&tnlCohortId=${tnlCohortId}`,
  );
};

const fetchSessionsMetaData = () => {
  return api.get(
    `${appConfig.metaTutorApiBaseUrl}/class-experience/web/neo/sessions/meta`,
  );
};

const fetchBookedSessions = async (
  isPrevious: boolean,
  subject: string,
  cohortId: number,
  pageNumber: number,
) => {
  const currentSession = localStorage.getItem('SessionState') || '';
  const { userId } = JSON.parse(currentSession);
  let subjectParam = '';
  if (subject) {
    subjectParam = `&subject=${subject}`;
  }
  const res = await api
    .get(
      `${
        appConfig.newTutorApiBaseUrl
      }/tth/sessionsList/${userId}?tenant=byjus&isPast=${isPrevious.toString()}&size=16&page=${
        pageNumber || 1
      }&cohortId=${cohortId}${subjectParam}`,
      null,
      false,
      { validateStatus: handleResourceNotFoundStatus },
    )
    .then(async sessionListData => {
      sessionListData?.data?.data?.sessions?.map(sessionData => {
        sessionData.frozen = true;
        sessionData.course_tag =
          sessionData.class_type === 'ptm'
            ? 'ptm'
            : COURSETAGMAP[sessionData.course_tag];
        sessionData.course_type = sessionData.course_type || 'neo';
        // plts, ptm topic and subject name update
        sessionData.topic_name =
          sessionData?.class_type === 'ptm'
            ? 'Parent Teacher Meeting'
            : sessionData.topic_name;
        sessionData.subject_name =
          sessionData?.class_type === 'ptm' ? 'PTM' : sessionData.subject_name;
        sessionData.start_time =
          new Date(sessionData.start_time).getTime() / 1000;
        sessionData.end_time = new Date(sessionData.end_time).getTime() / 1000;
      });
      if (!isPrevious) {
        await fetchSessionsMetaData()
          .then(metaData => {
            sessionListData.data.data.meta.show_missed_class_survey_banner =
              metaData.data.show_missed_class_survey_banner;
            sessionListData.data.data.meta.show_early_leave_banner =
              metaData.data.show_early_leave_banner;
            sessionListData.data.data.meta.no_of_missed_classes =
              metaData.data.no_of_missed_classes;
          })
          .catch(error => {
            console.error(error);
            return null;
          });
      }
      const testSessionPayload = {};
      const testSessionIds = [];
      sessionListData?.data?.data?.sessions
        .filter(obj => obj.session_type?.toString() === 'assessment')
        .map(obj => {
          if (obj.topic_id && obj.alias_id) {
            testSessionIds.push({
              rawTopicId: obj.topic_id,
              classroomId: obj.alias_id,
            });
          }
        });
      testSessionPayload.tnlCohortId = cohortId;
      testSessionPayload.rawTopics = testSessionIds;
      if (testSessionIds.length > 0) {
        await fetchTestReqData(testSessionPayload)
          .then(testdata => {
            if (testdata.data) {
              const tData = testdata.data;
              sessionListData?.data?.data?.sessions.map(obj => {
                if (obj.session_type?.toString() === 'assessment') {
                  let testRequisitesData = tData[obj.topic_id];
                  if (testRequisitesData?.length > 0) {
                  testRequisitesData[0].display_start_time = obj.start_time;
                  testRequisitesData[0].display_end_time = obj.end_time;
                  }
                  obj.test_requisites = testRequisitesData;
                }
                return obj;
              });
            }
          })
          .catch(error => {
            console.error(error);
            return null;
          });
      }
      return sessionListData?.data;
    });
  return res;
};

const fetchTopics = (courseId: number | string, sessionId: number | string) => {
  return api.get(
    `${appConfig.tutorApiBaseUrl}/ttplus_web_v2/web/one_to_mega/courses/${courseId}/topics?session_id=${sessionId}`,
  );
};

const bookTopic = (
  courseTopicId: number | string,
  sessionId: number | string,
) => {
  return api.update(
    `${appConfig.tutorApiBaseUrl}/ttplus_web_v2/web/one_to_mega/sessions/${sessionId}`,
    { course_topic_id: courseTopicId },
  );
};

const studentAttendance = (
  sessionId: number | string,
  attendanceStatus: boolean,
) => {
  return api.update(
    `${appConfig.tutorApiBaseUrl}/ttplus_web_v2/web/neo/sessions/${sessionId}`,
    { attended: attendanceStatus },
  );
};

const fetchBlcBookedSessions = (
  isPrevious: boolean,
  subject: string,
  cohortId: number,
  pageNumber: number,
) => {
  let subjectParam = '';
  if (subject) {
    subjectParam = `&subject=${subject}`;
  }
  return api.get(
    `${
      appConfig.tutorApiBaseUrl
    }/blc_web/web/sessions?date_filter%5Bpivot_date%5D=${moment().format(
      'YYYY-MM-DD',
    )}&date_filter%5Bis_previous%5D=${isPrevious.toString()}&page=${
      pageNumber || 1
    }&per_page=16&cohort_id=${cohortId}${subjectParam}`,
    null,
    false,
    { validateStatus: handleResourceNotFoundStatus },
  );
};

const fetchFreeSessions = async (cohortId: number, swap = false) => {
  let queryParams = {};
  if (swap) {
    queryParams = { cohort_id: cohortId, swap: true };
  } else {
    queryParams = { cohort_id: cohortId };
  }
  const response = await api.get(
    `${appConfig.newTutorApiBaseUrl}/tth-orchestration/api/v1/batch_schedule/free_trial?courseTag=free_trial&tenant=byjus&cohortId=${cohortId}`,
    null,
    false,
    null,
  );
  const transformedResponse = fetchFreeSessionsApiResponseTransform(
    response.data,
  ); // response.data
  return { ...transformedResponse };
};

function bookAvailableSession(data: IAvailableSessionParam) {
  return api.create(
    `${appConfig.mentoringTllmsBaseUrl}/web/v1/mentoring_subscriptions`,
    data,
  );
}
function setWhatsapppReminderOption(data: IWhatsappReminderOption) {
  return api.create(`${appConfig.tllmsBaseUrl}/web/v1/update_user`, data);
}

function fetchServerTime() {
  return api.get(`${appConfig.tllmsBaseUrl}/web/v1/get_server_time`, null);
}

const getFreeSessionDetails = (slotId: number) => {
  const headers = {
    headers: {
      'x-tnl-tenant': 'byjus',
    },
  };
  const currentSession = localStorage.getItem('SessionState') || '';
  const { userId } = JSON.parse(currentSession);

  return api.get(
    `${appConfig.newTutorApiBaseUrl}/tth/sessionListBySlotId/${userId}?slotId=${slotId}`,
    null,
    false,
    headers,
  );
};

async function getSessionDetails(sessionId: number, courseType: string) {
  if (courseType === 'blc') {
    return api.get(
      `${appConfig.tutorBaseUrl}/blc_web/web/neo/sessions/${sessionId}`,
      null,
    );
  }
  const param = courseType === 'one_to_mega' ? courseType : 'neo';
  const headers = {
    headers: {
      'x-tnl-tenant': 'byjus',
    },
  };
  const sessionDetailData = await api
    .get(
      `${appConfig.metaTutorApiBaseUrl}/class-experience/web/${param}/sessions/${sessionId}`,
      null,
      null,
      headers,
    )
    .then(async sessionDet => {
      const currentCohort = localStorage.getItem('CurrentCohort') || '';
      const { cohort } = JSON.parse(currentCohort);
      await fetchPrePostReqData(
        sessionDet.data.session.topic_id,
        cohort.id,
        sessionDet.data.session.legacy_classroom_id,
      )
        .then(preResData => {
          sessionDet.data.session.pre_requisites =
            preResData.data.pre_requisites;
          sessionDet.data.session.post_requisites =
            preResData.data.post_requisites;
            let testReqData = preResData.data.test_requisites;
        if (testReqData?.length > 0) {
          testReqData[0].display_end_time = sessionDet.data.session.end_time;
          testReqData[0].display_start_time =
            sessionDet.data.session.start_time;
        }
        sessionDet.data.session.test_requisites = testReqData;
        })
        .catch(error => {
          console.error(error);
          return null;
        });
      return sessionDet;
    });

  return sessionDetailData;
}
const checkEligibility = async (cohortId: number) => {
  const currentSession = localStorage.getItem('SessionState') || '';
  const { userId } = JSON.parse(currentSession);
  const eligibility = JSON.parse(JSON.stringify(ELIGIBILITY_RESPONSE));
  await api
    .get(`${appConfig.tllmsBaseUrl}/elearn/api/v4/logistics/eligibility`, {
      cohort_id: cohortId,
    })
    .then(async paidEligibility => {
      eligibility.data.eligibility_response.paid = paidEligibility.data.paid;
      eligibility.data.eligibility_response.cohort_enabled =
        paidEligibility.data.cohort_enabled;
      if (!paidEligibility.data.paid.is_active) {
        const headers = {
          headers: {
            tenant: 'byjus',
          },
        };
        await api
          .get(
            `${appConfig.newTutorApiBaseUrl}/tth-batching/api/v1/batchSubscription/free/eligibility?studentId=${userId}&cohortId=${cohortId}`,
            null,
            false,
            headers,
          )
          .then(freeEligibility => {
            eligibility.data.eligibility_response.free.free_trial =
              freeEligibility.data.data.free_trial;
          });
      }
    });
  return eligibility;
};

const fetchLastSubscriptionStatus = (cohortId: number) => {
  return api.get(
    `${appConfig.tutorApiBaseUrl}/ttplus_web_v2/web/one_to_mega/subscriptions/last_subscription_status`,
    { cohort_id: cohortId },
    false,
    { validateStatus: handleResourceNotFoundStatus },
  );
};

const generateTestApi = (assesmentId: number) => {
  return api.create(`${appConfig.tllmsBaseUrl}/web/v1/assignments/generate/`, {
    assessment_id: assesmentId,
  });
};

const startSubjectiveAssessment = (data: ISubjectAssessment) => {
  return api.create(
    `${appConfig.tllmsAssessedUrl}/api-gateway/api/student/subjective-homework/assessment/start`,
    data,
  );
};

const getAssessmentResultDetails = (
  assessmentsIds: Array<number | string>,
  premium_account_id: number | string,
) => {
  const assessmentIdsString = assessmentsIds.join(',');
  const headers = {
    headers: {
      Authorization: `Bearer ${appConfig.assessedToken}`,
      'Content-Type': 'application/json',
    },
  };
  return api.get(
    `${appConfig.blcAssessedApiBaseUrl}/api-gateway/api/v2/student/assessments-assets?assessment_ids=${assessmentIdsString}&tllmsUserId=${premium_account_id}`,
    null,
    1,
    headers,
  );
};

const getSubjectiveAssessmentResultDetails = (
  assessmentsIds: Array<number | string>,
) => {
  return api.create(
    `${appConfig.tllmsAssessedUrl}/api-gateway/api/student/v1/subjective-homework/status`,
    assessmentsIds,
  );
};
const getSubjectiveTestResultDetails = (
  assessmentsIds: Array<number | string>,
) => {
  return api.create(
    `${appConfig.tllmsAssessedUrl}/api-gateway/api/v1/subjective-test/student/assessment/status`,
    assessmentsIds,
  );
};
const startSubjectiveTest = (body: ISubjectAssessment) => {
  return api.create(
    `${appConfig.tllmsAssessedUrl}/api-gateway/api/v1/subjective-test/student/assessment/start`,
    body,
  );
};
const getAssessAuthToken = (userId: number | string) => {
  return api.get(
    `${appConfig.tllmsBaseUrl}/web/v1/authentications/grant_token?user_id=${userId}`,
  );
};

const leadSquared = (data: ILeadData) => {
  return api.create(
    `${appConfig.tutorApiBaseUrl}/ttplus_web_v2/web/one_to_mega/requisites/lead_squared_activities`,
    data,
  );
};

const getSessionRatings = (session_id: number | string) => {
  return api.get(
    `${appConfig.metaTutorApiBaseUrl}/class-experience/web/neo/sessions/${session_id}/ratings?extra[]=steps&extend[]=resume&sort[]=-created_at&limit=1`,
  );
};

const submitNeoSessionRating = (
  session_id: number | string,
  params: INeoSessionRating,
) => {
  return api.create(
    `${appConfig.metaTutorApiBaseUrl}/class-experience/web/neo/sessions/${session_id}/ratings/`,
    params,
  );
};

const updateNeoSessionRating = (
  session_id: number | string,
  rating_id: number | string,
  params: INeoSessionRating,
) => {
  return api.updatePatch(
    `${appConfig.metaTutorApiBaseUrl}/class-experience/web/neo/sessions/${session_id}/ratings/${rating_id}`,
    params,
  );
};

const OLAPHelperByjusClasses = (params: IOlapData) => {
  olap({
    m_comments: 'Classes',
    phylum: 'click',
    ...params,
  });
};

const OLAPHelperBCls = (params: IOlapData) => {
  olap({
    phylum: 'click',
    ...params,
  });
};

const OLAPHelperPrePost = (params: IOlapData) => {
  olap({
    m_comments: 'Classes',
    m_category: 'Class page',
    phylum: 'click',
    ...params,
  });
};

const OLAPHelperAakash = (params: IOlapData) => {
  olap({
    m_comments: 'Aakash Live classes',
    ...params,
  });
};

const OLAPHelperSessionDetails = (params: IOlapData) => {
  olap({
    m_comments: 'Classes',
    ...params,
  });
};

const blcLeadSquared = (data: ILeadData) => {
  return api.create(
    `${appConfig.tutorApiBaseUrl}/blc_web/web/blc/v1/requisites/lead_squared_activities`,
    data,
  );
};

const fetchBTCBookedSession = (cohortId: number) => {
  const urlStr = encodeURI(
    `${
      appConfig.tutorApiBaseUrl
    }/blc_web/web/sessions?date_filter[pivot_date]=${moment().format(
      'YYYY-MM-DD',
    )}&date_filter[is_previous]=false&page=1&per_page=1&cohort_id=${cohortId}`,
  );
  // to avoid throwing error on 404 status which says cannot find student
  return api.get(urlStr, null, false, {
    validateStatus: handleResourceNotFoundStatus,
  });
};

const getUpComingClassDetailsState = (data: ISessionDataResponse) => {
  const eligibleSessions = data.sessions.filter(
    item =>
      item.course_tag !== 'masterclass' &&
      item?.session_type?.toLowerCase() === 'video',
  );
  if (eligibleSessions?.length > 0) {
    return eligibleSessions[0];
  }
  return null;
};

const OLAPHelperSubjectiveHomeWork = (params: IOlapData) => {
  olap({
    m_category: 'Subjective Homework',
    kingdom: 'byjus_classes',
    ...params,
  });
};

const getMicroSiteDetails = () => {
  return api.get(`${appConfig.micrositeUrl}`, null, false, {});
};

const fetchGetTimeTableData = (COHORT_ID: number) => {
  return api.get(
    `${AppConfig.tutorApiBaseUrl}/ttplus_web_v2/web/neo/time_table?cohort_id=${COHORT_ID}`,
  );
};

const whatsappTimeTable = (id: number | undefined) => {
  return api.create(
    `${AppConfig.tutorApiBaseUrl}/ttplus_web_v2/web/neo/time_table/${id}/share_time_table_whatsapp`,
  );
};

const seenTimeTableChanged = (id: number | undefined) => {
  return api.create(
    `${AppConfig.tutorApiBaseUrl}/ttplus_web_v2/web/neo/time_table/${id}/time_table_seen`,
  );
};

const fetchUserBatchDeatils = (userId: number, cohortId: number) => {
  const params = {
    userId,
    cohortId,
    fetchProfile: true,
    tenant: 'byjus',
  };
  const headers = {
    headers: {
      tenant: 'byjus',
    },
  };

  return api.get(
    `${AppConfig.newTutorApiBaseUrl}/tth-orchestration/api/v1/sub-batch/ptm/student-subbatch-details`,
    params,
    null,
    headers,
  );
};

export {
  fetchBookedSessions,
  getFreeSessionDetails,
  OLAPHelperSubjectiveHomeWork,
  fetchFreeSessions,
  bookAvailableSession,
  setWhatsapppReminderOption,
  checkEligibility,
  fetchServerTime,
  generateTestApi,
  fetchBlcBookedSessions,
  fetchLastSubscriptionStatus,
  leadSquared,
  OLAPHelperByjusClasses,
  OLAPHelperSessionDetails,
  OLAPHelperPrePost,
  getAssessmentResultDetails,
  getAssessAuthToken,
  OLAPHelperAakash,
  fetchTopics,
  bookTopic,
  studentAttendance,
  blcLeadSquared,
  getSubjectiveAssessmentResultDetails,
  startSubjectiveAssessment,
  fetchBTCBookedSession,
  getSessionDetails,
  missedClasses,
  missedSurveyFeedback,
  fetchExitIntentOptions,
  getUpComingClassDetailsState,
  submitExitFeedBack,
  getSubjectiveTestResultDetails,
  startSubjectiveTest,
  getSessionRatings,
  updateNeoSessionRating,
  submitNeoSessionRating,
  OLAPHelperBCls,
  getMicroSiteDetails,
  fetchGetTimeTableData,
  whatsappTimeTable,
  seenTimeTableChanged,
  fetchTestReqData,
  fetchPrePostReqData,
  fetchUserBatchDeatils,
};
