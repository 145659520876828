import { API_CACHE_KEY } from 'constants/global';

/* eslint-disable import/prefer-default-export */
const pathRegexMatch = (path: string): boolean => {
  const pathRegex = [
    '\\/web\\/v1\\/cohorts\\/\\d+\\/aakash_live_eligibility',
    '\\/web\\/v1\\/categories\\/\\d+',
  ];
  let result = false;
  pathRegex.forEach(pattern => {
    const regex = new RegExp(pattern, 'gm');
    if (regex.test(path)) {
      result = true;
    }
  });
  return result;
};

export const isCacheable = (requestUrl: string): boolean => {
  const cacheablePaths = [
    '/web/v1/batch_subscriptions',
    '/blc_web/common/blc/v2/settings/check_eligibility',
    '/web/v1/mentor/session_bookings',
    '/web/v1/public/videos/popular',
    '/web/v1/cohorts',
  ];
  const url = new URL(requestUrl);
  if (cacheablePaths.includes(url.pathname) || pathRegexMatch(url.pathname)) {
    return true;
  }
  return false;
};

export const clearSWCache = async () => {
  const cacheName = API_CACHE_KEY;
  await caches.delete(cacheName);
};
