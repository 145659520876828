import styled from 'styled-components';
import { Card, Row, Col, Button } from 'react-bootstrap';

export const SessionPopCard = styled(Card)`
  display: flex;
  justify-content: center;
  border: 1.07px solid #f6f6f6;
  max-width: 516px;
  margin: 1rem;
`;
export const SessionPopRow = styled(Row)`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const SessionPopCol = styled(Col)`
  display: flex;
  justify-content: center;
`;

export const SessionPopCardBody = styled(Card.Body)`
  margin: 0rem;
`;
export const SessionPopContentBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SessionPopImage = styled.img`
  width: 11.25rem;
  height: 11.25rem;
  align-item: center;
  margin-top: 1.564rem;

  @media (max-width: 575.98px) {
    width: 9.781rem;
    height: 9.25rem;
  }
`;
export const SessionPopHeader = styled.h1`
  font-weight: 600;
  font-size: 1.75rem;
  color: #444444;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  @media (max-width: 575.98px) {
    font-size: 1.1rem;
  }
`;

export const SessionPopImageDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const SessionPopTitle = styled.p`
  font-weight: 400;
  font-size: 0.875rem;
  color: #888888;
  text-align: center;
  margin-bottom: 2.5rem;
`;

export const SessionPopButton = styled(Button)`
  background: #8c69ff;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 0.875rem;
  text-align: center;
  color: #ffffff;
  height: 2.313rem;
  width: 9.063rem;
`;

export const SessionPopLink = styled(Button)`
  background: #ffffff;
  border: none;
  margin-top: 1rem;
  font-weight: 400;
  font-size: 0.875rem;
  height: 2.313rem;
  width: 9.063rem;
  color: #8c69ff;
  text-align: center;
  margin-bottom: 1.564rem;
  &:hover {
    background: #ffffff;
    border: none;
    text-decoration: underline;
    color: #8c69ff;
  }
`;

export const SessionPopBackGround = styled.div`
  display: flex;
  justify-content: center;
  content: '';
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1003;
  align-items: center;
`;
