export const ELIGIBILITY_RESPONSE = {
  data: {
    eligibility_response: {
      cohort_enabled: false,
      paid: {},
      free: {
        free_trial: {
          rebooking_enabled: false,
          booking_flow: 'normal',
          booking_eligible: false,
          has_upcoming_session: false,
          has_ongoing_session: false,
          atleast_one_normal_booking: false,
          remaining_normal_booking_count: 0,
        },
      },
    },
  },
};
