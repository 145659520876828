/* eslint-disable check-file/filename-naming-convention */
/* eslint-disable camelcase */
import { atom } from 'recoil';

interface BLCEligibilityStateInterface {
  display_cta: boolean;
  subscription: object;
  cohort_id: number | undefined;
  grade: { name: string } | undefined;
  name: string | undefined;
}

const BLCEligibilityState = atom<BLCEligibilityStateInterface>({
  key: 'BLCEligibility',
  default: {
    display_cta: false,
    subscription: {},
    cohort_id: undefined,
    grade: undefined,
    name: undefined,
  },
});

export default BLCEligibilityState;
