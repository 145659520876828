/* eslint-disable camelcase */
/* eslint-disable check-file/filename-naming-convention */
import { atom, selector } from 'recoil';

import { persistToLocalStorage } from 'helpers/persistence';
import { filter, get as _get, startCase } from 'lodash';
import { CurrentCohortState } from 'states/CohortState';
import { CohortState } from './CohortState';
import { CohortArray } from '../interfaces/profileInterface';

interface MentoringSubscription {
  id: number;
  user_id: number;
  sessions_available: number;
  expiry_time: string;
  created_at: string;
  deactivation_date: string;
  subscription_type: string;
  cohort_id: number | null;
  sessions_granted: number;
  is_active: boolean;
  is_paid: boolean;
  validity_start: string;
  mentoring_mode: string;
  cohort_name: string | null;
  category_name: string;
  category_id: number;
}

interface SubscriptionEnrollments {
  id: number;
  type: string;
  category_id: number;
  activation_date: string | null;
  deactivation_date: string | null;
  cohort_id: number | null;
  is_paid: boolean;
  cohort_name: string;
  category_name: string;
}
interface SubscriptionStateInterface {
  mentoring_subscriptions: MentoringSubscription[];
  subscription_enrollments: SubscriptionEnrollments[];
}

export const SubscriptionState = atom<SubscriptionStateInterface | null>({
  key: 'SubscriptionState',
  default: null,
  effects_UNSTABLE: [persistToLocalStorage],
});

function getCohortDisplayName(
  cohorts: CohortArray | null,
  cohortId: number | null,
) {
  const filteredCohort =
    filter(cohorts?.cohorts, { cohort: { id: cohortId } }) || [];
  return _get(filteredCohort, '0.cohort.display_name', '');
}

/**
 * Paid sync subscriptions: Mentor based subscriptions.
 * example: aakash_live_classes, blc, neo, new_one_to_mega, one_to_mega
 */
function filterPaidSyncSubscriptions(
  cohorts: CohortArray | null,
  syncSubscriptions: MentoringSubscription[] = [],
) {
  const filteredSyncSubscriptions: {
    type: 'sync';
    cohortId: number | null;
    mode: string;
    cohortName: string | null;
    expiresOn: number;
    startsOn?: number | '';
  }[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const subscription of syncSubscriptions) {
    const mentoringMode =
      subscription.mentoring_mode === 'blc'
        ? 'BLC'
        : startCase(subscription.mentoring_mode);
    const filteredSubscription = filteredSyncSubscriptions.find(
      item =>
        item.cohortId === subscription.cohort_id && item.mode === mentoringMode,
    );
    const isActive = Date.parse(subscription?.expiry_time) > Date.now();
    const isPaid = subscription?.subscription_type === 'paid';
    if (isActive && isPaid) {
      if (!filteredSubscription) {
        filteredSyncSubscriptions.push({
          type: 'sync',
          cohortId: subscription.cohort_id,
          mode: mentoringMode,
          cohortName:
            getCohortDisplayName(cohorts, subscription.cohort_id) ||
            subscription.cohort_name,
          expiresOn: Date.parse(subscription.expiry_time),
          startsOn:
            subscription.validity_start &&
            Date.parse(subscription.validity_start),
        });
      }
    }
  }
  return filteredSyncSubscriptions;
}

/**
 * Paid async subscriptions : Self paced learning content.
 * example: app2, parity, aakash_app, aakash_offline
 */
function filterPaidAsyncSubscriptions(
  cohorts: CohortArray | null,
  asyncSubscriptions: MentoringSubscription[] = [],
) {
  const filteredAsyncSubscriptions: {
    type: 'async';
    cohortId: number | null;
    cohortName: string | null;
    expiresOn: string;
    categories: string[];
    categoryIds: number[];
  }[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const subscription of asyncSubscriptions) {
    const filteredSubscription = filteredAsyncSubscriptions.find(
      item => item.cohortId === subscription.cohort_id,
    );
    const isActive = Date.parse(subscription?.deactivation_date) > Date.now();
    const isPaid = subscription?.is_paid;
    if (isActive && isPaid) {
      if (!filteredSubscription) {
        filteredAsyncSubscriptions.push({
          type: 'async',
          cohortId: subscription.cohort_id,
          cohortName:
            getCohortDisplayName(cohorts, subscription.cohort_id) ||
            subscription.cohort_name,
          expiresOn: subscription?.deactivation_date,
          categories: [subscription?.category_name],
          categoryIds: [subscription?.category_id],
        });
      } else {
        filteredSubscription.categories.push(subscription?.category_name);
        filteredSubscription.categoryIds.push(subscription?.category_id);
      }
    }
  }
  return filteredAsyncSubscriptions;
}

export const PaidSubscriptions = selector({
  key: 'ActiveSubscriptions',
  get: ({ get }) => {
    const subscriptions = get(SubscriptionState);
    const cohorts = get(CohortState);
    const paidSync = filterPaidSyncSubscriptions(
      cohorts,
      subscriptions?.mentoring_subscriptions,
    );
    const paidAsync = filterPaidAsyncSubscriptions(
      cohorts,
      subscriptions?.subscription_enrollments,
    );
    const paidSubscriptions = paidSync.concat(paidAsync);
    const totalPaidSubscriptions = paidSubscriptions.length;
    return {
      hasPaidSubscription: totalPaidSubscriptions !== 0,
      paidSync,
      paidAsync,
      paidSubscriptions,
      totalPaidSubscriptions,
    };
  },
});

/**
 * For free user, isPremium will be false. (This is the default condition)
 * For paid user, isPremium will be true.
 * For paid sync user, isPremium and isPaidSync will be true, mode will have the mentoring mode name.
 * For paid async user, isPremium and isPaidAsync will be true.
 */
export const CohortSubscriptionType = selector({
  key: 'CurrentCohortSubscriptionType',
  get: ({ get }) => {
    const currentCohort = get(CurrentCohortState);
    const currentCohortId = currentCohort?.cohort?.id;
    const subscriptions = get(PaidSubscriptions);
    if (currentCohortId && subscriptions) {
      const paidSyncSubscription = subscriptions.paidSync.find(
        item => item.cohortId === currentCohortId,
      );
      if (paidSyncSubscription) {
        return {
          isPremium: true,
          isPaidSync: true,
          isPaidAsync: false,
          mode: paidSyncSubscription.mode,
        };
      }
      const paidAsyncSubscription = subscriptions.paidAsync.find(
        item => item.cohortId === currentCohortId,
      );
      if (paidAsyncSubscription) {
        return {
          isPremium: true,
          isPaidSync: false,
          isPaidAsync: true,
          mode: null,
        };
      }
    }
    return {
      isPremium: false,
      isPaidSync: false,
      isPaidAsync: false,
      mode: null,
    };
  },
});
