/* eslint-disable camelcase */
import { TokenResponse } from '@openid/appauth';
import { AxiosError, AxiosResponse } from 'axios';
import AppConfig from 'config/AppConfig';
import { isTokenExpired } from 'helpers/helper';
import { IdentityResponse } from 'interfaces';
import { getActivityData } from 'modules/account/Utilities';
import {
  AuthInitiateResponse,
  PasscodePolicyResponse,
  UpdatePasscodeRequest,
  UpdatePasscodeUseCases,
} from 'states/PasscodeState';
import { IUserState } from 'states/UserState';
import { clearSWCache } from 'utils/swUtils';
import * as uuid from 'uuid';
import { CustomTracerEvents, otelCustomTracer } from 'tools/telemetry';
import qs from 'qs';
import { APICore } from './apiCore';
import App from './appAuth';

const api = new APICore();

type TllmsLoginResponse = {
  user: { id: number; [key: string]: unknown };
  token: string;
  server_time: number;
};

type RequestOtpResponse = {
  id: string;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
  phone: string;
  nonce: string;
};

async function requestOtp(params: {
  phone: string;
  app_client_id: string;
}): Promise<AxiosResponse<RequestOtpResponse>> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return api.create(
    `${AppConfig.idService.baseUrl}/api/request_otp`,
    params,
    true,
    undefined,
  );
}

async function getSessionDetails(sessionId: number, courseType: string) {
  const apiUrl = `${AppConfig.tutorApiBaseUrl}/ttplus_web_v2/web/${courseType}/sessions/${sessionId}`;
  return api.get(apiUrl, null, false, undefined);
}

async function lpBackendRegister(params: {
  user: {
    current_cohort_id: number;
    mobile: string;
    name: string;
    non_unique_email: string;
    city: string;
  };
  activity_data: Record<string, unknown>;
  root_oidc_token: string;
}) {
  otelCustomTracer(CustomTracerEvents.registerUtm, {
    tracerType: 'CustomTracer',
    options: params?.activity_data,
    sourceMedium: params?.activity_data?.SourceMedium,
    pageCategory: params?.activity_data?.mx_Page_Category,
    sourceLead: params?.activity_data?.mx_Source_of_Lead,
    eventPage: window.location.pathname,
  });
  const apiUrl = `${AppConfig.tllmsBaseUrl}/common/api/v1/users`;
  return api
    .createNew(apiUrl, params, {})
    .then(res => {
      return res;
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return error;
    });
}

async function checkIdentity(
  identity: string,
  params: {
    access_token: string;
    identity_id: string;
    profile_type: string;
  },
): Promise<AxiosResponse<IdentityResponse>> {
  const apiUrl = `${AppConfig.idService.baseUrl}/api/v2/identities/${identity}`;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return api.get(apiUrl, params, true, undefined);
}
async function getAccountDetails(
  accountId: string,
  params: {
    access_token: string;
    account_id: string;
  },
) {
  const apiUrl = `${AppConfig.idService.baseUrl}/api/accounts/${accountId}`;
  return api.get(apiUrl, params, true, undefined);
}

async function lpBackendLogin(profileId: number, forcedLogin = false) {
  let isForceLoginUseCase = false;
  const isProfileSwitchInitiated = localStorage.getItem(
    'isProfileSwitchInitiated',
  );
  if (isProfileSwitchInitiated) {
    localStorage.removeItem('isProfileSwitchInitiated');
    isForceLoginUseCase = true;
  }
  const idToken = localStorage.getItem('identity_id_token');
  const referrer = getActivityData();
  const params = {
    profile_id: parseInt(`${profileId}`, 10),
    verification_method: 'root_oidc_token',
    verification_payload: {
      root_oidc_token: idToken,
    },
    activity_data: referrer,
    forced_login: forcedLogin || isForceLoginUseCase,
  };
  otelCustomTracer(CustomTracerEvents.loginUtm, {
    tracerType: 'CustomTracer',
    options: referrer,
    sourceMedium: referrer.SourceMedium,
    pageCategory: referrer.mx_Page_Category,
    sourceLead: referrer.mx_Source_of_Lead,
    eventPage: window.location.pathname,
  });
  const apiUrl = `${AppConfig.tllmsBaseUrl}/common/api/v1/authentications/login`;
  return api
    .createNew(apiUrl, params, {})
    .then((res: AxiosResponse<TllmsLoginResponse>) => {
      return res;
    })
    .catch((error: AxiosError) => {
      return error;
    });
}

async function lpBackendLoginV2(
  profileId: number,
  authToken: string,
  forcedLogin = true,
) {
  const params = {
    profile_id: parseInt(`${profileId}`, 10),
    verification_method: 'identity',
    verification_payload: {
      identity_token: authToken,
    },
    activity_data: getActivityData(),
    forced_login: forcedLogin,
  };
  const apiUrl = `${AppConfig.tllmsBaseUrl}/common/api/v1/authentications/login`;
  return api
    .createNew(apiUrl, params, {})
    .then((res: AxiosResponse<TllmsLoginResponse>) => {
      return res;
    })
    .catch((error: AxiosError) => {
      return error;
    });
}

async function logout() {
  const apiUrl = `${AppConfig.tllmsBaseUrl}/common/api/v1/authentications`;
  return api
    .delete(apiUrl, { isCritical: 'Something went wrong' })
    .then((res: AxiosResponse<string>) => {
      return res;
    })
    .catch((error: AxiosError) => {
      return error;
    });
}

async function logoutIdService() {
  const idServiceTokens = localStorage.getItem('idServiceTokens');
  const idsvctokenobject = JSON.parse(idServiceTokens ?? '') as TokenResponse;
  const apiUrl = `${AppConfig.idService.hydraAuthBaseUrl}/oauth2/revoke`;
  return api
    .create(
      apiUrl,
      qs.stringify({
        client_id: AppConfig.idService.clientId,
        token: idsvctokenobject.accessToken,
      }),
      true,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )
    .then((res: AxiosResponse<string>) => {
      return res;
    })
    .catch((error: AxiosError) => {
      return error;
    });
}

function validateOTPAppPath(params: Record<string, string | null | undefined>) {
  const app = new App();
  App.fetchServiceConfiguration()
    .then(configuration => {
      app.configuration = configuration;
      app.makeAuthorizationRequest(configuration, params);
    })
    .catch((error: AxiosError) => {
      console.error('Something bad happened ', error);
    });
}

function isSessionExpired(): Promise<AxiosResponse<IUserState> | AxiosError> {
  const apiUrl = `${AppConfig.tllmsBaseUrl}/web/v1/me`;
  return api
    .get(apiUrl, null, false, null)
    .then((res: AxiosResponse<IUserState>) => {
      return res;
    })
    .catch((error: AxiosError) => {
      return error;
    });
}

function getAccountLevelTokens(params: Record<string, string>) {
  const requestParams = params;
  requestParams.state = uuid.v4();
  const app = new App();

  App.fetchServiceConfiguration()
    .then(configuration => {
      app.configuration = configuration;
      app.makeAccountLevelAuthRequest(configuration, requestParams);
    })
    .catch((error: AxiosError) => {
      console.error('Something bad happened ', error);
    });
}

function validateSignedToken(params: Record<string, string>) {
  const app = new App();
  App.fetchServiceConfiguration()
    .then(configuration => {
      app.configuration = configuration;
      app.signedTokenAuthRequest(configuration, params);
    })
    .catch(error => {
      console.error('Something bad happened ', error);
    });
}

const switchUser = (accountId: string | { 0: string }) => {
  localStorage.setItem('isProfileSwitchInitiated', 'true');
  const idServiceTokenData = localStorage.getItem(
    'identity_level_tokens',
  ) as string;
  const userAccountId =
    typeof accountId === 'string' ? accountId : accountId[0];
  const idSvcTokens = JSON.parse(idServiceTokenData) as TokenResponse;
  if (isTokenExpired(idSvcTokens)) {
    const app = new App();
    App.fetchServiceConfiguration()
      .then(configuration => {
        app.configuration = configuration;
        app
          .makeRefreshTokenRequest(
            configuration,
            idSvcTokens.refreshToken || '',
          )
          .then(response => {
            if (!response) {
              throw new Error('Error while fetching the Refresh Token');
            }
            localStorage.setItem(
              'identity_level_tokens',
              JSON.stringify(response),
            );
            localStorage.setItem(
              'identity_id_token',
              response.idToken as string,
            );
            const verification = {
              account_id: userAccountId,
              method: 'root_id_token',
              root_id_token: response.accessToken,
            };
            (async () => {
              sessionStorage.removeItem('selfServeApiData'); // HC, removing session storage on profile switch
              await clearSWCache();
              getAccountLevelTokens(verification);
            })().catch(error => {
              throw error;
            });
          })
          .catch(tokenError => {
            console.error('Error while fetching Token ', tokenError);
          });
      })
      .catch(error => {
        throw error;
      });
  } else {
    const verification = {
      account_id: userAccountId,
      method: 'root_id_token',
      root_id_token: idSvcTokens.accessToken,
    };
    (async () => {
      sessionStorage.removeItem('selfServeApiData'); // HC, removing session storage on profile switch
      await clearSWCache();
      getAccountLevelTokens(verification);
    })().catch(error => {
      throw error;
    });
  }
};

/**
 * Passcode policy can be obtained with the user's phone number or the token from the auto-login link
 * The API would work even with an expired token
 * @param identifier phone | token
 * @param value value of the identifier
 * @returns
 */
const getPasscodePolicy = (
  identifier: 'phone' | 'token',
  value: string,
): Promise<AxiosResponse<PasscodePolicyResponse>> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return api.create(
    `${AppConfig.idService.baseUrl}/api/v2/passcode/policy`,
    {
      identifier,
      value,
    },
    true,
    undefined,
  );
};

/**
 * Returns the nonce needed for the next step of passcode based login
 * This step will return 200 status and a nonce but that doesn't guarantee the credential is valid
 *
 * @param credential Passcode for the mobile number
 * @param phone mobile number of the user
 * @returns object
 */
const initiatePasscodeLogin = (
  credential: string,
  phone: string,
): Promise<AxiosResponse<AuthInitiateResponse>> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return api.create(
    `${AppConfig.idService.baseUrl}/api/authenticate/initiate/passcode`,
    {
      credential,
      phone,
    },
    true,
    undefined,
  );
};

/**
 * Tries to send passcode to the mobile number and email
 * for numbers which are not registered - it will be considered as bad request status
 * for numbers which are registered but does not have passcode set - it will be considered as not found status
 * @param phone mobile number of the user
 * @returns object Similar to passcode policy API
 */
const resendPasscode = (
  phone: string,
): Promise<AxiosResponse<PasscodePolicyResponse>> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return api.create(
    `${AppConfig.idService.baseUrl}/api/passcode/resend`,
    {
      phone,
    },
    true,
    undefined,
  );
};

/**
 * Returns the nonce needed for the next step of passcode based login
 * This step will return 200 status and a nonce but that doesn't guarantee the credential is valid
 *
 * @param token the token from auto login URL
 * @returns object
 */
const initiateTokenLogin = (
  token: string,
): Promise<AxiosResponse<AuthInitiateResponse>> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return api.create(
    `${AppConfig.idService.baseUrl}/api/authenticate/initiate/token`,
    {
      token,
    },
    true,
    undefined,
  );
};

/**
 * Can be used to add/change/update the passcode for a passcode policy eligible user
 * @param passcode string
 * @param useCase UpdatePasscodeUseCases
 * @returns PasscodePolicyResponse or Error
 */
const updatePasscode = async (
  passcode: string,
  useCase = UpdatePasscodeUseCases.RESET,
) => {
  const oldTokenResponse = JSON.parse(
    localStorage.getItem('identity_level_tokens') as string,
  ) as TokenResponse;
  const identityId = localStorage.getItem('identity_id') as string;
  let authToken = oldTokenResponse.accessToken;
  if (isTokenExpired(oldTokenResponse)) {
    const app = new App();
    const configuration = await App.fetchServiceConfiguration();
    app.configuration = configuration;
    const currentTokenResponse = await app.makeRefreshTokenRequest(
      configuration,
      oldTokenResponse.refreshToken as string,
    );
    if (!currentTokenResponse) {
      throw new Error(
        'Error fetching the Refresh Token during passcode update',
      );
    }
    localStorage.setItem(
      'identity_level_tokens',
      JSON.stringify(currentTokenResponse),
    );
    localStorage.setItem(
      'identity_id_token',
      currentTokenResponse.idToken as string,
    );
    authToken = currentTokenResponse.accessToken;
  }

  return api.update(
    `${AppConfig.idService.baseUrl}/api/passcode/update`,
    {
      authToken,
      identity: identityId,
      passcode,
      useCase,
    } as UpdatePasscodeRequest,
    null,
    true,
  );
};

interface IHaptik {
  logout: (callback) => void;
  destroy: () => void;
}

const logoutHaptic = () => {
  return new Promise((resolve, reject) => {
    if (typeof HaptikSDK === 'undefined') return;
    (HaptikSDK as IHaptik).logout(callback =>
      console.log('HAPTIC LOGOUT IS SUCCEEDED!', callback),
    );
    resolve('success');
  });
};

export {
  logout,
  logoutIdService,
  requestOtp,
  validateOTPAppPath,
  checkIdentity,
  lpBackendLogin,
  lpBackendRegister,
  getAccountLevelTokens,
  getAccountDetails,
  switchUser,
  lpBackendLoginV2,
  validateSignedToken,
  isSessionExpired,
  getSessionDetails,
  getPasscodePolicy,
  initiatePasscodeLogin,
  resendPasscode,
  initiateTokenLogin,
  updatePasscode,
  logoutHaptic,
};
