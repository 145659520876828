const setCookie = (name: string, value: string, expiryInHours: number) => {
  const now = new Date();
  now.setTime(now.getTime() + expiryInHours * 60 * 60 * 1000);
  const expires = `expires=${now.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

const getCookie = (cookieName: string) => {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  for (let i = 0; i < cookieArray.length; i += 1) {
    let item = cookieArray[i];
    while (item.charAt(0) === ' ') {
      item = item.substring(1);
    }
    if (item.indexOf(name) === 0) {
      return item.substring(name.length, item.length);
    }
  }
  return '';
};

export { setCookie, getCookie };
