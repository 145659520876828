import AppConfig from 'config/AppConfig';
import { ITutorProfileResponse } from 'interfaces';
import { APICore, handleResourceNotFoundStatus } from '../apiCore';
import { ITutorProfileRawResponse } from './ibht';
import transformTutorProfileAPIResponse from './transformTutorProfileAPIResponse';

const appConfig = AppConfig as {
  tutorApiBaseUrl: string;
};

const getTutorProfile = async (
  tutorId: number,
): Promise<Array<ITutorProfileResponse>> => {
  try {
    const api = new APICore();
    const getTutorProfileResponse: ITutorProfileRawResponse = await api.get(
      `${appConfig.tutorApiBaseUrl}/ttplus_web_v2/web/bht/tutor/profile?tutor_ids[]=${tutorId}`,
      null,
      false,
      { validateStatus: handleResourceNotFoundStatus },
    );
    const formattedTutorProfileRes =
      getTutorProfileResponse.data.tutor_details.map(
        transformTutorProfileAPIResponse,
      );
    return formattedTutorProfileRes;
  } catch (error) {
    return [];
  }
};

export default getTutorProfile;
