import { ITutorProfile, ITutorProfileResponse } from 'interfaces';
import { IProfileDetailRaw } from './ibht';

const transformApiToTutorDetails = (
  tutorDetails: IProfileDetailRaw,
): ITutorProfile => {
  return {
    id: tutorDetails?.id || '',
    name: tutorDetails?.name || '',
    phoneNumber: tutorDetails?.phone_number || '',
    profilePhotoUrl: tutorDetails?.profile_photo_url || '',
    email: tutorDetails?.email || '',
  };
};

const transformTutorProfileAPIResponse = (
  tutorProfileResponse: IProfileDetailRaw,
): Array<ITutorProfileResponse> => {
  return {
    tutorDetails: transformApiToTutorDetails(tutorProfileResponse),
  };
};

export default transformTutorProfileAPIResponse;
