import { IBhtRating, IRatingSteps } from 'interfaces';

const transformRatingsToIBhtRating = (options: IBhtRating): IBhtRating => {
  return {
    label: options?.label,
    value: options?.value,
    question: options?.question,
    feedbacks: options?.feedbacks,
  };
};

const transformRatingStepsAPIResponse = (
  ratingStep: IRatingSteps,
): IRatingSteps => {
  return {
    step: ratingStep?.step,
    title: ratingStep?.title,
    question: ratingStep?.question,
    ratings: ratingStep?.ratings.map(transformRatingsToIBhtRating),
    cta: ratingStep?.cta,
  };
};

const transformRatingListAPIResponse = (
  ratingSteps: IRatingSteps[],
): Array<IRatingSteps> => {
  return ratingSteps?.map(transformRatingStepsAPIResponse) || [];
};

export default transformRatingListAPIResponse;
