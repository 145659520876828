import moment from 'moment';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import AppConfig from 'config/AppConfig';
import {
  PasscodeToastBody,
  PasscodeToastContainer,
  PassCodeToastDiv,
  CloseImage,
  ResetButton,
  ToastDiv,
} from 'modules/passcode/passcode.style';
import MediaBreakPoints from 'utils/mediaBreakPoints';
import PasscodeState, {
  IsForcedCreatePasscodeFlow,
  PasscodeEligibilityState,
  SetNewPasscodeReminderData,
} from 'states/PasscodeState';

import './styles/Toast.scss';
import { olap } from 'analytics';

interface IPasscodeToast {
  show: boolean;
}

const PasscodeToast = ({ show }: IPasscodeToast) => {
  const smDown = MediaBreakPoints({ mediaName: 'smDown' });
  const passcodeData = useRecoilValue(PasscodeState);
  const passcodeEligibility = useRecoilValue(PasscodeEligibilityState);
  const [showToast, setShowToast] = useState(false);
  const expiryTime = moment(
    passcodeEligibility.isRolloutPlanUseCase
      ? passcodeEligibility.setPasscodeByDate
      : passcodeData?.expiryDate,
    'YYYY-MM-DD HH:mm:ss Z [UTC]',
  );
  const remainingTime = expiryTime.fromNow(true);
  const [eta, setEta] = useState(remainingTime);
  const [message, setMessage] = useState('');
  const history = useHistory();
  const setPasscodeReminderData = useSetRecoilState(SetNewPasscodeReminderData);
  const setIsForcedCreatePasscodeFlow = useSetRecoilState(
    IsForcedCreatePasscodeFlow,
  );

  const appConfig = AppConfig as {
    login: {
      svg: string;
    };
  };
  const getDefaultMessage = (timeLeft: string): string => {
    if (passcodeEligibility.isRolloutPlanUseCase) {
      return `Set your passcode now to easily login across devices`;
    }
    return smDown
      ? `Your passcode will expire in ${timeLeft}. Setup a new passcode.`
      : `Your system generated passcode will expire in ${timeLeft}. Please setup a new passcode`;
  };

  const getExpiryMessage = (): string => {
    if (passcodeEligibility.isRolloutPlanUseCase) {
      return `Your session has expired. Create a passcode now`;
    }
    return smDown
      ? `Your passcode has expired. Setup a new passcode.`
      : `Your system generated passcode has expired. Please setup a new passcode`;
  };

  useEffect(() => {
    olap({
      m_comments: 'Onboarding SSO',
      m_category: 'login',
      kingdom: 'profile',
      u_event_id: 9201078,
      m_desc: 'passcode local notification',
      phylum: 'view',
      counter: 'passcode_expires_in_days_in_user_account_details',
    });
    if (show) {
      setMessage(getDefaultMessage(eta));
      setShowToast(true);
    }
  }, [smDown]);
  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;
    if (show) {
      if (expiryTime.diff(moment()) <= 0) {
        setMessage(getExpiryMessage());
        if (passcodeEligibility.isRolloutPlanUseCase) {
          setIsForcedCreatePasscodeFlow(true);
        }
      } else {
        interval = setInterval(() => {
          setEta(expiryTime.fromNow(true));
          setMessage(getDefaultMessage(expiryTime.fromNow(true)));
        }, 60 * 1000);
      }
    }
    return () => clearInterval(interval);
  }, [eta]);

  const closeToast = () => {
    setShowToast(false);
    olap({
      m_comments: 'Onboarding SSO',
      m_category: 'login',
      kingdom: 'profile',
      u_event_id: 9201080,
      m_desc: 'cross button',
      phylum: 'click',
      counter: 'click_cross_button_from_reset',
      genus: 'Nudge',
    });
  };

  const onChangePasscode = () => {
    setPasscodeReminderData(currentVal => {
      return { ...currentVal, triggeredResetFromToast: true };
    });
    history.push('/profile', { triggerChangePasscode: true });
    olap({
      m_comments: 'Onboarding SSO',
      m_category: 'login',
      kingdom: 'profile',
      u_event_id: 9201079,
      m_desc: 'click reset passcode',
      phylum: 'click',
      counter: 'click_reset_passcode_user_account_details',
    });
  };

  return (
    <PassCodeToastDiv
      show={showToast}
      onClick={() => navigator.clipboard.writeText(message)}
    >
      <PasscodeToastContainer show={showToast}>
        <PasscodeToastBody
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {message}
          <ToastDiv>
            <ResetButton onClick={onChangePasscode}>
              {passcodeEligibility.isRolloutPlanUseCase ? 'Create' : 'Reset'}
            </ResetButton>
            <CloseImage
              src={`${appConfig.login.svg}close-circle.svg`}
              alt="close-circle"
              onClick={() => {
                setPasscodeReminderData(currentVal => {
                  return {
                    ...currentVal,
                    hasUserDismissedToastMessage: true,
                  };
                });
                closeToast();
              }}
            />
          </ToastDiv>
        </PasscodeToastBody>
      </PasscodeToastContainer>
    </PassCodeToastDiv>
  );
};

export default PasscodeToast;
