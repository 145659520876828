import AppConfig from 'config/AppConfig';

const default2d_thumbnail = `${AppConfig.byjusNeo.svg}/default_subject_2d.svg`;
const default3d_thumbnail = `${AppConfig.byjusNeo.svg}/default_subject_3d.svg`;

export const subjectImages = {
  '4-5': {
    maths: {
      image: `${AppConfig.subjects.images}maths-4-5.png`,
      bgColor: '#FFF5E0',
      color: '#ff8a00',
      thumbnail: `${AppConfig.byjusNeo.svg}/mathematics_3d.svg`,
    },
    science: {
      image: `${AppConfig.subjects.images}science-4-5.png`,
      bgColor: '#ffc6ba',
      color: '#ff6a4c',
      thumbnail: `${AppConfig.byjusNeo.svg}/science_3d.svg`,
    },
    english: {
      image: `${AppConfig.subjects.images}english-4-5.png`,
      bgColor: '#3f9fff',
      color: '#1a8cff',
      thumbnail: default3d_thumbnail,
    },
  },
  '6-7': {
    maths: {
      image: `${AppConfig.subjects.images}maths-6-7.png`,
      bgColor: '#FFF5E0',
      color: '#ff8a00',
      thumbnail: `${AppConfig.byjusNeo.svg}/mathematics_3d.svg`,
    },
    chemistry: {
      image: `${AppConfig.subjects.images}chemistry-6-7.png`,
      bgColor: '#e6f9ff',
      color: '#3fbee4',
      thumbnail: `${AppConfig.byjusNeo.svg}/chemistry_3d.svg`,
    },
    physics: {
      image: `${AppConfig.subjects.images}physics-6-7.png`,
      bgColor: '#FFF1EA',
      color: '#f38148',
      thumbnail: `${AppConfig.byjusNeo.svg}/physics_3d.svg`,
    },
    biology: {
      image: `${AppConfig.subjects.images}biology-6-7.png`,
      bgColor: '#f1fee0',
      color: '#81b93e',
      thumbnail: `${AppConfig.byjusNeo.svg}/biology_3d.svg`,
    },
    geography: {
      image: `${AppConfig.subjects.images}geography-6-7.png`,
      bgColor: '#dcf9ea',
      color: '#49a4d7',
      thumbnail: `${AppConfig.byjusNeo.svg}/geography_3d.svg`,
    },
    civics: {
      image: `${AppConfig.subjects.images}civics-6-7.png`,
      bgColor: '#fdf2ff',
      color: '#db86f8',
      thumbnail: `${AppConfig.byjusNeo.svg}/civics_3d.svg`,
    },
    history: {
      image: `${AppConfig.subjects.images}history-6-7.png`,
      bgColor: '#fff2f2',
      color: '#ffacb1',
      thumbnail: `${AppConfig.byjusNeo.svg}/history_3d.svg`,
    },
  },
  '8-above': {
    maths: {
      image: `${AppConfig.subjects.images}maths-8-abv.png`,
      bgColor: '#FFF5E0',
      color: '#ff8a00',
      thumbnail: `${AppConfig.byjusNeo.svg}/mathematics_2d.svg`,
    },
    civics: {
      image: `${AppConfig.subjects.images}civics-8-abv.png`,
      bgColor: '#fdf2ff',
      color: '#db86f8',
      thumbnail: `${AppConfig.byjusNeo.svg}/civics_2d.svg`,
    },
    physics: {
      image: `${AppConfig.subjects.images}physics-8-abv.png`,
      bgColor: '#FFF1EA',
      color: '#f38148',
      thumbnail: `${AppConfig.byjusNeo.svg}/physics_2d.svg`,
    },
    chemistry: {
      image: `${AppConfig.subjects.images}chemistry-8-abv.png`,
      bgColor: '#e6f9ff',
      color: '#3fbee4',
      thumbnail: `${AppConfig.byjusNeo.svg}/chemistry_2d.svg`,
    },
    biology: {
      image: `${AppConfig.subjects.images}biology-8-abv.png`,
      bgColor: '#f1fee0',
      color: '#81b93e',
      thumbnail: `${AppConfig.byjusNeo.svg}/biology_2d.svg`,
    },
    history: {
      image: `${AppConfig.subjects.images}history-8-abv.png`,
      bgColor: '#fff2f2',
      color: '#ffacb1',
      thumbnail: `${AppConfig.byjusNeo.svg}/history_2d.svg`,
    },
    competitiveexams: {
      image: `${AppConfig.subjects.images}competitiveexams-8-abv.png`,
      bgColor: '#e5fdff',
      color: '#02c8eb',
    },
    geography: {
      image: `${AppConfig.subjects.images}geography-8-abv.png`,
      bgColor: '#dcf9ea',
      color: '#49a4d7',
      thumbnail: `${AppConfig.byjusNeo.svg}/geography_2d.svg`,
    },
    economics: {
      image: `${AppConfig.subjects.images}economics-8-abv.png`,
      bgColor: '#a6f0ff',
      color: '#2986a4',
      thumbnail: `${AppConfig.byjusNeo.svg}/geography_2d.svg`,
    },
    internalsecurity: {
      image: `${AppConfig.subjects.images}internal-security-8-abv.png`,
      bgColor: '#f1fee0',
      color: '#81b93e',
      thumbnail: default2d_thumbnail,
    },
    science: {
      image: `${AppConfig.subjects.images}science-8-abv.png`,
      bgColor: '#ffc6ba',
      color: '#ff6a4c',
      thumbnail: `${AppConfig.byjusNeo.svg}/science_2d.svg`,
    },
    sciencetechnology: {
      image: `${AppConfig.subjects.images}science-tech-8-abv.png`,
      bgColor: '#ffc6ba',
      color: '#ff6a4c',
      thumbnail: default2d_thumbnail,
    },
    disastermanagement: {
      image: `${AppConfig.subjects.images}disaster-management-8-abv.png`,
      bgColor: '#fff1ea',
      color: '#f38148',
      thumbnail: default2d_thumbnail,
    },
    environmentandecology: {
      image: `${AppConfig.subjects.images}environment-ecology-8-abv.png`,
      bgColor: '#f1fee0',
      color: '#81b93e',
      thumbnail: default2d_thumbnail,
    },
    ethics: {
      image: `${AppConfig.subjects.images}ethics-8-abv.png`,
      bgColor: '#fff2f2',
      color: '#ffacb1',
      thumbnail: default2d_thumbnail,
    },
    csat: {
      image: `${AppConfig.subjects.images}csat-8-abv.png`,
      bgColor: '#FFF1EA',
      color: '#888888',
      thumbnail: default2d_thumbnail,
    },
    essay: {
      image: `${AppConfig.subjects.images}essay-8-abv.png`,
      bgColor: '#fff5e0',
      color: '#ff8a00',
    },
    ncerttests: {
      image: `${AppConfig.subjects.images}ncert-tests-8-abv.png`,
      bgColor: '#FFF1EA',
      color: '#888888',
      thumbnail: default2d_thumbnail,
    },
    diandlr: {
      image: `${AppConfig.subjects.images}di-lr-8-abv.png`,
      bgColor: '#fff1ea',
      color: '#f38148',
      thumbnail: default2d_thumbnail,
    },
    verbalability: {
      image: `${AppConfig.subjects.images}verbal-ability-8-abv.png`,
      bgColor: '#e6f9ff',
      color: '#3fbee4',
      thumbnail: default2d_thumbnail,
    },
    logicalreasoning: {
      image: `${AppConfig.subjects.images}logical-reasoning-8-abv.png`,
      bgColor: '#fff1ea',
      color: '#f38148',
      thumbnail: default2d_thumbnail,
    },
    previousyearspapers: {
      image: `${AppConfig.subjects.images}previousyearspapers-8-abv.png`,
      bgColor: '#FFF1EA',
      color: '#888888',
      thumbnail: default2d_thumbnail,
    },
    businessstudies: {
      image: `${AppConfig.subjects.images}business-studies-8-abv.png`,
      bgColor: '#ffd2a6',
      color: '#b75c3c',
      thumbnail: default2d_thumbnail,
    },
    accountancy: {
      image: `${AppConfig.subjects.images}accountancy-8-abv.png`,
      bgColor: '#c1f29d',
      color: '#338d24',
      thumbnail: default2d_thumbnail,
    },
    statistics: {
      image: `${AppConfig.subjects.images}statistics-8-abv.png`,
      bgColor: '#ffccdd',
      color: '#e15482',
      thumbnail: default2d_thumbnail,
    },
    quantitativeaptitude: {
      image: `${AppConfig.subjects.images}quat-8-abv.png`,
      bgColor: '#ffdc73',
      color: '#a65f0b',
      thumbnail: default2d_thumbnail,
    },
    gdpi: {
      image: `${AppConfig.subjects.images}gdpi-8-abv.png`,
      bgColor: '#99f6ff',
      color: '#39bcc4',
      thumbnail: default2d_thumbnail,
    },
    weeklycurrentaffairs: {
      image: `${AppConfig.subjects.images}weekly-current-affairs-8-abv.png`,
      bgColor: '#99f6ff',
      color: '#39bcc4',
      thumbnail: default2d_thumbnail,
    },
    polity: {
      image: `${AppConfig.subjects.images}polity-8-abv.png`,
      bgColor: '#a6f0ff',
      color: '#2986a4',
      thumbnail: default2d_thumbnail,
    },
    socialissues: {
      image: `${AppConfig.subjects.images}social-issues-8-abv.png`,
      bgColor: '#ffdc73',
      color: '#a65f0b',
      thumbnail: default2d_thumbnail,
    },
    internationalrelations: {
      image: `${AppConfig.subjects.images}internaltional-relations-8-abv.png`,
      bgColor: '#a6f0ff',
      color: '#2986a4',
      thumbnail: default2d_thumbnail,
    },
    economy: {
      image: `${AppConfig.subjects.images}economy-8-abv.png`,
      bgColor: '#ffdc73',
      color: '#a65f0b',
      thumbnail: default2d_thumbnail,
    },
  },
};
