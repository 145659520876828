/* eslint-disable import/prefer-default-export */
import { AxiosResponse } from 'axios';
import AppConfig from 'config/AppConfig';
import { APICore } from './apiCore';

const api = new APICore();

function cityList(): Promise<AxiosResponse<{ city: string }[]>> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return api.get(
    `${AppConfig.gmapNucleus}/api/geo_dimensions/india_city_list_non_paginated`,
    null,
    true,
    null,
  );
}

export { cityList };
