// @flow
import React, { useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import appConfig from '../config/AppConfig';

const AppLoader = () => {
  useEffect(()=> {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    }
  },[]);

  return (
    <div className="loader" id="preloader">
      <div className="status" id="status">
        <Image
          src={`${appConfig.dashboard.images}byjus-loader.gif`}
          width={200}
          fluid={true}
        />
      </div>
    </div>
  );
};

export default AppLoader;
