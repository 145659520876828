import AppConfig from 'config/AppConfig';
import { APICore } from './apiCore';

const setUserDetails = (premiumId: string) => {
  const api = new APICore();
  return api.create(
    `${AppConfig.selfServeBaseUrl}/api/v1/auth/signToken`,
    {
      premiumId,
      sourcePlatform: 'learnportal',
    },
    false,
    null,
  );
};
export default setUserDetails;
