/* eslint-disable camelcase */
import moment from 'moment-timezone';
import uaParserJs, { IResult } from 'ua-parser-js';
import AppConfig from 'config/AppConfig';
import { sessionId } from 'helpers/sessionId';
import { flattenObject } from 'helpers/helper';
import {
  ClevertapProfile,
  IDispatchClevertap,
  IHandleClevertapLogin,
  IOptionalData,
  CleverTapDataType,
} from './interface';

const getDeviceName = (userAgentData: IResult): string => {
  const source = JSON.parse(
    localStorage.getItem('LoginSource') as string,
  ) as Record<string, unknown>;
  if (source?.source === 'd4-app') {
    return 'learn_station';
  }
  return userAgentData?.device?.type ? userAgentData.device.type : 'desktop';
};

const getUserDeviceType = (userDeviceType: string | undefined): number => {
  if (userDeviceType === 'mobile') return 0;
  if (userDeviceType === 'tablet') return 2;
  return 1; // mobile_web/web/tablet_view <0/1/2>
};

const dispatchClevertapEvent = ({ eventName, params }: IDispatchClevertap) => {
  if (!window.clevertap) {
    return;
  }
  const finalObj = flattenObject({ ...(params as object), product: 'LP' });
  return window.clevertap.event.push(eventName, finalObj);
};

const dispatchClevertapPayload = (
  payload: CleverTapDataType,
  defaultParams: Record<string, unknown | Record<string, unknown>>,
) => {
  const { param7, ...rest } = defaultParams;
  const final: Record<string, unknown> = {
    ...payload,
    ...rest,
    ...(param7 as object),
  };
  // prefix "LP_" to eventname as requested by product team
  dispatchClevertapEvent({
    eventName: `LP_${final?.counter as string}`,
    params: final,
  });
};
const clevertap = (data: CleverTapDataType, optionalData?: IOptionalData) => {
  try {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (window.location.search === '') {
      const queryString = localStorage?.getItem('queryString');
      const querySubString = queryString?.substring(1);
      const queryArray = querySubString?.split('&');

      if (queryArray) {
        queryArray.forEach(item => {
          const splitedItem = item?.split('=');
          const key = splitedItem[0];
          const value = splitedItem[1];
          params[key] = value;
        });
      }
    }

    const userState = localStorage.getItem('UserState');
    const currentCohort = localStorage.getItem('CurrentCohort');
    let userData: Record<string, unknown> = {};
    let currentCohortData: Record<string, Record<string, unknown>> = {};
    userData = userState
      ? (JSON.parse(userState) as Record<string, unknown>)
      : {};
    currentCohortData = currentCohort
      ? (JSON.parse(currentCohort) as Record<string, Record<string, unknown>>)
      : {};
    const userAgentData: IResult = uaParserJs(navigator.userAgent);
    const defaultParams: Record<string, unknown> = {
      variety: userData?.user_subscription_type || optionalData?.userVariety, // 'premium_byjus_class_active_user', // <user_type> premium_byjus_class_active_user / premium_byjus_class_expired_user / parity_active_user / parity_expired_user / free_user
      user_id: userData?.mobile || optionalData?.userId,
      premium_id: userData?.premium_account_id || optionalData?.PremiumId,
      device_id: null,
      date_time: `$D_${Date.parse(
        moment.tz(new Date(), 'Asia/Calcutta').format('YYYY-MM-DD HH:mm:ss'),
      )}`, // '2021-11-29 00:28:18.000 +0530',
      session_id: sessionId(),
      param7: {
        device_type: getDeviceName(userAgentData),
        os_type: userAgentData.os.name,
        os_version: userAgentData.os.version,
        browser_name: userAgentData.browser.name,
        version: userAgentData.browser.version,
      },
      value1: currentCohortData?.cohort?.id || optionalData?.cohertId,
      value2: getUserDeviceType(userAgentData.device.type),
      app_id: AppConfig.olapAppId,
    };
    dispatchClevertapPayload(data, defaultParams);
  } catch (err) {
    console.error(err);
  }
};

const handleClevertapLogin = ({
  phone,
  gender,
  city,
  defaultCommPrefs = {},
  premiumID,
}: IHandleClevertapLogin) => {
  if (window.clevertap) {
    const profile: ClevertapProfile = {
      Identity: premiumID || phone,
      product: 'LP',
      product_source: ['LP'],
      city,
      ...defaultCommPrefs,
      user_type: premiumID ? 'PAID' : 'FREE',
    };

    if (gender === 'F' || gender?.toLocaleLowerCase() === 'female') {
      profile.Gender = 'F';
    }
    if (gender === 'M' || gender?.toLocaleLowerCase() === 'male') {
      profile.Gender = 'M';
    }
    window.clevertap.onUserLogin.push({
      Site: {
        ...profile,
      },
    });
  }
};

const clevertapWhatsappOptIn = (userConsent = true) => {
  window.clevertap.profile.push({
    Site: {
      'MSG-whatsapp': userConsent,
      is_whatsapp_consent: userConsent,
    },
  });
};

export { handleClevertapLogin, clevertap, clevertapWhatsappOptIn };
