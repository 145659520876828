import { IS_EMAIL_REGEX, IS_NAME_REGEX } from '../constants/global';

export function isEmail(mail: string | null) {
  if (mail) {
    const data = mail.trim();
    return new RegExp(IS_EMAIL_REGEX).test(data);
  }
  return false;
}

export function isName(name: string) {
  if (name && name.length >= 3 && name.length <= 72) {
    const data = name
      .split(' ')
      .filter((e: string) => e)
      .join(' ');

    // One or more combination of (UPPERCASE and SMALLCASE letters with optional dot or space or both).
    return new RegExp(IS_NAME_REGEX).test(data);
  }
  return false;
}
