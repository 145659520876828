/* eslint-disable check-file/filename-naming-convention */
import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import { Notifications } from 'react-push-notification';
import 'utils/Polyfills';

import { AlertProvider } from 'components/alert/alert';
import disableConsoleLogs from 'helpers/disableLogs';

import { toggleAddHomeScreenButtonOnIDSPageFlag } from 'config/toggleFeature';
import { TraceProvider } from 'tools/telemetry';
import App from './App';
import reportWebVitals from './reportWebVitals';
import appConfig from './config/AppConfig';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

disableConsoleLogs(appConfig.isConsoleLogsDisabled);

ReactDOM.render(
  <React.StrictMode>
    <TraceProvider>
      <RecoilRoot>
        <AlertProvider>
          <App />
        </AlertProvider>
      </RecoilRoot>
      <Notifications />
    </TraceProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
if (toggleAddHomeScreenButtonOnIDSPageFlag) {
  serviceWorkerRegistration.register();
}
