/* eslint-disable check-file/filename-naming-convention */
import { atom } from 'recoil';

import { persistToLocalStorage } from 'helpers/persistence';
import { VideoData } from '../interfaces/video';

export const CourseState = atom<VideoData | null>({
  key: 'CourseState',
  default: null,
  effects_UNSTABLE: [persistToLocalStorage],
});
