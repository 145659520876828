import { ISduiResponse, IWidgetInfo } from 'interfaces';
import { getSduiConfig } from '../getSduiConfig';
import transformSduiResponse from './transformSduiResponse';

export const getSduiResponseConfig = async (
  cohortId: number,
  subscriptionTag: string,
): Promise<IWidgetInfo | null> => {
  try {
    const response = await getSduiConfig(cohortId, [subscriptionTag]);

    const formattedResponse = transformSduiResponse(response as ISduiResponse);
    return formattedResponse;
  } catch (error) {
    console.error(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `Error fetching SDUI Response Config: ${error}`,
    );
    return null;
  }
};
