/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/state-in-constructor */
import React, { ReactNode } from 'react';
import 'assets/scss/custom/pages/_errorboundary.scss';
import StyledButton from 'components/styledbutton/StyledButton';
import styled from 'styled-components';
import { assetConfig } from 'config/AppConfig';
import { CustomTracerEvents, otelCustomTracer } from 'tools/telemetry';

const Button = styled(StyledButton)({
  padding: '5px 30px',
  borderRadius: 5,
});

type ErrorBoundaryState = {
  isError: boolean;
  error?: Error;
};

class ErrorBoundary extends React.Component<
  { children: ReactNode },
  ErrorBoundaryState
> {
  state = {
    isError: false,
  } as ErrorBoundaryState;

  componentDidCatch(error: Error) {
    this.setState({
      isError: true,
      error,
    });

    if (error) {
      otelCustomTracer(
        CustomTracerEvents.pageCrash,
        {
          tracerType: 'ErrorTracer',
          errorType: error.name,
          options: error,
          errorMessage: error.message,
        },
        true,
      );
    }
  }

  handleReload = () => {
    window.location.reload();
  };

  render() {
    const { children } = this.props;
    const { isError, error = null } = this.state;

    return (
      <div>
        {!isError ? (
          children
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <div className="error_boundary_container">
              <div>
                <img
                  className="error_boundary_image"
                  src={`${assetConfig.misc.images}404.png`}
                  alt="Error boundary"
                />
                <h2>There was some interruption</h2>
                <p>Please try to refresh the page</p>
                <Button variant="violet" onClick={this.handleReload}>
                  Reload
                </Button>
                <details
                  style={{
                    marginTop: '20px',
                    fontSize: '0.75rem',
                  }}
                >
                  <summary style={{ color: '#666666' }}>View More</summary>

                  {error && error.toString()}
                </details>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ErrorBoundary;
