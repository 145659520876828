/* eslint-disable react/jsx-props-no-spreading */
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const OrangeButton = styled(Button)`
  background: #fff1ea;
  border-radius: 0.25rem;
  border-color: #fff1ea;
  color: #f38148;
  &:hover,
  &:focus,
  &:active {
    background: #f8ccb6 !important;
    border-color: #f8ccb6 !important;
    color: #f38148 !important;
  }
`;

const OptionButton = styled(Button)`
  background: #f7f7f7;
  border-radius: 0.25rem;
  border-color: #d8d8d8;
  color: #333333;
  &:hover,
  &:focus,
  &:active {
    background: #fffae7 !important;
    color: #e37b00 !important;
    border-color: #e37b00 !important;
  }
`;

const JourneyButton = styled(Button)`
  background: #e37b00;
  border-radius: 0.25rem;
  border-color: #e37b00;
  color: #ffffff;
  &:hover,
  &:focus,
  &:active {
    background: #e37b00 !important;
    color: #ffffff !important;
    border-color: #e37b00 !important;
  }
`;

const LightOrange = styled(Button)`
  color: #ffffff;
  background-color: #f38148;
  border-radius: 0.25rem;
  border-color: #f38148;
  &:hover,
  &:focus,
  &:active {
    background-color: #f19465 !important;
    border-color: #f38148 !important;
    color: #ffffff !important;
  }
`;
const UCAButton = styled(LightOrange)`
  height: 37px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 32px 98px 8px 99px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #f38148;
`;

const Share = styled(Button)`
  background-color: #fff;
  border-color: #fff;
  color: #666666;
  &:hover,
  &:active,
  &:focus {
    background-color: rgb(236, 236, 236) !important;
    border-color: #fff !important;
    color: #666666 !important;
  }
`;
const Purple = styled(Button)`
  color: #8c69ff;
  background-color: #f1edff;
  border-color: #f1edff;
  &:hover,
  &:active,
  &:focus {
    background-color: #f1edff !important;
    border-color: #f1edff !important;
    color: #8c69ff !important;
  }
`;

const FilledPurple = styled(Button)`
  background: #8c69ff;
  border-radius: 0.3rem;
  padding: 0.5rem 1rem;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1.313rem;
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0rem 0.6rem;
  &:hover,
  &:active,
  &:focus {
    background-color: #8c69ff !important;
    border-color: #8c69ff !important;
    color: #ffffff !important;
  }
`;
const BrownButton = styled(Button)`
  color: #fff;
  background-color: #d2a565;
  border-color: #d2a565;
  // margin-bottom: 0.5rem;
  &:hover,
  &:active,
  &:focus {
    background-color: #d2a565 !important;
    border-color: #d2a565 !important;
    color: #fff !important;
  }
`;
const CyanButton = styled(Button)`
  color: #fff;
  background-color: #39bcc4;
  border-color: #39bcc4;
  &:hover,
  &:active,
  &:focus {
    background-color: #39bcc4 !important;
    border-color: #39bcc4 !important;
    color: #fff !important;
  }
`;
const BlueButton = styled(Button)`
  color: #fff;
  background-color: #4784f6;
  border-color: #4784f6;
  &:hover,
  &:active,
  &:focus {
    background-color: #4784f6 !important;
    border-color: #4784f6 !important;
    color: #fff !important;
  }
`;

const ModalClose = styled(Button)`
  color: #444444;
  background-color: #f6f6f6;
  border-color: #f6f6f6;
  border-radius: 50%;
  padding: 0.06rem 0.3rem 0rem 0.3rem;
  position: absolute;
  right: -0.8rem;
  top: -0.8rem;
  &:hover,
  &:active,
  &:focus {
    background-color: #f6f6f6 !important;
    border-color: #f6f6f6 !important;
    color: #444444 !important;
  }
  @media (max-width: 768px) {
    right: -0.5rem;
  }
`;

const VioletButton = styled(Button)`
  color: #fff;
  background-color: #7f5cf4;
  border-color: #7f5cf4;
  &:hover,
  &:active,
  &:focus {
    background-color: #7f5cf4 !important;
    border-color: #7f5cf4 !important;
    color: #fff !important;
  }
`;

const OrangeLink = styled(Button)`
  color: #f38148;
  &:hover,
  &:active,
  &:focus {
    color: #f38148 !important;
  }
`;

const SubjectiveEdit = styled(Button)`
  display: flex;
  background-color: #e5e5e5;
  border: 0.06rem solid #e5e5e5;
  color: #7f5cf4;
  padding: 0.3rem;
  font-size: 0.9rem;
  line-height: 1.5rem;
  opacity: 1 !important;
  &:hover,
  &:active,
  &:focus {
    color: #7f5cf4;
    background-color: #e5e5e5;
    border: 0.06rem solid #e5e5e5;
    // border-radius: 0.15rem;
  }
`;

const PinkButton = styled(Button)`
  color: #fff;
  background: #e53a73;
  &:hover,
  &:active,
  &:focus {
    color: #fff !important;
    background: #e53a73 !important;
  }
`;

const BlueLightButton = styled(Button)`
  color: #fff;
  background: #5992ff;
  &:hover,
  &:active,
  &:focus {
    color: #fff !important;
    background: #5992ff !important;
  }
`;
const BookmarkButton = styled(Button)`
  border: 0px;
  background-color: inherit;
`;
const DarkBrownButton = styled(Button)`
  color: #fff;
  background: #bd9054;
  &:hover,
  &:active,
  &:focus {
    color: #fff !important;
    background: #bd9054 !important;
  }
`;

const ShopifyPurpleButton = styled(Button)`
  color: #fff;
  background: #553176;
  &:hover,
  &:active,
  &:focus {
    color: #fff !important;
    background: #553176 !important;
  }
`;

const PracticeButton = styled(Button)`
  background: #d2a565;
  color: #fff;
  &:hover,
  &:active,
  &:focus {
    color: #fff !important;
    background: #d2a565 !important;
  }
`;

const StyledButton = ({ variant = 'plain', className = 'btn', ...props }) => {
  switch (variant) {
    case 'primary':
      // purple
      return (
        <Button {...props} className={`${className}`}>
          {props.children}
        </Button>
      );
    case 'secondary':
    case 'success':
    case 'danger':
    case 'warning':
    case 'info':
    case 'dark':
    case 'light':
    case 'link':
      return (
        <Button variant={variant} className={`${className} "btn" `} {...props}>
          {props.children}
        </Button>
      );
    case 'purple':
      // purple with opacity
      return (
        <Purple variant={variant} className={`${className}`} {...props}>
          {props.children}
        </Purple>
      );
    case 'filled-purple':
      return (
        <FilledPurple variant={variant} className={`${className}`} {...props}>
          {props.children}
        </FilledPurple>
      );
    case 'orange-light':
      // orange with opacity
      return (
        <LightOrange variant={variant} className={`${className}`} {...props}>
          {props.children}
        </LightOrange>
      );
    case 'orange-light-uca':
      // orange with opacity
      return (
        <UCAButton variant={variant} className={`${className}`} {...props}>
          {props.children}
        </UCAButton>
      );
    case 'orange':
      // orange
      return (
        <OrangeButton variant={variant} className={`${className}`} {...props}>
          {props.children}
        </OrangeButton>
      );
    case 'share':
      return (
        <Share variant={variant} className={`btn ${className}`} {...props}>
          <i className="mdi mdi-share-variant me-1" /> {props.children}
        </Share>
      );
    case 'brown':
      return (
        <BrownButton
          variant={variant}
          className={`btn ${className}`}
          {...props}
        >
          {props.children}
        </BrownButton>
      );

    case 'cyan':
      return (
        <CyanButton variant={variant} className={`btn ${className}`} {...props}>
          {props.children}
        </CyanButton>
      );
    case 'ask-a-doubt':
      return (
        <BlueButton variant={variant} className={`btn ${className}`} {...props}>
          {props.children}
        </BlueButton>
      );
    case 'practice-button':
      return (
        <PracticeButton
          variant={variant}
          className={`btn ${className}`}
          {...props}
        >
          {props.children}
        </PracticeButton>
      );

    case 'modal-close':
      return (
        <ModalClose variant={variant} className={`btn ${className}`} {...props}>
          <i className="mdi mdi-close" />
        </ModalClose>
      );
    case 'violet':
      return (
        <VioletButton
          variant={variant}
          className={`btn ${className}`}
          {...props}
        >
          {props.children}
        </VioletButton>
      );
    case 'primary-link':
      return (
        <Button variant="link" className={`btn ${className}`} {...props}>
          {props.children}
        </Button>
      );
    case 'bookmark-link':
      return (
        <BookmarkButton
          variant="link"
          className={`btn ${className}`}
          {...props}
        >
          {props.children}
        </BookmarkButton>
      );

    case 'orange-link':
      return (
        <OrangeLink variant="link" className={`btn ${className}`} {...props}>
          {props.children}
        </OrangeLink>
      );
    case 'subjective-edit':
      return (
        <SubjectiveEdit
          variant="link"
          className={`btn ${className}`}
          {...props}
        >
          {props.children}
        </SubjectiveEdit>
      );
    case 'pink':
      return (
        <PinkButton variant={variant} className={`${className}`} {...props}>
          {props.children}
        </PinkButton>
      );
    case 'blue-light':
      return (
        <BlueLightButton
          variant={variant}
          className={`${className}`}
          {...props}
        >
          {props.children}
        </BlueLightButton>
      );

    case 'journey-btn':
      return (
        <JourneyButton varaint="link" className={`btn ${className}`} {...props}>
          {props.children}
        </JourneyButton>
      );

    case 'option-btn':
      return (
        <OptionButton varaint="link" className={`btn ${className}`} {...props}>
          {props.children}
        </OptionButton>
      );

    case 'dark-brown':
      return (
        <DarkBrownButton
          variant="link"
          className={`btn ${className}`}
          {...props}
        >
          {props.children}
        </DarkBrownButton>
      );
      case 'shopify-purple':
        return (
          <ShopifyPurpleButton
            variant="link"
            className={`btn ${className}`}
            {...props}
          >
            {props.children}
          </ShopifyPurpleButton>
        ); 
    default:
      return (
        <Button variant={variant} className={`${className}`} {...props}>
          {props.children}
        </Button>
      );
  }
};

export default StyledButton;
