import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { handleDispatchAlert } from 'components/alert/alert';
import { UserState } from 'states/UserState';
import { getPasscodePolicy } from 'helpers/api/auth';
import { togglePasscodeFlag } from 'config/toggleFeature';
import PasscodeState, {
  PasscodeEligibilityState,
  SetNewPasscodeReminderData,
} from 'states/PasscodeState';
import MaintenancePage from 'layouts/MaintenancePage';
import AppConfig from 'config/AppConfig';
import withErrorBoundary from 'helpers/errorboundary';

import { Routes } from './routes';

import './assets/scss/Saas.scss';

const App = () => {
  const maintenanceModeLocalStorage = localStorage?.getItem('maintenanceMode');
  const currentUser = useRecoilValue(UserState) as null | Record<
    string,
    unknown
  >;
  const [passcodeData, setPasscodeData] = useRecoilState(PasscodeState);
  const setPasscodeEligibility = useSetRecoilState(PasscodeEligibilityState);
  const setPasscodeReminderData = useSetRecoilState(SetNewPasscodeReminderData);
  useEffect(() => {
    // Registering event listeners for online and offline notification
    window.addEventListener('online', () => {
      document.body.classList.remove('offline');
      handleDispatchAlert({
        title: 'Back online',
        id: 'online',
        delay: 2000,
        type: 'success',
      });
    });
    window.addEventListener('offline', () => {
      handleDispatchAlert({
        title: 'No internet connection',
        id: 'offline',
        delay: 2000,
        type: 'failure',
      });
      document.body.classList.add('offline');
    });
    return () => {
      window.removeEventListener('online', () => {});
      window.removeEventListener('offline', () => {});
      document.body.classList.remove('offline');
    };
  }, []);

  /**
   * Used to get passcode policy on reload of page
   * This ensures the existing users get a nudge to setup passcode
   */
  useEffect(() => {
    if (togglePasscodeFlag && currentUser) {
      if (!passcodeData) {
        const phoneNo = localStorage.getItem('phone_no') as string;
        getPasscodePolicy('phone', phoneNo)
          .then(response => {
            setPasscodeData(response?.data);
            setPasscodeEligibility(currentVal => {
              return {
                ...currentVal,
                isEligible: Boolean(response?.data?.isEligible),
                setPasscodeByDate: response?.data?.setPasscodeByDate,
                isRolloutPlanUseCase:
                  Boolean(!response?.data?.isPasscodeSet) &&
                  Boolean(response?.data?.isEligible),
              };
            });
          })
          .catch(error => {
            console.error('Failed to fetch the passcode policy', error);
          });
      } else {
        setPasscodeReminderData(currentVal => {
          return {
            ...currentVal,
            triggeredResetFromToast: false,
            hasUserDismissedToastMessage: false,
            triggeredCreatePasscodeAfterExpiry: false,
            triggeredResetFromProfile: false,
          };
        });
      }
    }
  }, []);

  if (maintenanceModeLocalStorage !== '0') {
    const isMaintenance = AppConfig?.maintenanceMode;
    if (isMaintenance === '1') {
      return <MaintenancePage />;
    }
  }

  return <Routes />;
};

export default withErrorBoundary(App);
