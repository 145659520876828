import { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  SearchPageComponent,
  Layout,
} from '@toppr-engg/instalearn-components/lib/esm';
import '@toppr-engg/instalearn-components/lib/esm/index.css';
import '@toppr-engg/byjus-search-qna/lib/esm/index.css';
import jwt from 'jsonwebtoken';
import AppConfig from 'config/AppConfig';
import { useRecoilState, useRecoilValue } from 'recoil';
import { OngoingTutorSession } from 'hooks/AskDoubts/UseAskTutorPopup';
import { UserState } from 'states/UserState';
import { getGradeNumber } from 'helpers/getGradeNumber';
import { CurrentCohortState } from 'states/CohortState';
import { DoesSelfServeWidgetNeedDifferentStyle } from 'states/SelfServeState';
import {
  searchKey,
  docQueryText,
  docQueryImage,
  searchCount,
} from 'states/SearchState';
import AskTutorPage from './AskTutorPage';
import './DoubtsOnChat.scss';

const SearchPage = () => {
  const [showActivationCta, setShowActivationCta] = useState(false);
  const [, setShowVideoBanner] = useState(false);
  const [subjectId, setSubjectId] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const { pathname, search } = location;
  const query = new URLSearchParams(search);
  const queryParam = query.get('searchkey');

  const baseUrl = pathname?.split('/')[1];

  const isNavigatingToQuestionPage = useRef(false);
  const [, setSearchKey] = useRecoilState(searchKey);
  const [, setDocQueryImage] = useRecoilState(docQueryImage);
  const [, setDocQueryText] = useRecoilState(docQueryText);
  const [, setSearchCount] = useRecoilState(searchCount);
  const [
    selfServeWidgetNeedDifferentStyle,
    setSelfServeWidgetNeedDifferentStyle,
  ] = useRecoilState(DoesSelfServeWidgetNeedDifferentStyle);
  const [ongoingTutorSession] = useRecoilState(OngoingTutorSession);

  useEffect(() => {
    setDocQueryText('');
    setDocQueryImage('');

    if (!queryParam?.trim()) {
      history.push(`/${baseUrl}`);
    }
  }, []);

  useEffect(() => {
    if (!ongoingTutorSession) setSelfServeWidgetNeedDifferentStyle(true);
    else setSelfServeWidgetNeedDifferentStyle(false);

    return () => {
      if (!isNavigatingToQuestionPage) {
        setSelfServeWidgetNeedDifferentStyle(false);
      }
    };
  }, [ongoingTutorSession, selfServeWidgetNeedDifferentStyle]);

  const userState = useRecoilValue(UserState);
  const currentCohortState = useRecoilValue(CurrentCohortState);
  const docSecret = '7HpCPOgGUZHgxXlKui9eN7aUoW5wJjvM93viUBCMsU';

  const tokenPayload = {
    user_profile_id: userState?.id,
    platform: 'web',
    name: userState?.full_name,
    klass: getGradeNumber(currentCohortState?.cohort?.grade),
    cohort_id: currentCohortState?.cohort?.id,
    client_name: 'learn_portal',
  };
  const imageData = localStorage.getItem('imageSearched');
  const origin = AppConfig.baseUrl;
  const userId = userState.id;
  const authKey = AppConfig.qnaToken?.split(' ')[1];
  const docToken = jwt.sign(tokenPayload, docSecret, {
    algorithm: 'HS256',
    noTimestamp: true,
  });
  const docApiBaseUrl = AppConfig.instalearnApiBaseUrl;
  const docClientId = AppConfig.instalearnClientId;
  const searchType = imageData ? 'image' : 'text';

  const handleSearchListClick = data => {
    isNavigatingToQuestionPage.current = true;
    const searchedText = data?.hrefUrl;
    if (searchedText) {
      setSearchKey(searchedText);
      history.push(`/${baseUrl}/question?searchkey=${searchedText}`);
      document.querySelector('.instalearnContainer').scrollTop = 0;
    } else {
      history.push(`/${baseUrl}/question?searchkey=-1`);
    }
  };

  const getSubjectRelatedToSearch = data => {
    setSubjectId(data.subjectId);
  };

  const handleActivationCtaClick = data => {
    if (data.searchType === 'image') {
      setDocQueryImage(data.imageData);
    } else if (data.searchType === 'text') {
      setDocQueryText(data.searchText);
    }
    setSearchCount(data.searchResultCount);
  };

  const handleBackClick = () => {
    setDocQueryText('');
    setDocQueryImage('');
    history.goBack();
  };

  return (
    <div className="instalearnContainer">
      <SearchPageComponent
        apiBaseUrl={docApiBaseUrl}
        origin={origin}
        userId={userId}
        authKey={`Bearer ${authKey}`}
        docToken={docToken}
        docApiBaseUrl={docApiBaseUrl}
        docClientId={docClientId}
        searchText={queryParam}
        imageData={imageData}
        searchType={searchType}
        handleSearchListClick={handleSearchListClick}
        getSubjectRelatedToSearch={getSubjectRelatedToSearch}
        showActivationCta={showActivationCta}
        handleActivationCtaClick={handleActivationCtaClick}
        handleBackClick={handleBackClick}
        isTodFeature={true}
      />
      <Layout layoutClassName="layoutDoc">
        <AskTutorPage
          showSessionList={false}
          setShowActivationCta={setShowActivationCta}
          setShowVideoBanner={setShowVideoBanner}
          subjectId={subjectId}
        />
      </Layout>
    </div>
  );
};

export default SearchPage;
