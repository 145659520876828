import AppConfig from 'config/AppConfig';
import { APICore } from 'helpers/api/apiCore';

const api = new APICore();

const leadSquare = (lead_squared: any) => {
    const data =   JSON.stringify({
        lead_squared,
    })
  return api.create(
    `${AppConfig.tllmsBaseUrl}/web/v1/lead_squared/create_generic`,
    data,
  );
};

export default leadSquare;
