/* Note:
  - Same from and to paths not required.
  - Paths should not end with Trailing slash.
  - regex, matchedRegexArrayLength, matchedRegexArrayKeyAndIndexs and backDropUrl are mandatory if we are redirecting to repecting path params
  - regex property should have grouped regex format(/\/practice\/analysis\/([0-9]+)/ here ([0-9]+) is grouped) so that we can easily capture path params using array index
  - matchedRegexArrayKeyAndIndexs should have to path param as a key and regex index as value. to: '/adaptive-questions/analysis/:topicId', eg { ':topicId': 1 },:
  - matchedRegexArrayLength should have matched regex array length for ex: regex: /\/practice\/analysis\/([0-9]+)/ -> 2
*/

/* eslint-disable check-file/filename-naming-convention */

// eslint-disable-next-line import/prefer-default-export
export const lp1RedirectionMapping = [
  // { from: '/', to: '/home' },
  // { from: '/aakash-class/dashboard', to: '/aakash-class/dashboard' },
  { from: '/aits-tab', to: '/aits/jee-mains' },
  { from: '/aits/jee_main', to: '/aits/jee-mains' },
  // { from: '/aits/neet', to: '/aits/neet' },
  // { from: '/ask-a-doubt', to: '/ask-a-doubt' },
  { from: '/ask-a-doubt/dashboard', to: '/ask-a-doubt' },
  { from: '/ask-a-doubt/post/:subjectId', to: '/ask-a-doubt' },
  { from: '/ask-a-doubt/posts', to: '/ask-a-doubt' },
  { from: '/ask-a-doubt/posts/:id', to: '/ask-a-doubt' },
  { from: '/ask-a-doubt/search/:subjectId', to: '/ask-a-doubt' },
  { from: '/bookmark', to: '/all-bookmarks' },
  { from: '/bookmark/dashboard', to: '/all-bookmarks' },
  // { from: '/byjus-class/dashboard', to: '/byjus-classes' },
  { from: '/download', to: '/downloads' },
  { from: '/download/dashboard', to: '/downloads' },
  // { from: '/home', to: '/home' },
  { from: '/home/dashboard', to: '/home' },
  { from: '/jee-advanced/dashboard', to: '/aits/jee-advanced' },
  { from: '/login', to: '/account/login' },
  { from: '/mock-test', to: '/mock-tests/jee-mains' },
  { from: '/mock-test/jee_advanced', to: '/mock-tests/jee-advanced' },
  { from: '/mock-test/jee_main', to: '/mock-tests/jee-mains' },
  { from: '/mock-test/neet', to: '/mock-tests/neet' },
  { from: '/mock-test/bitsat', to: '/mock-tests/jee-mains' },
  { from: '/practice', to: '/adaptive-questions' },
  {
    from: '/practice/analysis/:chapterId',
    to: '/adaptive-questions/analysis/:topicId',
    regex: /\/practice\/analysis\/([0-9]+)/,
    matchedRegexArrayLength: 2,
    matchedRegexArrayKeyAndIndexs: { ':topicId': 1 },
    backDropUrl: '/adaptive-questions',
  },
  { from: '/practice/dashboard', to: '/adaptive-questions' },
  { from: '/premium-school', to: '/byjus-classes' },
  { from: '/premium-school/dashboard', to: '/byjus-classes' },
  { from: '/premium-school/dashboard/:type', to: '/byjus-classes' },
  { from: '/premium-school/rebook-class', to: '/byjus-classes/swap' },
  { from: '/premium-school/swap-session', to: '/byjus-classes/swap' },
  { from: '/profile/dashboard', to: '/profile' },
  { from: '/questions/:friendlyId', to: '/ask-a-doubt' },
  { from: '/revision', to: '/all-subjects' },
  {
    from: '/revision-summaries/:revisionId',
    to: '/all-subjects/detail/:id',
    regex: /\/revision-summaries\/([0-9]+)/,
    matchedRegexArrayLength: 2,
    matchedRegexArrayKeyAndIndexs: { ':id': 1 },
    backDropUrl: '/all-subjects',
  },
  {
    from: '/revision/:subjectId',
    to: '/all-subjects/:subjectId',
    regex: /\/revision\/([0-9]+)/,
    matchedRegexArrayLength: 2,
    matchedRegexArrayKeyAndIndexs: { ':subjectId': 1 },
    backDropUrl: '/all-subjects',
  },
  { from: '/revision/dashboard', to: '/all-subjects' },
  {
    from: '/start-practice/:chapterId',
    to: '/adaptive-questions/start-practice/:chapterid',
    regex: /\/start-practice\/([0-9]+)/,
    matchedRegexArrayLength: 2,
    matchedRegexArrayKeyAndIndexs: { ':chapterid': 1 },
    backDropUrl: '/adaptive-questions',
  },
  { from: '/tests', to: '/chapter-wise-tests' },
  {
    from: '/tests/:subjectId',
    to: '/chapter-wise-tests/subjects/:id',
    regex: /\/tests\/([0-9]+)/,
    matchedRegexArrayLength: 2,
    matchedRegexArrayKeyAndIndexs: { ':id': 1 },
    backDropUrl: '/chapter-wise-tests',
  },
  { from: '/tests/dashboard', to: '/chapter-wise-tests' },
  { from: '/video', to: '/concept-videos' },
  { from: '/video/dashboard', to: '/concept-videos' },
  {
    from: '/video/chapter-videos/:chapterId',
    to: '/concept-videos/topics/:topicId',
    regex: /\/video\/chapter-videos\/([0-9]+)/,
    matchedRegexArrayLength: 2,
    matchedRegexArrayKeyAndIndexs: { ':topicId': 1 },
    backDropUrl: '/concept-videos',
  },
  {
    from: '/video/popular-clips/:id',
    to: '/concept-videos/video-detail/:id',
    regex: /\/video\/popular-clips\/([0-9]+)/,
    matchedRegexArrayLength: 2,
    matchedRegexArrayKeyAndIndexs: { ':id': 1 },
    backDropUrl: '/concept-videos',
  },
  {
    from: '/career-counselling',
    to: 'https://assess.byjus.com/career-counselling',
    isExternalUrl: true,
  },
  {
    from: '/doubt-session',
    to: '/home',
  },
  {
    from: '/doubt-session/dashboard',
    to: '/home',
  },
  {
    from: '/doubt-session/download',
    to: '/home',
  },
  {
    from: '/doubt-session/session-form',
    to: '/home',
  },
  {
    from: '/doubt-session/speedtest',
    to: '/home',
  },
  {
    from: '/exam-prep',
    to: '/byjus-test-series',
  },
  {
    from: '/qotd/jee_main',
    to: '/home',
  },
  {
    from: '/qotd/neet',
    to: '/home',
  },
  {
    from: '/quiz-of-the-day',
    to: '/home',
  },
  {
    from: '/tutor',
    to: '/home',
  },
  {
    from: '/tutor/dashboard',
    to: '/home',
  },
  {
    from: '/workshops',
    to: '/home',
  },
  {
    from: '/workshops/dashboard',
    to: '/home',
  },
  {
    from: '/bce',
    to: '/home',
  },
  {
    from: '/Bce/dashboard',
    to: '/home',
  },
];
