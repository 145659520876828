/* eslint-disable camelcase */
/* eslint-disable check-file/filename-naming-convention */
import { persistToLocalStorage } from 'helpers/persistence';
import { atom } from 'recoil';

export interface QueryData {
  queryImage: Record<string, unknown>;
  queryText: string;
  subjectId: number;
}

export interface IPtmEligibility {
  isPTMEnable: boolean;
  isReBookingEnable: boolean;
  remainingRebookings: number;
}

export interface IUpcomingPtmData {
  id: string;
  subBatchId: string;
  batchId: string;
  classStartTime: string;
  classEndTime: string;
  tenant: string;
  grade: string;
  board: string;
  cohortId: string;
  sessionType: string;
  status: string;
}

export const navigatedFromState = atom<string | null>({
  key: 'navigatedFrom',
  default: null,
});

export const searchInputFocusFlagState = atom<boolean>({
  key: 'searchInputFocus',
  default: false,
});

export const isDoubtsOnChatEnabled = atom<boolean>({
  key: 'isDoubtsOnChatEnabled',
  default: false,
});

export const isInstaLearnEnabled = atom<boolean>({
  key: 'isInstaLearnEnabled',
  default: false,
});

export const isTodEnabled = atom<boolean>({
  key: 'isTodEnabled',
  default: false,
});

export const ptmEligibility = atom<IPtmEligibility | null>({
  key: 'ptmEligibility',
  default: null,
});

export const UpcomingPtmData = atom<IUpcomingPtmData | null>({
  key: 'UpcomingPtmData',
  default: null,
});

export const searchKey = atom<string | null>({
  key: 'searchKey',
  default: null,
  effects_UNSTABLE: [persistToLocalStorage],
});

export const docQueryText = atom<string | null>({
  key: 'docQueryText',
  default: '',
});

export const docQueryImage = atom<string | null>({
  key: 'docQueryImage',
  default: '',
});

export const searchCount = atom<string | null>({
  key: 'searchCount',
  default: null,
});

export const idsQueryDataForTOD = atom<QueryData | null>({
  key: 'idsQueryDataForTOD',
  default: null,
  effects_UNSTABLE: [persistToLocalStorage],
});

interface SuggestionType {
  // eslint-disable-next-line camelcase
  book_name: string;
  // eslint-disable-next-line camelcase
  chapter_name: string;
  grade: string;
  href: string;
  // eslint-disable-next-line camelcase
  mathjax_question: string;
  qid: number;
  question: string;
  subject: string;
}
export const suggestionsState = atom<Array<SuggestionType>>({
  key: 'suggestions',
  default: [],
});

export const questionsState = atom({
  key: 'questions',
  default: [],
});
