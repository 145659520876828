export function getGradeNumber(inputString){
    var mapping = {
        'Standard I' : 1,
        'Standard II' : 2,
        'Standard III' : 3,
        'Standard IV' : 4,
        'Standard V' : 5,
        'Standard VI' :6,
        'Standard VII' : 7,
        'Standard VIII' : 8,
        'Standard IX' : 9,
        'Standard X' : 10,
        'Standard XI' : 11,
        'Standard XII' : 12
    }
    if(mapping[inputString]){
        return mapping[inputString]
    }
    return 6
  }