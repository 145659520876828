/* eslint-disable check-file/filename-naming-convention */
import { AppConfigInterface } from 'interfaces';
import { togglePasscodeFlag } from './toggleFeature';

// setting environment name
interface ConfigType {
  [key: string]: { [key: string]: string | number | boolean | object };
}

const environmentName = () => {
  const envName: string = process.env.REACT_APP_ENVIRONMENT || 'development';
  if (envName.includes('local')) {
    return 'local';
  }
  if (envName.includes('development')) {
    return 'development';
  }
  if (envName.includes('qa')) {
    return 'qa';
  }
  return envName;
};

const baseConfig = {
  environment: environmentName(),
  appId: '14',
  appSubPath: process.env.REACT_APP_SUB_DIRECTORY || '',
  oldLearnPortalUrl: process.env.REACT_APP_OLD_LEARN_PORTAL_URL || '',
};

const commonConfig = {
  // eslint-disable-next-line no-restricted-globals
  baseUrl: `${location.origin}/${baseConfig.appSubPath}`,
  btlaPageUrl:
    'https://play.google.com/store/apps/details?id=com.byjus.parentappv2',
  gaId: 'UA-59617890-40',
  visibleMenuItems:
    'home,aakash-dashboard,byjus-classes-dashboard,mock-test,aits,apq-dashboard,cwt-dashboard,aad-dashboard,revision-dashboard,downloads,concept-videos,bookmarks,all-subjects,byjus-tuition-center,monthly-exam,byjus-test-series,byjus-home-tuitions,leave-management,exam-prep-corner',
  cdn:
    baseConfig.environment === 'prod'
      ? 'https://learn-assets.byjusweb.com'
      : 'https://np-lp-assets.byjusweb.com',
  docPaymentSecretKey: '94f967ea-02b5-11ed-b939-0242ac120002',
  // eslint-disable-next-line no-restricted-globals
  hostName: `${location.origin}`,
  docWebTechTokenExpiryTime: 300,
  k10Grades: [
    'Standard IV',
    'Standard V',
    'Standard VI',
    'Standard VII',
    'Standard VIII',
    'Standard IX',
    'Standard X',
  ],
};

// Configs common for local, dev, qa, uat and staging
const commonNonProd = {
  selfServeBusinessId: '6475',
  selfServeClientId: '137403a16a68f36797ca841a65c115ada82ff2b8',
  selfHeptikBaseUrl: 'https://staging.hellohaptik.com/',
  tllmsBaseUrl: 'https://staging.tllms.com',
  mentoringTllmsBaseUrl: 'https://staging.tllms.com',
  sduiBaseUrl: 'https://sdui.stage.tllms.com',
  quizBaseUrl: 'https://stage-apigateway.whjr.one',
  calendarBaseUrl: 'https://calendar.stage.tllms.com',
  suggestionBaseUrl: 'https://suggestion.stage.tllms.com',
  userPreferenceBaseUrl: 'https://user-account.stage.tllms.com',
  tutorApiBaseUrl: 'https://api-stage.tllms.com/staging',
  metaTutorApiBaseUrl: 'https://api-stage.tllms.com/staging',
  newTutorApiBaseUrl: 'https://api-stage.tllms.com',
  wQuiz: {
    baseUrl: 'https://stage-apigateway.whjr.one',
    tenantId: '633ad3832609d41461f8b39d',
    clientId: 'byjus',
  },
  uxosApiBaseUrl: 'https://h-stage-apigateway.byjus.onl',
  selfServeBaseUrl: 'https://h-stage-apigateway.byjus.onl/self-serve',
  blcWquiz: {
    baseUrl: 'https://stage-apigateway.whjr.one',
  },
  tutorBaseUrl: 'https://api.tllms.com/staging',
  blcAssessedApiBaseUrl: 'https://blc-assessed.stage.tllms.com',
  loginUrl: 'https://students-staging.byjus.com',
  assessBaseUrl: 'https://api-assess-stage.tllms.com',
  qnaBaseUrl: 'https://search-api-stg.byjusweb.com',
  olapAppId: '123',
  olapApi: 'https://api-stage.tllms.com/1/stats/web/web.counterstage',
  instalearnBaseUrl: 'https://search-stg-instalearn.byjusweb.com/',
  instalearnApiBaseUrl: 'https://docpreprod.toppr.com',
  doubtsOnChatBaseUrl: 'https://search-stg-doc.byjusweb.com/',
  wprBaseUrl: 'https://mpr.stage.tllms.com/',
  wprForm: 'https://forms.gle/o5QG7TN96LeVe5xk6',
  wprSupport: 'mailto:support@byjus.com',
  tllmsAssessedUrl: 'https://api-stage.tllms.com/staging/blc-assessed',
  isAppDeepLinkEnabled: true,
  allowSubjective: true,
  allowQuarterlyTest: true,
  schoolId: '62623491b4ddaa0009f0d357',
  academicYear: '2022-2023',
  iasCohortId: 24,
  currentAffairsId: 24824,
  isRatingNowEnabled: true,
  isPdfUploadNotAllowed: false,
  hideUploadButton: false,
  vwoAccountID: 631422,
  vwoCampaignID: 14,
  instalearnCdnUrl: 'https://search-static-stg.byjusweb.com/question-images',
  micrositeUrl:
    'https://softwarelicences.pythonanywhere.com/xcelMicrositeDetails',
  loadCalendarModule: false,
  xcelJwtSecret:
    '6c4b75a64449287c2361ebcf1cb59b2207aad2ac574b2add45955c3f2baa7149f495226254cf33e6d129e77b633da6b7e4a82d71ca24288652029ad51379653b',
  uxosApiKey: '61555419b92cc5cb258e9741',
  androidApp2: 'app2_android',
  iosApp2: 'app2_ios',
  uxosClientId: 'dcc13989af575b2348c60e51efc288eb',
  uxosClientSecret: '486fdc92d46e587df19c02aff4e8e3127235127d',
  subjectSequenceId: {
    eng: [20],
    sst: [21],
  },
  middleEastCountrieIds: [2, 3, 4, 6, 7, 8],
  newJoinNowBaseDomain: 'https://stage-classroom.byjusweb.com',
};

const constants = {
  defaultCohortId: 29,
  defaultGrade: 'Standard IV',
  legacyAppId: 4,
  videoPausePercent: '100',
  upcomingClassAlertTime: 15,
};

const keys = {
  instalearnClientId: process.env.REACT_APP_INSTALEARN_CLIENT_ID,
  qnaToken: process.env.REACT_APP_QNA_TOKEN,
  assessedToken: process.env.REACT_APP_ASSESSED_TOKEN,
};

// Maintaining a separate configuration for idServiceConfig
const idServiceConfig = {
  local: {
    baseUrl: 'https://identity-staging.tllms.com',
    clientId: 'fdcd2d9b-32d8-47d0-aa02-af08eeaff690',
    redirectUri: `http://localhost:3000/${baseConfig.appSubPath}/callback`,
    scopes: `openid offline identities.read accounts.read clients.authorize accounts.update${
      togglePasscodeFlag ? ' passcode.update' : ''
    }`,
    hydraAuthBaseUrl: 'https://hydra-auth-staging.tllms.com',
    xTnlAppId: '14',
    lpHost: 'http://localhost:3000',
    nonce: '-swCI7-UL_3pfYRsQCBOU3XaM89X_-01HOslgZSoPPA',
    appClientId: '35a25ad2-7b6d-44f8-843a-da5e6a1bc030',
  },
  development: {
    baseUrl: 'https://identity-staging.tllms.com',
    clientId: 'fdcd2d9b-32d8-47d0-aa02-af08eeaff690',
    redirectUri: `https://dev.byjusweb.com/${baseConfig.appSubPath}/callback`,
    scopes: `openid offline identities.read accounts.read clients.authorize accounts.update${
      togglePasscodeFlag ? ' passcode.update' : ''
    }`,
    hydraAuthBaseUrl: 'https://hydra-auth-staging.tllms.com',
    xTnlAppId: '14',
    lpHost: 'https://dev.byjusweb.com',
    nonce: '-swCI7-UL_3pfYRsQCBOU3XaM89X_-01HOslgZSoPPA',
    appClientId: '35a25ad2-7b6d-44f8-843a-da5e6a1bc030',
  },
  qa: {
    baseUrl: 'https://identity-staging.tllms.com',
    clientId: 'fdcd2d9b-32d8-47d0-aa02-af08eeaff690',
    redirectUri: `https://qa.byjusweb.com/${baseConfig.appSubPath}/callback`,
    scopes: `openid offline identities.read accounts.read clients.authorize accounts.update${
      togglePasscodeFlag ? ' passcode.update' : ''
    }`,
    hydraAuthBaseUrl: 'https://hydra-auth-staging.tllms.com',
    xTnlAppId: '14',
    lpHost: 'https://qa.byjusweb.com',
    nonce: '-swCI7-UL_3pfYRsQCBOU3XaM89X_-01HOslgZSoPPA',
    appClientId: '35a25ad2-7b6d-44f8-843a-da5e6a1bc030',
  },
  uat: {
    baseUrl: 'https://identity.tllms.com',
    clientId: 'fa83838d-81c8-4c0f-a58c-30f28d6c0861',
    redirectUri: 'https://uat.byjusweb.com/learn/callback',
    scopes: `openid offline identities.read accounts.read clients.authorize`,
    hydraAuthBaseUrl: 'https://hydra-auth.tllms.com',
    xTnlAppId: '14',
    lpHost: 'https://uat.byjusweb.com',
    nonce: '-swCI7-UL_3pfYRsQCBOU3XaM89X_-01HOslgZSoPPA',
    appClientId: 'dd960805-efe1-4ea2-b67e-217f3b61b463',
  },
  staging: {
    baseUrl: 'https://identity-staging.tllms.com',
    clientId: 'fdcd2d9b-32d8-47d0-aa02-af08eeaff690',
    redirectUri: 'https://stage.byjusweb.com/learn/callback',
    scopes: `openid offline identities.read accounts.read clients.authorize accounts.update${
      togglePasscodeFlag ? ' passcode.update' : ''
    }`,
    hydraAuthBaseUrl: 'https://hydra-auth-staging.tllms.com',
    xTnlAppId: '14',
    lpHost: 'https://stage.byjusweb.com',
    nonce: '-swCI7-UL_3pfYRsQCBOU3XaM89X_-01HOslgZSoPPA',
    appClientId: 'test',
  },
  prep: {
    baseUrl: 'https://identity-prep.tllms.com',
    clientId: 'fdcd2d9b-32d8-47d0-aa02-af08eeaff690',
    redirectUri: 'https://prep.byjusweb.com/learn/callback',
    scopes: `openid offline identities.read accounts.read clients.authorize accounts.update${
      togglePasscodeFlag ? ' passcode.update' : ''
    }`,
    hydraAuthBaseUrl: 'https://hydra-auth-prep.tllms.com',
    xTnlAppId: '14',
    lpHost: 'https://prep.byjusweb.com',
    nonce: '-swCI7-UL_3pfYRsQCBOU3XaM89X_-01HOslgZSoPPA',
    appClientId: 'test',
  },
  prod: {
    baseUrl: 'https://identity.tllms.com',
    clientId: '8f1f3c9d-c847-4f2c-a128-2cb4db93a06f',
    redirectUri: 'https://byjus.com/learn/callback',
    scopes: `openid offline identities.read accounts.read clients.authorize accounts.update${
      togglePasscodeFlag ? ' passcode.update' : ''
    }`,
    hydraAuthBaseUrl: 'https://hydra-auth.tllms.com',
    xTnlAppId: '14',
    lpHost: 'https://byjus.com',
    nonce: '-swCI7-UL_3pfYRsQCBOU3XaM89X_-01HOslgZSoPPA',
    appClientId: '90391da1-ee49-4378-bd12-1924134e906e',
  },
};

const config: ConfigType = {
  local: {
    idService: idServiceConfig.local,
    oldPortalUrl:
      baseConfig.oldLearnPortalUrl || 'https://learn-devtest.tllms.com',
    gmapNucleus: 'https://dev-gmaps-nucleus.byjusweb.com',
    olapEnable: 'true',
    lptNucleusBaseUrl: 'https://dev-lpt-nucleus.byjusweb.com/api/',
    maintenanceMode: '0',
    isConsoleLogsDisabled: false,
    isUnitTestCaseEnabled: true,
    webTechFrontendBaseUrl: 'https://dev.byjusweb.com',
    onboardingUrl: 'https://h-stage-apigateway.byjus.onl/onboarding',
    onboardingIframeUrl: 'https://student-onboarding-staging.byjusorders.com',
    isOtelEnabled: false,
    otelServiceName: 'dev-learn-portal',
    tracesEndpoint: 'https://otel-dev.byjus.app/v1/traces',
    ...commonNonProd,
  },
  development: {
    idService: idServiceConfig.development,
    oldPortalUrl:
      baseConfig.oldLearnPortalUrl || 'https://learn-devtest.tllms.com',
    gmapNucleus: 'https://dev-gmaps-nucleus.byjusweb.com',
    olapEnable: 'true',
    lptNucleusBaseUrl: 'https://dev-lpt-nucleus.byjusweb.com/api/',
    maintenanceMode: '0',
    isConsoleLogsDisabled: false,
    webTechFrontendBaseUrl: 'https://dev.byjusweb.com',
    onboardingUrl: 'https://h-stage-apigateway.byjus.onl/onboarding',
    onboardingIframeUrl: 'https://student-onboarding-staging.byjusorders.com',
    isOtelEnabled: true,
    otelServiceName: 'dev-learn-portal',
    tracesEndpoint: 'https://otel-dev.byjus.app/v1/traces',
    ...commonNonProd,
  },
  qa: {
    idService: idServiceConfig.qa,
    oldPortalUrl:
      baseConfig.oldLearnPortalUrl || 'https://learn-common.stage.tllms.com',
    gmapNucleus: 'https://dev-gmaps-nucleus.byjusweb.com',
    olapEnable: 'true',
    lptNucleusBaseUrl: 'https://qa-lpt-nucleus.byjusweb.com/api/',
    maintenanceMode: '0',
    isConsoleLogsDisabled: true,
    webTechFrontendBaseUrl: 'https://qa.byjusweb.com',
    onboardingUrl: 'https://h-stage-apigateway.byjus.onl/onboarding',
    onboardingIframeUrl: 'https://student-onboarding-staging.byjusorders.com',
    isOtelEnabled: false,
    otelServiceName: 'dev-learn-portal',
    tracesEndpoint: 'https://otel-dev.byjus.app/v1/traces',
    ...commonNonProd,
  },
  uat: {
    idService: idServiceConfig.uat,
    oldPortalUrl: 'https://learn-common.stage.tllms.com',
    gmapNucleus: 'https://uat-gmaps.byjusweb.com',
    olapEnable: 'true',
    lptNucleusBaseUrl: 'https://uat-lpt-nucleus.byjusweb.com/api/',
    maintenanceMode: '0',
    isConsoleLogsDisabled: false,
    webTechFrontendBaseUrl: 'https://uat.byjusweb.com',
    onboardingUrl: 'https://h-stage-apigateway.byjus.onl/onboarding',
    onboardingIframeUrl: 'https://student-onboarding-staging.byjusorders.com',
    isOtelEnabled: true,
    otelServiceName: 'uat-learn-portal',
    tracesEndpoint: 'https://traces-receiver.bom.byjustech.net/v1/traces',
    ...commonNonProd,
  },
  staging: {
    idService: idServiceConfig.staging,
    oldPortalUrl: 'https://learn-common.stage.tllms.com',
    gmapNucleus: 'https://uat-gmaps.byjusweb.com',
    olapEnable: 'true',
    lptNucleusBaseUrl: 'https://uat-lpt-nucleus.byjusweb.com/api/',
    maintenanceMode: '0',
    isConsoleLogsDisabled: false,
    webTechFrontendBaseUrl: 'https://qa.byjusweb.com',
    onboardingUrl: 'https://h-stage-apigateway.byjus.onl/onboarding',
    onboardingIframeUrl: 'https://student-onboarding-staging.byjusorders.com',
    isOtelEnabled: true,
    otelServiceName: 'dev-learn-portal',
    tracesEndpoint: 'https://otel-dev.byjus.app/v1/traces',
    ...commonNonProd,
  },
  prep: {
    selfServeBusinessId: '6475',
    selfServeClientId: '137403a16a68f36797ca841a65c115ada82ff2b8',
    selfHeptikBaseUrl: 'https://staging.hellohaptik.com/',
    selfServeBaseUrl: 'https://h-stage-apigateway.byjus.onl/self-serve',
    uxosApiBaseUrl: 'https://h-stage-apigateway.byjus.onl',
    idService: idServiceConfig.prep,
    oldPortalUrl: 'https://learn-common.stage.tllms.com',
    gmapNucleus: 'https://uat-gmaps.byjusweb.com',
    olapEnable: 'true',
    lptNucleusBaseUrl: 'https://uat-lpt-nucleus.byjusweb.com/api/',
    maintenanceMode: '0',
    isConsoleLogsDisabled: false,
    webTechFrontendBaseUrl: 'https://qa.byjusweb.com',
    tllmsBaseUrl: 'https://prep.tllms.com',
    sduiBaseUrl: 'https://sdui.tllms.com',
    userPreferenceBaseUrl: 'https://user-account.prep.tllms.com',
    tutorApiBaseUrl: 'https://api-prep.tllms.com/prep',
    tutorBaseUrl: 'https://api-prep.tllms.com/prep',
    blcAssessedApiBaseUrl: 'https://blc-assessed.prep.tllms.com',
    loginUrl: 'https://students-prep.byjus.com',
    assessBaseUrl: 'https://api-assess-prep.tllms.com',
    qnaBaseUrl: 'https://search-api-prep.byjusweb.com',
    olapAppId: '123',
    olapApi: 'https://api-prep.tllms.com/1/stats/web/web.counterstage',
    instalearnBaseUrl: 'https://search-prep-instalearn.byjusweb.com/',
    instalearnApiBaseUrl: 'https://docprep.toppr.com',
    doubtsOnChatBaseUrl: 'https://search-prep-doc.byjusweb.com/',
    wprBaseUrl: 'https://mpr.prep.tllms.com/',
    wprForm: 'https://forms.gle/o5QG7TN96LeVe5xk6',
    wprSupport: 'mailto:support@byjus.com',
    tllmsAssessedUrl: 'https://api-prep.tllms.com/staging/blc-assessed',
    isAppDeepLinkEnabled: true,
    allowSubjective: true,
    schoolId: '62623491b4ddaa0009f0d357',
    academicYear: '2022-2023',
    iasCohortId: 24,
    currentAffairsId: 24824,
    isRatingNowEnabled: true,
    isPdfUploadNotAllowed: false,
    hideUploadButton: false,
    vwoAccountID: 631422,
    vwoCampaignID: 14,
    micrositeUrl:
      'https://softwarelicences.pythonanywhere.com/liveXcelMicrositeDetails',
    loadCalendarModule: false,
    xcelJwtSecret:
      'b19d8835b4e1dac8d055e1ae5728106fe276076cd85bf084a7b0879a9cd57af24610aa10917471d6d3416ddb3f7ca72f508bdd4be8786d16f4cd2197ca77c877',
    uxosApiKey: '61555419b92cc5cb258e9741',
    subjectSequenceId: {
      eng: [10],
      sst: [11],
    },
    androidApp2: 'app2_android',
    iosApp2: 'app2_ios',
    middleEastCountrieIds: [23, 24, 25, 26, 27, 28],
    isOtelEnabled: false,
    otelServiceName: 'prep-learn-portal',
    tracesEndpoint: 'https://otel-dev.byjus.app/v1/traces',
  },
  prod: {
    selfServeBusinessId: '6475',
    selfServeClientId: 'ae5c8eca645d1636e69c41ef42428196d4fb3839',
    selfHeptikBaseUrl: 'https://api.haptikapi.com/',
    idService:
      baseConfig.environment === 'uat'
        ? idServiceConfig.uat
        : idServiceConfig.prod,
    oldPortalUrl: 'https://learn.byjus.com',
    gmapNucleus: 'https://prod-gmaps.byjusweb.com',
    olapEnable: baseConfig.environment === 'uat' ? 'false' : 'true', // pre-prod/uat env level changes, desabled olap events
    lptNucleusBaseUrl: 'https://lpt-nucleus.byjusweb.com/api/',
    maintenanceMode: '0',
    isConsoleLogsDisabled: baseConfig.environment !== 'uat', // pre-prod/uat env level changes, enabled consoling errors
    webTechFrontendBaseUrl: 'https://byjus.com',
    tllmsBaseUrl: `https://marketing.tllms.com`,
    mentoringTllmsBaseUrl: `https://${
      baseConfig.environment === 'uat' ? 'marketing-pre-prod' : 'marketing'
    }.tllms.com`,
    sduiBaseUrl: 'https://sdui.tllms.com',
    quizBaseUrl: 'https://apigw.whitehatjr.com',
    userPreferenceBaseUrl: 'https://user-account.tllms.com',
    tutorApiBaseUrl: `https://api.tllms.com${
      baseConfig.environment === 'uat' ? '/pre-prod' : ''
    }`,
    metaTutorApiBaseUrl: `https://api.tllms.com${
      baseConfig.environment === 'uat' ? '/pre-prod' : '/production'
    }`,
    wQuiz: {
      baseUrl: 'https://apigw.whitehatjr.com',
      tenantId: '646eedc539558230466ee1ed',
      clientId: 'byjus',
    },
    uxosApiBaseUrl: 'https://api.byjus.com',
    selfServeBaseUrl: 'https://api.byjus.com/self-serve',
    tutorBaseUrl: `https://api.tllms.com${
      baseConfig.environment === 'uat' ? '/pre-prod' : ''
    }`,
    blcWquiz: {
      baseUrl: 'https://apigw.whitehatjr.com',
    },
    blcAssessedApiBaseUrl: 'https://assessed.blc.tllms.com',
    loginUrl: 'https://students.byjus.com/',
    assessBaseUrl: 'https://api-assess.tllms.com',
    qnaBaseUrl: 'https://doc.toppr.com/api/v1.0/qna/',
    olapAppId: '23',
    olapApi: 'https://api.tllms.com/1/stats/web/web.counter',
    instalearnBaseUrl: 'https://search-instalearn.byjusweb.com/',
    instalearnApiBaseUrl: 'https://doc.toppr.com',
    doubtsOnChatBaseUrl: 'https://search-prd-doc.byjusweb.com/',
    wprBaseUrl: 'https://mpr.tllms.com/',
    wprForm: 'https://forms.gle/o5QG7TN96LeVe5xk6',
    wprSupport: 'mailto:support@byjus.com',
    tllmsAssessedUrl: 'https://api.tllms.com/blc-assessed',
    allowSubjective: true,
    allowQuarterlyTest: true,
    isAppDeepLinkEnabled: true,
    schoolId: '628f777e607fee0008aa5a76',
    academicYear: '2022-2023',
    iasCohortId: 24,
    currentAffairsId: 24824,
    isRatingNowEnabled: true,
    isPdfUploadNotAllowed: false,
    hideUploadButton: false,
    vwoAccountID: 640121,
    vwoCampaignID: 2,
    instalearnCdnUrl: 'https://search-static.byjusweb.com/question-images',
    micrositeUrl:
      'https://softwarelicences.pythonanywhere.com/liveXcelMicrositeDetails',
    loadCalendarModule: false,
    xcelJwtSecret:
      'b19d8835b4e1dac8d055e1ae5728106fe276076cd85bf084a7b0879a9cd57af24610aa10917471d6d3416ddb3f7ca72f508bdd4be8786d16f4cd2197ca77c877',
    onboardingUrl: 'https://api.byjus.com/onboarding',
    onboardingIframeUrl: 'https://student-onboarding.byjusorders.com',
    uxosApiKey: '5af96461816f0305f8d01574',
    uxosClientId: 'f7b972e6e8dc613bb2d931d88a5359a2',
    uxosClientSecret: 'a9b1162ecee2c0a44456b10694c804679e2a88c5',
    subjectSequenceId: {
      eng: [10],
      sst: [11],
    },
    androidApp2: 'app2_android',
    iosApp2: 'app2_ios',
    middleEastCountrieIds: [23, 24, 25, 26, 27, 28],
    isOtelEnabled: true,
    otelServiceName: 'learn-portal',
    tracesEndpoint: 'https://traces-receiver.bom.byjustech.net/v1/traces',
    newJoinNowBaseDomain: 'https://byjus.com/classroom',
    newTutorApiBaseUrl: `https://api.tllms.com${
      baseConfig.environment === 'uat' ? '/pre-prod' : ''
    }`,
  },
};

// Set module wise asset configs
export const assetConfig = {
  static: {
    images: `${commonConfig.cdn}/images/static/`,
    svg: `${commonConfig.cdn}/svg/static/`,
  },
  dashboard: {
    images: `${commonConfig.cdn}/images/dashboard/`,
    svg: `${commonConfig.cdn}/svg/dashboard/`,
  },
  onboarding: {
    images: `${commonConfig.cdn}/images/onboarding/`,
    svg: `${commonConfig.cdn}/svg/onboarding/`,
  },
  profile: {
    images: `${commonConfig.cdn}/images/profile/`,
    svg: `${commonConfig.cdn}/svg/profile/`,
  },
  videos: {
    images: `${commonConfig.cdn}/images/videos/`, // to map images
    svg: `${commonConfig.cdn}/svg/videos/`, // to map svg
  },
  byjusclasses: {
    images: `${commonConfig.cdn}/images/byjusclasses/`,
    svg: `${commonConfig.cdn}/svg/byjusclasses/`,
  },
  misc: {
    images: `${commonConfig.cdn}/images/misc/`,
    svg: `${commonConfig.cdn}/svg/misc/`,
  },
  aits: {
    images: `${commonConfig.cdn}/images/aits/`,
    svg: `${commonConfig.cdn}/svg/aits/`,
  },
  subjects: {
    images: `${commonConfig.cdn}/images/subjects/`,
  },
  downloads: {
    images: `${commonConfig.cdn}/images/downloads/`,
    svg: `${commonConfig.cdn}/svg/downloads/`,
  },
  askadoubt: {
    images: `${commonConfig.cdn}/images/askadoubt/`,
    svg: `${commonConfig.cdn}/svg/askadoubt/`,
  },
  login: {
    images: `${commonConfig.cdn}/images/login/countries/`, // to map svg
    svg: `${commonConfig.cdn}/svg/login/`,
  },
  allsubject: {
    images: `${commonConfig.cdn}/images/allsubject/`,
  },
  apq: {
    images: `${commonConfig.cdn}/images/apq/`,
    svg: `${commonConfig.cdn}/svg/apq/`,
  },
  cwt: {
    svg: `${commonConfig.cdn}/svg/cwt/`,
  },
  bookmarks: {
    svg: `${commonConfig.cdn}/svg/bookmarks/`,
  },
  common: {
    images: `${commonConfig.cdn}/images/common/`,
    svg: `${commonConfig.cdn}/svg/common`,
  },
  byjusTuitionCenter: {
    svg: `${commonConfig.cdn}/svg/byjus-tuition-center/`,
  },
  byjusNeo: {
    svg: `${commonConfig.cdn}/svg/byjusclasses`,
    images: `${commonConfig.cdn}/images/byjusclasses`,
  },
  learnJourney: {
    svg: `${commonConfig.cdn}/svg/learn-journey/`,
    images: `${commonConfig.cdn}/images/learn-journey`,
  },
  learnCalendar: {
    svg: `${commonConfig.cdn}/svg/learn-calendar/`,
    images: `${commonConfig.cdn}/images/learn-calendar`,
  },
  calendar: {
    svg: `${commonConfig.cdn}/svg/calendar/`,
  },
  byjustestseries: {
    svg: `${commonConfig.cdn}/svg/byjustestseries/`,
  },
  selfServe: {
    images: `${commonConfig.cdn}/images/selfServe/`,
    svg: `${commonConfig.cdn}/svg/selfServe/`,
  },
  onBoarding: {
    svg: `${commonConfig.cdn}/svg/onboarding/`,
  },
  orintationIcon: {
    svg: `${commonConfig.cdn}/svg/byjus-tuition-center/`,
  },
  examPrepCornerIcon: {
    images: `${commonConfig.cdn}/images/misc/`
  },
  slotPreference: {
    images: `${commonConfig.cdn}/images/slot-preferences/`
  }
};

export default Object.assign(
  baseConfig,
  commonConfig,
  constants,
  keys,
  config[baseConfig.environment === 'uat' ? 'prod' : baseConfig.environment], // If env variable is not set for REACT_APP_ENVIRONMENT will take it as development , pre-prod/uat env level changes, pointing all prod apis to uat.
  assetConfig,
) as AppConfigInterface;
