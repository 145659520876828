import AppConfig from 'config/AppConfig';
import { olap } from 'analytics';
import { getAccountId, getConfigData } from 'helpers/idServiceParams';
import { APICore } from './apiCore';

const api = new APICore();

export function getSubjectTopics(cohort_id: any) {
  return api.get(
    `${AppConfig.tllmsBaseUrl}/web/v1/cohorts/${cohort_id}/category_tree`,
  );
}

export function getAvatars() {
  return api.get(`${AppConfig.tllmsBaseUrl}/web/v1/avatars`);
}

export function setSubjectTopics(data: any) {
  return api.create(
    `${AppConfig.userPreferenceBaseUrl}/api/v1/preference`,
    data,
  );
}

export function updateUser(data: any) {
  return api.create(`${AppConfig.tllmsBaseUrl}/web/v1/update_user`, data);
}

export async function updateName(data: any) {
  const params = await getConfigData();
  return api.update(
    `${AppConfig.idService.baseUrl}/api/v2/accounts/${getAccountId()}`,
    data,
    params,
    true,
  );
}

export function getSubscription() {
  return api.get(`${AppConfig.tllmsBaseUrl}/common/api/v1/constants`);
}

export function requestCallBack(data: any) {
  return api.create(
    `${AppConfig.tllmsBaseUrl}/web/v1/lead_squared/create_generic`,
    data,
  );
}

export function OLAPHelperPersonalize(params) {
  olap({
    m_comments: 'personalization',
    m_category: 'Personalise suggestions',
    ...params,
  });
}

export function OLAPHelperProfile(params) {
  olap({
    m_comments: 'Profile',
    m_category: 'profile page',
    ...params,
  });
}
