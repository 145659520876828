import { useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import Image from 'components/Image';
import AppConfig from 'config/AppConfig';
import StyledButton from 'components/styledbutton/StyledButton';
import { SetNewPasscodeReminderData } from 'states/PasscodeState';

import {
  Helptext,
  HelptextLabel,
  SessionExpiredModal,
  SessionExpiredModalBody,
  SessionExpiredModalFooter,
} from './sessionExpiredModal.style';

const SessionExpired = (props: { show: boolean }) => {
  const { show } = props;
  const history = useHistory();
  const setPasscodeReminderData = useSetRecoilState(SetNewPasscodeReminderData);
  return (
    <SessionExpiredModal show={show} centered backdrop="static">
      <SessionExpiredModalBody>
        <Image
          src={`${AppConfig.login.svg}sessionexpired.svg`}
          alt="session-expired-icon"
          height="auto"
          width={96}
          style={{ marginBottom: '2.5rem' }}
        />

        <HelptextLabel>Your session is expired</HelptextLabel>
        <Helptext>
          Create your own, easy to remember passcode to log in across devices.
        </Helptext>
      </SessionExpiredModalBody>
      <SessionExpiredModalFooter>
        <StyledButton
          variant="primary"
          style={{ width: '14.0625rem' }}
          onClick={() => {
            setPasscodeReminderData(currentValue => {
              return {
                ...currentValue,
                triggeredCreatePasscodeAfterExpiry: true,
              };
            });
            history.push('/profile', { triggerChangePasscode: true });
          }}
        >
          Create Passcode
        </StyledButton>

        <StyledButton
          variant="link"
          style={{ width: '14.0625rem' }}
          onClick={() => {
            history.push('/account/logout');
          }}
        >
          Logout
        </StyledButton>
      </SessionExpiredModalFooter>
    </SessionExpiredModal>
  );
};

export default SessionExpired;
