import xss from 'xss';

// eslint-disable-next-line import/prefer-default-export
export const getSanitisedUrlParams = (
  urlParams: Record<string, unknown>,
): Record<string, string> => {
  const newFinalParams = new Map();

  Object.entries(urlParams).forEach(([key, value]) => {
    const sanitisedKey = xss(key);
    const sanitisedValue = xss(value as string);
    newFinalParams.set(sanitisedKey, sanitisedValue);
  });

  return Object.fromEntries(newFinalParams) as Record<string, string>;
};

export const getSanitisedValue = (param: unknown) => {
  const sanitisedValue = xss(param as string);
  return sanitisedValue;
};
