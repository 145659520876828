import { AxiosResponse } from 'axios';
import lpAxios from './lpAxios';

interface IHeaders {
  'content-type': string;
  'X-TNL-DEVICEOS'?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const axiosPost = async <T, R>(
  url: string,
  body?: T,
  overrideHeaders?: IHeaders,
): Promise<AxiosResponse<R>> => {
  return lpAxios.post<R>(url, body, {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    headers: {
      // FIX-ME: check => most of the headers are set by interceptor
      ...lpAxios.defaults.headers,
      ...overrideHeaders,
    },
  });
};
