export interface ICourseData {
  id: string;
  name: string;
  type: string;
}

const getSubjectName = (id: string, courseData: ICourseData[]) => {
  const subjectObj = courseData.filter(item => item.id === id)[0];
  if (subjectObj && subjectObj.type === 'subject') {
    return subjectObj.name;
  }
  return '';
};

// to get subject id from subject name from courseData
const getSubjectId = (name: string, courseData: ICourseData[]) => {
  const subjectObj = courseData.filter(item => item.name === name)[0];
  if (subjectObj && subjectObj.type === 'subject') {
    return subjectObj.id;
  }
  return '';
};

export { getSubjectName, getSubjectId };
