import AppConfig from 'config/AppConfig';
import { AxiosError } from 'axios';
import { APICore, handleResourceNotFoundStatus } from '../apiCore';

const appConfig = AppConfig as {
  tutorApiBaseUrl: string;
};

const submitRating = async (
  sessionId: string,
  step: string,
  rating: number,
  feedbacks: Array<string>,
) => {
  try {
    const api = new APICore();
    const submitRatingResponse = await api.create(
      `${appConfig.tutorApiBaseUrl}/ttplus_web_v2/web/bht/v1/sessions/${sessionId}/ratings`,
      {
        step,
        rating,
        feedbacks,
        comment: '',
      },
      false,
      { validateStatus: handleResourceNotFoundStatus },
    );
    return submitRatingResponse;
  } catch (error) {
    const err = error as AxiosError;
    console.error(err?.response?.data);
    return null;
  }
};

export default submitRating;
