/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import './alert.scss';

type IAlertTypes = 'success' | 'failure' | 'default';
interface IAlert {
  title: string;
  id?: string;
  delay?: number;
  type?: IAlertTypes;
}

const AlertColors = {
  success: '#4dcd4d',
  failure: '#de5454',
  default: '#333333',
};

const AlertItem = styled.div<{ type: IAlertTypes }>`
  border-radius: 0.313rem;
  background-color: ${({ type = 'default' }) => AlertColors[type]};
  opacity: 0.8;
  padding: 0.75rem 1.25rem;
  color: white;
  text-align: center;
  margin: 0.313rem;
  min-width: 37.5rem;
`;

const AlertContext = React.createContext<{
  alerts: IAlert[] | [];
  handleAddAlert?: (alert: IAlert) => void;
}>({
  alerts: [],
});
const AlertProvider: React.FC = ({ children }) => {
  const [alerts, setAlerts] = useState<IAlert[] | []>([]);
  const handleAddAlert = (alert: IAlert) => {
    const id = uuid();
    const { delay = 2000 } = alert;
    setAlerts(currentAlerts => [{ ...alert, id }, ...currentAlerts]);
    setTimeout(() => {
      setAlerts(alerts => alerts.filter(alert => alert.id !== id));
    }, delay);
  };
  useEffect(() => {
    document.addEventListener('alert_message', e => {
      const { title, delay, type } = e?.detail;
      handleAddAlert({ title, delay, type });
    });
    return () => {
      document.removeEventListener('alert_message', () => {});
    };
  }, []);
  return (
    <AlertContext.Provider value={{ alerts, handleAddAlert }}>
      {children}
      <div className="alertContainer">
        {alerts.map(item => (
          <div className="d-flex justify-content-center" key={item.id}>
            <AlertItem className="alert" type={item.type}>
              {item.title}
            </AlertItem>
          </div>
        ))}
      </div>
    </AlertContext.Provider>
  );
};

const useAlert = () => {
  const { alerts, handleAddAlert } = useContext(AlertContext);
  return { alerts, handleAddAlert };
};

const handleDispatchAlert = ({ title, delay, type }: IAlert) => {
  const event = new CustomEvent('alert_message', {
    detail: {
      title,
      delay,
      type,
    },
  });
  document.dispatchEvent(event);
};

export { AlertProvider, useAlert, handleDispatchAlert };
