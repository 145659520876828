import AppConfig from 'config/AppConfig';
import { ICancelClassOptionsResponse } from './ibht';
import { APICore, handleResourceNotFoundStatus } from '../apiCore';
import transformCancelOptionsAPIResponse from './transformCancelOptionsAPIResponse';

const appConfig = AppConfig as {
  tutorApiBaseUrl: string;
};

const getCancelOptions = async (): Promise<Array<string>> => {
  try {
    const api = new APICore();
    const cancelClassOptionsResponse: ICancelClassOptionsResponse =
      await api.get(
        `${appConfig.tutorApiBaseUrl}/ttplus_web_v2/web/bht/sessions/cancel`,
        null,
        false,
        { validateStatus: handleResourceNotFoundStatus },
      );
    const formattedCancelClassOptions = transformCancelOptionsAPIResponse(
      cancelClassOptionsResponse.data,
    );
    return formattedCancelClassOptions;
  } catch (error) {
    return [];
  }
};

export default getCancelOptions;
