import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import withErrorBoundary from 'helpers/errorboundary';
import TodRouter from 'modules/tod/TodRouter';
import lazyWithRetry from 'utils/LazyWithRetry';
import { togglePasscodeFlag } from 'config/toggleFeature';
import { flattenRoutes } from 'helpers';
import { IRedirectionMapping } from 'interfaces';
import PrivateRoute from './PrivateRoute';
import { lp1RedirectionMapping } from './lp1RedirectionMapping';

import { ILPRoute } from '../interfaces';

const HomeRouter = lazyWithRetry(
  () => import(/* webpackChunkName: "HomeRouter" */ 'modules/home/HomeRouter'),
  'HomeRouter',
);
const ReportsRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "ReportsRouter" */ 'modules/static/reports/ReportsRouter'
    ),
  'ReportsRouter',
);
const PopularVideosRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "PopularVideosRouter" */ 'modules/popularvideos/PopularVideosRouter'
    ),
  'PopularVideosRouter',
);

const BookmarksRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "BookmarksRouter" */ 'modules/bookmarks/BookmarksRouter'
    ),
  'BookmarksRouter',
);
const AsRouter = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "AsRouter" */ 'modules/allsubjects/AsRouter'),
  'AsRouter',
);
const AqRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "AqRouter" */ 'modules/adaptivequestions/AqRouter'
    ),
  'AqRouter',
);
const CwtRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "CwtRouter" */ 'modules/chapterwisetests/CwtRouter'
    ),
  'CwtRouter',
);
const TestRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "TestRouter" */ 'modules/chapterwisetests/TestRouter'
    ),
  'TestRouter',
);

const CwtQuizRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "TestRouter" */ 'modules/chapterwisetests/QuizRouter'
    ),
  'QuizRouter',
);

const AadRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "DoubtsOnChatRouter" */ 'modules/doubtsonchat/DoubtsOnChatRouter'
    ),
  'DoubtsOnChatRouter',
);

const MtRouter = lazyWithRetry(
  () => import(/* webpackChunkName: "MtRouter" */ 'modules/mocktests/MtRouter'),
  'MtRouter',
);

const Callback = lazyWithRetry(
  () => import(/* webpackChunkName: "Callback" */ 'modules/account/Callback'),
  'Callback',
);

const ByjusClassesRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "ByjusClassesRouter" */ 'modules/byjusclasses/ByjusClassesRouter'
    ),
  'ByjusClassesRouter',
);

const BhtRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "BhtRouter" */ 'modules/byjusHomeTuition/BhtRouter'
    ),
  'BhtRouter',
);

const SubjectiveTestRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "SubjectiveTestRouter" */ 'modules/byjusclasses/SubjectiveTestRouter'
    ),
  'SubjectiveTestRouter',
);

const WQuizRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "WQuizRouter" */ 'modules/byjusclasses/WQuizRouter'
    ),
  'WQuizRouter',
);

const SubjectiveQuarterlyTestRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "QuarterlySubjectiveTestRouter" */ 'modules/byjusclasses/QuarterlySubjectiveTestRouter'
    ),
  'QuarterlySubjectiveTestRouter',
);

const ByjusClassRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "ByjusClassRouter" */ 'modules/byjusclass/ByjusClassRouter'
    ),
  'ByjusClassRouter',
);

const AakashClassesRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "AakashClassesRouter" */ 'modules/aakashclasses/AakashClassesRouter'
    ),
  'AakashClassesRouter',
);
const AitsRouter = lazyWithRetry(
  () => import(/* webpackChunkName: "AitsRouter" */ 'modules/aits/AitsRouter'),
  'AitsRouter',
);
const MonthlyTestRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "MonthlyTestRouter" */ 'modules/monthlytest/MonthlyTestRouter'
    ),
  'MonthlyTestRouter',
);
const ProfileRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "ProfileRouter" */ 'modules/profile/ProfileRouter'
    ),
  'ProfileRouter',
);
const DownloadsRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "DownloadsRouter" */ 'modules/downloads/DownloadsRouter'
    ),
  'DownloadsRouter',
);
const DownloadAppRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "DownloadAppRouter" */ 'modules/downloadApp/DownloadAppRouter'
    ),
  'DownloadAppRouter',
);
const InClassLoading = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "InClassLoading" */ 'modules/byjusclasses/InClassLoading'
    ),
  'InClassLoading',
);
const ByjusTestSeriesRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "ByjusTestSeriesRouter" */ 'modules/byjustestseries/ByjusTestSeriesRouter'
    ),
  'ByjusTestSeriesRouter',
);

// auth
const Login = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "LoginRevamp" */ 'modules/account/LoginRevamp'),
  'Login',
);
const Logout = lazyWithRetry(
  () => import(/* webpackChunkName: "Logout" */ 'modules/account/Logout'),
  'Logout',
);
const Register = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "RegisterRevamp" */ 'modules/account/RegisterRevamp'
    ),
  'Register',
);
const SsoCallback = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "SsoCallback" */ 'modules/account/SsoCallback'),
  'SsoCallback',
);
const LoginMultipleAcc = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "LoginMultipleAcc" */ 'modules/account/LoginMultipleAcc'
    ),
  'LoginMultipleAcc',
);
const ByjusTuitionCenterRoutes = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "ByjusTuitionCenterRouter" */ 'modules/byjusTuitionCenter/ByjusTuitionCenterRouter'
    ),
  'ByjusTuitionCenterRouter',
);
const LearnJourneyRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "LearnJourneyRouter" */ 'modules/premiumHome/components/LearnJourneyRouter'
    ),
  'LearnJourneyRouter',
);
const CalendarRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "CalenderRouter" */ '../modules/calendarHome/components/CalendarRouter'
    ),
  'CalendarRouter',
);
const CalendarSessionRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "CalenderSessionRouter" */ 'modules/calendarHome/CalendarSession'
    ),
  'CalendarSessionRouter',
);
const LeaveManagementRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "LeaveManagementRouter" */ 'modules/leaveManagement/LeaveManagementRouter'
    ),
  'LeaveManagementRouter',
);

const StudentOfflineReport = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "StudentOfflineReport" */ '../modules/byjusclasses/components/StudentOfflineReport'
    ),
  'StudentOfflineReport',
);

const ExamPrepCornerRouter = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "StudentOfflineReport" */ '../modules/examprepcorner/ExamPrepCornerRouter'
    ),
  'ExamPrepCornerRouter',
)

// root routes
const rootRoute: ILPRoute = {
  path: '/',
  exact: true,
  component: () => <Redirect to="/home" />,
  route: PrivateRoute,
};

const cwt: ILPRoute[] = [
  {
    path: '/chapter-wise-tests/start-test/:testId',
    name: 'Take test',
    component: TestRouter,
    route: Route,
  },
  {
    path: '/chapter-wise-tests/quiz/:attemptNo/:quizId',
    name: 'Quiz test',
    component: CwtQuizRouter,
    route: Route,
  },
  {
    path: '/byjus-classes/start-test/:assessmentId',
    name: 'Start test',
    component: SubjectiveTestRouter,
    route: Route,
  },
  {
    path: '/quarterly-test/start-test/:assessmentId',
    name: 'Subjective test',
    component: SubjectiveQuarterlyTestRouter,
    route: Route,
  },
  {
    path: '/byjus-classes/quiz/:attemptNo/:quizId',
    name: 'QuizCard',
    component: WQuizRouter,
    route: Route,
  },
];
// auth
const authRoutes: ILPRoute[] = [
  {
    path: '/account/login',
    name: 'Login',
    component: Login,
    route: Route,
  },
  {
    path: '/account/logout',
    name: 'Logout',
    component: Logout,
    route: Route,
  },
  {
    path: '/account/multiple-accounts',
    name: 'LoginMultipleAcc',
    component: LoginMultipleAcc,
    route: Route,
  },
  {
    path: '/account/register',
    name: 'Register',
    component: Register,
    route: Route,
  },
  {
    path: '/callback',
    component: Callback,
    route: Route,
  },
  {
    path: '/sso/callback',
    component: SsoCallback,
    route: Route,
  },
  {
    path: '/s/reports',
    name: 'Reports',
    component: ReportsRouter,
    route: Route,
  },
  {
    path: '/quiz/report/:slug/:quizId',
    name: 'Student Offline Report',
    component: StudentOfflineReport,
    route: Route,
  },
  {
    path: '/quiz/report/:slug/:quizId',
    name: 'Student Offline Report',
    component: StudentOfflineReport,
    route: Route,
  },
];

/**
 * FIX-ME: Let's not do this
 * We do not want to run anything
 * at the time of bundling.
 */
if (togglePasscodeFlag) {
  authRoutes.push({
    path: '/auth_verify',
    component: SsoCallback,
    route: Route,
  });
}

const loadComponent = ({
  to,
  regex,
  matchedRegexArrayLength,
  matchedRegexArrayKeyAndIndexs,
  backDropUrl,
  isExternalUrl,
}: IRedirectionMapping): JSX.Element | null => {
  if (isExternalUrl) {
    window.location.assign(to);
    return null;
  }

  let redirectTo = to;

  if (
    regex &&
    matchedRegexArrayLength &&
    matchedRegexArrayKeyAndIndexs &&
    backDropUrl
  ) {
    const matchedArray = window.location.href.match(regex);

    if (matchedArray?.length === matchedRegexArrayLength) {
      Object.keys(matchedRegexArrayKeyAndIndexs).forEach(property => {
        redirectTo = to.replace(
          property,
          matchedArray[matchedRegexArrayKeyAndIndexs[property]],
        );
      });
    } else {
      redirectTo = backDropUrl;
    }
  }

  return <Redirect to={redirectTo} />;
};

// auth lp1 old Routes mapping
try {
  const authLpOneRoutes = lp1RedirectionMapping.map(
    (value: IRedirectionMapping) => {
      const obj = {
        path: value.from,
        exact: true,
        component: () => loadComponent(value),
        route: Route,
      };
      return obj;
    },
  );
  authRoutes.push(...authLpOneRoutes);
} catch (err) {
  console.info('Failed while mapping LP1 redirection routes');
}

/** FIX-ME: remove LPRouteWithChildren & related logic if redundant
 * query: do we have any routes with children?
 * TO-DO: move to separate file & test all cases
 */
// flatten the list of all nested routes

const lpRoutes: ILPRoute[] = [
  {
    path: '/home',
    component: HomeRouter,
    route: PrivateRoute,
  },
  {
    path: '/all-subjects',
    component: AsRouter,
    route: PrivateRoute,
  },
  {
    path: '/byjus-classes',
    component: ByjusClassesRouter,
    route: PrivateRoute,
  },
  {
    path: '/byjus-home-tuitions',
    component: BhtRouter,
    route: PrivateRoute,
  },
  {
    path: '/byjus-class',
    component: ByjusClassRouter,
    route: PrivateRoute,
  },
  {
    path: '/aakash-class/dashboard',
    component: AakashClassesRouter,
    route: PrivateRoute,
  },
  {
    path: '/adaptive-questions',
    component: AqRouter,
    route: PrivateRoute,
  },
  {
    path: '/chapter-wise-tests',
    component: CwtRouter,
    route: PrivateRoute,
  },
  {
    path: '/mock-tests',
    component: MtRouter,
    route: PrivateRoute,
  },
  {
    path: '/aits',
    component: AitsRouter,
    route: PrivateRoute,
  },
  {
    path: '/monthly-exam',
    component: MonthlyTestRouter,
    route: PrivateRoute,
  },
  {
    path: '/ask-a-doubt',
    component: AadRouter,
    route: PrivateRoute,
  },
  {
    path: '/tutor-on-demand',
    component: TodRouter,
    route: PrivateRoute,
  },
  {
    path: '/all-bookmarks',
    component: BookmarksRouter,
    route: PrivateRoute,
  },
  {
    path: '/downloads',
    component: DownloadsRouter,
    route: PrivateRoute,
  },
  {
    path: '/concept-videos',
    component: PopularVideosRouter,
    route: PrivateRoute,
  },
  {
    path: '/profile',
    component: ProfileRouter,
    route: PrivateRoute,
  },
  {
    path: '/download-app',
    component: DownloadAppRouter,
    route: PrivateRoute,
  },
  {
    path: '/byjus-tuition-centre',
    component: ByjusTuitionCenterRoutes,
    route: PrivateRoute,
  },
  {
    path: '/journey',
    component: LearnJourneyRouter,
    route: PrivateRoute,
    criteria: 'is_learn_journey_enabled',
  },
  {
    path: '/in-class-loading',
    component: InClassLoading,
    route: PrivateRoute,
  },
  {
    path: '/byjus-test-series',
    component: ByjusTestSeriesRouter,
    route: PrivateRoute,
  },
  {
    path: '/calendar',
    component: CalendarRouter,
    route: PrivateRoute,
  },
  {
    path: '/session/:type/:id',
    component: CalendarSessionRouter,
    route: PrivateRoute,
  },
  {
    path: '/leave-management',
    component: LeaveManagementRouter,
    route: PrivateRoute,
  },
  {
    path: '/exam-prep-corner',
    component: ExamPrepCornerRouter,
    route: PrivateRoute,
  }
];

/**
 *
 * @param {Array} routes
 * @returns
 */
const wrapErrorBoundary = (routes: ILPRoute[]) => {
  return routes.map(item => {
    return {
      ...item,
      component: withErrorBoundary(item.component),
    };
  });
};

// All routes
const authProtectedRoutes = [...wrapErrorBoundary(lpRoutes)];
const exactAuthProtectedRoutes = [rootRoute];
const publicRoutes = [...authRoutes];
const exactAuthProtectedFlattenRoutes = flattenRoutes(exactAuthProtectedRoutes);
const authProtectedFlattenRoutes = flattenRoutes(authProtectedRoutes);
const publicProtectedFlattenRoutes = flattenRoutes(publicRoutes);
const cwtProtectedRoutes = [...cwt];

export {
  authProtectedRoutes,
  publicRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
  exactAuthProtectedFlattenRoutes,
  cwtProtectedRoutes,
};
