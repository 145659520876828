import AppConfig from 'config/AppConfig';
import { AxiosError } from 'axios';
import { ISlotData } from 'interfaces';
import { APICore, handleResourceNotFoundStatus } from '../apiCore';
import { ISlotsResponse } from './ibht';
import transformSlotsAPIResponse from './transformSlotsAPIResponse';

const appConfig = AppConfig as {
  tutorApiBaseUrl: string;
};

const fetchSlots = async (
  batchId: string,
  gender: string,
  language: string,
): Promise<ISlotData[]> => {
  try {
    let url = '';
    if (language.toLowerCase() === 'any' && gender.toLowerCase() === 'any') {
      url = `${appConfig.tutorApiBaseUrl}/ttplus_web_v2/web/bht/rebook/slots?batch_id=${batchId}`;
    } else if (
      language.toLowerCase() === 'any' &&
      gender.toLowerCase() !== 'any'
    ) {
      url = `${appConfig.tutorApiBaseUrl}/ttplus_web_v2/web/bht/rebook/slots?gender=${gender}&batch_id=${batchId}`;
    } else if (
      language.toLowerCase() !== 'any' &&
      gender.toLowerCase() === 'any'
    ) {
      url = `${appConfig.tutorApiBaseUrl}/ttplus_web_v2/web/bht/rebook/slots?languages[]=${language}&batch_id=${batchId}`;
    } else {
      url = `${appConfig.tutorApiBaseUrl}/ttplus_web_v2/web/bht/rebook/slots?languages[]=${language}&gender=${gender}&batch_id=${batchId}`;
    }
    const api = new APICore();
    const slotsResponse: ISlotsResponse = await api.get(url, null, false, {
      validateStatus: handleResourceNotFoundStatus,
    });
    const formattedSlotsResponse: Array<ISlotData> =
      slotsResponse.data.slots.map(transformSlotsAPIResponse);
    return formattedSlotsResponse;
  } catch (error) {
    const err = error as AxiosError;
    console.error(err?.response?.data);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return err?.response?.data;
  }
};

export default fetchSlots;
