import moment from 'moment';
import React, { useState, Suspense, useEffect } from 'react';
import { changeLeftSidebarType } from 'utils/layout';
import ToastComponent from 'modules/account/ToastComponent';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UserState } from 'states/UserState';
import { pushToDataLayer } from 'analytics';
import { isDoubtsOnChatEnabled, isInstaLearnEnabled } from 'states/SearchState';
import MetaDecorator from 'components/MetaDecorator';
import Asktutor from '@toppr-engg/ask-tutor';
import { useAskTutorPopup } from 'hooks';
import lazyWithRetry from 'utils/LazyWithRetry';

import PasscodeToast from 'modules/account/PasscodeToast';
import { togglePasscodeFlag, toggleSelfServe } from 'config/toggleFeature';
import PasscodeState, {
  Actors,
  IsForcedCreatePasscodeFlow,
  PasscodeEligibilityState,
  SetNewPasscodeReminderData,
} from 'states/PasscodeState';
import SessionExpired from 'modules/passcode/SessionExpired';
import { ErrorState } from 'states/LoginState';
import { isSelfServeAuthenticated } from 'states/SelfServeState';
import { User } from 'helpers/api/user';
import { safeJsonParse } from 'utils';
import { AppConfig } from 'config';
import { isHideHelpCenterCTA } from 'modules/byjusclasses/states/SelfservState';

import * as layoutConstants from '../constants';
import useSelfServe from '../hooks/selfServe/useSelfServe';

const SelfServeWidget = React.lazy(
  () => import('components/Selfserve/Selfserve'),
);

const InstaLearn = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "ComponentInstaLearn" */ 'components/InstaLearn'
    ),
  'ComponentInstaLearn',
);
const Topbar = lazyWithRetry(
  () => import(/* webpackChunkName: "ComponentTopBar" */ './Topbar'),
  'ComponentTopBar',
);
const LeftSidebar = lazyWithRetry(
  () => import(/* webpackChunkName: "ComponentLeftSidebar" */ './LeftSidebar'),
  'ComponentLeftSidebar',
);
const Footer = lazyWithRetry(
  () => import(/* webpackChunkName: "ComponentLPFooter" */ './LPFooter'),
  'ComponentLPFooter',
);
const loading = () => <div className="text-center" />;

type VerticalLayoutProps = {
  children?: {
    props?: {
      children?: [];
    };
  };
};
const DetachedLayout = ({ children }: VerticalLayoutProps) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isPasscodeToastEnable, setIsPasscodeToastEnable] = useState(false);
  const [hamburgerRef, setHamburgerRef] = useState(null);
  const loginErrorState = useRecoilValue(ErrorState);
  const isDoubtsOnChatEnabledValue = useRecoilValue(isDoubtsOnChatEnabled);
  const isInstaLearnEnabledValue = useRecoilValue(isInstaLearnEnabled);
  const askTutorObj = useAskTutorPopup();
  const { showAskTutorpopup, getAskTutorProps, ongoingTutorSession } =
    askTutorObj;
  const selfServeAuthenticated = useRecoilValue(isSelfServeAuthenticated);
  const passcodeData = useRecoilValue(PasscodeState);
  const user = useRecoilValue(UserState) as null | User;
  const passcodeReminderData = useRecoilValue(SetNewPasscodeReminderData);
  const passcodeEligibility = useRecoilValue(PasscodeEligibilityState);
  const [etaForLogout, setEtaForLogout] = useState('');
  const [isApp2Webview, setApp2WebView] = useState<boolean>(false);
  const [isd4View, setd4View] = useState<boolean>(false);
  const hideHelpCenterCTA = useRecoilValue<boolean>(isHideHelpCenterCTA);

  const [isForcedCreatePasscodeFlow, setIsForcedCreatePasscodeFlow] =
    useRecoilState(IsForcedCreatePasscodeFlow);
  const showSessionExpiry =
    passcodeEligibility.isRolloutPlanUseCase &&
    isForcedCreatePasscodeFlow &&
    !(
      passcodeData?.isPasscodeSet && passcodeData.lastUpdatedBy === Actors.USER
    ) &&
    !passcodeReminderData.triggeredCreatePasscodeAfterExpiry;
  const showPasscodeToast =
    ((passcodeData?.isPasscodeSet &&
      passcodeData.lastUpdatedBy === Actors.SYSTEM) ||
      passcodeEligibility.isRolloutPlanUseCase) &&
    !passcodeReminderData.hasUserDismissedToastMessage &&
    !passcodeReminderData.triggeredResetFromToast &&
    !passcodeReminderData.triggeredResetFromProfile;

  useEffect(() => {
    if (user) {
      pushToDataLayer({
        event: 'all_common_parameters',
        registered_user: 'Yes',
        user_id: user?.id,
        membership_plan: user?.user_subscription_type,
      });
    }
  }, [user]);

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;
    if (togglePasscodeFlag && passcodeEligibility.isRolloutPlanUseCase) {
      const expiryTime = moment(
        passcodeEligibility?.setPasscodeByDate,
        'YYYY-MM-DD HH:mm:ss Z [UTC]',
      );
      if (expiryTime.diff(moment()) <= 0) {
        setIsForcedCreatePasscodeFlow(true);
      } else {
        interval = setInterval(() => {
          setEtaForLogout(expiryTime.fromNow(true));
        }, 60 * 1000);
      }
    }
    return () => clearInterval(interval);
  }, [etaForLogout]);

  changeLeftSidebarType(
    isMenuOpened
      ? layoutConstants.LEFT_SIDEBAR_TYPE_FIXED
      : layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED,
  );

  const hideScrollbarBody = () => {
    const style = document.createElement('style');
    style.innerHTML = `body::-webkit-scrollbar {display: none;}  {-ms-overflow-style: none;}
    `;
    document.head.appendChild(style);
  };
  useEffect(() => {
    setInterval(() => {
      setIsPasscodeToastEnable(true);
    }, 7000);
  }, []);
  useEffect(() => {
    hideScrollbarBody();
  }, []);
  const location = useLocation();
  const { pathname } = location;
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const [isInstaLearnVisible, setIsInstaLearnVisible] = useState(false);

  useEffect(() => {
    if (
      !pathname.includes('ask-a-doubt') &&
      !pathname.includes('tutor-on-demand') &&
      (isDoubtsOnChatEnabledValue || isInstaLearnEnabledValue)
    ) {
      setIsInstaLearnVisible(true);
    } else {
      setIsInstaLearnVisible(false);
    }
  }, [pathname, isDoubtsOnChatEnabledValue, isInstaLearnEnabledValue]);

  useEffect(() => {
    if (
      pathname.includes('ask-a-doubt') ||
      pathname.includes('tutor-on-demand')
    ) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      });
    }
  }, [pathname]);

  useEffect(() => {
    children?.props?.children?.forEach(
      (route: {
        props?: { path: string; title?: string; content?: string };
      }) => {
        if (route?.props?.path === pathname) {
          setTitle(route?.props?.title || '');
          setContent(route?.props?.content || '');
        }
      },
    );
  }, [pathname]);

  useEffect(() => {
    const loginSource = localStorage.getItem('LoginSource');
    if (loginSource) {
      const loginSourceData = safeJsonParse(
        (o: Record<string, string>): o is { source: string } => {
          return 'source' in o;
        },
      )(loginSource);
      if (!loginSourceData.hasError) {
        const sourceApp = loginSourceData.parsed.source;
        if ([AppConfig.androidApp2, AppConfig.iosApp2].includes(sourceApp)) {
          setApp2WebView(true);
        }
        if (sourceApp === 'd4-app') {
          setd4View(true);
        }
      }
    }
  }, []);

  useSelfServe(); // selfServe

  return (
    <>
      <MetaDecorator title={title} content={content} />
      {!isApp2Webview && (
        <Suspense fallback={loading()}>
          <Topbar
            isMenuOpened={isMenuOpened}
            setIsMenuOpened={setIsMenuOpened}
            setHamburgerRef={setHamburgerRef}
            navCssClasses="topnav-navbar topnav-navbar-light"
            topbarDark={true}
          />
        </Suspense>
      )}
      <div>
        <div className="wrapper">
          <Suspense fallback={loading()}>
            <LeftSidebar
              isMenuOpened={isMenuOpened}
              setIsMenuOpened={setIsMenuOpened}
              hamburgerRef={hamburgerRef}
              showSideBar={!isApp2Webview}
            />
          </Suspense>

          <div
            className={`${'content-page'} ${
              isApp2Webview ? 'content-app2-webview' : ''
            }`}
          >
            <div className="content">
              {!hideHelpCenterCTA &&
                toggleSelfServe &&
                selfServeAuthenticated &&
                !isApp2Webview && (
                  <Suspense fallback={loading()}>
                    <SelfServeWidget
                      ongoingTutorSession={ongoingTutorSession}
                    />
                  </Suspense>
                )}
              <Suspense fallback={loading()}>{children}</Suspense>
            </div>
            <Suspense fallback={loading()}>
              <ToastComponent />
            </Suspense>

            {/* Do not show instalearn for ask a doubt page and for free user */}
            {isInstaLearnVisible && !showAskTutorpopup && !isApp2Webview ? (
              <Suspense fallback={false}>
                <InstaLearn />
              </Suspense>
            ) : null}
            {!isApp2Webview &&
            !pathname.includes('ask-a-doubt') &&
            !pathname.includes('tutor-on-demand') &&
            showAskTutorpopup ? (
              <Asktutor {...getAskTutorProps()} />
            ) : null}

            {loginErrorState.isError === false && (
              <>
                {togglePasscodeFlag &&
                  showPasscodeToast &&
                  !showSessionExpiry &&
                  !isApp2Webview &&
                  !isd4View &&
                  isPasscodeToastEnable && <PasscodeToast show={true} />}
                {togglePasscodeFlag && showSessionExpiry && (
                  <SessionExpired show={true} />
                )}
              </>
            )}
          </div>
          {!isApp2Webview && (
            <Suspense fallback={loading()}>
              <Footer />
            </Suspense>
          )}
        </div>
      </div>
    </>
  );
};

export default DetachedLayout;
