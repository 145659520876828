const toggleTopStrip = false;
const topStripDownTextFlag = false;
const toggleTodIdsFlag = true;
const toggleTouchPointsForTod = toggleTodIdsFlag && true; // dependent on TOD
const toggleVwoTrack = true;
const toggleQTResults = false;
const togglePasscodeFlag = true;
const toggleAddHomeScreenButtonOnIDSPageFlag = true;
const toggleSelfServe = true;
const toggleTodInIds = false;
const toggleGradeSwitchDashboard = true;
const toggleBTLPGradeSwitchBanner = true;
const toggleSelfServeBatchSwitch = true;
const toggleBHT = true;
const toggleSDUI = true;
const toggleQuizSDK = true;
const toggleAskaDoubt = true;

export {
  toggleTopStrip,
  topStripDownTextFlag,
  toggleTodIdsFlag,
  toggleTouchPointsForTod,
  toggleVwoTrack,
  toggleQTResults,
  togglePasscodeFlag,
  toggleAddHomeScreenButtonOnIDSPageFlag,
  toggleTodInIds,
  toggleSelfServe,
  toggleGradeSwitchDashboard,
  toggleSelfServeBatchSwitch,
  toggleBHT,
  toggleBTLPGradeSwitchBanner,
  toggleSDUI,
  toggleQuizSDK,
  toggleAskaDoubt
};
