import AppConfig from 'config/AppConfig';
import { APICore, handleResourceNotFoundStatus } from 'helpers/api/apiCore';
import { ICheckBhtEligibilityRes } from './ibht';

const appConfig = AppConfig as {
  tutorApiBaseUrl: string;
};

const checkBhtEligibility = async (cohortId: number): Promise<boolean> => {
  try {
    const api = new APICore();
    const checkBhtEligibilityRes: ICheckBhtEligibilityRes = await api.get(
      `${appConfig.tutorApiBaseUrl}/ttplus_web_v2/web/bht/v1/eligibility`,
      { cohort_id: cohortId },
      false,
      { validateStatus: handleResourceNotFoundStatus },
    );
    const formattedCheckEligibility =
      checkBhtEligibilityRes.data.bht_subscription_active;
    return formattedCheckEligibility;
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    console.error(`Error checking Eligibility ${error}`);
    return false;
  }
};

export default checkBhtEligibility;
