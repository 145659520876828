/* eslint-disable check-file/filename-naming-convention */
import { atom, selector } from 'recoil';
import { persistToLocalStorage } from 'helpers/persistence';
import moment from 'moment';

export enum Actors {
  SYSTEM = 'system',
  USER = 'user',
}

enum Identifiers {
  PHONE = 'phone',
  TOKEN = 'token',
}
/**
 * RESET is considered by default if use case is not specified
 */
export enum UpdatePasscodeUseCases {
  RESET = 'reset_passcode',
  SETUP = 'setting_up_passcode',
}

export interface UpdatePasscodeRequest {
  authToken: string;
  identity: string;
  passcode: string;
  useCase: UpdatePasscodeUseCases;
}

export interface PasscodePolicyResponse {
  isEligible?: boolean;
  isPasscodeSet: boolean;
  lastUpdatedBy: Actors;
  phone: string;
  email: string;
  expiryDate: string;
  setPasscodeByDate?: string;
}

export interface PasscodePolicyRequest {
  identifier: Identifiers;
  value: string;
}

export interface AuthInitiateResponse {
  nonce: string;
}

export const PasscodeState = atom<null | PasscodePolicyResponse>({
  key: 'Passcode',
  default: null,
  effects_UNSTABLE: [persistToLocalStorage],
});

export const PasscodeEligibilityState = atom<{
  isRolloutPlanUseCase: boolean;
  isEligible: boolean;
  setPasscodeByDate?: string;
}>({
  key: 'PasscodeEligibility',
  default: { isRolloutPlanUseCase: false, isEligible: false },
  effects_UNSTABLE: [persistToLocalStorage],
});

export const IsForcedCreatePasscodeFlow = atom<boolean>({
  key: 'IsForcedCreatePasscodeFlow',
  default: false,
  effects_UNSTABLE: [persistToLocalStorage],
});

export const IsPasscodeMandatedFlow = selector({
  key: 'IsPasscodeMandatedFlow',
  get: ({ get }) => {
    const passcodeEligibilityData = get(PasscodeEligibilityState);
    if (
      passcodeEligibilityData.isRolloutPlanUseCase &&
      passcodeEligibilityData?.setPasscodeByDate
    ) {
      const expiryTime = moment(
        passcodeEligibilityData?.setPasscodeByDate,
        'YYYY-MM-DD HH:mm:ss Z [UTC]',
      );
      return expiryTime.diff(moment()) <= 0;
    }
    return false;
  },
});

export const IsPasscodePolicyFlow = selector({
  key: 'IsPasscodePolicyFlow',
  get: ({ get }) => {
    const passcodeData = get(PasscodeState);
    return passcodeData?.isPasscodeSet;
  },
});

export const SetNewPasscodeReminderData = atom<{
  displayedDuringLogin: boolean;
  hasUserOptedToSetItUpLater: boolean;
  triggeredResetFromToast: boolean;
  hasUserDismissedToastMessage: boolean;
  triggeredCreatePasscodeAfterExpiry: boolean;
  triggeredResetFromProfile: boolean;
}>({
  key: 'SetNewPasscodeReminderData',
  default: {
    displayedDuringLogin: false,
    hasUserOptedToSetItUpLater: false,
    triggeredResetFromToast: false,
    hasUserDismissedToastMessage: false,
    triggeredCreatePasscodeAfterExpiry: false,
    triggeredResetFromProfile: false,
  },
  effects_UNSTABLE: [persistToLocalStorage],
});

export const IsSetNewPasscodeFlow = selector({
  key: 'IsSetNewPasscodeFlow',
  get: ({ get }) => {
    const passcodeData = get(PasscodeState);
    const isPasscodeFlow = get(IsPasscodePolicyFlow);
    const reminderData = get(SetNewPasscodeReminderData);
    if (isPasscodeFlow && passcodeData?.lastUpdatedBy === Actors.SYSTEM) {
      return !reminderData.hasUserOptedToSetItUpLater;
    }
    return false;
  },
});

export const ShowReminderAfterLogin = selector({
  key: 'ShowReminderAfterLogin',
  get: ({ get }) => {
    const passcodeData = get(PasscodeState);
    const isPasscodeFlow = get(IsPasscodePolicyFlow);
    const reminderData = get(SetNewPasscodeReminderData);
    if (isPasscodeFlow && passcodeData?.lastUpdatedBy === Actors.SYSTEM) {
      return !reminderData.hasUserDismissedToastMessage;
    }
    return false;
  },
});

export const IsForgotPasscodeState = atom<boolean>({
  key: 'IsForgotPasscode',
  default: false,
  effects_UNSTABLE: [persistToLocalStorage],
});

export const IsTokenBasedLogin = atom<boolean>({
  key: 'IsTokenBasedLogin',
  default: false,
  effects_UNSTABLE: [persistToLocalStorage],
});

export const IsChangePasscodeState = atom<boolean>({
  key: 'IsChangePasscode',
  default: false,
  effects_UNSTABLE: [persistToLocalStorage],
});

export const IsAlternateLoginState = atom<boolean>({
  key: 'IsAlternateLogin',
  default: false,
  effects_UNSTABLE: [persistToLocalStorage],
});

export default PasscodeState;
