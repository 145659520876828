/* eslint-disable check-file/filename-naming-convention */
/* eslint-disable import/prefer-default-export */

import { atom } from 'recoil';

import { persistToLocalStorage } from 'helpers/persistence';

interface ISessionState {
  tnlToken: string;
  userId: string;
}
export const SessionState = atom<ISessionState | null>({
  key: 'SessionState',
  default: null,
  effects_UNSTABLE: [persistToLocalStorage],
});
