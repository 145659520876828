/* eslint-disable @typescript-eslint/restrict-template-expressions */
import classNames from 'classnames';
import MetaDecorator from 'components/MetaDecorator';
import { Suspense, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import lazyWithRetry from 'utils/LazyWithRetry';

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = lazyWithRetry(
  () => import(/* webpackChunkName: "ComponentTopBar" */ './Topbar'),
  'ComponentTopBar',
);

const Footer = lazyWithRetry(
  () => import(/* webpackChunkName: "ComponentLPFooter" */ './LPFooter'),
  'ComponentLPFooter',
);

const loading = () => <div className="text-center" />;

type IRoute = {
  props?: { path: string; title?: string; content?: string };
};
type VerticalLayoutProps = {
  children?: {
    props?: {
      children?: [];
    };
  };
};

const TestLayout = ({ children }: VerticalLayoutProps) => {
  const location = useLocation();
  const { pathname } = location;
  const isQuiz = pathname?.includes('quiz');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  useEffect(() => {
    children?.props?.children?.forEach((route: IRoute) => {
      let rPath = route?.props?.path || '';
      rPath = rPath?.substring(0, rPath?.lastIndexOf('/'));
      const browserPath =
        pathname?.substring(0, pathname?.lastIndexOf('/')) || '';
      if (rPath === browserPath) {
        setTitle(route?.props?.title || '');
        setContent(route?.props?.content || '');
      }
    });
  }, [pathname]);

  return (
    <>
      <MetaDecorator title={title} content={content} />
      {!isQuiz && (
        <Suspense fallback={loading()}>
          <Topbar
            navCssClasses="topnav-navbar topnav-navbar-light"
            testLayout={true}
          />
        </Suspense>
      )}
      <div>
        <div className={classNames('wrapper', { 'wquiz-wrapper': isQuiz })}>
          <div
            className={`content-page test-content-page ${isQuiz && 'p-0 mt-0'}`}
          >
            <div className="content">
              <Suspense fallback={loading()}>{children}</Suspense>
            </div>
          </div>
          {!isQuiz && (
            <Suspense fallback={loading()}>
              <Footer fullWidth={true} />
            </Suspense>
          )}
        </div>
      </div>
    </>
  );
};

export default TestLayout;
