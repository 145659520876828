/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { toggleSelfServe } from 'config/toggleFeature';
import setUserDetails from 'helpers/api/selfServe';

import { ILocalData } from 'interfaces/classes';
import { AppConfig } from 'config';
import { ISelfServe } from 'interfaces';
import { useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';

import {
  isSelfServeOpen,
  isSelfServeAuthenticated,
} from 'states/SelfServeState';
import { CohortSettingsState } from 'states/CohortState';
import { NeoEligibilityInterface } from 'interfaces/subjects';
import haptikSdkEventListener from './useHaptikEventListener';
import { getUserFromSession } from '../../helpers/api/apiCore';

const useSelfServe = () => {
  if (!getUserFromSession()) {
    return;
  }
  if (!toggleSelfServe) return;
  const retrievedObject = localStorage.getItem('learn_portal_user');

  const localData: ILocalData = JSON.parse(retrievedObject) as Record<
    string,
    unknown
  >;
  const premiumId: string | undefined = retrievedObject
    ? localData?.premium_account_id
    : '';

  const [, setIsSelfServeOpen] = useRecoilState(isSelfServeOpen);
  const [, setIsSelfServeAuthenticated] = useRecoilState(
    isSelfServeAuthenticated,
  );

  const cohortEligibility: NeoEligibilityInterface | null =
    useRecoilValue(CohortSettingsState);

  const HapticInit = (data: ISelfServe) => {
    const closeChatCallback = () => {
      setIsSelfServeOpen(false);
    };

    window.haptikInitSettings = {
      'business-id': `${AppConfig.selfServeBusinessId}`,
      'client-id': `${AppConfig.selfServeClientId}`,
      'base-url': `${AppConfig.selfHeptikBaseUrl}`,
      'bot-prompt': {
        timeout: 90,
        message: '',
      },
      'widget-positioning': 'bottom-right',
      'custom-button': true,
      'custom-css': `${AppConfig.cdn}/css/help-center/custom-style.css`,
      onChatWindowHide: closeChatCallback,
    };
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src =
      'https://toolassets.haptikapi.com/platform/javascript-xdk/production/loader.js';
    script.crossOrigin = 'anonymous';

    document.body.appendChild(script);
    document.addEventListener('haptik_sdk', () => haptikSdkEventListener(data));
  };

  const handleUserDetails = async (premiumAccountId: string) => {
    const selfServeApiData = sessionStorage.getItem('selfServeApiData');
    if (selfServeApiData !== null) {
      const res = JSON.parse(selfServeApiData) as ISelfServe;
      HapticInit(res.data.data);
      setIsSelfServeAuthenticated(true);
      return;
    }

    await setUserDetails(premiumAccountId)
      .then((res: ISelfServe) => {
        if (res.status !== 200) {
          console.error('Error Other then 200 status code', res);
          return;
        }
        const frameEle = document.querySelector(
          '#onboarding-iframe',
        ) as HTMLIFrameElement;
        if (frameEle && frameEle.contentWindow) {
          frameEle.contentWindow.postMessage(
            {
              type: 'SELF_SERVE_ELIGIBILITY',
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              isEligible: res.data.data.eligibility,
            },
            '*',
          );
        }
        // if (!res.data?.data?.eligibility) {
        //   console.error(
        //     'Not initiating Haptic Service due to eligibility is false in API response.',
        //     res,
        //   );
        //   return;
        // }
        HapticInit(res.data.data);
        sessionStorage.setItem('selfServeApiData', JSON.stringify(res));
        setIsSelfServeAuthenticated(true);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error('Error Response', e);
        setIsSelfServeAuthenticated(false);
      });
  };

  useEffect(() => {
    if (premiumId) {
      handleUserDetails(premiumId)
        .then(() => {})
        .catch(() => {});
    }
  }, [premiumId]);
};
export default useSelfServe;
