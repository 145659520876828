/* eslint-disable camelcase */
import { atom } from 'recoil';
import { ILeaveReason, ILeaveEligibility } from 'interfaces/leaveManagement';

const LeaveReasonsState = atom<ILeaveReason>({
  key: 'leave_reasons',
  default: {} as ILeaveReason,
});

const LeaveEligibilityState = atom<ILeaveEligibility>({
  key: 'leave_eligibility',
  default: { leave_eligibility: false } as ILeaveEligibility,
});

export { LeaveReasonsState, LeaveEligibilityState };
