import { AppConfig } from 'config';
import {
  IAssessResponse,
  IAssessmentDetails,
  IPrePostRequisites,
  IResult2,
  ISession,
} from 'interfaces';
import { ILocalData } from 'interfaces/classes';
import {
  olderSubjectiveAssessmentTimestamp,
  schoolId,
  academicYear,
} from 'modules/byjusclasses/components/utils/constants';
import { getSubjectiveAssessmentResultDetails } from '../byjusClasses';

const isNewSubjectiveAssessment = (sessionDetails: ISession): boolean => {
  if (
    sessionDetails?.courseTag === 'classroom' &&
    sessionDetails?.batchType === 'regular' &&
    +sessionDetails.endTime >= olderSubjectiveAssessmentTimestamp
  ) {
    return true;
  }
  return false;
};

const getSubjectiveAssessmentIdsFromRequisites = (
  requisites: IPrePostRequisites[],
  subjAssesments: [],
  session: ISession,
  premiumAccountId: number | string,
): IAssessmentDetails => {
  if (Array.isArray(requisites)) {
    requisites.map((requisite: IPrePostRequisites) => {
      const { assessmentDetails = null } = requisite;
      if (assessmentDetails && assessmentDetails?.isSubjectiveAssessment) {
        if (isNewSubjectiveAssessment(session)) {
          const subjAssessDetail = {
            tllmsAssessmentId: assessmentDetails.assessmentId,
            studentIdentifier: premiumAccountId,
            board: session.syllabus,
            grade: session.grade,
            subject: session.subjectName,
            section: session.subBatchId,
            schoolId,
            academicYear,
          };
          subjAssesments.push(subjAssessDetail);
        }
      }
      return '';
    });
  }
  return subjAssesments;
};

const getSubjectiveAssessmentIds = (
  sessions: ISession[],
  premiumAccountId: string | number,
): Array<IAssessmentDetails> => {
  let subjAssesmentsIds: IAssessmentDetails[] = [];
  if (sessions && sessions.length > 0) {
    sessions.map((session: ISession) => {
      const { postRequisites } = session;
      if (postRequisites.length > 0) {
        subjAssesmentsIds = getSubjectiveAssessmentIdsFromRequisites(
          postRequisites,
          subjAssesmentsIds,
          session,
          premiumAccountId,
        );
      }
      return '';
    });
  }
  return subjAssesmentsIds;
};

const getSubjectiveAssessmentStatusText = (
  assignmentDetails: IAssessmentDetails,
) => {
  let assignmentStatusDetails: { statustext: string; btnText: string } = {};
  switch (assignmentDetails.status) {
    case 'YET_TO_START':
      assignmentStatusDetails = {
        statustext: 'Not started',
        btnText: 'Start Homework',
      };
      break;
    case 'MISSED_ATTEMPTING':
      assignmentStatusDetails = {
        statustext: 'Deadline Passed',
        btnText: 'View',
      };
      break;
    case 'COMPLETED':
      assignmentStatusDetails = {
        statustext: 'Completed',
        btnText: 'Edit Homework',
      };
      break;
    case 'SUBMITTED_FOR_EVALUATION':
      assignmentStatusDetails = {
        statustext: 'Submitted - awaiting evaluation',
        btnText: 'View Answers',
      };
      break;
    case 'RESULTS_AVAILABLE':
      assignmentStatusDetails = {
        statustext: 'Result Published',
        btnText: 'View Result',
      };
      break;
    case 'INCOMPLETE':
      assignmentStatusDetails = {
        statustext: 'Partially completed',
        btnText: 'Resume Homework',
      };
      break;
    default: {
      assignmentStatusDetails = {
        statustext: 'Not started',
        btnText: 'Start Homework',
      };
    }
  }
  return assignmentStatusDetails;
};

const appendSubjAssessmentStatus = (
  requisites: IPrePostRequisites[],
  assessmentsMap: IResult2,
  subBatchId: number,
  session: ISession,
): IPrePostRequisites => {
  return requisites.map((requisite: IPrePostRequisites) => {
    const requisitesData = requisite;
    const { assessmentDetails = null, assetsType = null } = requisite;
    if (assetsType === 'Assessment') {
      const assessKey = `${assessmentDetails?.assessmentId || 0}${subBatchId}`;
      if (assessmentDetails) {
        if (assessmentsMap[assessKey]) {
          if (isNewSubjectiveAssessment(session)) {
            requisitesData.assessmentDetails.status =
              assessmentsMap[assessKey]?.status || null;
            requisitesData.assessmentDetails.marks =
              assessmentsMap[assessKey]?.marks ?? 0;
            requisitesData.assessmentDetails.answerId =
              assessmentsMap[assessKey]?.answerId || null;
            requisitesData.assessmentDetails.availableUntil =
              assessmentsMap[assessKey]?.availableUntil || null;
            requisitesData.assessmentDetails.isError =
              assessmentsMap[assessKey]?.isError || false;
            requisitesData.assessmentDetails.message =
              assessmentsMap[assessKey]?.message || null;
            requisitesData.assessmentDetails.totalMarks =
              assessmentsMap[assessKey]?.totalMarks ?? 0;
            requisitesData.assessmentDetails.isNewerAssessment = true;
            requisitesData.statusText = getSubjectiveAssessmentStatusText(
              requisitesData?.assessmentDetails,
            ).statustext;
            requisitesData.btnText = getSubjectiveAssessmentStatusText(
              requisitesData?.assessmentDetails,
            ).btnText;
            requisitesData.isSubjectiveAssignments = true;
          } else {
            requisitesData.assessmentDetails.isNewerAssessment = false;
            requisitesData.isSubjectiveAssignments = false;
          }
        }
      }
    }
    return requisitesData;
  });
};

const addSubjectiveAssessmentStatusToSessions = (
  sessions: ISession[],
  assessmentsResponse: IAssessResponse,
): Array<ISession> => {
  const sessionsData = sessions;
  if (!assessmentsResponse) {
    return sessions;
  }

  const isErrorStatus = assessmentsResponse.data.status.isError;
  const isResult = assessmentsResponse.data.result;

  const isError: boolean = isErrorStatus;

  if (isError) {
    return sessionsData;
  }
  const assessments: IAssessmentDetails[] = isResult;
  const assessmentsMap = {};

  assessments.map((asess: IAssessmentDetails) => {
    assessmentsMap[`${asess?.tllmsAssessmentId || 0}${asess?.section || 0}`] =
      asess;
    return '';
  });
  if (sessions && sessions.length > 0) {
    sessions.map((session: ISession, index: number) => {
      let { postRequisites } = session;
      const { subBatchId } = session;
      if (postRequisites.length > 0 && subBatchId) {
        postRequisites = appendSubjAssessmentStatus(
          postRequisites,
          assessmentsMap,
          subBatchId,
          session,
        );
        sessionsData[index].postRequisites = postRequisites;
      }
      return '';
    });
  }
  return sessionsData;
};

const checkForSubjectiveAssessment = async (
  sessions: Array<ISession>,
  sessionId: string | number,
): ISession | ISession[] => {
  const retrievedObject = localStorage.getItem('learn_portal_user');
  const localData: ILocalData = JSON.parse(retrievedObject as string) as Record<
    string,
    unknown
  >;
  if (sessions.length > 0) {
    let premiumAccountId: string;
    if (retrievedObject) {
      premiumAccountId = localData.premium_account_id;
    }
    const subjAssessmentsIdsdetails = getSubjectiveAssessmentIds(
      sessions,
      premiumAccountId,
    );
    if (
      subjAssessmentsIdsdetails.length > 0 &&
      premiumAccountId &&
      AppConfig.allowSubjective
    ) {
      const uniqueSubjAssessmentsIdsdetails = _.uniqBy(
        subjAssessmentsIdsdetails,
        (assessValue: IAssessmentDetails) =>
          JSON.stringify([assessValue.tllmsAssessmentId, assessValue.section]),
      );
      const assessmentsResponse = await getSubjectiveAssessmentResultDetails(
        uniqueSubjAssessmentsIdsdetails,
      );
      const sesions: ISession[] = addSubjectiveAssessmentStatusToSessions(
        sessions,
        assessmentsResponse,
      );
      if (!sessionId) {
        return sesions;
      }
      const sesion: ISession = sesions.find(
        e => e.id.toString() === sessionId.toString(),
      );
      return sesion;
    }
    if (!sessionId) {
      return sessions;
    }
    const sesion: ISession = sessions.find(
      e => e.id.toString() === sessionId.toString(),
    );
    return sesion;
  }
};

export default checkForSubjectiveAssessment;
