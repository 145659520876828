import axios from 'axios';
// import { errorInterceptor } from './errorInterceptor';
import { requestInterceptor } from './requestInterceptor';

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
axios.defaults.headers.post['Content-Type'] = 'application/json';

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
axios.interceptors.request.use(requestInterceptor);

// Causing Duplication(Response is empty)
// axios.interceptors.response.use(response => response, errorInterceptor);

export default axios;
