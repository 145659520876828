import topicIcons from 'constants/topicIcons';
import { COURSETAGMAP } from 'modules/byjusclasses/components/utils/constants';

const fetchFreeSessionsApiResponseTransform = ({ data }: any) => {
  const toepoch = (number: string) => new Date(number).getTime() / 1000;
  const objectToArray = (inputObject: {
    [x: string]: { [x: string]: any };
  }) => {
    const outputArray = [];
    for (const size in inputObject.web) {
      if (inputObject.web.hasOwnProperty(size)) {
        const url = inputObject.web[size];
        outputArray.push({ size, url });
      }
    }
    return outputArray;
  };

  const transformedData = data.map((item: any) => {
    return {
      course_id: Number(item.courseId), // not coming as part of api
      batch_id: item.batchId,
      batch_type: item.type,
      courses_topic_id: item.topicId,
      course_tag: COURSETAGMAP[item.courseTag],
      course_type: item?.courseType?.toLowerCase(), // not coming as part of api
      image_url: item.imageUrl,
      video_teaser_url: item.videoTeaserUrl,
      // slot_group_id: 19904, // not required
      slot_id: item.id, // not coming as part of api
      start_time: toepoch(item.classStartTime),
      end_time: toepoch(item.classEndTime),
      subject_name: item.subjectName,
      chapter_name: item.chapterName,
      topic_id: item.topicId,
      grade: item.grade,
      topic_name: item.topicName,
      description: item.description,
      topic_icons: objectToArray(topicIcons[item.subjectName]),
      available_slots: item.availableSlots.map((slot: any) => {
        return {
          course_id: Number(slot.courseId),
          courses_topic_id: slot.topicId,
          slot_id: slot.id,
          start_time: toepoch(slot.classStartTime),
          end_time: toepoch(slot.classEndTime),
        };
      }),
    };
  });
  return { data: { sessions: [...transformedData] } };
};

export default fetchFreeSessionsApiResponseTransform;
