/* eslint-disable check-file/filename-naming-convention */
import { atom } from 'recoil';

export const DoesSelfServeWidgetNeedDifferentStyle = atom<boolean>({
  key: 'DoesSelfServeWidgetNeedDifferentStyle',
  default: false,
});

export const isSelfServeOpen = atom<boolean>({
  key: 'isSelfServeOpen',
  default: false,
});

export const isSelfServeAuthenticated = atom<boolean>({
  key: 'isSelfServeAuthenticated',
  default: false,
});
