import AppConfig from 'config/AppConfig';

const appConfig = AppConfig as {
  visibleMenuItems: string;
};

const VISIBLE_MENU = appConfig.visibleMenuItems?.split(',') || [];
function isMenuItemVisible(key: string) {
  return VISIBLE_MENU.length === 0 || VISIBLE_MENU.indexOf(key) !== -1;
}

const MENU_ITEMS = [
  {
    key: 'home',
    isVisible: isMenuItemVisible('home'),
    url: '/home',
    label: 'Home',
    icon: 'menu-icons/home.png',
    iconHover: 'menu-icons/home-hover.png',
    olap_event_id: 9201046,
  },
  {
    key: 'aakash-dashboard',
    isVisible: isMenuItemVisible('aakash-dashboard'),
    url: '/aakash-class/dashboard',
    criteria: 'is_aakash_live_enabled',
    icon: 'menu-icons/aakash_live_classes.png',
    iconHover: 'menu-icons/aakash_live_classes-hover.png',
    aakash_event_id: 9200181,
    counter: 'select_aakash_classes',
    kingdom: 'aakash_classes,',
  },
  {
    key: 'byjus-classes-dashboard',
    isVisible: isMenuItemVisible('byjus-classes-dashboard'),
    url: '/byjus-classes',
    label: "BYJU'S Classes",
    criteria: 'is_one_to_mega_sessions_enabled',
    newTag: true,
    icon: 'menu-icons/byjus_class.png',
    iconHover: 'menu-icons/byjus_class-hover.png',
    classes_event_id: 9200134,
    counter: 'click_byjus_classes',
    kingdom: 'homepage',
    m_desc: 'click on byjus classes in burger menu',
    m_comments: 'Classes',
  },
  {
    key: 'exam-prep-corner',
    isVisible: isMenuItemVisible('exam-prep-corner'),
    url: '/exam-prep-corner',
    criteria: 'exam-prep-corner',
    label: 'Exam Prep Corner',
    icon: 'menu-icons/exam-prep-corner.png',
    iconHover: 'menu-icons/exam-prep-corner-hover.png',
    olap_event_id: 6500635,
    isNewBadge: true,
  },
  {
    key: 'byjus-tuition-center',
    isVisible: isMenuItemVisible('byjus-tuition-center'),
    url: '/byjus-tuition-centre',
    criteria: 'is_btc_free_user',
    label: "BYJU'S Tuition Centre",
    icon: 'menu-icons/btc-icon.png',
    iconHover: 'menu-icons/btc-icon-hover.png',
  },
  {
    key: 'byjus-test-series',
    isVisible: isMenuItemVisible('byjus-test-series'),
    url: '/byjus-test-series',
    label: "BYJU'S Test Series",
    criteria: 'is_byjus_test_series_enabled',
    icon: 'menu-icons/bts.png',
    iconHover: 'menu-icons/bts-icon-hover.png',
    olap_event_id: 9200042,
    counter: 'click_burger_test_series',
    m_desc: 'click on test_series on the burger menu',
    m_comments: 'test_series',
    m_category: 'burger menu',
  },
  {
    key: 'byjus-home-tuitions',
    isVisible: isMenuItemVisible('byjus-home-tuitions'),
    url: '/byjus-home-tuitions',
    label: "BYJU'S Home Tuitions",
    criteria: 'is_bht_tab_enabled',
    icon: 'menu-icons/bht-icon.png',
    iconHover: 'menu-icons/bht-icon-hover.png',
  },
  {
    key: 'mock-test',
    isVisible: isMenuItemVisible('mock-test'),
    url: '/mock-tests',
    label: 'Mock Test',
    criteria: 'mock_tests_web_enabled',
    icon: 'menu-icons/mock_tests.png',
    iconHover: 'menu-icons/mock_tests-hover.png',
    olap_event_id: 9200052,
    m_comments_mock: 'Mock AITS',
    m_category_mock: 'burger menu',
    m_desc_mock: 'click on mock aits on the burger menu',
    children: [
      {
        key: 'jee-advanced-mock-test',
        url: '/mock-tests/jee-advanced',
        label: 'JEE Advanced',
        criteria: 'mock_tests_web_enabled',
        parentKey: 'mock-test',
        isVisible: true,
        olap_event_id: 9200052,
        m_comments_mock: 'Mock AITS',
        m_category_mock: 'burger menu',
        m_desc_mock: 'click on mock aits on the burger menu',
      },
      {
        key: 'jee-main-mock-test',
        url: '/mock-tests/jee-mains',
        label: 'JEE Main',
        criteria: 'mock_tests_web_enabled',
        parentKey: 'mock-test',
        isVisible: true,
        olap_event_id: 9200052,
        m_comments_mock: 'Mock AITS',
        m_category_mock: 'burger menu',
        m_desc_mock: 'click on mock aits on the burger menu',
      },
      {
        key: 'neet-mock-test',
        url: '/mock-tests/neet',
        label: 'NEET',
        criteria: 'mock_tests_web_enabled',
        parentKey: 'mock-test',
        isVisible: true,
        olap_event_id: 9200052,
        m_comments_mock: 'Mock AITS',
        m_category_mock: 'burger menu',
        m_desc_mock: 'click on mock aits on the burger menu',
      },
    ],
  },
  {
    key: 'aits',
    isVisible: isMenuItemVisible('aits'),
    url: '/aits',
    label: 'All India Test Series (AITS)',
    criteria: 'mock_tests_web_enabled',
    icon: 'menu-icons/all_india_test_series.png',
    iconHover: 'menu-icons/all_india_test_series-hover.png',
    olap_event_id: 9200042,
    m_comments_aits: 'AITS',
    m_category_aits: 'burger menu',
    m_desc_aits: 'click on aits on the burger menu',
    children: [
      {
        key: 'jee-advanced-aits',
        url: '/aits/jee-advanced',
        label: 'JEE Advanced',
        criteria: 'mock_tests_web_enabled',
        parentKey: 'aits',
        isVisible: true,
        olap_event_id: 9200042,
        m_comments_aits: 'AITS',
        m_category_aits: 'burger menu',
        m_desc_aits: 'click on aits on the burger menu',
      },
      {
        key: 'jee-main-aits',
        url: '/aits/jee-mains',
        label: 'JEE Main',
        criteria: 'mock_tests_web_enabled',
        parentKey: 'aits',
        isVisible: true,
        olap_event_id: 9200042,
        m_comments_aits: 'AITS',
        m_category_aits: 'burger menu',
        m_desc_aits: 'click on aits on the burger menu',
      },
      {
        key: 'neet-aits',
        url: '/aits/neet',
        label: 'NEET',
        criteria: 'mock_tests_web_enabled',
        parentKey: 'aits',
        isVisible: true,
        olap_event_id: 9200042,
        m_comments_aits: 'AITS',
        m_category_aits: 'burger menu',
        m_desc_aits: 'click on aits on the burger menu',
      },
    ],
  },
  {
    key: 'monthly-exam',
    isVisible: isMenuItemVisible('monthly-exam'),
    url: '/monthly-exam',
    label: 'Olympiad Mock Test', // #olamiadmocktest, Change me to 'Monthly Exam' once Olympiad test is over.
    criteria: 'is_exam_prep_enabled',
    icon: 'menu-icons/monthly_test.png',
    iconHover: 'menu-icons/monthly_exam_hover.png',
    olap_event_id: 9200042,
  },
  {
    key: 'apq-dashboard',
    isVisible: isMenuItemVisible('apq-dashboard'),
    url: '/adaptive-questions',
    label: 'Adaptive Practice Questions',
    criteria: 'is_web_practice_enabled',
    icon: 'menu-icons/apq.png',
    iconHover: 'menu-icons/apq-hover.png',
    olap_event_id: 9200700,
    m_category: 'Adaptive Practice Questions',
    m_des: 'APQs',
  },
  {
    key: 'cwt-dashboard',
    isVisible: isMenuItemVisible('cwt-dashboard'),
    url: '/chapter-wise-tests',
    label: 'Chapter wise test',
    criteria: 'is_web_test_enabled',
    icon: 'menu-icons/chapter_wise.png',
    iconHover: 'menu-icons/chapter_wise-hover.png',
    olap_event_id: 9200062,
    m_des: 'chapterwise test',
  },
  {
    key: 'aad-dashboard',
    isVisible: isMenuItemVisible('aad-dashboard'),
    url: '/ask-a-doubt',
    label: 'Ask a Doubt',
    criteria: 'is_doubts_enabled',
    icon: 'menu-icons/ask_a_doubt.png',
    iconHover: 'menu-icons/ask_a_doubt-hover.png',
    olap_event_id: 9100011,
  },
  {
    key: 'tod',
    isVisible: isMenuItemVisible('aad-dashboard'),
    url: '/tutor-on-demand',
    label: 'Tutor on Demand',
    criteria: 'is_doubts_enabled',
    icon: 'menu-icons/tod.png',
    iconHover: 'menu-icons/tod-hover.png',
    isNewBadge: true,
  },
  {
    key: 'downloads',
    isVisible: isMenuItemVisible('downloads'),
    url: '/downloads',
    label: 'Downloads',
    criteria: 'downloads_web_enabled',
    icon: 'menu-icons/downloads.png',
    iconHover: 'menu-icons/downloads-hover.png',
    olap_event_id: 9200103,
  },
  {
    key: 'concept-videos',
    isVisible: isMenuItemVisible('concept-videos'),
    url: '/concept-videos',
    label: 'Concept Videos',
    icon: 'menu-icons/concept_videos.png',
    iconHover: 'menu-icons/concept_videos-hover.png',
    olap_event_id: 9201045,
  },
  {
    key: 'bookmarks',
    isVisible: isMenuItemVisible('bookmarks'),
    url: '/all-bookmarks',
    label: 'Bookmarks',
    criteria: [
      'is_web_practice_enabled',
      'is_doubts_enabled',
      'revision_web_enabled',
      'is_web_test_enabled',
    ],
    icon: 'menu-icons/bookmarks.png',
    iconHover: 'menu-icons/bookmarks-hover.png',
    olap_event_id: 9201047,
    m_category: 'bookmark',
  },
  {
    key: 'all-subjects',
    isVisible: isMenuItemVisible('all-subjects'),
    url: '/all-subjects',
    criteria: 'revision_web_enabled',
    label: 'All Subjects',
    icon: 'menu-icons/all_subjects.png',
    iconHover: 'menu-icons/all_subjects-hover.png',
    olap_event_id: 9201048,
  },
  {
    key: 'leave-management',
    isVisible: isMenuItemVisible('leave-management'),
    url: '/leave-management',
    criteria: 'leave-management',
    label: 'Leave management',
    icon: 'menu-icons/leave-management.png',
    iconHover: 'menu-icons/leave-management-hover.png',
    olap_event_id: 6500565,
  }
];

export const SECONDARY_MENU_ITEMS = [
  {
    key: 'profile',
    isVisible: true,
    url: '/profile',
    label: 'My profile',
    icon: 'menu-icons/my_profile.png',
    iconHover: 'menu-icons/my_profile-hover.png',
  },
  {
    key: 'switch-profile',
    isVisible: false,
    url: '/switch-profile',
    label: 'Switch profile',
    icon: 'menu-icons/switch_profile.png',
    iconHover: 'menu-icons/switch_profile-hover.png',
  },
  {
    key: 'logout',
    isVisible: true,
    url: '/account/logout',
    label: 'Logout',
    icon: 'menu-icons/log_out.png',
    iconHover: 'menu-icons/log_out-hover.png',
  },
];

export default MENU_ITEMS;

/* const OLD_MENU_ITEMS = [
  { key: 'menu', label: 'Menu', isTitle: true },
  {
    key: 'home',
    label: 'Home',
    isTitle: false,
    icon: 'menu-icons/ .png',
iconHover: 'keyboard-icon.png') !== -1),
    url: '/home',
  },
  {
    key: 'all-subjects',
    label: 'All Subjects',
    isTitle: false,
    icon: 'menu-icons/ .png',
iconHover: 'keyboard-icon.png') !== -1),
    url: '/all-subjects',
  },
  {
    key: 'byjus-classes',
    label: "BYJU'S Classes",
    isTitle: false,
   icon: 'keyboard-icon.png',
iconHover: 'keyboard-icon.png') !== -1),
    url: '/byjus-classes',
  },
  {
    key: 'adaptive-questions',
    label: 'Adaptive Questions',
    isTitle: false,
    icon: 'menu-icons/ .png',
iconHover: 'keyboard-icon.png') !== -1),
    url: '/adaptive-questions',
  },
  {
    key: 'chapter-wise-tests',
    label: 'Chapter Wise Tests',
    isTitle: false,
    icon: 'menu-icons/ .png',
iconHover: 'keyboard-icon.png') !== -1),
    url: '/chapter-wise-tests',
  },
  {
    key: 'mock-tests',
    label: 'Mock Tests',
    isTitle: false,
    icon: 'menu-icons/ .png',
iconHover: 'keyboard-icon.png') !== -1),
    url: '/mock-tests',
    children: [
      {
        key: 'jee-mains',
        label: 'JEE Mains',
        url: '/mock-tests/jee-mains',
        parentKey: 'mock-tests',
      },
      {
        key: 'jee-advanced',
        label: 'JEE Advanced',
        url: '/mock-tests/jee-advanced',
        parentKey: 'mock-tests',
      },
      {
        key: 'neet',
        label: 'NEET',
        url: '/mock-tests/neet',
        parentKey: 'mock-tests',
      },
    ],
  },
  {
    key: 'aits',
    label: 'AITS',
    isTitle: false,
    icon: 'menu-icons/ .png',
iconHover: 'keyboard-icon.png') !== -1),
    url: '/aits',
    children: [
      {
        key: 'ds-analytics',
        label: 'JEE Mains',
        url: '/aits/jee-mains',
        parentKey: 'aits',
      },
      {
        key: 'ds-crm',
        label: 'JEE Advanced',
        url: '/aits/jee-advanced',
        parentKey: 'aits',
      },
      {
        key: 'ds-ecommerce',
        label: 'NEET',
        url: '/aits/neet',
        parentKey: 'aits',
      },
    ],
  },
  {
    key: 'daily-quiz',
    label: 'Daily Quiz',
    isTitle: false,
    icon: 'menu-icons/ .png',
iconHover: 'keyboard-icon.png') !== -1),
    url: '/daily-quiz',
  },
  {
    key: 'ask-a-doubt',
    label: 'Ask a Doubt',
    isTitle: false,
    icon: 'menu-icons/ .png',
iconHover: 'keyboard-icon.png') !== -1),
    url: '/ask-a-doubt',
  },
  {
    key: 'bookmarks',
    label: 'Bookmarks',
    isTitle: false,
    icon: 'menu-icons/ .png',
iconHover: 'keyboard-icon.png') !== -1),
    url: '/bookmarks',
  },
  {
    key: 'revision',
    label: 'Revision',
    isTitle: false,
    icon: 'menu-icons/ .png',
iconHover: 'keyboard-icon.png') !== -1),
    url: '/revision',
  },
  {
    key: 'downloads',
    label: 'Downloads',
    isTitle: false,
    icon: 'menu-icons/ .png',
iconHover: 'keyboard-icon.png') !== -1),
    url: '/downloads',
  },
  {
    key: 'byjus-corner',
    label: "BYJU's Corner",
    isTitle: false,
    icon: 'menu-icons/ .png',
iconHover: 'keyboard-icon.png') !== -1),
    url: '/byjus-corner',
  },
  {
    key: 'concept-videos',
    label: 'Concept Videos',
    isTitle: false,
    icon: 'menu-icons/ .png',
iconHover: 'keyboard-icon.png') !== -1),
    url: '/concept-videos',
  },
  {
    key: 'faqs',
    label: 'FAQs',
    isTitle: false,
    icon: 'menu-icons/ .png',
iconHover: 'keyboard-icon.png') !== -1),
    url: '/faqs',
  },
]; */
